import { type DateTimeUnit, DateTime } from 'luxon';
import { capitalize } from ':utils/common';

type EventDateTime = {
    start: DateTime;
    end: DateTime;
    isAllDay?: boolean;
    startDay: DateTime;
    /** in whole days */
    duration: number;
};

/**
 * This method, called once in the localizer constructor, is used by eventLevels
 * 'eventSegments()' to assist in determining the 'span' of the event in the display,
 * specifically when using a timezone that is greater than the browser native timezone.
 */
function browserTZOffset(): number {
    /**
     * Date.prototype.getTimezoneOffset horrifically flips the positive/negative from
     * what you see in it's string, so we have to jump through some hoops to get a value
     * we can actually compare.
     */
    const date = new Date();
    const neg = /-/.test(date.toString()) ? '-' : '';
    const dateOffset = date.getTimezoneOffset();
    const comparator = Number(`${neg}${Math.abs(dateOffset)}`);
    // ?mmnt correctly provides positive/negative offset, as expected
    const mtOffset = DateTime.local().offset;
    return mtOffset > comparator ? 1 : 0;
}

const segmentOffset = browserTZOffset();

function inRange(day: DateTime, min: DateTime, max: DateTime, unit?: DateTimeUnit): boolean {
    if (!unit)
        return +day >= +min && +day <= +max;

    const dayStart = startOf(day, unit);
    const minStart = startOf(min, unit);
    const maxStart = startOf(max, unit);

    return +dayStart >= +minStart && +dayStart <= +maxStart;
}

function lt(a: DateTime, b: DateTime, unit?: DateTimeUnit): boolean {
    return unit
        ? +startOf(a, unit) < +startOf(b, unit)
        : +a < +b;
}

function lte(a: DateTime, b: DateTime, unit?: DateTimeUnit): boolean {
    return unit
        ? +startOf(a, unit) <= +startOf(b, unit)
        : +a <= +b;
}

function gt(a: DateTime, b: DateTime, unit?: DateTimeUnit): boolean {
    return unit
        ? +startOf(a, unit) > +startOf(b, unit)
        : +a > +b;

}

function gte(a: DateTime, b: DateTime, unit?: DateTimeUnit): boolean {
    return unit
        ? +startOf(a, unit) >= +startOf(b, unit)
        : +a >= +b;
}

// TODO disallow null here and fix where it's problematic.
function eq(a: DateTime | null, b: DateTime | null, unit?: DateTimeUnit): boolean {
    if (!a || !b)
        return a === b;

    return unit
        ? +startOf(a, unit) === +startOf(b, unit)
        : +a === +b;
}

// TODO disallow null here and fix where it's problematic.
function neq(a: DateTime | null, b: DateTime | null, unit?: DateTimeUnit): boolean {
    if (!a || !b)
        return a !== b;

    return unit
        ? +startOf(a, unit) !== +startOf(b, unit)
        : +a !== +b;
}

// When unit === 'week', the firstOfWeek argument is required.
function startOf(date: DateTime, unit: DateTimeUnit): DateTime {
    // TODO different start of week
    return date.startOf(unit);
}

// When unit === 'week', the firstOfWeek argument is required.
function endOf(date: DateTime, unit: DateTimeUnit): DateTime {
    // TODO different start of week
    return date.endOf(unit);
}

function add(date: DateTime, num: number, unit: DateTimeUnit): DateTime {
    return date.plus({ [unit]: num });
}

function range(start: DateTime, end: DateTime, unit: DateTimeUnit = 'day'): DateTime[] {
    const days: DateTime[] = [];
    let current = start;
    while (lte(current, end)) {
        days.push(current);
        current = add(current, 1, unit);
    }

    return days;
}

function diff(a: DateTime, b: DateTime, unit: DateTimeUnit = 'day'): number {
    const diff = b.diff(a, unit, { conversionAccuracy: 'longterm' }).get(unit);
    return Math.floor(diff);
}

function ceil(date: DateTime, unit: DateTimeUnit = 'day'): DateTime {
    const floor = startOf(date, unit);
    return eq(floor, date) ? floor : add(floor, 1, unit);
}

function min(...dates: DateTime[]): DateTime {
    return DateTime.min(...dates);
}

function max(...dates: DateTime[]): DateTime {
    return DateTime.max(...dates);
}

function format(value: DateTime, format: string): string {
    return value.toFormat(format);
}

/** @deprecated Somehow, somewhere this is called with invalid inputs. Shame. */
function mergeUnsafe(date: DateTime, time: DateTime): DateTime | null {
    if (!date && !time)
        return null;

    return startOf(date, 'day').set({
        hour: time.hour,
        minute: time.minute,
        second: time.second,
        millisecond: time.millisecond,
    });
}

function merge(date: DateTime, time: DateTime): DateTime {
    return startOf(date, 'day').set({
        hour: time.hour,
        minute: time.minute,
        second: time.second,
        millisecond: time.millisecond,
    });
}

function firstVisibleDay(date: DateTime): DateTime {
    const startOfMonth = startOf(date, 'month');
    return startOf(startOfMonth, 'week');
}

function lastVisibleDay(date: DateTime): DateTime {
    const endOfMonth = endOf(date, 'month');
    return endOf(endOfMonth, 'week');
}

function visibleDays(date: DateTime): DateTime[] {
    return range(firstVisibleDay(date), lastVisibleDay(date), 'day');
}

function getSlotDate(date: DateTime, minutesFromMidnight: number, offset: number): DateTime {
    return startOf(date, 'day').set({ minute: minutesFromMidnight + offset });
}

function getTimezoneOffset(date: DateTime): number {
    return date.toJSDate().getTimezoneOffset();
}

function getDstOffset(start: DateTime, end: DateTime) {
    return getTimezoneOffset(start) - getTimezoneOffset(end);
}

function getTotalMin(start: DateTime, end: DateTime): number {
    return diff(start, end, 'minute');
}

function getMinutesFromMidnight(start: DateTime): number {
    const dayStart = startOf(start, 'day');
    return diff(dayStart, start, 'minute');
}

function continuesPrior(start: DateTime, first: DateTime): boolean {
    return lt(start, first);
}

function continuesAfter(start: DateTime, end: DateTime, last: DateTime): boolean {
    return gte(end, last);
}

function sortEvents(a: EventDateTime, b: EventDateTime): number {
    // const startSort = +startOf(a.start, 'day') - +startOf(b.start, 'day');
    const startSort = +a.startDay - +b.startDay;
    if (startSort !== 0)
        return startSort; // sort by start Day first

    // const aDurration = diff(a.start, ceil(a.end, 'day'), 'day');
    // const bDurration = diff(b.start, ceil(b.end, 'day'), 'day');

    return (
        // Math.max(bDurration, 1) - Math.max(aDurration, 1) || // events spanning multiple days go first
        b.duration - a.duration || // events spanning multiple days go first
        +!b.isAllDay - +!a.isAllDay || // then allDay single day events
        +a.start - +b.start || // then sort by start time
        +a.end - +b.end // then sort by end time
    );
}

/**
 * Use the fast option if range is aligned to the whole days and event is aligned to the minutes.
 */
function inEventRange(event: EventDateTime, range: DateRange, fast?: boolean): boolean {
    const startsBeforeEnd = fast
        ? +event.startDay <= +range.end
        : lte(event.startDay, range.end, 'day');
    if (!startsBeforeEnd)
        return false;

    if (fast) {
        const sameMin = neq(event.startDay, event.end);
        const endsAfterStart = sameMin
            ? gt(event.end, range.start)
            : gte(event.end, range.start);

        return endsAfterStart;
    }

    const sameMin = neq(event.startDay, event.end, 'minute');
    const endsAfterStart = sameMin
        ? gt(event.end, range.start, 'minute')
        : gte(event.end, range.start, 'minute');

    return endsAfterStart;
}

function inRangeDay(event: EventDateTime, startDay: DateTime, endDay: DateTime) {
    const range = { start: startDay, end: endDay };
    return inEventRange(event, range, true);
}

function isSameDate(a: DateTime, b: DateTime): boolean {
    return a.hasSame(b, 'day');
}

function startAndEndAreDateOnly(a: DateTime, b: DateTime): boolean {
    return a.hour === 0 && b.hour === 0
        && a.minute === 0 && b.minute === 0
        && a.second === 0 && b.second === 0
        && a.millisecond === 0 && b.millisecond === 0;
}

export type DateRange = {
    start: DateTime;
    end: DateTime;
};

type DateFormatFunction = (date: DateTime) => string;
type DateRangeFormatFunction = (range: DateRange) => string;
type DateFormat = string | DateFormatFunction;

function weekRangeFormat(range: DateRange): string {
    // If the years aren't equal, the months are not equal as well.
    // Example: December 2023 - January 2024
    if (!eq(range.start, range.end, 'year'))
        return capitalize(format(range.start, 'LLLL yyyy')) + ' - ' + capitalize(format(range.end, 'LLLL yyyy'));

    // If the months aren't equal but the year is.
    // Example: January - February 2024
    const firstPart = !eq(range.start, range.end, 'month')
        ? capitalize(format(range.start, 'LLLL - '))
        : '';

    // If the months are equal.
    // Example: January 2024
    return firstPart + capitalize(format(range.end, 'LLLL yyyy'));
}

function timeRangeFormat(range: DateRange): string {
    return range.start.toLocaleString(DateTime.TIME_SIMPLE)
        + ' – '
        + range.end.toLocaleString(DateTime.TIME_SIMPLE);
}

function monthHeaderFormat(date: DateTime): string {
    return capitalize(format(date, 'LLLL yyyy'));
}

const formats: Formats = {
    dateFormat: 'dd',
    dayFormat: 'dd EEE',
    weekdayFormat: 'EEE',

    selectRangeFormat: timeRangeFormat,
    eventTimeRangeFormat: timeRangeFormat,

    timeGutterFormat: 'HH:mm',

    monthHeaderFormat,
    dayHeaderFormat: 'EEEE MMM dd',
    dayRangeHeaderFormat: weekRangeFormat,
};

export type Formats = {
    /**
     * Format for the day of the month heading in the Month view.
     * e.g. `01`, `02`, `03`, etc
     */
    dateFormat: DateFormat;

    /**
     * A day of the week format for Week and Day headings,
     * e.g. `Wed 01/04`
     *
     */
    dayFormat: DateFormat;

    /**
     * Week day name format for the Month week day headings,
     * e.g: `Sun`, `Mon`, `Tue`, etc
     *
     */
    weekdayFormat: DateFormat;

    /**
     * The timestamp cell formats in Week and Time views, e.g. `4:00 AM`
     */
    timeGutterFormat: DateFormat;

    /**
     * Toolbar header format for the Month view, e.g `2015 April`
     *
     */
    monthHeaderFormat: DateFormat;

    /**
     * Toolbar header format for the Week views, e.g. `Mar 29 - Apr 04`
     */
    dayRangeHeaderFormat: DateRangeFormatFunction;

    /**
     * Toolbar header format for the Day view, e.g. `Wednesday Apr 01`
     */
    dayHeaderFormat: DateFormat;

    /**
     * A time range format for selecting time slots, e.g `8:00am — 2:00pm`
     */
    selectRangeFormat: DateRangeFormatFunction;

    /**
     * Time range displayed on events.
     */
    eventTimeRangeFormat: DateRangeFormatFunction;
};

function fullFormat(value: DateTime | DateRange, formatName: keyof Formats): string {
    // TODO lot of ugly hacks here, fix as soon as possible
    const formatter = formats[formatName];
    return typeof formatter === 'function'
        ? formatter(value as DateTime & DateRange)
        : format(value as DateTime, formatter ?? formatName);
}

function isWeekend(date: DateTime): boolean {
    // 6 and 7 are Saturday and Sunday (always).
    return date.weekday >= 6;
}

const luxonLocalizer = {
    segmentOffset,
    lt,
    lte,
    gt,
    gte,
    eq,
    neq,
    startOf,
    endOf,
    add,
    range,
    diff,
    ceil,
    min,
    max,
    mergeUnsafe,
    merge,
    firstVisibleDay,
    lastVisibleDay,
    visibleDays,
    getSlotDate,
    getTimezoneOffset,
    getDstOffset,
    getTotalMin,
    getMinutesFromMidnight,
    continuesPrior,
    continuesAfter,
    sortEvents,
    inRange,
    inEventRange,
    inRangeDay,
    isSameDate,
    startAndEndAreDateOnly,
    format,
    fullFormat,
    isWeekend,
};

export default luxonLocalizer;

export const getNow = () => DateTime.now();

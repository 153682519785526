import { Modal, Card, Form, Button } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { SpinnerButton } from '../common';
import { LockOpen2Icon, CreditCardIcon, RectanglesHorizontalIcon, ClockRotateClockwise1Icon, CirclePercentageIcon, ChartComboIcon, Globe2Icon, PersonArrowUpIcon, Label1Icon, GoalFlagIcon, ChartStackedAreaIcon, ChartDonut1Icon, ChartCircular2Icon, Tasks2Icon, CalendarCheck2Icon, LockIcon, Copy1Icon,
} from ':components/icons/basic';
import { type IconType } from ':components/icons/common';
import clsx from 'clsx';
import { cn } from ':components/shadcn/utils';
import { useSubscriptionUpsert } from ':frontend/pages/settings/Subscriptions';
import { SubscriptionTierCode, SubscriptionTierPaymentPeriod } from ':utils/entity/subscription';
import { useUser } from ':frontend/context/UserProvider';
import { useState, type ComponentProps } from 'react';
import { useToggle } from ':frontend/hooks';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedSuccessAlert } from '../notifications';
import { routesFE } from ':utils/routes';

type UpsellModalProps = Readonly<{
    isOpen: boolean;
    onClose(): void;
}>;

export function UpsellModal({ isOpen, onClose }: UpsellModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'upsellModal' });
    const { t: ts } = useTranslation('pages', { keyPrefix: 'subscription' });
    const { team } = useUser();

    const [ period, setPeriod ] = useState<SubscriptionTierPaymentPeriod>(SubscriptionTierPaymentPeriod.yearly);

    const { buySubscription, isFetching } = useSubscriptionUpsert();

    function handleClick() {
        buySubscription(SubscriptionTierCode.paid, period);
    }

    return (
        <Modal.Root
            open={isOpen}
            onOpenChange={open => !open && onClose()}
        >
            <Modal.Content closeButton={t('close-button')} className='gap-5 px-4 md:px-15 py-8 md:py-15 max-w-2xl'>
                <Modal.Header>
                    <Modal.Title className='text-center font-semibold text-secondary-500 text-xl/6 md:text-2xl/6'>
                        {t('title')}
                    </Modal.Title>
                </Modal.Header>

                <div className='space-y-0.5'>
                    <div className='flex items-center gap-2'>
                        <div className='p-2 rounded-full bg-success-100'>
                            <LockOpen2Icon size='md' className='text-success-500' />
                        </div>

                        <div className='font-semibold text-lg'>{t('pre-benefit.title')}</div>
                    </div>

                    <div className='flex justify-center w-[34px]'>
                        <RectanglesHorizontalIcon size='sm' className='text-secondary-200' />
                    </div>

                    <Card className='py-5'>
                        <BenefitsList />
                    </Card>

                    <div className='flex justify-center w-[34px]'>
                        <RectanglesHorizontalIcon size='sm' className='text-secondary-200' />
                    </div>

                    <div className='flex items-center gap-2'>
                        <div className='p-2 rounded-full bg-success-100'>
                            <CreditCardIcon size='md' className='text-success-500' />
                        </div>

                        <div className='space-y-1'>
                            <div className='font-semibold text-lg'>{t('after-benefit.title')}</div>

                            <div className='text-secondary-400 leading-5'>{t('after-benefit.description')}</div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-wrap gap-y-4 items-center justify-between'>
                    <h2 className='text-4xl font-semibold leading-none tabular-nums'>{ts(`paid.price-${period}`)}</h2>

                    <div className='shrink-0 flex gap-2 text-secondary-400'>
                        <Form.SwitchBetween
                            isRight={period === SubscriptionTierPaymentPeriod.yearly}
                            onIsRightChange={value => setPeriod(value ? SubscriptionTierPaymentPeriod.yearly : SubscriptionTierPaymentPeriod.monthly)}
                            labelLeft={ts('monthly')}
                            labelRight={ts('yearly')}
                        />
                    </div>
                </div>

                <SpecialOfferPurple period={period} />

                <Modal.Footer className='flex justify-center'>
                    <div className='w-2/3 max-w-full'>
                        <SpinnerButton
                            variant='primary'
                            isFetching={isFetching}
                            onClick={handleClick}
                            className='w-full mb-3'
                        >
                            {!team.dateTrialStarted ? t('start-trial') : t('buy-now')}
                        </SpinnerButton>

                        <div className='text-center text-sm text-secondary-400 mb-6 mx-auto'>{t('cancel-note')}</div>

                        <div className='text-center mx-auto max-w-[250px] leading-5'>{t('footer-note')}</div>
                    </div>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}

function IconCircle({ Icon, className }: { Icon: IconType, className?: string }) {
    return (
        <div className={clsx('p-3 rounded-full border border-secondary-100 bg-white shadow-md', className)}>
            <Icon size='md' />
        </div>
    );
}

const BENEFITS = [
    {
        key: 'fee', labelTitle: true, icons: [
            <IconCircle key='1' Icon={CirclePercentageIcon} className='relative z-50 sm:left-3' />,
            <IconCircle key='2' Icon={ChartComboIcon} className='relative z-40 max-sm:right-3' />,
        ],
    },
    {
        key: 'domain', comingSoon: true, icons: [
            <IconCircle key='1' Icon={Globe2Icon} className='relative z-50 sm:left-3' />,
            <IconCircle key='2' Icon={PersonArrowUpIcon} className='relative z-40 max-sm:right-3' />,
        ],
    },
    {
        key: 'custom-design', icons: [
            <IconCircle key='1' Icon={Label1Icon} className='relative z-50 sm:left-3' />,
            <IconCircle key='2' Icon={GoalFlagIcon} className='relative z-40 max-sm:right-3' />,
        ],
    },
    {
        key: 'analytics', icons: [
            <IconCircle key='1' Icon={ChartStackedAreaIcon} className='relative z-50 sm:left-6' />,
            <IconCircle key='2' Icon={ChartDonut1Icon} className='relative z-40 sm:left-3 max-sm:right-3' />,
            <IconCircle key='3' Icon={ChartCircular2Icon} className='relative z-30 max-sm:right-6' />,
        ],
    },
    {
        key: 'smart-features', icons: [
            <IconCircle key='1' Icon={Tasks2Icon} className='relative z-50 sm:left-3' />,
            <IconCircle key='2' Icon={CalendarCheck2Icon} className='relative z-40 max-sm:right-3' />,
        ],
    },
];

export function BenefitsList() {
    const { t } = useTranslation('components', { keyPrefix: 'upsellModal.benefits' });

    return (
        <div>
            {BENEFITS.map(benefit => (
                <div key={benefit.key} className='flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-2'>
                    <div className='order-2 sm:order-1 space-y-1'>
                        <div className='flex items-baseline gap-2'>
                            <div className={clsx('font-semibold text-lg/6', benefit.labelTitle && 'bg-warning-300 text-warning-900 px-1.5 rounded-xs')}>{t(`${benefit.key}.title`)}</div>

                            {benefit.labelTitle && (
                                <div className='line-through font-semibold text-lg'>{t(`${benefit.key}.title-crossed`)}</div>
                            )}

                            {benefit.comingSoon && (
                                <div className='text-[#0C3FE6] bg-info px-1.5 rounded-xs font-semibold text-lg/6'>
                                    {t(`${benefit.key}.coming-soon`)}
                                </div>
                            )}
                        </div>

                        <div className='text-secondary-400 leading-5'>{t(`${benefit.key}.description`)}</div>
                    </div>

                    <div className='order-1 sm:order-2 flex items-center justify-end'>
                        {benefit.icons}
                    </div>
                </div>
            )).flatMap((e, index) => [ e, <Card.Divider key={`divider_${index}`} className='my-3' /> ]).slice(0, -1)}
        </div>
    );
}

export function SpecialOfferPurple({ period, className }: { period: SubscriptionTierPaymentPeriod, className?: string }) {
    const { t } = useTranslation('components', { keyPrefix: 'upsellModal.offer-card-purple' });
    const { addAlert } = useNotifications();

    function copyCode() {
        navigator.clipboard.writeText(t('code'));
        addAlert(createTranslatedSuccessAlert('common:copied-to-clipboard'));
    }

    return (
        <div className={cn('relative py-4 rounded-lg bg-primary-800 flex flex-col items-center gap-2 overflow-hidden', className)}>
            <GradientOverlay />
            <div className='text-secondary-400 text-sm'>{t('intro')}</div>

            <div className='bg-[#CD2B99] text-white pl-1 pr-2 py-[3px] rounded-full font-semibold flex items-center gap-2'>
                <ClockRotateClockwise1Icon size={17} />
                {t('special-offer')}
            </div>

            {period === SubscriptionTierPaymentPeriod.yearly ? (
                <div className='flex items-baseline gap-1'>
                    <div className='line-through text-secondary-200 font-semibold text-lg'>{t('price-crossed')}</div>

                    <div className='bg-[#CD2B99] text-white px-1 rounded-xs font-semibold text-lg/6'>{t('price')}</div>

                    <div className='text-secondary-400 text-sm ml-1'>{t('price-yearly-per-month')}</div>
                </div>
            ) : (
                <div className='bg-[#CD2B99] text-white px-1 rounded-xs font-semibold text-lg/6'>{t('price-monthly')}</div>
            )}

            <div className='flex items-center gap-1 text-white font-semibold'>
                <span>{t('code-label')}</span>
                <div className='flex items-center gap-2 py-1 pl-2 pr-2.5 border border-[#CD2B99] rounded-full'>
                    <span className='leading-[10px]'>{t('code')}</span>
                    <Button variant='transparent' size='exact' className='[&_svg]:size-[13px]' onClick={copyCode}>
                        <Copy1Icon size={13} />
                    </Button>
                </div>
            </div>
        </div>
    );
}

function GradientOverlay() {
    return (
        <img
            src={routesFE.files.static('purple-gradient.svg')}
            className='absolute w-full -mt-4 pointer-events-none'
            draggable={false}
            style={{
                // keep only the upper half
                clipPath: 'inset(0 0 50% 0)',
            }}
        />
    );
}

export function SpecialOfferBasic({ className }: { className?: string }) {
    const { t } = useTranslation('components', { keyPrefix: 'upsellModal.offer-card' });

    return (
        <div className={cn('p-3 rounded-lg bg-secondary-50 space-y-1', className)}>
            <div className='text-center text-secondary-400 text-sm'>{t('intro')}</div>

            <div className='flex justify-center'>
                <div className='bg-warning-300 text-warning-900 px-1.5 py-0.5 rounded-full font-semibold flex items-center gap-2'>
                    <ClockRotateClockwise1Icon size='sm' />

                    {t('special-offer')}
                </div>
            </div>

            <div className='flex items-center justify-center gap-2'>
                <div className='line-through font-semibold text-lg'>{t('price-crossed')}</div>

                <div className='bg-warning-300 text-warning-900 px-1.5 py-0.5 rounded-xs font-semibold text-lg'>{t('price')}</div>

                <div className='text-secondary-400 text-sm'>{t('price-monthly')}</div>
            </div>

            <div className='text-center text-secondary-400 text-sm'>{t('note')}</div>
        </div>
    );
}

type UpsellButtonProps = Readonly<{
    /** default: 'Upgrade' */
    text?: string;
    /** button className */
    className?: string;
    /** button size, default 'small' */
    size?: ComponentProps<typeof Button>['size'];
    /** disables UpsellModal so you can manage it yourself */
    onClick?: () => void;
}>;

export function UpsellButton({ text, className, size, onClick }: UpsellButtonProps) {
    const { t } = useTranslation('components', { keyPrefix: 'upsellModal' });

    const [ showUpsell, setShowUpsell ] = useToggle(false);

    return (<>
        {!onClick && <UpsellModal isOpen={showUpsell} onClose={setShowUpsell.false} />}
        <Button variant='secondary' size={size ?? 'small'} onClick={onClick ?? setShowUpsell.true} className={className}>
            <LockIcon />
            {text ?? t('upgrade-button')}
        </Button>
    </>);
}

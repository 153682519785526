import { createContext } from 'react';
import { Views, type View } from './Views';
import { type DateTime } from 'luxon';
import { monthViewObject } from './Month';
import { weekViewObject } from './Week';
import { agendaViewObject } from './Agenda';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import type { Selected } from ':frontend/pages/CalendarDetail';
import type { EventChangeArgs, SlotInfo } from '.';
import type { ClientInfoFE } from ':frontend/types/Client';
import { withDragAndDrop } from './dragAndDrop/withDragAndDrop';

export const DEFAULT_CALENDAR_VIEW = Views.WEEK;

// creating a higher-order component or creating a local component inside Calendar
// as recommended in https://github.com/jquense/react-big-calendar/issues/709#issuecomment-362099377
// did not work
const LoadingContext = createContext({ loading: true });

type CalendarProps = {
    loading: boolean;
    events: CalendarEvent[];
    selected?: Selected;
    view: View;
    onNavigate?: (date: DateTime, view: View) => void;
    onSelectSlot?: (slot: SlotInfo) => void;
    onSelectEvent?: (event: CalendarEvent) => void;
    onMoveEvent?: (event: EventChangeArgs) => void;
    onResizeEvent?: (event: EventChangeArgs) => void;
    date: DateTime;
    clients: ClientInfoFE[];
};

export function Calendar({ events, selected, onSelectEvent, onNavigate, view, onSelectSlot, onMoveEvent, onResizeEvent, loading, date, clients }: CalendarProps) {
    const selectedOrDraft = selected
        ? ('event' in selected ? selected.event : selected.draft)
        : null;

    return (
        <LoadingContext.Provider value={{ loading }}>
            <DragAndDropCalendar
                events={events}
                defaultView={DEFAULT_CALENDAR_VIEW}
                selected={selectedOrDraft}
                view={view}
                onNavigate={onNavigate}
                onSelectEvent={onSelectEvent}
                onSelectSlot={onSelectSlot}
                selectable={true}
                onEventDrop={onMoveEvent}
                onEventResize={onResizeEvent}
                date={date}
                clients={clients}
            />
        </LoadingContext.Provider>
    );
}

function InnerCalendar({ view, events, date, onNavigate, ...props }: Omit<CalendarProps, 'loading'>) {
    const ViewComponent = ViewObjects[view].component;

    return (
        <div className='w-full h-full flex flex-col items-stretch'>
            <ViewComponent
                {...props}
                events={events}
                date={date}
                onNavigate={onNavigate}
                onSelectEvent={props.onSelectEvent}
                onSelectSlot={props.onSelectSlot}
            />
        </div>
    );
}

const DragAndDropCalendar = withDragAndDrop(InnerCalendar);

export const ViewObjects = {
    [Views.MONTH]: monthViewObject,
    [Views.WEEK]: weekViewObject,
    [Views.AGENDA]: agendaViewObject,
};


import { useId, useRef, useState } from 'react';
import { Form } from ':components/shadcn';
import type { StoreOutput } from ':utils/entity/store';
import { useTranslation } from 'react-i18next';
import { routeToDisplayString, routesStore } from ':utils/routes';
import { createSlug, STORE_SLUG_MIN_LENGTH } from ':utils/common';
import { trpc } from ':frontend/context/TrpcProvider';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { SpinnerButton } from '../common';
import { isNewTypedError } from ':frontend/types/TypedError';
import { ErrorMessage } from '../forms/ErrorMessage';

type StoreSlugProps = Readonly<{
    store: StoreOutput;
}>;

export function StoreSlug({ store }: StoreSlugProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const [ isFetching, setIsFetching ] = useState(false);
    const [ slugInit, setSlugInit ] = useState(store.slug);
    const alreadyUsed = useRef<string[]>([]);

    const displayUrlPrefix = routeToDisplayString(routesStore.$router.absolutePrefix);
    const finalSlug = createSlug(slugInit);

    function validate(slug: string) {
        if (slug.length < STORE_SLUG_MIN_LENGTH)
            return 'too-short';

        if (alreadyUsed.current.includes(slug))
            return 'already-used';

        return undefined;
    }

    const error = validate(finalSlug);

    const updateStoreMutation = trpc.store.updateStore.useMutation();
    const utils = trpc.useUtils();
    const { addAlert } = useNotifications();

    function save() {
        if (error)
            return;

        setIsFetching(true);
        updateStoreMutation.mutate({ slug: finalSlug }, {
            onError: error => {
                if (isNewTypedError(error.data, 'store.slugAlreadyExists'))
                    alreadyUsed.current.push(finalSlug);
                else
                    addAlert(createErrorAlert(error.data));
            },
            onSuccess: data => {
                utils.store.getStore.setData(undefined, data);
                alreadyUsed.current = [];
                addAlert(createTranslatedSuccessAlert('pages:settings.general.storeSlug-success'));
            },
            onSettled: () => {
                setIsFetching(false);
            },
        });
    }

    const descriptionId = useId();

    return (
        <div className='flex flex-col gap-4'>
            <div>
                <Form.Input
                    isError={!!error}
                    aria-describedby={descriptionId}
                    value={slugInit}
                    onChange={e => setSlugInit(e.target.value)}
                />
                {error === 'already-used' && (
                    <ErrorMessage message={t('storeSlug-already-used-error')} />
                )}
                {error === 'too-short' && (
                    <ErrorMessage message={t('storeSlug-too-short-error')} />
                )}
            </div>
            <Form.Description id={descriptionId}>
                <span className='text-secondary-400'>{displayUrlPrefix}/</span>
                <span className='text-secondary-500'>{finalSlug}</span>
            </Form.Description>
            <SpinnerButton size='small' className='w-full' disabled={!!error} isFetching={isFetching} onClick={save}>
                {t('save-button')}
            </SpinnerButton>
        </div>
    );
}

import { type ReactNode, useState } from 'react';
import { type ClientFE } from ':frontend/types/Client';
import { Trans, useTranslation } from 'react-i18next';
import { Card, Skeleton, StringSelect, Tooltip } from ':components/shadcn';
import { moneyFromServer } from ':utils/money';
import { masterComponent, useMaster } from ':frontend/context/UserProvider';
import { MoneyDisplay } from ':components/custom';
import { trpc } from ':frontend/context/TrpcProvider';
import { ChartColumnIcon, CircleInfoIcon } from ':components/icons/basic';
import { OrderStatsRange, type OrderStatsInput } from ':utils/entity/order';
import { getEnumValues } from ':utils/common';
import { StatsUpsell } from '../orders/OrdersStatsDisplay';

type ClientStatsProps = Readonly<{
    client: ClientFE;
}>;

export const ClientStatsView = masterComponent(({ client }: ClientStatsProps) => {
    const { teamSettings, subscription } = useMaster();
    const { t } = useTranslation('components', { keyPrefix: 'clientForm.stats' });

    const [ rangeState, setRangeState ] = useState<OrderStatsInput>({
        range: OrderStatsRange.last7Days,
    });

    const rangeAvailable = subscription.restrictions.statsRange === 'any' || rangeState.range === OrderStatsRange.today || rangeState.range === OrderStatsRange.last7Days;

    const currency = teamSettings.currency;

    const clientStatsQuery = trpc.$client.getClientStats.useQuery({
        clientId: client.id,
        range: rangeState,
    }, { enabled: rangeAvailable });

    return (
        <div className='flex flex-col gap-2'>
            <div className='flex justify-between items-center flex-wrap gap-4'>
                <Card.Title className='flex items-center gap-2'>
                    <ChartColumnIcon className='text-primary' />{t('stats-title')}
                </Card.Title>

                <div className='w-36'>
                    <RangeSelect range={rangeState} setRange={setRangeState} />
                </div>
            </div>

            {clientStatsQuery.data ? (
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-2'>
                    <StatsTile translationId='orders-total'>
                        <MoneyDisplay money={moneyFromServer(clientStatsQuery.data.ordersTotal, currency.id)} />
                    </StatsTile>

                    <StatsTile translationId='order-count'>
                        <span className='font-semibold'>{clientStatsQuery.data.orderCount}</span>
                    </StatsTile>
                </div>
            ) : (rangeAvailable ? (
                <Skeleton height={120} />
            ) : (
                <div className='relative grid grid-cols-1 sm:grid-cols-2 gap-2 py-8 mt-3'>
                    <StatsUpsell />
                    <StatsTile translationId='orders-total'>
                        <MoneyDisplay money={moneyFromServer(3485000, currency.id)} />
                    </StatsTile>

                    <StatsTile translationId='order-count'>
                        <span className='font-semibold'>35</span>
                    </StatsTile>
                </div>
            ))}
        </div>
    );
});

type StatsHeaderProps = Readonly<{
    range: OrderStatsInput;
    setRange: (range: OrderStatsInput) => void;
}>;

function RangeSelect({ range, setRange }: StatsHeaderProps) {
    const { t: tr } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay.range' });

    return (
        <StringSelect
            value={range.range}
            onChange={value => value && value in OrderStatsRange && value !== OrderStatsRange.custom && setRange({ range: value } as OrderStatsInput)}
            options={getEnumValues(OrderStatsRange).filter(value => value !== OrderStatsRange.custom)}
            t={tr}
            immutableProps={{
                size: 'compact',
                variant: 'outline-transparent',
            }}
            styles={{
                menu(base) {
                    return {
                        ...base,
                        zIndex: 100,
                    };
                },
            }}
        />
    );
}


type StatsTileProps = Readonly<{
    translationId: 'orders-total' | 'order-count';
    children?: ReactNode;
}>;

function StatsTile({ translationId, children }: StatsTileProps) {
    const { t } = useTranslation('components', { keyPrefix: 'statsTile' });

    return (
        <div className='relative rounded-md p-8 bg-secondary-100/25 border-secondary-100 border'>
            <div className='flex gap-2 flex-col items-start justify-center'>
                <div className='text-xl leading-7'>{children}</div>

                <div className='flex items-center gap-2'>
                    <span>{t(`${translationId}-label`)}</span>

                    <Tooltip
                        tooltipText={<TileTooltip translationId={translationId} />}
                        side='left'
                    >
                        <CircleInfoIcon size='md' className='opacity-40' />
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}

type TileTooltipProps = Readonly<{
    translationId: string;
}>;

function TileTooltip({ translationId }: TileTooltipProps) {
    return <Trans
        i18nKey={`components:statsTile.${translationId}-tooltip`}
        components={{
            ul: <ul className='pl-4 mb-0' />,
            li: <li />,
            list: <div className='pl-1 text-left' />,
        }}
    />;
}

import { SpinnerButton } from ':frontend/components/common';
import { useTranslation } from 'react-i18next';
//import { createTranslatedErrorAlert } from ':frontend/components/notifications';
//import useNotifications from ':frontend/context/NotificationProvider';
import { type ClientInfoFE } from ':frontend/types/Client';
//import { trpc } from ':frontend/context/TrpcProvider';
import { Modal, Button } from ':components/shadcn';
import { Trash2Icon } from ':components/icons/basic';
import { type GenericProductItem } from ':frontend/types/orders/ProductOrderItem';
import { trpc } from ':frontend/context/TrpcProvider';

type RemoveProductModalProps = Readonly<{
    client?: ClientInfoFE;
    productItem?: GenericProductItem<true>;
    onClose(): void;
    onRemoved(): Promise<void>;
}>;

export function RemoveProductModal({ client, productItem, onClose, onRemoved }: RemoveProductModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'removeProductModal' });
    const removeProductItemMutation = trpc.order.removeProductOrderItem.useMutation();

    async function removeProduct() {
        if (!productItem)
            return;

        await removeProductItemMutation.mutateAsync({ orderItemId: productItem.id });

        await onRemoved();
    }

    return (
        <Modal.Root
            open={!!productItem}
            onOpenChange={open => !open && onClose()}
        >
            <Modal.Content className='gap-6' closeButton={t('close-button')}>
                <Modal.Header className='space-y-4'>
                    <Trash2Icon size={32} className='mx-auto text-danger-500 stroke-2' />

                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                        {t('title', { clientName: client?.name })}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Footer className='grid grid-cols-2 gap-4'>
                    <Button variant='secondary' onClick={onClose}>
                        {t('cancel-button')}
                    </Button>

                    <SpinnerButton
                        variant='danger'
                        isFetching={removeProductItemMutation.isPending}
                        onClick={removeProduct}
                    >
                        {t('confirm-button')}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}

import { useUser } from ':frontend/context/UserProvider';
import clsx from 'clsx';
import { type Id } from ':utils/id';
import { isColorDark } from ':utils/common';
import { Tooltip } from ':components/shadcn';
import { getPersonName } from ':utils/entity/person';
import { getPersonShortcut } from ':utils/entity/person';

type Size = 'sm' | 'md' | 'lg';

type TeamMemberBadgeProps = Readonly<{
    appUserId?: Id;
    className?: string;
    size?: Size;
}>;

export function TeamMemberBadge({ appUserId, className, size }: TeamMemberBadgeProps) {
    const { teamMembers } = useUser();
    if (!appUserId)
        return null;

    const member = teamMembers.getOtherByAppUserId(appUserId);
    if (!member)
        return null;

    return (
        <Tooltip tooltipText={getPersonName(member)}>
            <div className='w-fit'>
                <TeamMemberIconCustom text={getPersonShortcut(member)} color={member.color} className={className} size={size} />
            </div>
        </Tooltip>
    );
}

type TeamMemberIconCustomProps = Readonly<{
    text: string;
    color: string;
    className?: string;
    size?: Size;
}>;

function TeamMemberIconCustom({ text, color, className, size }: TeamMemberIconCustomProps) {
    return (
        <div {...getSettings(color, className, size)}>
            <span>{text}</span>
        </div>
    );
}

const settingsBySize: Record<Size, { className: string, style: React.CSSProperties }> = {
    sm: {
        className: 'shrink-0 flex items-center justify-center select-none leading-3',
        style: { width: 20, height: 18, padding: 2, borderRadius: 4, fontSize: 10 },
    },
    md: {
        className: 'shrink-0 flex items-center justify-center select-none leading-4',
        style: { width: 24, height: 24, padding: 2, borderRadius: 4, fontSize: 10 },
    },
    lg: {
        className: 'shrink-0 flex items-center justify-center select-none leading-4 text-lg',
        style: { width: 40, height: 40, padding: 2, borderRadius: 4, fontSize: 12 },
    },
};

function getSettings(color: string, className?: string, size?: Size) {
    const settings = settingsBySize[size ?? 'sm'];
    const textColor = isColorDark(color) ? 'text-white' : 'text-body';

    return {
        className: clsx(settings.className, textColor, className),
        style: { ...settings.style, backgroundColor: `#${color}` },
    };
}

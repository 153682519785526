import { useEffect, useMemo, useState } from 'react';
import { type NavigateProps, useNavigate, useLocation } from 'react-router-dom';

const RESET_ACTION_TIMEOUT = 4000;

type LocationState = {
    parameters?: NavigationProperty<string, unknown>[];
    actions?: NavigationProperty<string, unknown>[];
}

function useHistoryState(): LocationState | undefined {
    const location = useLocation();
    // We have to cache the state locally because it is not reseted by the react router when the `history.replaceState` is called.
    const [ state, setState ] = useState(location.state as LocationState | null ?? undefined);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!history.state || !('usr' in history.state) || !history.state.usr || typeof history.state.usr !== 'object' || !('actions' in history.state.usr))
                return;

            const newHistoryState = { ...history.state, usr: { ...history.state.usr } };
            delete newHistoryState.usr.actions;
            history.replaceState(newHistoryState, '');

            setState(oldState => ({ parameters: oldState?.parameters }));
        }, RESET_ACTION_TIMEOUT);

        return () => clearTimeout(timeout);
    }, []);

    return state;
}

export type NavigationProperty<TType extends string, TData = undefined> = {
    type: TType;
    data?: TData;
}

type PropertyType<TProperty> = TProperty extends NavigationProperty<infer TType, unknown> ? TType : never;
type PropertyData<TProperty> = TProperty extends NavigationProperty<string, infer TData> ? TData : never;

/**
 * A state that is passed from the previous page and that is persisted in the history.
 */
export function useNavigationParameter<TParameter extends NavigationProperty<string, unknown>>(type: PropertyType<TParameter>): TParameter | undefined {
    const state = useHistoryState();

    const parameter = useMemo(() => state?.parameters?.find(a => a.type === type), [ type, state ]);

    return parameter as TParameter | undefined;
}

/**
 * An one-time action that is passed from the previous page but it's reseted after the timeout.
 */
export function useNavigationAction<TAction extends NavigationProperty<string, unknown>>(type: PropertyType<TAction>): TAction | undefined {
    const state = useHistoryState();

    const action = useMemo(() => state?.actions?.find(a => a.type === type), [ type, state ]);

    return action as TAction | undefined;
}

export function createParameterState<TParameter>(type: PropertyType<TParameter>, data?: PropertyData<TParameter>): LocationState {
    return {
        parameters: [ { type, data } ],
    };
}

export function createActionState<TAction>(type: PropertyType<TAction>, data?: PropertyData<TAction>): LocationState {
    return {
        actions: [ { type, data } ],
    };
}

type NavigateWithStateProps = NavigateProps & {
    state: LocationState;
};

/** @deprecated Not used anymore. */
export function NavigateWithState({ state, to, replace }: NavigateWithStateProps) {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(to, { replace, state });
    });

    return null;
}

import { deepClone, deepEquals, zColor, zDateTime, zRequiredString } from ':utils/common';
import { z } from 'zod';
import { zFileOutput, zFileUpsert } from './file';

export type StoreSocial = z.infer<typeof zStoreSocial>;
const zStoreSocial = z.object({
    // FIXME this should be an enum
    type: z.string(),
    url: z.string(),
    title: z.string(),
});

export type StoreBio = z.infer<typeof zStoreBio>;
const zStoreBio = z.object({
    name: z.string(),
    headline: z.string().optional(),
    description: z.string().optional(),
    videoUrl: z.string().optional(),
    socials: z.array(zStoreSocial),
});

export type StoreBackground = z.infer<typeof zStoreBackground>;
export const zStoreBackground = z.object({
    type: z.enum([ 'solid', 'gradient' ]),
    color1: zColor,
    /** For gradients. */
    color2: zColor,
});

export type StoreDesign = z.infer<typeof zStoreDesign>;
export const zStoreDesign = z.object({
    background: zStoreBackground,
    card: z.object({
        corners: z.enum([ 'square', 'rounded' ]),
        shadow: z.enum([ 'none', 'soft', 'hard' ]),
        button: z.enum([ 'square', 'rounded', 'pill' ]),
        buttonBackground: zColor,
        buttonColor: zColor,
    }),
    font: z.string(),
    other: z.object({
        hideFlowlanceBadge: z.boolean(),
    }),
});

export function cloneStoreDesign(design: StoreDesign): StoreDesign {
    return deepClone(design);
}

export function isStoreDesignEqual(design: StoreDesign, original: StoreDesign): boolean {
    return deepEquals(design, original);
}

// 2^16 ... This should be enough for 30 A4 pages of text.
export const TERMS_OF_SERVICE_MAX_LENGTH = 65536;

export type StoreOutput = z.infer<typeof zStoreOutput>;
export const zStoreOutput = z.object({
    paymentEnabled: z.boolean(),
    slug: z.string(),
    bio: zStoreBio,
    design: zStoreDesign,
    image: zFileOutput.optional(),
    termsOfService: z.string().optional(),
});

/** Like PATCH (then like PUT for nested objects). */
export type StoreEdit = z.infer<typeof zStoreEdit>;
export const zStoreEdit = z.object({
    slug: zRequiredString(),
    bio: zStoreBio,
    design: zStoreDesign,
    image: zFileUpsert.nullable(),
    termsOfService: z.string().nullable(),
}).partial();

/** There is also the default font, Gilmer, which is not custom but is available (also, it's the default option). */
export const customFonts = [
    'Manrope',
    'Montserrat',
    'Poppins',
    'Raleway',
    'Roboto',
    'Space Grotesk',
    'Nunito',
    'Playfair Display',
    'Karla',
    'Fira Sans',
    'Lora',
    'Anton',
    'Inter',
    // This font is disabled because it has very large descenders, which make it hard to center.
    // Also, its baseline is extremely high, it's just a pain to watch, honestly.
    // 'Amiko',
    'Mulish',
    'Crimson Text',
    'Roboto Mono',
];

export function fontsNamesToUrl(fonts: string[]) {
    const payload = fonts.map(font => `family=${fontNameToDefinition(font)}`).join('&');
    return `https://fonts.googleapis.com/css2?${payload}&display=swap`;
}

function fontNameToDefinition(font: string) {
    return font.replace(/ /g, '+') + ':wght@500;600';
}

export type VisitOutput = z.infer<typeof zVisitOutput>;
export const zVisitOutput = z.object({
    createdAt: zDateTime,
});

export type StoreValidationOutput = z.infer<typeof zStoreValidationOutput>;
export const zStoreValidationOutput = z.discriminatedUnion('type', [ z.object({
    type: z.literal('available'),
    slug: z.string(),
}), z.object({
    type: z.literal('taken'),
    suggestion: z.string(),
}) ]);

import { useState, type ReactElement, type ReactNode } from 'react';
import { useLocalStorageState } from ':frontend/utils/localStorage';
import { Button, Modal } from ':components/shadcn';
import { Trans, useTranslation } from 'react-i18next';
import { routesFE } from ':utils/routes';
import { Link } from 'react-router-dom';
import { XmarkIcon, PeopleRoofIcon, AutomatedLogistics1Icon, ShieldUserIcon, MediaPlayIcon, CircleCheck1Icon } from ':components/icons/basic';
import type { IconType } from ':components/icons/common';
import type { TFunction } from 'i18next';
import { cn } from ':components/shadcn/utils';
import { RadioCheckIcon } from ':components/icons/custom';
import clsx from 'clsx';

const INFO_CARDS_KEY = 'info-cards';
const ONBOARDING_VIDEOS_KEY = 'onboarding-videos';

type InfoKey = 'orders' | 'clients' | 'productOrder' | 'eventOrder';

type InfoCardProps = Readonly<{
    infoKey: InfoKey;
    className?: string;
}>;

export function InfoCard({ infoKey, className }: InfoCardProps) {
    const { t } = useTranslation('common', { keyPrefix: `infoCards.${infoKey}` });
    const info = infos[infoKey];

    return (
        <InfoCardCloseable
            infoKey={infoKey}
            title={t('title')}
            description={<Trans components={transComponents}>{t('description', { defaultValue: false })}</Trans>}
            videoThumbnail={info.videoThumbnail ? { ...info.videoThumbnail, text: t('video-text') } : undefined}
            mediaContent={info.mediaContent}
            closeable={!info.notCloseable}
            className={className}
        />
    );
}

const transComponents: Record<string, ReactElement> = {
    s: <span />,
    pr: <span className='text-primary' />,
    ol: <ol className='mb-0' />,
    ul: <ul className='mb-0' />,
    li: <li />,
    callLink: <a href='https://app.lemcal.com/@patrikkruntorad' target='_blank' rel='noreferrer' />,
    dashboardLink: <Link to={routesFE.dashboard.path} />,
    eventOrderLink: <Link to={routesFE.directSale.event} />,
    customOrderLink: <Link to={routesFE.directSale.custom} />,
};

type InfoCardCloseableProps = Omit<InfoCardCommonProps, 'onClose'> & {
    infoKey: InfoKey;
};

type InfoCardLocalStorageState = {
    closed: boolean;
};

function InfoCardCloseable({ infoKey, ...commonProps }: InfoCardCloseableProps) {
    const { cardState, close } = useInfoCardState(infoKey);

    if (cardState.closed)
        return null;

    return (
        <InfoCardCommon {...commonProps} onClose={close} />
    );
}

function useInfoCardState(infoKey: InfoKey) {
    const [ infoCardsState, setInfoCardsState ] = useLocalStorageState<Record<string, InfoCardLocalStorageState>>(INFO_CARDS_KEY);
    const currentState = infoCardsState?.[infoKey] ?? { closed: false };
    if (infos[infoKey].notCloseable)
        currentState.closed = false;

    function open() {
        setInfoCardsState({ ...infoCardsState, [infoKey]: { ...currentState, closed: false } });
    }

    function close() {
        setInfoCardsState({ ...infoCardsState, [infoKey]: { ...currentState, closed: true } });
    }

    return {
        infoCardsState,
        setInfoCardsState,
        cardState: currentState,
        open,
        close,
    };
}

type InfoCardCommonProps = Readonly<{
    title: string;
    description?: ReactNode;
    videoThumbnail?: VideoThumbnailCommonProps;
    mediaContent?: ReactNode;
    onClose: () => void;
    closeable?: boolean;
    className?: string;
}>;

function InfoCardCommon({ title, description, videoThumbnail, mediaContent, onClose, closeable = true, className }: InfoCardCommonProps) {
    return (
        <div className={cn('relative flex flex-col lg:flex-row gap-5 items-center bg-secondary-100 rounded-4xl p-5', className)}>
            <div className='flex flex-col md:flex-row gap-5 items-center'>
                {videoThumbnail && (
                    <div className='shrink-0 w-[180px] rounded-xl'>
                        <VideoThumbnail
                            href={videoThumbnail.href}
                            img={videoThumbnail.img}
                            duration={videoThumbnail.duration}
                            text={videoThumbnail.text}
                        />
                    </div>
                )}

                <div className='grow space-y-2'>
                    <h2 className='text-2xl font-semibold leading-6'>{title}</h2>

                    <div className='leading-5'>{description}</div>
                </div>
            </div>

            {mediaContent}

            {closeable && (
                <div className='absolute top-5 right-5'>
                    <Button variant='transparent' size='exact' className='text-secondary-400' onClick={onClose}>
                        <XmarkIcon />
                    </Button>
                </div>
            )}
        </div>
    );
}

type Info = {
    videoThumbnail?: Omit<VideoThumbnailCommonProps, 'text'>;
    mediaContent?: ReactNode;
    notCloseable?: boolean;
};

// FIXME Missing texts for orders and eventOrder (we use empty string now)
const infos: Record<InfoKey, Info> = {
    orders: {
        videoThumbnail: {
            href: 'https://www.youtube.com/embed/oLGF73Pgaik?si=o1u4kcIEKRH851TV',
            img: '/static/images/info-cards/video-orders.png',
            duration: '0:41',
        },
        mediaContent: <OrdersMediaContent />,
    },
    clients: {
        videoThumbnail: {
            href: 'https://www.youtube.com/embed/vmaeoWFMAA4?si=eCjtr3nvVte3wkZL',
            img: '/static/images/info-cards/video-clients.png',
            duration: '0:42',
        },
        mediaContent: <ClientsMediaContent />,
    },
    productOrder: {
    },
    eventOrder: {
        videoThumbnail: {
            href: 'https://www.youtube.com/embed/nuGHUKPEqgw?si=vos0_vkbHGEXBE-P',
            img: '/static/images/info-cards/video-event-order.png',
            duration: '0:23',
        },
    },
};

type VideoThumbnailCommonProps = Readonly<{
    /** eg. 'Invoices from\nevents: Learn the\npower of\nFlowlance' */
    text: string;
    /** eg. 'https://www.youtube.com/embed/dQw4w9WgXcQ' */
    href: string;
    /**
     * eg. '/images/info-cards/video-event-order.png'
     * Store in the /packages/static/ directory. The img path must start with /static/
     */
    img: string;
    /** eg. '1:34' */
    duration: string;
}>;

function VideoThumbnail({ text, href, img, duration }: VideoThumbnailCommonProps) {
    const [ isOpen, setIsOpen ] = useState(false);

    return (

        <Modal.Root open={isOpen} onOpenChange={setIsOpen}>
            <Modal.Trigger asChild>
                <button className='group block select-none drag-none no-underline w-full aspect-[3/4] rounded-lg relative bg-black'>
                    <img
                        src={img}
                        alt={text}
                        className='absolute top-0 right-0 left-0 bottom-0 h-full w-full rounded-lg overflow-hidden object-cover'
                    />

                    <div className='absolute top-0 right-0 left-0 bottom-0 h-full w-full flex items-center justify-center'>
                        <div className='py-[10px] pl-[11px] pr-[9px] rounded-full bg-[#1d054d]/90 text-white group-hover:scale-110 transition-transform'>
                            <MediaPlayIcon size='lg' />
                        </div>
                    </div>

                    <div className='absolute right-0 left-0 bottom-0 h-full w-full flex flex-col'>
                        <div className='h-1/2' />
                        <div className='h-1/2 flex items-center justify-center text-center text-white whitespace-pre-wrap drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]'>
                            {text}
                        </div>
                    </div>

                    <div className='absolute bottom-0 right-0 pr-1 pb-2'>
                        <span className='bg-black/50 bg-black rounded-md py-1 px-2 text-white'>
                            {duration}
                        </span>
                    </div>
                </button>

            </Modal.Trigger>
            {/* <Modal.Content className='gap-6' closeButton={t('close-button')}> */}
            <Modal.Content className='max-w-[1080px] p-0 overflow-hidden border-0' closeButton={null}>
                <Modal.Title className='sr-only text-3xl leading-9 text-center font-semibold text-secondary-900'>
                    {text}
                </Modal.Title>

                <iframe
                    className='w-full aspect-video border-0'
                    src={href}
                    title='YouTube video player'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    referrerPolicy='strict-origin-when-cross-origin'
                    allowFullScreen
                />
            </Modal.Content>
        </Modal.Root>
    );
}

type ContentBoxProps = Readonly<{
    name: string;
    Icon: IconType;
    title?: boolean;
    t: TFunction;
}>;

function ContentBox({ name, Icon, title, t }: ContentBoxProps) {
    return (
        <div className='w-full md:max-w-[176px] p-6 rounded-2xl border border-secondary-200 space-y-2'>
            <Icon size='lg' />

            {title && <div className='font-semibold text-lg'>{t(`content-${name}-title`)}</div>}

            <div className='leading-5 whitespace-pre-wrap'>{t(`content-${name}`)}</div>
        </div>
    );
}

function OrdersMediaContent() {
    const { t } = useTranslation('common', { keyPrefix: 'infoCards.orders' });

    return (
        <div className='grid grid-cols-1 md:grid-cols-3 items-stretch gap-2'>
            <ContentBox name='store' Icon={PeopleRoofIcon} title t={t} />
            <ContentBox name='sale' Icon={AutomatedLogistics1Icon} title t={t} />
            <ContentBox name='others' Icon={ShieldUserIcon} title t={t} />
        </div>
    );
}

function ClientsMediaContent() {
    const { t } = useTranslation('common', { keyPrefix: 'infoCards.clients' });

    return (
        <div className='flex flex-col md:flex-row w-full items-center gap-2'>
            <ContentBox name='eye' Icon={PeopleRoofIcon} t={t} />
            <ContentBox name='invoice' Icon={AutomatedLogistics1Icon} t={t} />
            <ContentBox name='slash' Icon={ShieldUserIcon} t={t} />
        </div>
    );
}

// Onboarding

type VideoKey = 'start' | 'store' | 'product' | 'payments';

const onboardingVideos: Record<VideoKey, Omit<VideoThumbnailCommonProps, 'text'>> = {
    start: {
        href: 'https://www.youtube.com/embed/1i4DWk9dpUk?si=2Ocb5DUUyvPp71TF',
        img: '/static/images/info-cards/video-start.png',
        duration: '0:54',
    },
    store: {
        href: 'https://www.youtube.com/embed/Bmk-gXQA85Y?si=cCzQKd_4cSzcLaMa',
        img: '/static/images/info-cards/video-store.png',
        duration: '0:58',
    },
    product: {
        href: 'https://www.youtube.com/embed/busNStUS6Yw?si=NfYJzncrq8f8mPk1',
        img: '/static/images/info-cards/video-product.png',
        duration: '1:00',
    },
    payments: {
        href: 'https://www.youtube.com/embed/5NEtYaQsay0?si=26OEWXip2r6-vZHG',
        img: '/static/images/info-cards/video-payments.png',
        duration: '0:54',
    },
};

export function OnboardingVideos({ className }: Readonly<{ className?: string }>) {
    const [ state, setState ] = useLocalStorageState<Record<VideoKey, boolean>>(ONBOARDING_VIDEOS_KEY);
    const currentState = state ?? defaultState;

    const isAllCompleted = Object.values(currentState).every(Boolean);
    if (isAllCompleted)
        return null;

    return (
        <div className={className}>
            <div className='p-10 rounded-4xl w-fit flex flex-nowrap gap-6 bg-secondary-100'>
                {Object.keys(onboardingVideos).map(videoKey => (
                    <OnboardingVideo
                        key={videoKey}
                        videoKey={videoKey as VideoKey}
                        isCompleted={!!currentState[videoKey as VideoKey]}
                        onComplete={() => setState({ ...currentState, [videoKey as VideoKey]: true })}
                    />
                ))}
            </div>
        </div>
    );
}

const defaultState = Object.fromEntries(Object.keys(onboardingVideos).map(key => [ key, false ])) as Record<VideoKey, boolean>;

type OnboardingVideoProps = Readonly<{
    videoKey: VideoKey;
    isCompleted: boolean;
    onComplete: () => void;
}>;

function OnboardingVideo({ videoKey, isCompleted, onComplete }: OnboardingVideoProps) {
    const { t } = useTranslation('common', { keyPrefix: `onboardingVideos.${videoKey}` });
    const { t: td } = useTranslation('common', { keyPrefix: 'onboardingVideos' });

    return (
        <div className='relative shrink-0'>
            <div className={clsx('shrink-0 w-[218px] rounded-xl', isCompleted && 'opacity-70')}>
                <VideoThumbnail {...onboardingVideos[videoKey]} text={t('video-text')} />

                <h4 className='mt-4 text-2lg font-semibold text-secondary-700'>
                    {t('title')}
                </h4>

                <p className='my-2 leading-5'>
                    {t('description')}
                </p>

                {!isCompleted && (
                    <Button variant='outline' size='tiny' className='mt-4 flex mx-auto' onClick={onComplete}>
                        <CircleCheck1Icon />{td('complete-button')}
                    </Button>
                )}
            </div>

            {isCompleted && (
                <RadioCheckIcon className='absolute -top-1 -right-1 rounded-full bg-primary text-white scale-125' />
            )}
        </div>
    );
}

import { type Dispatch } from 'react';
import { type CheckoutAction, CheckoutPhase, type CheckoutState, type EmailPreviewState, type EmailPreviewAction } from './useCheckout';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from ':components/shadcn';
import { TranslatedErrorMessage } from ':frontend/components/forms/ErrorMessage';

type EmailPreviewProps = Readonly<{
    state: CheckoutState;
    dispatch: Dispatch<CheckoutAction>;
}>;

export function CheckoutEmailPreview({ state, dispatch }: EmailPreviewProps) {
    const { t } = useTranslation('components', { keyPrefix: 'checkout.emailPreview' });
    const isChanged = state.emailPreview.isChanged;

    return (
        <Modal.InnerContent className='max-w-[680px] md:w-[680px] border-0' closeButton={null}>
            <Modal.Header>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>

            <EmailPreviewForm state={state.emailPreview} dispatch={dispatch} />

            <Modal.Footer>
                {isChanged && (
                    <Button variant='outline' onClick={() => dispatch({ type: 'emailPreview', operation: 'reset' })}>
                        {t('reset-button')}
                    </Button>
                )}
                <Button onClick={() => dispatch({ type: 'phase', phase: CheckoutPhase.Overview })}>
                    {t('back-button')}
                </Button>
            </Modal.Footer>
        </Modal.InnerContent>
    );
}

type EmailPreviewFormProps = Readonly<{
    state: EmailPreviewState;
    dispatch: Dispatch<EmailPreviewAction>;
}>;

export function EmailPreviewForm({ state, dispatch }: EmailPreviewFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'checkout.emailPreview' });
    const { isMultipleClients, form, formErrors, unusedVariables } = state;

    return (<>
        <div className='md:w-1/2'>
            {isMultipleClients ? (<>
                <Form.Label>{t('email-label')}</Form.Label>
                <div className='bg-secondary-100 text-secondary-900 border border-secondary-200 rounded-md m-0 py-2'>
                    {t('multiple-clients-email')}
                </div>
            </>) : (<>
                <Form.Input
                    // This is disabled only on FE. But it's ok, it isn't a security issue, it's just a minor inconvenience.
                    disabled
                    label={t('email-label')}
                    value={form.email}
                    onChange={event => dispatch({ type: 'emailPreview', field: 'email', value: event.target.value })}
                />
                <TranslatedErrorMessage translationId={formErrors?.email} />
            </>)}
        </div>

        <div className='md:w-1/2'>
            <Form.Textarea
                label={t('cc-label')}
                value={form.cc}
                onChange={event => dispatch({ type: 'emailPreview', field: 'cc', value: event.target.value })}
                minRows={1}
            />
            <div className='text-sm mt-1'>{t('cc-description')}</div>
            <TranslatedErrorMessage translationId={formErrors?.cc} />
        </div>

        <div className='md:w-1/2'>
            <Form.Input
                label={t('subject-label')}
                value={form.subject}
                onChange={event => dispatch({ type: 'emailPreview', field: 'subject', value: event.target.value })}
            />
            <TranslatedErrorMessage translationId={formErrors?.subject} />
        </div>

        <div>
            <Form.Textarea
                label={t('body-label')}
                value={form.body}
                onChange={event => dispatch({ type: 'emailPreview', field: 'body', value: event.target.value })}
                minRows={9}
            />
            <TranslatedErrorMessage translationId={formErrors?.body} />
        </div>

        {unusedVariables.length > 0 && (
            <div className='bg-warning-100 text-warning-900 border border-warning-200 rounded-md p-4'>
                {t('unused-variables-description-1')}
                <ul className='m-0'>
                    {unusedVariables.map(variable => (
                        <li key={variable} className='py-2'>
                            <span className='bg-white rounded-md mr-2 px-2 pt-[2px] pb-1'>
                                {variable}
                            </span>
                            {t(`${variable}-description`)}
                        </li>
                    ))}
                </ul>
                {t('unused-variables-description-2')}
            </div>
        )}
    </>);
}

import { useCallback, useMemo } from 'react';
import EmojiPickerReact, { EmojiStyle, type EmojiClickData, Categories } from 'emoji-picker-react';
import { type Control, Controller, type FieldPath, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, DropdownMenu } from ':components/shadcn';
import { useToggle } from ':frontend/hooks';
import { FacePlusIcon, Trash2Icon } from ':components/icons/basic';

type EmojiPickerProps = Readonly<{
    value?: string;
    onChange: (emoji: string) => void;
    className?: string;
}>;

export function EmojiPicker({ value, onChange, className }: EmojiPickerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'emojiPicker' });
    const [ isShow, setIsShow ] = useToggle(false);
    const innerOnChange = useCallback((emoji: EmojiClickData) => {
        onChange(emoji.emoji);
        setIsShow.false();
    }, [ onChange ]);

    function unselect() {
        onChange('');
        setIsShow.false();
    }

    const categories: CategoryConfig[] = useMemo(() => allCategories.map(category => ({
        category,
        name: t('categories.' + category),
    })), [ t ]);

    const isEmoji = value && value !== '';

    return (
        <div className={clsx('flex items-center', className)}>
            <DropdownMenu.Root open={isShow} onOpenChange={setIsShow.value}>
                <DropdownMenu.Trigger
                    className='size-9 min-h-0 py-1 px-[5px] border rounded-md bg-transparent block text-inherit'
                    aria-label={t('emoji-button-aria')}
                    onClick={setIsShow.toggle}
                >
                    {isEmoji
                        ? <span className='text-lg'>{value}</span>
                        : <FacePlusIcon size={24} />
                    }
                </DropdownMenu.Trigger>
                <DropdownMenu.Content className='p-0'>
                    <EmojiPickerReact
                        onEmojiClick={innerOnChange}
                        skinTonesDisabled={true}
                        previewConfig={{ showPreview: false }}
                        emojiStyle={EmojiStyle.NATIVE}
                        searchPlaceholder={t('search-placeholder')}
                        categories={categories}
                        className='!border-0'
                    />
                </DropdownMenu.Content>
            </DropdownMenu.Root>
            {isEmoji && (
                <Button variant='transparent' size='exact' className='p-2' onClick={unselect} aria-label={t('remove-button-aria')}>
                    <Trash2Icon />
                </Button>
            )}
        </div>
    );
}

type CategoryConfig = {
    category: Categories;
    name: string;
};

const allCategories: Categories[] = [
    Categories.SUGGESTED,
    Categories.SMILEYS_PEOPLE,
    Categories.ANIMALS_NATURE,
    Categories.FOOD_DRINK,
    Categories.TRAVEL_PLACES,
    Categories.ACTIVITIES,
    Categories.OBJECTS,
    Categories.SYMBOLS,
    Categories.FLAGS,
];

type ControlledEmojiPickerProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    className?: string;
};

export function ControlledEmojiPicker<TFieldValues extends FieldValues>({ control, name, className }: ControlledEmojiPickerProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value: string | undefined, onChange: (value: string | undefined) => void } }) => {
        return (
            <EmojiPicker
                value={field.value}
                onChange={field.onChange}
                className={className}
            />
        );
    }, [ className ]);

    return (
        <Controller
            control={control as Control<FieldValues>}
            name={name}
            render={InnerSelect}
        />
    );
}

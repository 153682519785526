import { useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { useMaster } from ':frontend/context/UserProvider';
import { type InvoicingProfileFE } from ':frontend/types/Invoicing';
import { type ClientInfoFE } from ':frontend/types/Client';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert } from '../notifications';
import { SpinnerButton } from '../common';
import { PlainInvoicingProfileSelect } from '../forms';
import { type Id } from ':utils/id';
import { trpc } from ':frontend/context/TrpcProvider';
import { Trash2Icon, UserArrowRightIcon } from ':components/icons/basic';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';

type DeleteProfileModalProps = Readonly<{
    profile?: InvoicingProfileFE;
    allClients: ClientInfoFE[];
    onClose(): void;
    onDelete(deletedLocation: InvoicingProfileFE): void;
}>;

export function DeleteProfileModal({ profile, allClients, onClose, onDelete }: DeleteProfileModalProps) {
    // TODO cache the profile

    const { t } = useTranslation('components', { keyPrefix: 'deleteProfileModal' });
    const { addAlert } = useNotifications();
    const { profiles } = useMaster();

    const clients = useMemo(
        () => profile && allClients.filter(client => client.invoicingProfileId === profile.id),
        [ profile, allClients ],
    );

    const availableProfiles = useMemo(
        () => profile && profiles.filter(p => p.id !== profile.id),
        [ profile, profiles ],
    );

    const [ replacementId, setReplacementId ] = useState<Id>();

    useEffect(() => {
        setReplacementId(availableProfiles?.[0].id);
    }, [ availableProfiles ]);

    const deleteInvoicingProfileMutation = trpc.invoicing.deleteInvoicingProfile.useMutation();

    function deleteProfile() {
        if (!profile)
            return;

        deleteInvoicingProfileMutation.mutate({ id: profile.id, replacementId }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
                onClose();
            },
            onSuccess: () => {
                onDelete(profile);
            },
        });
    }

    return (
        <Modal.Root
            open={!!profile}
            onOpenChange={open => !open && onClose()}
        >
            <Modal.Content className='gap-6' closeButton={t('cancel-button')}>
                <Modal.Header className='space-y-4'>
                    <Trash2Icon size={32} className='mx-auto text-danger-500 stroke-2' />

                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                        {t('title')}
                    </Modal.Title>
                </Modal.Header>

                {availableProfiles && clients && clients.length > 0 && (<>
                    <div className='text-center'>
                        {t('client-dependency-text', { count: clients.length })}
                    </div>

                    <div className='text-center'>
                        <Link to={routesFE.clients.list.path}>
                            <Button variant='secondary' size='small'>
                                <UserArrowRightIcon className='h-4' /> {t('view-clients-button')}
                            </Button>
                        </Link>
                    </div>

                    <div>
                        <Form.Label>
                            {t('new-profile-label', { count: clients.length })}
                        </Form.Label>

                        <PlainInvoicingProfileSelect
                            id={replacementId}
                            onChange={setReplacementId}
                            options={availableProfiles}
                        />
                    </div>
                </>)}

                <Modal.Footer className='grid grid-cols-2 gap-4'>
                    <Button variant='secondary' onClick={onClose}>
                        {t('cancel-button')}
                    </Button>

                    <SpinnerButton
                        variant='danger'
                        isFetching={deleteInvoicingProfileMutation.isPending}
                        onClick={deleteProfile}
                    >
                        {t('confirm-button')}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}

import { CalendarCheck2Icon } from ':components/icons/basic';
import { Button, DropdownMenu, Form, ScrollArea } from ':components/shadcn';
import type { GoogleCalendar } from ':frontend/types/calendar/Google';
import { Query } from ':frontend/utils/common';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type GoogleCalendarsFilterProps = Readonly<{
    calendars: GoogleCalendar[];
    activeIds: Set<string>;
    toggleCalendar: (calendar: GoogleCalendar, newValue: boolean) => void;
}>;

export function GoogleCalendarsFilter({ calendars, activeIds, toggleCalendar }: GoogleCalendarsFilterProps) {
    // The default calendar should be already filtered out.
    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <Button variant='outline' size='small'>
                    <CalendarCheck2Icon />
                    <PlaceholderDots items={calendars} predicate={calendar => activeIds.has(calendar.id)} />
                </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className='p-0 w-[400px] overflow-hidden'>
                <ScrollArea className='max-h-[600px] flex flex-col'>
                    <DropdownInner calendars={calendars} activeIds={activeIds} toggleCalendar={toggleCalendar} />
                </ScrollArea>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

const placeholderColors = [ '550ce6', '57b757', 'f7d700', '0095d9', 'f75384', 'fc6d26', '808080', 'f43636' ];

type PlaceholderDotsProps<TItem extends { color: string }> = Readonly<{
    items: TItem[];
    predicate: (item: TItem) => boolean;
}>;

export function PlaceholderDots<TItem extends { color: string }>({ items, predicate }: PlaceholderDotsProps<TItem>) {
    const activeColors: string[] = [];
    for (const item of items) {
        if (predicate(item)) {
            activeColors.push(item.color);
            if (activeColors.length === placeholderColors.length)
                break;
        }
    }

    const missingNumber = placeholderColors.length - activeColors.length;
    for (let i = 0; i < missingNumber; i++)
        activeColors.push(placeholderColors[i]);

    return (
        <div className='flex items-center pl-1'>
            {activeColors.map((color, index) => (
                <div key={index} className='-ml-1 size-3 rounded-full border border-2 border-white' style={{ backgroundColor: `#${color}` }} />
            ))}
        </div>
    );
}

function DropdownInner({ calendars, activeIds, toggleCalendar }: GoogleCalendarsFilterProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'calendar' });
    const [ query, setQuery ] = useState('');

    const filteredCalendars = useMemo(() => {
        const trimmed = query.trim();
        if (!trimmed)
            return calendars;

        const filterQuery = new Query(...query.split(' '));
        return calendars.filter(calendar => calendar.query.match(filterQuery));
    }, [ calendars, query ]);

    return (
        <div className='p-2 flex flex-col gap-1'>
            <Form.Input
                value={query}
                onChange={e => setQuery(e.target.value)}
                placeholder={t('calendar-query-placeholder')}
                size='compact'
                className='mb-1'
            />
            {filteredCalendars.map(calendar => {
                const isEnabled = activeIds.has(calendar.id);

                return (
                    <div key={calendar.id} className={clsx('px-3 py-2 flex items-center gap-2 rounded transition-colors', isEnabled && 'bg-primary-50')}>
                        <div className='size-3 rounded-full' style={{ backgroundColor: `#${calendar.color}` }} />
                        <div className='flex-1 truncate'>
                            {calendar.name}
                        </div>
                        <Form.Switch
                            label={calendar.name}
                            labelClassName='sr-only'
                            checked={isEnabled}
                            onCheckedChange={value => toggleCalendar(calendar, value)}
                        />
                    </div>
                );
            })}

        </div>
    );
}

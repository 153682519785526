import type { DateTime } from 'luxon';

export type SubscriptionTierRestrictions = {
    invoicing: {
        customLogo: boolean; // if true, the user can upload a custom logo which will be used on the invoice
        maxInvoicingProfiles?: number; // the maximum number of invoicing profiles the user can create
        bankTransferEnabled: boolean; // if true user can create Order with paymentMethod bankTransfer
    };
    store: {
        videoIntroduction: boolean; // if true, the user can upload a video introduction which will be shown on the store
        customDomain: boolean; // if true, the user can use a custom domain for the store
        customDesign: boolean; // if true the user can customize the appearance of the store
        termsOfService: boolean; // if true, the user can upload a terms of service which will be shown on the store
    };
    bankIntegration: boolean; // if true, the user can generate paymentEmail and sync payments
    maxClientTags?: number; // the maximum number of client tags the user can create
    statsRange: 'any' | 'todayAndWeek'; // the ranges user can see in the stats
}

export type SubscriptionOutput = {
    status: SubscriptionStatus;
    paymentPeriod: SubscriptionTierPaymentPeriod;
    createdAt: DateTime;
    trialDays?: number;
    isCustomLogoEnabled: boolean;
    isBankIntegrationEnabled: boolean;
    tierCode: SubscriptionTierCode;
    restrictions: SubscriptionTierRestrictions;
};

export const isSubscription = {
    active({ status }: { status: SubscriptionStatus }) {
        return status === SubscriptionStatus.active
            || status === SubscriptionStatus.trialing
            || status === SubscriptionStatus.incomplete;
    },
    pending({ status }: { status: SubscriptionStatus }) {
        return status === SubscriptionStatus.pastDue
            || status === SubscriptionStatus.incompleteExpired;
    },
    trial({ status }: { status: SubscriptionStatus }) {
        return status === SubscriptionStatus.trialing;
    },
};

export enum SubscriptionStatus {
    active = 'active',
    canceled = 'canceled',
    pastDue = 'pastDue',
    trialing = 'trialing',
    unpaid = 'unpaid',
    incomplete = 'incomplete',
    incompleteExpired = 'incompleteExpired',
}

export enum SubscriptionTierCode {
    free = 'free',
    paid = 'paid',
}

export enum SubscriptionTierPaymentPeriod {
    monthly = 'monthly',
    yearly = 'yearly',
}

export enum SubscriptionTierAutoCreate {
    onRegister = 'onRegister',
}

import { useId, useState, type ReactNode } from 'react';
import type { InternalFieldName, UseFormRegisterReturn } from 'react-hook-form';
import { Button, Form } from ':components/shadcn';
import { Eye2Icon, Eye2SlashIcon } from ':components/icons/basic';

type PasswordInputProps<TFieldName extends InternalFieldName = InternalFieldName> = Readonly<{
    register: UseFormRegisterReturn<TFieldName>;
    label: ReactNode;
    isError?: boolean;
    className?: string;
}>;

export function PasswordInput({ register, label, isError, className }: PasswordInputProps) {
    const [ show, setShow ] = useState(false);
    const id = useId();

    return (
        <div>
            <Form.Label htmlFor={id}>{label}</Form.Label>
            <div className='relative'>
                <Form.Input
                    id={id}
                    {...register}
                    type={show ? 'text' : 'password'}
                    isError={isError}
                    className={className}
                />
                <Button
                    onClick={() => setShow(!show)}
                    variant='transparent'
                    size='tiny'
                    className='absolute right-0 top-0 bottom-0 h-full'
                >
                    {show ? <Eye2SlashIcon /> : <Eye2Icon />}
                </Button>
            </div>
        </div>
    );
}

import type { DateTime } from 'luxon';
import type { Id } from ':utils/id';
import { type LogData, type LogOutput, type LogType } from ':utils/entity/log';
import { OrderLogType } from ':utils/entity/order';
import { type DocumentType } from ':utils/entity/invoicing';

export class LogFE {
    private constructor(
        readonly id: Id,
        readonly createdAt: DateTime,
        readonly data: LogData,
        readonly translationId: string,
    ) {}

    static fromServer(input: LogOutput, documentType: DocumentType): LogFE {
        return new LogFE(
            input.id,
            input.createdAt,
            input.data,
            getLogTranslationId(input, documentType),
        );
    }

    /**
     * Sorts logs from the newest to the oldest.
     */
    static compareDesc(a: LogFE, b: LogFE): number {
        const dateCompare = +b.createdAt - +a.createdAt;
        if (dateCompare !== 0)
            return dateCompare;

        return (TYPE_PRIORITY[a.data.type] ?? 0) - (TYPE_PRIORITY[b.data.type] ?? 0);
    }
}

function getLogTranslationId(input: LogOutput, documentType: DocumentType): string {
    if (input.data.type === OrderLogType.Transition && 'automatic' in input.data && !!input.data.isAutomatic)
        return 'order.transitionAutomatic';

    if (input.data.type === OrderLogType.Created)
        return `${input.data.type}-${documentType}`;

    return input.data.type;
}

/**
 * A way how to add priority to log types that will be used if the date is the same.
 * A higher number means lower sorting number (i.e., the log will be "older" because of "lower timestamp").
 */
const TYPE_PRIORITY: { [key in LogType]?: number } = {
    [OrderLogType.Created]: 1,
};

import { useEffect } from 'react';
import { useUser } from ':frontend/context/UserProvider';
import { useTranslation } from 'react-i18next';
import { routesFE } from ':utils/routes';
import { Link } from 'react-router-dom';
import { trpc } from ':frontend/context/TrpcProvider';
import { AccessibilityIcon, ArrowRightIcon, BoxPlusIcon, MoneyBillCoinIcon, ShopIcon } from ':components/icons/basic';
import type { IconType } from ':components/icons/common';
import { RadioCheckIcon } from ':components/icons/custom';
import type { TFunction } from 'i18next';
import clsx from 'clsx';
import type { OnboardingStateOutput } from ':utils/entity/user';

export function OnboardingJourney() {
    const { onboarding } = useUser();
    const { t } = useTranslation('components', { keyPrefix: 'onboardingJourney' });

    const utils = trpc.useUtils();

    // TODO is this necessary?
    useEffect(() => {
        utils.user.getOnboarding.invalidate();
    }, []);

    return (
        <div className='lg:p-10 lg:rounded-4xl lg:bg-secondary-100 grid max-lg:grid-cols-[20px,1fr] lg:grid-rows-[20px,1fr] gap-4'>
            <div className='flex max-lg:flex-col items-center gap-2 max-lg:pt-[30px] lg:pl-[99px]'>
                {steps.map((step, index) => onboardingNumber(step, onboarding, index))}
            </div>

            <div className='max-lg:space-y-2 max-w-[300px] lg:max-w-full lg:grid lg:grid-cols-4 lg:gap-2'>
                {steps.map(step => onboardingTile(step, onboarding, t))}
            </div>
        </div>
    );
}

type OnboardingKey = 'storeSetUp' | 'productUpserted' | 'paymentMethodConnected' | 'clientCreated';

type OnboardingStep = Readonly<{
    // Hey stepKey, are you stuck?
    stepKey: OnboardingKey;
    icon: IconType;
    to: string;
}>;

const steps: OnboardingStep[] = [
    { stepKey: 'storeSetUp', icon: ShopIcon, to: routesFE.store.resolve({ key: 'overview' }) },
    { stepKey: 'productUpserted', icon: BoxPlusIcon, to: routesFE.products.list },
    { stepKey: 'paymentMethodConnected', icon: MoneyBillCoinIcon, to: routesFE.settings.resolve({ key: 'payments' }) },
    { stepKey: 'clientCreated', icon: AccessibilityIcon, to: routesFE.clients.list.path },
];

function onboardingNumber({ stepKey }: OnboardingStep, onboarding: OnboardingStateOutput, index: number) {
    const isCompleted = onboarding[stepKey];
    const showLine = index !== steps.length - 1;

    return (<>
        {isCompleted ? (
            <div key={stepKey} className='p-[2px]'>
                <RadioCheckIcon className='rounded-full bg-primary text-white' />
            </div>
        ) : (
            <div key={stepKey} className='w-6 h-6 rounded-full flex items-center justify-center border border-secondary-200 text-secondary-900 bg-white'>
                {index + 1}
            </div>
        )}
        {showLine && (
            <div key={index} className={clsx('max-lg:h-[60px] lg:w-[198px]', isCompleted ? 'max-lg:fl-bg-dashed-primary-y lg:fl-bg-dashed-primary-x' : 'max-lg: fl-bg-dashed-y lg:fl-bg-dashed-x' )} />
        )}
    </>);
}

function onboardingTile({ stepKey, icon, to }: OnboardingStep, onboarding: OnboardingStateOutput, t: TFunction) {
    const isCompleted = onboarding[stepKey];

    if (isCompleted) {
        return (
            <div key={stepKey} className='py-6 p-4 lg:h-full border border-secondary rounded-xl space-y-2'>
                {icon({ size: 'md', className: 'text-secondary-900' })}

                <div className='text-lg line-through'>
                    {t(stepKey)}
                </div>
            </div>
        );
    }

    return (
        <Link key={stepKey} to={to} className='block py-6 p-4 lg:h-full border border-primary rounded-xl space-y-2 text-primary bg-white'>
            {icon({ size: 'md' })}

            <div className='flex items-center gap-2 text-lg'>
                {t(stepKey)} <ArrowRightIcon size='sm' />
            </div>
        </Link>
    );
}

import { type ClientInfoFE } from ':frontend/types/Client';
import { useTranslation } from 'react-i18next';
import { OrdersTable } from ':frontend/components/orders/OrdersTable';
import { InvoiceIcon } from ':components/icons/basic';
import { Card } from ':components/shadcn';
import { ClientPackages } from ':frontend/components/client/ClientPackages';

type ClientOrdersProps = Readonly<{
    client: ClientInfoFE;
}>;

export default function ClientOrders({ client }: ClientOrdersProps) {
    const { t } = useTranslation('pages');

    return (
        <Card className='space-y-6 max-lg:bg-transparent max-lg:p-0 max-lg:border-none max-lg:shadow-none'>
            <Card.Title className='flex items-center gap-2'>
                <InvoiceIcon size='lg' />{t('client.orders.page-title', { clientName: client.name })}
            </Card.Title>

            <ClientPackages client={client} />

            <OrdersTable filterClient={client} />
        </Card>
    );
}

import { useCallback, useMemo, type ReactNode } from 'react';
import { COUNTRY_CODES, getCountryOptionsTranslator } from ':frontend/types/i18n';
import { Select, type SelectConfig } from ':components/shadcn';
import { createFilter } from 'react-select';
import { useTranslation } from 'react-i18next';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { getI18nLocale, type CountryCode } from ':utils/i18n';
import { countries as countryFlags } from 'country-flags-svg';

type CountrySelectProps = Readonly<{
    value?: CountryCode;
    onChange: (value?: CountryCode) => void;
    filterCountries?: readonly CountryCode[];
    placeholder?: string;
    isClearable?: boolean;
    disabled?: boolean;
    immutableProps?: SelectConfig<CountryOption>;
}>;

//const MIN_LETTERS_TO_OPEN = 2;

export function CountrySelect({ value, onChange, filterCountries, placeholder, isClearable, disabled, immutableProps }: CountrySelectProps) {
    const { t, i18n } = useTranslation('components', { keyPrefix: 'countrySelect' });
    //const [ menuIsOpen, setMenuIsOpen ] = useState(false);

    const locale = getI18nLocale(i18n);

    const countryToOption: (country: CountryCode) => CountryOption = useMemo(() => {
        const translator = getCountryOptionsTranslator(locale);

        return (country: CountryCode) => {
            const flagUrl = countryToFlagUrl.get(country);

            return {
                value: country,
                label: (
                    <div className='flex items-center gap-2'>
                        {flagUrl ? (
                            <img src={flagUrl} className='size-5 rounded-full object-cover border' />
                        ) : (
                            <div className='size-5'/>
                        )}
                        {translator(country)}
                    </div>
                ),
            } satisfies CountryOption;
        };
    }, [ locale ]);

    const filteredOptions = useMemo(() => {
        return (filterCountries ?? COUNTRY_CODES).map(countryToOption);
    }, [ filterCountries, countryToOption ]);

    const filterOption = useMemo(() => {
        const translator = getCountryOptionsTranslator(locale);

        function stringify(option: CountryOption) {
            return `${option.value} ${translator(option.value)}`;
        }

        return createFilter({ stringify });
    }, [ locale ]);

    return (
        <Select
            placeholder={placeholder ?? t('placeholder')}
            noOptionsMessage={() => t('no-options-message')}
            options={filteredOptions}
            value={value ? countryToOption(value) : undefined}
            onChange={option => onChange(option?.value)}
            isClearable={isClearable}
            isDisabled={disabled}
            immutableProps={immutableProps}
            filterOption={filterOption}
            //menuIsOpen={menuIsOpen}
            //onInputChange={newValue => setMenuIsOpen(newValue !== undefined && newValue.length >= MIN_LETTERS_TO_OPEN)}
        />
    );
}

type CountryOption = {
    value: CountryCode;
    label: ReactNode;
};

/** Includes flags of all supported countries + EU */
export const countryToFlagUrl = new Map<CountryCode, string>(Object.values(countryFlags).map(({ iso2, flag }) => [ iso2, flag ]));
countryToFlagUrl.set('EU', 'https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg');

type ControlledCountrySelectProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    filterCountries?: readonly CountryCode[];
    onChange?: (value?: CountryCode) => void;
    disabled?: boolean;
};

export function ControlledCountrySelect<TFieldValues extends FieldValues>({ control, name, filterCountries, onChange: externalOnChange, disabled }: ControlledCountrySelectProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value: CountryCode, onChange: (value?: CountryCode ) => void } }) => {
        const finalOnChange = externalOnChange
            ? (value?: CountryCode) => {
                field.onChange(value);
                externalOnChange(value);
            } : field.onChange;

        return (
            <CountrySelect
                value={field.value}
                onChange={finalOnChange}
                filterCountries={filterCountries}
                disabled={disabled}
            />
        );
    }, [ filterCountries, externalOnChange ]);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}

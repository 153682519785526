import { useTranslation } from 'react-i18next';
import { addVatAsNumber, type CurrencyFE, isUnderMinimalAmount, type Money, type TaxRateFE } from ':utils/money';
import { percentToFloat, roundMoney, toNumber } from ':utils/math';
import type { UseProductOrderState } from './useProductOrder';
import { MoneyDisplay } from ':components/custom';
import type { UseCustomOrderState } from './useCustomOrder';
import type { FormDiscount } from './DiscountInput';

type PriceSummaryDisplayProps = Readonly<{
    summary: PriceSummary;
}>;

export function PriceSummaryDisplay({ summary }: PriceSummaryDisplayProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.priceSummary' });

    if (summary.currencies.length === 0)
        return null;

    const isDiscount = summary.currencies[0].discount !== undefined;
    const isVat = summary.currencies[0].vat !== undefined;

    return (
        <div className='w-full flex justify-between sm:justify-end gap-2'>
            <div className='flex flex-col text-right gap-2'>
                {isDiscount && (
                    <div className='text-primary'>{t('discount-label')}</div>
                )}
                {isVat && (<>
                    <div>{t('subtotal-label')}</div>
                    <div>{t('vat-label')}</div>
                </>)}
                <div className='leading-6 text-2lg font-semibold'>{t('total-label')}</div>
            </div>

            {summary.currencies.map(forCurrency => (
                <div key={forCurrency.total.currency.id} className='min-w-[112px] flex flex-col items-end gap-2'>
                    {isDiscount && (
                        <MoneyDisplay money={forCurrency.discount!} className='text-primary' noColor />
                    )}
                    {isVat && (<>
                        <MoneyDisplay money={forCurrency.subtotal!} />
                        <MoneyDisplay money={forCurrency.vat!} />
                    </>)}
                    <div className='leading-6 text-2lg font-semibold'>
                        <MoneyDisplay money={forCurrency.total} />
                    </div>
                </div>
            ))}
        </div>
    );
}

export type PriceSummary = {
    /** A summary for each currency. */
    currencies: PriceSummaryForCurrency[];
}

/** A summary for a single currency. All prices here have the same currency. */
export type PriceSummaryForCurrency = {
    /** The total discount (of the base, i.e., without VAT). Usually negative. Undefined if there is no discount. It's defined for either all currencies or none. */
    discount?: Money;
    /** The sum of all items and discount (but without VAT). Undefined if all items have zero VAT. It's defined for either all currencies or none. */
    subtotal?: Money;
    /** The sum of all VATs. The same behavior as for subtotal. */
    vat?: Money;
    /** Subtotal plus VAT. */
    total: Money;
    /** True if the total amount is under the minimal allowed amount. */
    isUnderMinimalAmount: boolean;
};

// Let b_i be the base of a price of an item (i.e., without VAT), t_i its total price (i.e., with VAT), x_i its tax rate, and p_i its price (i.e., what's displayed to the user).
// If the vat is exclusive, then b_i = p_i.
// If it's inclusive, then b_i = p_i / (1 + x_i).
// Hover, in either way, b_i = t_i / (1 + x_i).
//
// Let's use S_* to denote the sum of a property * over all items. Then we have:
//  S_s = S_t - S_x = [sum_i b_i * (1 + x_i)] - [sum_i b_i * x_i] = sum_i b_i
// So that is subtotal, vat, and total. However, we need to apply the discount. Let d be the discount coefficient (usually positive), then we are actually looking for (1 - d) * S_s and so on.
// Lastly, we need to show the discount. It should be computed from the base, so it's -d * S_s.

export function computeCustomPriceSummary({ items, currency, discount }: UseCustomOrderState['form']): PriceSummary {
    const isOnlyZeroVat = items.every(item => item.vat.isZero);
    const commonItems = items.map(item => ({ amount: toNumber(item.unitPrice) * toNumber(item.quantity), vat: item.vat }));
    const currencies = [ computePriceSummaryForCurrency(commonItems, currency, isOnlyZeroVat, discount) ];

    return { currencies };
}

export function computProductPriceSummary({ items, discount }: UseProductOrderState['form']): PriceSummary {
    const nonFreeItems = items.filter(item => item.price);
    const isOnlyZeroVat = nonFreeItems.every(pricing => pricing.vat.isZero);

    const byCurrencies = new Map<CurrencyFE, { amount: number, vat: TaxRateFE }[]>();
    nonFreeItems.forEach(item => {
        const currency = item.price!.currency;
        const itemsForCurrency = byCurrencies.get(currency) ?? [];
        itemsForCurrency.push({ amount: item.price!.amount, vat: item.vat });
        byCurrencies.set(currency, itemsForCurrency);
    });

    const currencies = [ ...byCurrencies.entries() ].map(([ currency, items ]) => computePriceSummaryForCurrency(items, currency, isOnlyZeroVat, discount));

    return { currencies };
}

function computePriceSummaryForCurrency(items: { amount: number, vat: TaxRateFE }[], currency: CurrencyFE, isOnlyZeroVat: boolean, discount: FormDiscount | undefined): PriceSummaryForCurrency {
    const discountCoefficient = discount ? percentToFloat(toNumber(discount.amountInPercent)) : 0;
    let sumOfBase = 0;
    let sumOfVat = 0;

    items.forEach(item => {
        const { withoutVat, difference } = addVatAsNumber(item.amount, item.vat);
        sumOfBase += withoutVat;
        sumOfVat += difference;
    });

    const discountAmount = roundMoney(-discountCoefficient * sumOfBase);
    const subtotalAmount = roundMoney((1 - discountCoefficient) * sumOfBase);
    const vatAmount = roundMoney((1 - discountCoefficient) * sumOfVat);
    const totalAmount = isOnlyZeroVat ? subtotalAmount : roundMoney(subtotalAmount + vatAmount);
    const total = { amount: totalAmount, currency };

    return {
        discount: discount ? { amount: discountAmount, currency } : undefined,
        subtotal: isOnlyZeroVat ? undefined : { amount: subtotalAmount, currency },
        vat: isOnlyZeroVat ? undefined : { amount: vatAmount, currency },
        total,
        isUnderMinimalAmount: isUnderMinimalAmount(total),
    };
}

import { useMemo, useState, type KeyboardEvent, useCallback, useRef } from 'react';
import { Button, Card, Form } from ':components/shadcn';
import { EditingPhase, useEditing, useUpdating } from ':frontend/hooks';
import { ArrowsExpandDiagonal6Icon, ArrowsReduceDiagonal1Icon, ChevronDownIcon, ChevronUpIcon, ListCheckbox1Icon, Trash2Icon } from ':components/icons/basic';
import { useTranslation } from 'react-i18next';
import { trpc } from ':frontend/context/TrpcProvider';
import type { TaskOutput } from ':utils/entity/task';
import { DateTimeDisplay } from '../common';
import clsx from 'clsx';

export function TaskList() {
    const { t } = useTranslation('components', { keyPrefix: 'taskList' });
    const [ isVisible, setIsVisible ] = useState(true);
    const [ isCompletedVisible, setIsCompletedVisible ] = useState(false);
    const trpcUtils = trpc.useUtils();
    const tasksQuery = trpc.task.getTasks.useQuery();
    const tasks = tasksQuery.data;

    const uncompleted = useMemo(() => tasks?.filter(task => !task.isCompleted), [ tasks ]);
    const completed = useMemo(() => tasks?.filter(task => task.isCompleted), [ tasks ]);

    function updateTask(action: UpdateTaskAction) {
        if (!tasks)
            return;

        trpcUtils.task.getTasks.setData(undefined, computeTaskUpdate(tasks, action));
    }

    return (
        <Card className='p-5'>
            <div className='flex items-center gap-2' onClick={() => setIsVisible(!isVisible)}>
                <ListCheckbox1Icon size='sm' className='text-[#4083F5]' />

                <h2 className='grow text-secondary-700'>{t('title')}</h2>

                <Button variant='transparent' size='exact' aria-label={t(isVisible ? 'hide-tasks-aria' : 'show-tasks-aria')}>
                    {isVisible ? <ArrowsReduceDiagonal1Icon /> : <ArrowsExpandDiagonal6Icon />}
                </Button>
            </div>

            {isVisible && (<>
                <div className='my-4'>
                    <NewTaskRow onUpdate={updateTask} />
                </div>

                {!!uncompleted?.length && (
                    <div className='mb-2 flex flex-col gap-2'>
                        {uncompleted.map(task => (
                            <TaskRow
                                key={task.id}
                                task={task}
                                onUpdate={updateTask}
                            />
                        ))}
                    </div>
                )}

                {!!completed?.length && (
                    <div className='text-center'>
                        <Button
                            variant='outline'
                            size='tiny'
                            className='pl-3b pr-1b [&_svg]:size-2'
                            onClick={() => setIsCompletedVisible(!isCompletedVisible)}
                        >
                            {isCompletedVisible ? (<>
                                <ChevronUpIcon />
                                <span>{t('hide-completed-button')}</span>
                            </>) : (<>
                                <ChevronDownIcon />
                                <span>{t('show-completed-button')}</span>
                            </>)}
                            <div className='size-5 bg-secondary-50 rounded-full flex items-center justify-center'>
                                {completed.length}
                            </div>
                        </Button>
                    </div>
                )}

                {isCompletedVisible && !!completed?.length && (
                    <div className='mt-2 flex flex-col gap-2'>
                        {completed.map(task => (
                            <TaskRow
                                key={task.id}
                                task={task}
                                onUpdate={updateTask}
                            />
                        ))}
                    </div>
                )}
            </>)}
        </Card>
    );
}

export type UpdateTaskAction = {
    type: 'create' | 'update' | 'delete';
    task: TaskOutput;
};

function computeTaskUpdate(tasks: TaskOutput[], { type, task }: UpdateTaskAction): TaskOutput[] {
    if (type === 'create')
        return [ task, ...tasks ];

    if (type === 'delete')
        return tasks.filter(t => task.id !== t.id);

    const index = tasks.findIndex(t => task.id === t.id);
    if (index === -1)
        return tasks;

    const newTasks = [ ...tasks ];
    newTasks[index] = task;

    return newTasks;
}

type TaskRowProps = Readonly<{
    task: TaskOutput;
    onUpdate: (action: UpdateTaskAction) => void;
}>;

function TaskRow({ task, onUpdate }: TaskRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'taskList' });
    const deleteButtonRef = useRef<HTMLButtonElement>(null);
    const updateTaskMutation = trpc.task.updateTask.useMutation();
    const id = task.id;

    const syncText = useCallback(async (newValue: string) => {
        const trimmed = newValue.trim();
        if (!trimmed)
            return false;

        try {
            const response = await updateTaskMutation.mutateAsync({ id, text: trimmed });
            onUpdate({ type: 'update', task: response });
            return true;
        }
        catch {
            return false;
        }
    }, [ onUpdate, id, updateTaskMutation ]);

    const { state: { value: innerValue, phase }, setValue, doUpdate, setEditing } = useEditing(task.text, syncText);

    const syncIsCompleted = useCallback(async (newValue: boolean) => {
        try {
            const response = await updateTaskMutation.mutateAsync({ id, isCompleted: newValue });
            onUpdate({ type: 'update', task: response });
            return true;
        }
        catch {
            return false;
        }
    }, [ onUpdate, id, updateTaskMutation ]);

    const [ isCompleted, updateIsCompleted, isUpdatingIsCompleted ] = useUpdating<boolean>(task.isCompleted, syncIsCompleted);

    const deleteTaskMutation = trpc.task.deleteTask.useMutation({
        onError: () => {
            // TODO Do something.
        },
        onSuccess: () => {
            onUpdate({ type: 'delete', task });
        },
    });

    return (
        <div className='group flex items-start gap-2'>
            <div className='py-px'>
                <Form.Checkbox
                    label={t('toggle-task-label')}
                    labelClassName='sr-only'
                    checked={isCompleted}
                    onCheckedChange={updateIsCompleted}
                    disabled={isUpdatingIsCompleted || deleteTaskMutation.isPending}
                />
            </div>

            <div className='grow min-w-0 flex flex-col gap-1'>
                <div className='flex items-center justify-start gap-1 leading-[18px]'>
                    {phase === EditingPhase.View && (
                        <div
                            className={clsx('grow min-w-0 cursor-pointer hover:text-secondary-600 truncate',
                                isCompleted && 'text-secondary-400 line-through',
                            )}
                            onClick={setEditing}
                        >
                            {innerValue}
                        </div>
                    )}

                    {phase === EditingPhase.Editing && (
                        <Form.Textarea
                            variant='transparent'
                            size='exact'
                            className='grow'
                            autoFocus
                            value={innerValue}
                            onKeyDown={event => event.key === 'Enter' && !event.shiftKey && doUpdate()}
                            onChange={e => setValue(e.target.value)}
                            onBlur={e => e.relatedTarget !== deleteButtonRef.current && doUpdate()}
                        />
                    )}

                    {phase === EditingPhase.Updating && (
                        <span className='opacity-80 truncate'>{innerValue}</span>
                    )}
                </div>

                <DateTimeDisplay dateTime={task.createdAt} date className='text-secondary-300' />
            </div>

            <Button
                ref={deleteButtonRef}
                variant='transparent'
                size='exact'
                className='hidden group-hover:block p-px text-secondary-400'
                onClick={() => deleteTaskMutation.mutate({ id })}
            >
                <Trash2Icon />
            </Button>
        </div>
    );
}

type NewTaskRowProps = Readonly<{
    onUpdate: (action: UpdateTaskAction) => void;
}>;

function NewTaskRow({ onUpdate }: NewTaskRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'taskList' });
    const [ text, setText ] = useState('');

    const createTaskMutation = trpc.task.createTask.useMutation({
        onError: () => {
            // TODO Do something.
        },
        onSuccess: response => {
            onUpdate({ type: 'create', task: response });
            setText('');
        },
    });

    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            e.preventDefault();
            (e.target as HTMLInputElement).blur();
        }
    }

    function createTask() {
        const trimmed = text.trim();
        if (trimmed)
            createTaskMutation.mutate({ text: trimmed });
    }

    return (
        <Form.Input
            size='compact'
            value={text}
            onChange={e => setText(e.target.value)}
            placeholder={t('new-task')}
            onKeyDown={handleKeyDown}
            onBlur={createTask}
            disabled={createTaskMutation.isPending}
        />
    );
}

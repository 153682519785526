import { SpinnerButton } from ':frontend/components/common';
import { useTranslation } from 'react-i18next';
import { createTranslatedErrorAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { type ClientFE } from ':frontend/types/Client';
import { trpc } from ':frontend/context/TrpcProvider';
import { Modal, Button } from ':components/shadcn';
import { Trash2Icon } from ':components/icons/basic';

type DeleteClientModalProps = Readonly<{
    client?: ClientFE;
    onClose(): void;
    onDelete?(deletedClient: ClientFE): void;
}>;

export function DeleteClientModal({ client, onClose, onDelete }: DeleteClientModalProps) {
    // TODO cache the client

    const { t } = useTranslation('components', { keyPrefix: 'deleteClientModal' });
    const { addAlert } = useNotifications();

    const deleteClientMutation = trpc.$client.deleteClient.useMutation();

    function deleteClient() {
        if (!client)
            return;

        deleteClientMutation.mutate({ id: client.id }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
                onClose();
            },
            onSuccess: () => {
                onDelete?.(client);
            },
        });
    }

    return (
        <Modal.Root open={!!client} onOpenChange={open => !open && onClose()}>
            <Modal.Content className='gap-6'  closeButton={t('close-button')}>
                <Modal.Header className='space-y-4'>
                    <Trash2Icon size={32} className='mx-auto text-danger-500 stroke-2' />

                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                        {t('title')}
                    </Modal.Title>
                </Modal.Header>


                <div className='text-center'>
                    {t('text')}
                </div>

                <Modal.Footer className='grid grid-cols-2 gap-4'>
                    <Button variant='secondary' onClick={onClose}>
                        {t('cancel-button')}
                    </Button>

                    <SpinnerButton
                        variant='danger'
                        isFetching={deleteClientMutation.isPending}
                        onClick={deleteClient}
                    >
                        {t('confirm-button')}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}

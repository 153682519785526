import { type Filter, type FilterDefinition, type FilterFunction, type FilterMenuProps } from './FilterRow';
import { Button, Form, DropdownMenu } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { compareArrays, emptyFunction } from ':frontend/utils/common';
import { type ClientInfoFE } from ':frontend/types/Client';
import { CLIENT_STATE_VALUES, ClientStateBadge } from ':frontend/components/client/ClientStateBadge';
import { ClientState } from ':utils/entity/client';
import { enumFilterToArray } from ':utils/common';
import { CircleCopy1Icon } from ':components/icons/basic';

const filterName = 'clientState';

type FilterState = {
    [key in ClientState]: boolean;
};

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <div className='p-1.5'>
            {CLIENT_STATE_VALUES.map(clientState => (
                <div
                    key={clientState}
                    className='flex items-center gap-4 px-4 py-3 rounded-sm cursor-pointer hover:bg-primary-100'
                    onClick={() => setState({ ...state, [clientState]: !state[clientState] })}
                >
                    {/**
                  * This is needed in order to make the checkbox clickable. Without this, when clicking on the checkbox, the state is changing, but the checkbox still looks the same. Only after changing the state on other client state, the checkbox will change.
                  * For some reason, nothing else really works. It is possible to use something like key={'' + state[clientState]} to force the component to re-render, but this seems unnecessary.
                  * It is probably caused by the whole Dropdown thing that somehow manipulates the clients.
                  */}
                    <Form.Checkbox checked={state[clientState]}
                        onClick={e => {
                            setState({ ...state, [clientState]: !state[clientState] });
                            e.stopPropagation();
                        }}
                        onChange={emptyFunction}
                    />

                    <ClientStateBadge state={clientState} />
                </div>
            ))}
        </div>
    );
}

function FilterItemBadge() {
    return null;
}

function remove(state: FilterState, item: ClientState): FilterState {
    return {
        ...state,
        [item]: false,
    };
}

function toItems(state: FilterState): ClientState[] {
    return Object.entries(state)
        .filter(([ , value ]) => value)
        .map(([ key ]) => key as ClientState);
}

function createFilterFunction(state: FilterState): FilterFunction<ClientInfoFE> {
    if (toItems(state).length === 0)
        return () => true;

    return (data: ClientInfoFE) => !!state[data.state];
}

function createFilterRowDropdown(filterName: string, ToggleMenu: React.FC<FilterMenuProps<FilterState>>): React.FC<FilterMenuProps<FilterState>> {
    return ({ state, setState }: FilterMenuProps<FilterState>) => {
        const { t } = useTranslation('common', { keyPrefix: 'filters' });

        const selectedStates = CLIENT_STATE_VALUES.filter(clientState => state[clientState]);

        return (
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <Button variant='outline' className={selectedStates.length > 0 ? 'border-primary text-primary hover:border-primary' : undefined}>
                        {selectedStates.length > 0 && selectedStates.map(clientState => <ClientStateBadge key={clientState} state={clientState} />).flatMap((e, index) => [ e, <span key={`or_${index}`}>/</span> ]).slice(0, -1)}
                        {selectedStates.length === 0 && (
                            <div className='flex items-center gap-2'>
                                <CircleCopy1Icon size='md' /> {t(`${filterName}.menu-button`)}
                            </div>
                        )}
                    </Button>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content side='bottom' align='start'>
                    <ToggleMenu state={state} setState={setState} />
                </DropdownMenu.Content>
            </DropdownMenu.Root>
        );
    };
}

function toServer(state: FilterState, previous: string[] | undefined): string[] {
    const current = enumFilterToArray(state).sort();
    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

const ClientStateFilter: FilterDefinition<ClientState, FilterState, ClientInfoFE> = {
    name: filterName,
    defaultValues: {
        [ClientState.lead]: false,
        [ClientState.active]: false,
        [ClientState.inactive]: false,
    },
    FilterToggleMenu,
    FilterRowMenu: createFilterRowDropdown(filterName, FilterToggleMenu),
    FilterItemBadge,
    remove,
    toItems,
    createFilterFunction,
    toServer: toServer as (state: FilterState, previous: unknown | undefined) => string[],
};

export default ClientStateFilter as Filter;

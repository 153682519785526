import { useTranslation } from 'react-i18next';
import { OrdersTable } from ':frontend/components/orders/OrdersTable';
import { InfoCard } from ':frontend/components/settings/InfoCard';
import { Card } from ':components/shadcn';

export default function Orders() {
    const { t } = useTranslation('pages');

    return (
        <div className='px-4 py-9 fl-main-scroller'>
            <div className='max-w-[1200px] mx-auto space-y-8'>
                <h1 className='leading-9 text-2xl font-semibold'>{t('orders.page-title')}</h1>

                <InfoCard infoKey='orders' />

                <Card className='max-lg:p-0 max-lg:bg-transparent max-lg:shadow-none max-lg:border-none'>
                    <OrdersTable />
                </Card>
            </div>
        </div>
    );
}

import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimeDisplay, Pagination, Table } from ':frontend/components/common';
import { MoneyDisplayString } from ':components/custom';
import { OrderInfoFE, SchedulerOrderInfoFE } from ':frontend/types/orders/Order';
import { OrderStateBadge } from ':frontend/components/orders/OrderStateBadge';
import { getSkeletonArray } from ':utils/math';
import { useClients, usePagination, type PaginationController } from ':frontend/hooks';
import { routesBE, routesFE } from ':utils/routes';
import { ClientIconLink } from ':frontend/components/client/ClientIconLink';
import FilterRow, { useFilters } from ':frontend/components/common/filters/FilterRow';
import createOrderClientFilter, { filterName as orderClientFilterName } from ':frontend/components/common/filters/OrderClientFilter';
import OrderStateFilter from '../common/filters/OrderStateFilter';
import { SortOrder } from ':utils/common';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { type TFunction } from 'i18next';
import { TeamMemberBadge } from '../team/TeamMemberBadge';
import { OrderCreatedBeforeFilter, OrderCreatedAfterFilter } from '../common/filters/OrderDateFilter';
import { type Id, type Entity } from ':utils/id';
import { TeamMemberRole } from ':utils/entity/team';
import { type OrderInfoOutput, type SchedulerOrderInfoOutput } from ':utils/entity/order';
import { trpc } from ':frontend/context/TrpcProvider';
import type { DateTime } from 'luxon';
import { InvoiceIcon, Receipt1Icon } from ':components/icons/basic';
import { SortOrderIcon } from ':components/icons/custom';
import { useMaster } from ':frontend/context/UserProvider';
import { productStyles } from ':components/store/product/ProductCard';
import { cn } from ':components/shadcn/utils';
import { DocumentType, PaymentMethod, type OrderState } from ':utils/entity/invoicing';

type OrderByState = {
    createdAt: SortOrder;
    index: SortOrder;
};

type OrderType = OrderInfoFE | SchedulerOrderInfoFE;

type OrdersTableProps = Readonly<{
    filterClient?: Entity;
    filterProfile?: Entity;
}>;

export function OrdersTable({ filterClient, filterProfile }: OrdersTableProps) {
    const [ orderBy, setOrderBy ] = useState<OrderByState>({
        createdAt: SortOrder.Descending,
        index: SortOrder.Descending,
    });

    const { clients } = useClients();
    const filters = useMemo(() => [
        OrderStateFilter,
        OrderCreatedAfterFilter,
        OrderCreatedBeforeFilter,
        ...(filterClient ? [] : [ createOrderClientFilter(clients ?? []) ]),
    ], [ filterClient, clients ]);

    const filtersControl = useFilters(filters);
    const stateFilterToServer = filtersControl.toServer(OrderStateFilter.name) as OrderState[] | undefined;
    const clientFilterNameToServer = filtersControl.toServer(orderClientFilterName);
    const clientFilterToServer = useMemo(() => filterClient ? [ filterClient.id ] : clientFilterNameToServer as Id[] | undefined, [ filterClient, clientFilterNameToServer ]);
    const profileFilterToServer = filterProfile?.id;
    const createAtAfterFilterToServer = filtersControl.toServer(OrderCreatedAfterFilter.name) as DateTime | undefined;
    const createdBeforeFilterToServer = filtersControl.toServer(OrderCreatedBeforeFilter.name) as DateTime | undefined;

    const pagination = usePagination();
    const { page, reset } = pagination;
    useEffect(() => reset(), [ filtersControl.state, reset ]);

    const isMasterOrFreelancer = !!toMaster(useUser());
    const { isInvoicingEnabled } = useMaster().teamSettings;

    const filtredOrdersOutputs = trpc.order.getOrders.useQuery({
        state: stateFilterToServer,
        client: clientFilterToServer,
        invoicingProfile: profileFilterToServer,
        createdAfter: createAtAfterFilterToServer,
        createdBefore: createdBeforeFilterToServer,
        orderBy,
        page,
    });

    const orders = useMemo(() => {
        if (!filtredOrdersOutputs.data)
            return;
        const filtredOrders = isMasterOrFreelancer
            ? (filtredOrdersOutputs.data as OrderInfoOutput[]).map(OrderInfoFE.fromServer)
            : (filtredOrdersOutputs.data as SchedulerOrderInfoOutput[]).map(SchedulerOrderInfoFE.fromServer);
        return filtredOrders;
    }, [ filtredOrdersOutputs, isMasterOrFreelancer ]);

    function switchOrder() {
        setOrderBy({
            createdAt: orderBy.createdAt === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending,
            index: SortOrder.Descending,
        });
    }

    const showClients = !filterClient;

    return (
        <div>
            <FilterRow className='w-full' rowSelectClassName='w-full max-md:flex-col-reverse max-md:items-start' control={filtersControl} />

            <div className='mt-2 fl-hide-scrollbar overflow-x-auto max-lg:-mx-4'>
                <div className='max-lg:px-4 max-lg:w-fit'>
                    <Table py={3} className='bg-white'>
                        <OrdersHeader orders={orders} showClients={showClients} isInvoicingEnabled={isInvoicingEnabled} orderBy={orderBy} switchOrder={switchOrder}  />

                        <Table.Body>
                            <OrdersList orders={orders} showClients={showClients} pagination={pagination} />
                        </Table.Body>
                    </Table>
                </div>
            </div>

            <Pagination controller={pagination} className='mt-4' />
        </div>
    );
}

type OrdersHeaderProps = Readonly<{
    orders?: OrderType[];
    showClients: boolean;
    isInvoicingEnabled: boolean;
    orderBy: OrderByState;
    switchOrder: () => void;
}>;

function OrdersHeader({ orders, showClients, isInvoicingEnabled, orderBy, switchOrder }: OrdersHeaderProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersTable' });
    const userContext = useUser();
    const isMasterOrFreelancer = !!toMaster(userContext);

    if (!orders)
        return <Table.HeaderSkeleton height={17} />;

    return isMasterOrFreelancer
        ? masterOrdersHeader(showClients, isInvoicingEnabled, userContext.role === TeamMemberRole.master, orderBy, switchOrder, t)
        : schedulerOrdersHeader(showClients, orderBy, switchOrder, t);
}

function masterOrdersHeader(showClients: boolean, isInvoicingEnabled: boolean, showScheduler: boolean, orderBy: OrderByState, switchOrder: () => void, t: TFunction) {
    return (
        <Table.Header>
            <Table.HeaderCol>{t('title-label')}</Table.HeaderCol>
            {showClients && (
                <Table.HeaderCol xs={2}>{t('client-label')}</Table.HeaderCol>
            )}
            <Table.HeaderCol className='whitespace-nowrap'>
                <div className='flex items-center gap-1'>
                    <div>{t('created-label')}</div>

                    <div onClick={switchOrder} className='cursor-pointer select-none'>
                        <SortOrderIcon orderBy={orderBy.createdAt} size='md' />
                    </div>
                </div>
            </Table.HeaderCol>
            <Table.HeaderCol className='text-right'>{t('price-label')}</Table.HeaderCol>
            <Table.HeaderCol className='text-right'>{t('state-label')}</Table.HeaderCol>
            <Table.HeaderCol xs='fit' className='text-right'>{t(isInvoicingEnabled ? 'invoice-label' : 'receipt-label')}</Table.HeaderCol>
            {showScheduler && (
                <Table.HeaderCol xs='fit' className='whitespace-nowrap'>{t('scheduler-label')}</Table.HeaderCol>
            )}
        </Table.Header>
    );
}

function schedulerOrdersHeader(showClients: boolean, orderBy: OrderByState, switchOrder: () => void, t: TFunction) {
    return (
        <Table.Header>
            <Table.HeaderCol xs={6}>{t('title-label')}</Table.HeaderCol>

            {showClients && (
                <Table.HeaderCol xs={2}>{t('client-label')}</Table.HeaderCol>
            )}

            <Table.HeaderCol className='whitespace-nowrap'>
                <span className='mr-1'>{t('created-label')}</span>

                <div onClick={switchOrder} className='cursor-pointer'>
                    <SortOrderIcon
                        orderBy={orderBy.createdAt}
                        size='md'
                    />
                </div>
            </Table.HeaderCol>
            <Table.HeaderCol className='text-center'>{t('state-label')}</Table.HeaderCol>
        </Table.Header>
    );
}

type OrdersListProps = Readonly<{
    orders?: OrderType[];
    showClients: boolean;
    pagination: PaginationController;
}>;

function OrdersList({ orders, showClients, pagination }: OrdersListProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersTable' });
    const userContext = useUser();
    const isMasterOrFreelancer = !!toMaster(userContext);

    if (!orders)
        return <>{getSkeletonArray(pagination).map(id => <Table.RowSkeleton key={id} height={20} />)}</>;

    if (orders.length === 0) {
        return (
            <Table.Row>
                <Table.Col colSpan={7} >
                    <div className='text-center text-danger-500 text-lg py-12'>
                        {t('no-orders-text')}
                    </div>
                </Table.Col>
            </Table.Row>
        );
    }

    return (<>
        {orders.map(order => isMasterOrFreelancer
            ? masterOrderRow(order as OrderInfoFE, showClients, userContext.role === TeamMemberRole.master)
            : schedulerOrderRow(order as SchedulerOrderInfoFE, showClients),
        )}
    </>);
}

function masterOrderRow(order: OrderInfoFE, showClients: boolean, showScheduler: boolean) {
    const link = routesFE.orders.detail.resolve({ id: order.id });
    const styles = order.productType && productStyles[order.productType];

    return (
        <Table.Row className='hover:bg-secondary-50' key={order.id}>
            <Table.Col link={link} className='max-w-xs'>
                <div className={cn('flex items-center gap-2', styles?.color)}>
                    {styles && styles.icon({ size: 22, className: 'shrink-0' })}
                    <span className='font-semibold truncate'>
                        {order.title}
                    </span>
                </div>
            </Table.Col>

            {showClients && (
                <Table.Col xs={2} className='max-w-xs'>
                    <ClientIconLink client={order.client} />
                </Table.Col>
            )}

            <Table.Col link={link}><DateTimeDisplay date dateTime={order.createdAt} /></Table.Col>
            <Table.Col link={link} className='text-right'><MoneyDisplayString money={order.price} /></Table.Col>

            <Table.Col link={link} className='text-right'>
                <div className='inline-block'><OrderStateBadge order={order} /></div>
            </Table.Col>

            <Table.Col xs='fit' className='text-center leading-0'>
                {order.paymentMethod !== PaymentMethod.noInvoice && (
                    <a href={routesBE.public.document.resolve({ id: order.id })} target='_blank' rel='noreferrer' className='inline-block'>
                        {order.documentType === DocumentType.Invoice && <InvoiceIcon size='md' />}
                        {order.documentType === DocumentType.Receipt && <Receipt1Icon size='md' />}
                    </a>
                )}
            </Table.Col>

            {showScheduler && (
                <Table.Col xs='fit' truncate className='select-none'>
                    <div className='flex justify-center gap-2'>
                        {order.schedulerIds.map(id => (
                            <TeamMemberBadge key={id} appUserId={id} />
                        ))}
                    </div>
                </Table.Col>
            )}
        </Table.Row>
    );
}

function schedulerOrderRow(order: SchedulerOrderInfoFE, showClients: boolean) {
    return (
        <Table.Row className='hover:bg-secondary-50' key={order.id}>
            <Table.Col xs={6} truncate>{order.title}</Table.Col>
            {showClients && (
                <Table.Col xs={2} truncate>
                    <ClientIconLink client={order.client} />
                </Table.Col>
            )}
            <Table.Col><DateTimeDisplay date dateTime={order.createdAt} /></Table.Col>
            <Table.Col><OrderStateBadge order={order} /></Table.Col>
        </Table.Row>
    );
}


import { capitalize } from ':utils/common';

export type Person = {
    readonly email?: string;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly phoneNumber?: string;
};

/** Returns a full person name (or email). */
export function getPersonName(person: Person): string {
    if (person.firstName)
        return person.firstName + (person.lastName ? ' ' + person.lastName : '');

    if (person.lastName)
        return person.lastName;

    return person.email ?? '#';
}

/** Shorten some words in the person's name. */
export function getPersonShortName(person: Person): string {
    if (person.firstName) {
        if (!person.lastName)
            return person.firstName;

        const dot = person.lastName.length > 1 ? '.' : '';
        return `${person.firstName} ${person.lastName[0]}${dot}`;
    }

    if (person.lastName)
        return person.lastName;

    if (person.email)
        return person.email.slice(0, person.email.indexOf('@'));

    return '#';
}

/** Returns 2 characters (if possible) or 1 character (fallback). */
export function getPersonShortcut(person: Person): string {
    if (person.firstName) {
        return person.lastName
            ? person.firstName[0].toUpperCase() + person.lastName[0].toUpperCase()
            : capitalize(person.firstName.slice(0, 2));
    }

    return capitalize(((person.lastName || person.email) ?? '#').slice(0, 2));
}

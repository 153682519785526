import { FlowlanceBanner } from ':components/icons/logos';
import { Button } from ':components/shadcn';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { GoogleOauthButton } from ':frontend/components/auth/googleButton';
import { PillsSetSort } from '../../components/auth/PillsSet';
import { ChevronLeftIcon } from ':components/icons/basic';
import { routeToDisplayString, routesFE, routesStore } from ':utils/routes';
import { RegisterUserForm } from ':frontend/components/auth/RegisterUserForm';
import { RegisterPhase, useRegisterEmail } from ':frontend/components/auth/useRegisterEmail';
import { RegisterPersonForm } from ':frontend/components/auth/RegisterPersonForm';
import clsx from 'clsx';

export default function Register() {
    const { t } = useTranslation('pages', { keyPrefix: 'register' });

    const [ searchParams ] = useSearchParams();
    const storeSlug = searchParams.get('claimed');
    if (!storeSlug)
        throw new Error('Claimed url is missing');

    const fullUrl = routeToDisplayString(routesStore.root.absoluteResolve({ store: storeSlug }));

    const [ isEmailOpen, setIsEmailOpen ] = useState(false);
    const navigate = useNavigate();


    const toggleEmailForm = () => setIsEmailOpen(!isEmailOpen);
    const handleBack = () => navigate(routesFE.register.claimUrl);

    const [ isSorted, setIsSorted ] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setIsSorted(true), 100);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className='min-w-80 h-screen w-screen bg-secondary-50 flex md:items-center md:justify-center overflow-auto'>
            <div className='flex flex-col z-20 w-full md:space-x-20 md:flex-row md:max-w-5xl'>
                <div className={clsx('order-2 md:order-1 md:w-1/2 p-10 md:p-0 flex flex-col justify-center gap-8 bg-secondary-50 rounded-t-xl shadow-[0px_-25px_35px_0px_rgba(0,0,0,0.08)] md:shadow-none md:bg-transparent transition-transform duration-1000', !isSorted && 'max-md:translate-y-[100vh]')}>
                    <div className='space-y-4'>
                        <div className='flex space-x-4 items-center'>
                            <Button variant='outline' size='exact' className='size-7 [&_svg]:size-3 bg-transparent' aria-label={t(`prev-claim-button`)} onClick={handleBack}>
                                <ChevronLeftIcon />
                            </Button>

                            <FlowlanceBanner />
                        </div>

                        <div className='text-lg'>{fullUrl} {t('is-yours')}</div>

                        <h1 className='text-2xl font-semibold leading-8 md:text-4xl md:leading-10'>{t('title')}</h1>

                        {isEmailOpen ? (
                            <RegisterEmail storeSlug={storeSlug} />
                        ) : (<>
                            <GoogleOauthButton type='register' storeSlug={storeSlug} variant='primary' />

                            <div className='text-lg'>{t('or-label')}</div>

                            <Button
                                className='w-full bg-transparent'
                                variant='outline'
                                onClick={toggleEmailForm}
                            >
                                {t('email-and-password')}
                            </Button>
                        </>)}
                    </div>

                    <p className='text-sm max-w-prose inline-block pb-40 md:pb-0'>
                        <Trans
                            i18nKey='terms-privacy-text'
                            t={t}
                            components={{
                                a1: <Link className='text-secondary whitespace-nowrap underline' to='/static/Terms.pdf' target='_blank' rel='noreferrer' />,
                                a2: <Link className='text-secondary whitespace-nowrap underline' to='/static/Privacy.pdf' target='_blank' rel='noreferrer' />,
                            }}
                        />
                    </p>
                </div>

                <div className='flex justify-center order-1 h-[220px] py-4 md:order-2 md:h-auto md:w-1/2'>
                    <PillsSetSort isSorted={isSorted} />
                    <img src='/static/images/login/login-store-mockup.svg' className='h-fit w-[240px] md:w-[375px] drag-none' />
                </div>
            </div>

            <div className='max-md:hidden w-96 h-96 rounded-full bg-primary-500 blur-[150px] fixed -bottom-10 -right-10' />
        </div>
    );
}

function RegisterEmail({ storeSlug }: Readonly<{ storeSlug: string }>) {
    const { t } = useTranslation('pages', { keyPrefix: 'register' });

    const { phase, userData, isFetching, submitUserForm, submitPersonForm } = useRegisterEmail(storeSlug);

    return phase === RegisterPhase.UserForm ? (<>
        <RegisterUserForm defaultValues={userData} isFetching={isFetching} onSubmit={submitUserForm} />

        <div className='text-lg'>{t('or-label')}</div>

        <GoogleOauthButton type='register' storeSlug={storeSlug} variant='outline' />
    </>) : (<>
        <RegisterPersonForm isFetching={isFetching} onSubmit={submitPersonForm} />

        <div className='h-4' />

        <div className='h-10' />
    </>);
}

import { useEffect, useMemo, type FC } from 'react';
import { Form } from ':components/shadcn';
import { useForm } from 'react-hook-form';
import { type ClientFE } from ':frontend/types/Client';
import { useTranslation } from 'react-i18next';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { ControlledInvoicingProfileSelect, ControlledCountrySelect, ControlledTimezoneSelect } from ':frontend/components/forms';
import type { CountryCode, TimezoneCode } from ':utils/i18n';
import { ClientAccessType, type ClientUpdate } from ':utils/entity/client';
import { stringToPatch } from ':frontend/utils/common';
import type { OmitId } from ':utils/id';

type ClientPreferencesFormProps = Readonly<{
    onSubmit: (output: OmitId<ClientUpdate>) => void;
    defaultValue: ClientFE;
    onChange?: (isDirty: boolean) => void;
    saveButton?: FC<{ onClick: () => void }>;
}>;

export function ClientPreferencesForm({ onSubmit, defaultValue, onChange, saveButton }: ClientPreferencesFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientForm' });

    const {
        form,
        handleSubmit,
        showProfileSelect,
        isEditable,
    } = useClientPreferencesForm(onSubmit, defaultValue, onChange);
    const { control } = form;

    return (
        <Form.Root onSubmit={handleSubmit} className='space-y-6'>
            <div className='space-y-6'>
                <div className='space-y-2'>
                    <Form.Label>{t('timezone-label')}</Form.Label>

                    <ControlledTimezoneSelect
                        control={control}
                        name='timezone'
                        disabled={!isEditable}
                    />

                    <div>{t('timezone-description')}</div>
                </div>

                {/*
                    Hidden for the same reason as AppUser locale.
                    <div className='space-y-2'>
                        <Form.Label>{t('locale-label')}</Form.Label>

                        <ControlledLocaleSelect
                            control={control}
                            name='locale'
                            type='base'
                            disabled={!isEditable}
                        />

                        <div>{t('locale-description')}</div>
                    </div>
                */}

                <div className='space-y-2'>
                    <Form.Label>{t('country-label')}</Form.Label>

                    <ControlledCountrySelect
                        control={control}
                        name='countryForPreferences'
                        disabled={!isEditable}
                    />

                    <div>{t('country-description')}</div>
                </div>

                {showProfileSelect && (
                    <div className='space-y-2'>
                        <Form.Label>{t('profile-label')}</Form.Label>

                        <ControlledInvoicingProfileSelect
                            control={control}
                            name='invoicingProfileId'
                            disabled={!isEditable}
                        />
                    </div>
                )}
            </div>

            <>{saveButton?.({ onClick: handleSubmit })}</>
        </Form.Root>
    );
}

export function useClientPreferencesForm(
    onSubmit: (output: OmitId<ClientUpdate>) => void,
    defaultValue: ClientFE,
    onChange?: (isDirty: boolean) => void,
) {
    const form = useForm<ClientPreferencesFormData>();
    const isDirty = form.formState.isDirty;
    const userContext = useUser();
    const masterContext = toMaster(userContext);

    const isEditable = useMemo(() => {
        if (masterContext)
            return true;
        return defaultValue.access === ClientAccessType.owner;
    }, [ defaultValue, userContext, masterContext ]);

    useEffect(() => onChange?.(isDirty), [ isDirty, onChange ]);

    function onValidSubmit(data: ClientPreferencesFormData) {
        onSubmit(formToOutput(defaultValue, data));
    }

    useEffect(() => {
        form.reset(inputToForm(defaultValue, !!masterContext));
    }, [ defaultValue ]);

    return {
        form,
        handleSubmit: form.handleSubmit(onValidSubmit),
        showProfileSelect: masterContext && masterContext.profiles.length > 1,
        isEditable,
    };
}

type ClientPreferencesFormData = {
    timezone: TimezoneCode;
    // Hidden for the same reason as AppUser locale.
    // locale: LocaleCode;
    countryForPreferences: CountryCode;
    invoicingProfileId?: string;
};

function inputToForm(client: ClientFE, isMaster: boolean): ClientPreferencesFormData {
    return {
        timezone: client.timezone,
        // Hidden for the same reason as AppUser locale.
        // locale: client.locale,
        countryForPreferences: client.country,
        invoicingProfileId: isMaster ? client.invoicingProfileId : undefined,
    };
}

function formToOutput(client: ClientFE, data: ClientPreferencesFormData): OmitId<ClientUpdate> {
    return {
        timezone: stringToPatch(client.timezone, data.timezone),
        // Hidden for the same reason as AppUser locale.
        // locale: stringToPatch(client.locale, data.locale),
        country: stringToPatch(client.country, data.countryForPreferences),
        invoicingProfileId: data.invoicingProfileId && stringToPatch(client.invoicingProfileId, data.invoicingProfileId),
    };
}

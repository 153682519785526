import { TaskList } from '../task/TaskList';
import { useState } from 'react';
import { Button, ScrollArea } from ':components/shadcn';
import { SidebarRight2ShowIcon, SidebarRight3HideIcon } from ':components/icons/basic';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import theme from '../../../tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';

const fullConfig = resolveConfig(theme);

export function CalendarSidebar() {
    const lgScreen = useMediaQuery({ query: `(min-width: ${fullConfig.theme.screens.lg})` });

    const [ isCollapsed, setIsCollapsed ] = useState(!lgScreen);

    return (
        // The max-height and flex are necessary for the scroll area to work. If the dimensions change, they need to be recalculated.
        <div className={clsx(`
            shrink-0
            max-lg:fixed max-lg:z-30 max-lg:right-0 max-lg:top-[61px] max-md:bottom-[80px]
            md:max-h-[calc(100vh-61px)] md:h-full
            flex flex-col border-l border-secondary-100 transition-all bg-secondary-50
            `, isCollapsed ? 'w-[37px]' : 'w-[281px]',
        )}>
            <div className='shrink-0 h-12 flex items-center'>
                <Button
                    size='small'
                    variant='transparent'
                    className={clsx('[&_svg]:size-5 px-2 text-secondary-300 transition-opacity', isCollapsed && 'text-secondary-500')}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    {isCollapsed ? (
                        <SidebarRight2ShowIcon />
                    ) : (
                        <SidebarRight3HideIcon />
                    )}
                </Button>
            </div>

            <div className='shrink min-h-0 border-t border-secondary-100'>
                {!isCollapsed && (
                    <ScrollArea className='h-full flex flex-col'>
                        <div className='max-w-[280px] p-1'>
                            <TaskList />
                        </div>
                    </ScrollArea>
                )}
            </div>
        </div>
    );
}

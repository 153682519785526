import { Component } from 'react';
import { EventRowMixin } from './EventRowMixin';
import { eventLevels } from './utils/eventLevels';
import range from 'lodash/range';

const isSegmentInSlot = (seg, slot) => seg.left <= slot && seg.right >= slot;
const eventsInSlot = (segments, slot) => segments.filter(seg => isSegmentInSlot(seg, slot)).length;

export class EventEndingRow extends Component {
    render() {
        let {
            segments,
            slotMetrics: { slots },
        } = this.props;
        let rowSegments = eventLevels(segments).levels[0];

        let current = 1,
            lastEnd = 1,
            row = [];

        while (current <= slots) {
            let key = '_lvl_' + current;

            let { event, left, right, span } =
        rowSegments.filter((seg) => isSegmentInSlot(seg, current))[0] || {} //eslint-disable-line

            if (!event) {
                current++;
                continue;
            }

            let gap = Math.max(0, left - lastEnd);

            if (this.canRenderSlotEvent(left, span)) {
                let content = EventRowMixin.renderEvent(this.props, event);

                if (gap)
                    row.push(EventRowMixin.renderSpan(slots, gap, key + '_gap'));


                row.push(EventRowMixin.renderSpan(slots, span, key, content));

                lastEnd = current = right + 1;
            }
            else {
                if (gap)
                    row.push(EventRowMixin.renderSpan(slots, gap, key + '_gap'));

                row.push(
                    EventRowMixin.renderSpan(
                        slots,
                        1,
                        key,
                        this.renderShowMore(segments, current),
                    ),
                );
                lastEnd = current = current + 1;
            }
        }

        return <div className='flex'>{row}</div>;
    }

    canRenderSlotEvent(slot, span) {
        let { segments } = this.props;

        return range(slot, slot + span).every(s => {
            let count = eventsInSlot(segments, s);

            return count === 1;
        });
    }

    renderShowMore(segments, slot) {
        let count = eventsInSlot(segments, slot);
        // const { t } = this.props;

        return count ? (
            <button
                type='button'
                key={'sm_' + slot}
                className='rbc-show-more'
                onClick={e => this.showMore(slot, e)}
            >
                {/*
                    // TODO Use translation (would require turning this into a functional component)
                    {t('calendar.showMore', { count })}
                */}
                +{count} more
            </button>
        ) : (
            false
        );
    }

    showMore(slot, e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onShowMore(slot, e.target);
    }
}

EventEndingRow.defaultProps = {
    ...EventRowMixin.defaultProps,
};

import { env } from ':env';
import qs from 'qs';
import { deepClone } from './common';

// TODO use Symbol instead
const FRAGMENT_KEY = '#fragment';
export type Fragment = typeof FRAGMENT_KEY;

export class Route<TParam extends string = never, TQuery extends string = never> {
    constructor(
        /**
         * Used for matching. E.g., `/users/:id`.
         * Contains only the last part, i.e., without any prefixing routers.
         */
        readonly path: string,
        /**
         * On backend, some part of the path is usually taken by express routers, and then the rest is used for the final routing.
         */
        private readonly prefix: string,
        /**
         * The same as prefix but absolute.
         */
        private readonly absolutePrefix: string,
    ) {}

    // TODO what does this return?
    resolve(params: Record<TParam, string>, query?: Record<TQuery, string | undefined>): string {
        return this.innerResolve(this.prefix + this.path, params, query);
    }

    // TODO what does this return?
    get absolutePath(): string {
        return this.absolutePrefix + this.path;
    }

    // TODO what does this return?
    absoluteResolve(params: Record<TParam, string>, query?: Record<TQuery, string | undefined>): string {
        return this.innerResolve(this.absolutePrefix + this.path, params, query);
    }

    // TODO what does this return?
    private innerResolve(value: string, params: Record<TParam, string>, query?: Record<TQuery, string | undefined>): string {
        // TODO use URL and URLSearchParams
        // https://developer.mozilla.org/en-US/docs/Web/API/URL_API
        for (const key of Object.keys(params))
            value = value.replace(':' + key, params[key as keyof typeof params]);

        if (query) {
            const queryParams = deepClone(query);
            if (FRAGMENT_KEY in queryParams)
                delete queryParams[FRAGMENT_KEY];

            value += qs.stringify(queryParams, { addQueryPrefix: true });

            if (FRAGMENT_KEY in query)
                value += '#' + query[FRAGMENT_KEY];
        }

        return value;
    }
}

export class Router {
    private constructor(
        /**
         * Relative path (join of all parent relative routes).
         */
        readonly prefix: string,
        /**
         * Absolute path (join of all parent absolute routes).
         */
        readonly absolutePrefix: string,
    ) {}

    static root(prefix: string, host: string): Router {
        return new Router(prefix, host + prefix);
    }

    child(childPrefix: string): Router {
        return new Router(this.prefix + childPrefix, this.absolutePrefix + childPrefix);
    }

    route<TParam extends string = never, TQuery extends string = never>(path: string): Route<TParam, TQuery> {
        return new Route(path, this.prefix, this.absolutePrefix);
    }
}


// Not all routes are included here - only those that are needed by frontend. Exclusively backend routes are kept in the backend.

const backendRouter = Router.root('/api', env.VITE_APP_URL);
const authRouter = backendRouter.child('/auth');
const publicRouter = backendRouter.child('/public');
const privateRouter = backendRouter.child('/private');
const trpcRouter = backendRouter.child('/trpc');
const googleRouter = backendRouter.child('/google');

export const routesBE = {
    $router: backendRouter,
    trpc: {
        $router: trpcRouter,
    },
    auth: {
        $router: authRouter,
        login: authRouter.route('/login'),
        registerValidate: authRouter.route('/register-validate'),
        register: authRouter.route('/register'),
        logout: authRouter.route('/logout'),
        refresh: authRouter.route('/refresh'),
        updatePassword: authRouter.route('/update-password'),
        resetPassword: authRouter.route('/reset-password'),
    },
    public: {
        $router: publicRouter,
        version: publicRouter.route('/version'),
        document: publicRouter.route<'id'>('/orders/:id/document'),
        payment: publicRouter.route<'id'>('/orders/:id/pay'),
        $legacy: {
            /**
             * @deprecated Not used since 1.1.2025, but some users still might need this.
             * Delete after next tax season, just to be sure (1.6.2027) ... or when the root domain changes.
             */
            invoice: publicRouter.route<'id'>('/orders/:id/invoice'),
        },
    },
    private: {
        $router: privateRouter,
        invoicePreview: privateRouter.route('/orders/preview'),
        ordersExport: privateRouter.route('/orders/export'),
        invoicingProfileExample: privateRouter.route<'id'>('/invoicing-profiles/:id/example'),
    },
    google: {
        $router: googleRouter,
        oauth: googleRouter.route('/oauth'),
        integration: googleRouter.route('/integration'),
    },
} as const;

const frontendRouter = Router.root('', env.VITE_APP_URL);

export const routesFE = {
    $router: frontendRouter,
    root: '/',
    dashboard: frontendRouter.route('/'),
    calendar: '/calendar',
    products: {
        list: '/products',
        new: frontendRouter.route<'type'>('/products/new/:type'),
        detail: frontendRouter.route<'id'>('/products/:id'),
    },
    clients: {
        list: frontendRouter.route('/clients'),
        detail: frontendRouter.route<'id' | 'key', Fragment>('/clients/:id/:key'),
    },
    orders: {
        list: frontendRouter.route('/orders'),
        detail: frontendRouter.route<'id'>('/orders/:id'),
        export: '/orders/export',
    },
    store: frontendRouter.route<'key'>('/store/:key'),
    directSale: {
        root: frontendRouter.route<'key'>('/direct-sale/:key'),
        product: '/direct-sale/product',
        event: '/direct-sale/event',
        custom: '/direct-sale/custom',
    },
    team: '/team',
    events: {
        detail: frontendRouter.route<'id'>('/events/:id'),
    },
    settings: frontendRouter.route<'key'>('/settings/:key'),
    payments: frontendRouter.route('/settings/payments'),
    integrations: frontendRouter.route<never, 'refresh-google' | 'error'>('/settings/advanced'),
    login: {
        index: frontendRouter.route('/login'),
        google: '/login/google',
    },
    // The url pages are displayed to the users so we use the 'sign-' version.
    register: {
        claimUrl: '/signup',
        google: frontendRouter.route<never, 'error'>('/signup/google'),
        register: frontendRouter.route<never, 'claimed'>('/create-account'),
    },
    resetPassword: '/reset-password',
    /** Only for development purposes. Not active in production. */
    dev: '/dev',
    /** Not really routes, more like resources accessible from the frontend. */
    files: {
        uploads: (hash: string) => `${frontendRouter.absolutePrefix}/uploads/${hash.slice(0, 2)}/${hash}`,
        static: (fileName: string) => `${frontendRouter.absolutePrefix}/static/${fileName}`,
    },
};

const storeRouter = Router.root('', env.VITE_STORE_URL);

export const routesStore = {
    $router: storeRouter,
    root: storeRouter.route<'store'>('/:store'),
    termsOfService: storeRouter.route<'store'>('/:store/terms'),
    product: storeRouter.route<'store' | 'product'>('/:store/:product'),
    order: storeRouter.route<'store' | 'order'>('/:store/order/:order'),
};

/**
 * Simplifies the url for the users. Only for display purposes.
 * Essentially the opposite of {@link linkify}.
 * However, the use case is completely different - here we want to prettify our system routes. We don't want to do this for users' urls, because the users should decide how they should be displayed.
 */
export function routeToDisplayString(url: string): string {
    return url.replace('https://', '').replace('http://', '');
}

export const PAYMENT_SUCCESS_URL = 'https://flowlance.com/success';

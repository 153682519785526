import { EventCell } from './EventCell';
import { isSelected } from './utils/selection';

/* eslint-disable react/prop-types */
export const EventRowMixin = {
    defaultProps: {
        segments: [],
        selected: {},
    },

    renderEvent(props, event) {
        const {
            selected,
            isAllDay: _,
            onSelect,
            onDoubleClick,
            onKeyPress,
            slotMetrics,
        } = props;

        const continuesPrior = slotMetrics.continuesPrior(event);
        const continuesAfter = slotMetrics.continuesAfter(event);

        return (
            <EventCell
                event={event}
                onSelect={onSelect}
                onDoubleClick={onDoubleClick}
                onKeyPress={onKeyPress}
                continuesPrior={continuesPrior}
                continuesAfter={continuesAfter}
                selected={isSelected(event, selected)}
            />
        );
    },

    renderSpan(slots, len, key, content = ' ') {
        const per = (Math.abs(len) / slots) * 100 + '%';

        return (
            <div
                key={key}
                className='pr-3 py-px'
                // IE10/11 need max-width. flex-basis doesn't respect box-sizing
                style={{ WebkitFlexBasis: per, flexBasis: per, maxWidth: per }}
            >
                {content}
            </div>
        );
    },
};

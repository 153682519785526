import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { type MasterProductItemFromServer, type SchedulerProductItemFromServer, masterProductItemFromServer, schedulerProductItemFromServer } from ':frontend/types/orders/OrderItem';
import { ProductOrderItemFE, type GenericProductItem } from ':frontend/types/orders/ProductOrderItem';
import { ProductItemTile } from '../product/ProductItemTile';
import { useClients } from ':frontend/hooks';
import { useCached } from ':components/hooks';
import { EventForm } from '../event/EventForm';
import { useScheduling } from '../event/useEvent';
import { SpinnerButton } from '../common';
import { EventScheduleModal } from '../event/EventUpdateModal';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import { Modal } from ':components/shadcn';
import { ShapeTriangleIcon, CalendarPlus1Icon, CalendarCheck2Icon } from ':components/icons/basic';
import clsx from 'clsx';
import { RemoveProductModal } from '../product/RemoveProductModal';
import { productStyles } from ':components/store/product/ProductCard';

type ClientPackagesProps = Readonly<{
    client: ClientInfoFE;
}>;

export function ClientPackages({ client }: ClientPackagesProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientPackages' });

    const { clients, addClients } = useClients();
    const context: ClientsContext | undefined = useMemo(() => clients && { clients, addClients }, [ clients, addClients ]);

    const [ productItems, setProductItems ] = useState<GenericProductItem<true>[]>();
    const activeItems = useMemo(() => productItems?.filter(oi => !ProductOrderItemFE.isCompleted(oi)), [ productItems ]);
    const completedItems = useMemo(() => productItems?.filter(oi => ProductOrderItemFE.isCompleted(oi)), [ productItems ]);

    const isMasterOrFreelancer = !!toMaster(useUser());
    const [ showCompleted, setShowCompleted ] = useState(false);

    const productItemsQuery = trpc.order.getSchedulableProductItems.useQuery({ guestId: client.id });

    useEffect(() => {
        if (!productItemsQuery.data)
            return;

        const fetchedOrderItems: GenericProductItem[] = isMasterOrFreelancer
            ? (productItemsQuery.data as MasterProductItemFromServer[]).map(masterProductItemFromServer)
            : (productItemsQuery.data as SchedulerProductItemFromServer[]).map(schedulerProductItemFromServer);

        const schedulableItems = fetchedOrderItems.filter((item): item is GenericProductItem<true> => item.sessionsCount !== undefined);

        setProductItems(schedulableItems);
    }, [ productItemsQuery.data, isMasterOrFreelancer ]);

    const [ schedulingProductItem, setSchedulingProductItem ] = useState<GenericProductItem<true>>();
    const finishScheduling = useCallback((update: GenericProductItem<true>) => {
        setProductItems(oldItems => oldItems?.map(item => (item.id === update.id ? update : item)));
        setSchedulingProductItem(undefined);
    }, []);
    const closeScheduling = useCallback(() => setSchedulingProductItem(undefined), []);

    const [ removingProductItem, setRemovingProductItem ] = useState<GenericProductItem<true>>();
    const cancelRemoving = useCallback(() => setRemovingProductItem(undefined), []);

    async function onRemoved() {
        await productItemsQuery.refetch();
        setRemovingProductItem(undefined);
    }

    if (!activeItems || !completedItems || !context)
        return null;

    return (
        <div>
            <ScheduleProductModal
                productItem={schedulingProductItem}
                client={client}
                onSchedule={finishScheduling}
                onClose={closeScheduling}
                context={context}
            />

            <RemoveProductModal
                client={client}
                productItem={removingProductItem}
                onClose={cancelRemoving}
                onRemoved={onRemoved}
            />

            <div className='space-y-4'>
                <div className='fl-hide-scrollbar max-sm:overflow-x-auto max-sm:-mx-4'>
                    <div className='flex gap-4 max-sm:px-4 max-sm:w-fit items-start flex-nowrap whitespace-nowrap'>
                        <div
                            className={clsx(
                                'relative flex items-center gap-1 hover:opacity-90 px-4 py-3 rounded-full cursor-pointer',
                                !showCompleted && 'text-warning-700 bg-warning-100',
                                showCompleted && 'text-secondary bg-secondary-50 max-lg:bg-white',
                            )}
                            onClick={() => setShowCompleted(false)}
                        >
                            <CalendarPlus1Icon size='sm' className='shrink-0' />

                            {t('active-title')}

                            {!showCompleted && <ShapeTriangleIcon className='max-sm:hidden fill-warning-100 text-transparent absolute top-[90%] m-auto left-0 right-0 rotate-180' />}
                        </div>

                        <div
                            className={clsx(
                                'relative flex items-center gap-1 hover:opacity-90 px-4 py-3 rounded-full cursor-pointer',
                                showCompleted && 'text-warning-700 bg-warning-100',
                                !showCompleted && 'text-secondary bg-secondary-50 max-lg:bg-white',
                            )}
                            onClick={() => setShowCompleted(true)}
                        >
                            <CalendarCheck2Icon size='sm' className='shrink-0' />

                            {t('completed-title')}

                            {showCompleted && <ShapeTriangleIcon className='max-sm:hidden fill-warning-100 text-transparent absolute top-[90%] m-auto left-0 right-0 rotate-180' />}
                        </div>
                    </div>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
                    {!showCompleted ? (
                        activeItems.map(productItem => (
                            <ProductItemTile
                                key={productItem.id}
                                productItem={productItem}
                                onSchedule={setSchedulingProductItem}
                                onRemove={setRemovingProductItem}
                                hideClientId={client.id}
                            />
                        ))
                    ): (
                        completedItems.map(productItem => (
                            <ProductItemTile
                                key={productItem.id}
                                productItem={productItem}
                                onRemove={setRemovingProductItem}
                                hideClientId={client.id}
                            />
                        ))
                    )}
                </div>

                {((!showCompleted && activeItems.length === 0) || (showCompleted && completedItems.length == 0)) && <div className='p-4 rounded-md text-center border border-warning max-lg:bg-white'>
                    {t('no-packages-to-show')}
                </div>}
            </div>
        </div>
    );
}

export type ClientsContext = {
    clients: ClientInfoFE[];
    addClients: (clients: ClientInfoFE[]) => void;
};

type ScheduleProductModalProps = Readonly<{
    productItem?: GenericProductItem<true>;
    client: ClientInfoFE;
    onSchedule: (productItem: GenericProductItem<true>) => void;
    onClose: () => void;
    context: ClientsContext;
}>;

export function ScheduleProductModal({ productItem, client, onSchedule, onClose, context }: ScheduleProductModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientPackages' });

    const cachedProductItem = useCached(productItem);
    if (!cachedProductItem)
        return null;

    return (
        <Modal.Root
            open={!!productItem}
            onOpenChange={open => !open && onClose()}
        >
            <Modal.Content className='gap-6 max-sm:max-w-[calc(100vw-32px)]' closeButton={t('cancel-button')}>
                <ScheduleProductModalInner
                    productItem={cachedProductItem}
                    client={client}
                    onSchedule={onSchedule}
                    context={context}
                />
            </Modal.Content>
        </Modal.Root>
    );
}

type ScheduleProductModalInnerProps = Readonly<{
    productItem: GenericProductItem<true>;
    client: ClientInfoFE;
    onSchedule: (productItem: GenericProductItem<true>) => void;
    context: ClientsContext;
}>;

function ScheduleProductModalInner({ productItem, client, onSchedule, context }: ScheduleProductModalInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientPackages' });
    const { state, dispatch } = useScheduling(productItem, client, onSchedule, context.addClients);
    const recurrenceCountLimit = productItem.sessionsCount - productItem.scheduledCount;

    const styles = useMemo(() => productStyles[productItem.type], [ productItem ]);

    return (<>
        <Modal.Header className='space-y-4'>
            <styles.icon size={32} className='mx-auto' />

            <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                {t('schedule-product-title')}
            </Modal.Title>
        </Modal.Header>

        {/* TODO Move from modal, so that both modals are in the same modal (or that there is just one modal).
                Either way, we don't want two modals over each other. */}
        {state.sync && <EventScheduleModal state={state.sync} dispatch={dispatch} />}

        <EventForm
            state={state}
            dispatch={dispatch}
            clients={context.clients}
            isForProduct
            recurrenceCountLimit={recurrenceCountLimit}
        />

        <Modal.Footer>
            <SpinnerButton
                onClick={() => dispatch({
                    type: 'sync',
                    operation: { type: 'start', isBillLater: false },
                    fid: FID_FINISH,
                })}
                fetching={state.sync?.fetching}
                fid={FID_FINISH}
            >
                {t('finish-scheduling-button')}
            </SpinnerButton>
        </Modal.Footer>
    </>);
}

const FID_FINISH = 'finish';

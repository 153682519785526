import { useMemo, useState } from 'react';
import { Button } from ':components/shadcn';
import { NewLocationModal } from ':frontend/components/location/NewLocationModal';
import { EditLocationModal } from ':frontend/components/location/EditLocationModal';
import { DeleteLocationModal } from ':frontend/components/location/DeleteLocationModal';
import { useTranslation } from 'react-i18next';
import type { LocationOutput } from ':utils/entity/location';
import { trpc } from ':frontend/context/TrpcProvider';

type LocationsEditorProps = Readonly<{
    editingLocation: LocationOutput | undefined;
    setEditingLocation: (location: LocationOutput | undefined) => void;
    deletingLocation: LocationOutput | undefined;
    setDeletingLocation: (location: LocationOutput | undefined) => void;
}>;

export function LocationsEditor({ editingLocation, setEditingLocation, deletingLocation, setDeletingLocation }: LocationsEditorProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.locations' });

    const [ showNewLocationModal, setShowNewLocationModal ] = useState(false);

    const trpcUtils = trpc.useUtils();

    const setLocations = useMemo(() => {
        return {
            add: (location: LocationOutput) => trpcUtils.location.getLocations.setData(undefined,
                prev => [ ...(prev ?? []), location ],
            ),
            edit: (location: LocationOutput) => trpcUtils.location.getLocations.setData(undefined,
                prev => (prev ?? []).map(old => old.id === location.id ? location : old),
            ),
            delete: (location: LocationOutput) => trpcUtils.location.getLocations.setData(undefined,
                prev => (prev ?? []).filter(old => old.id !== location.id),
            ),
        };
    }, [ trpcUtils ]);

    return (
        <div>
            <Button
                variant='primary'
                size='small'
                onClick={() => setShowNewLocationModal(true)}
            >
                {t('add-location-button')}
            </Button>

            <NewLocationModal
                show={showNewLocationModal}
                onClose={() => setShowNewLocationModal(false)}
                onLocationCreated={setLocations.add}
            />

            <EditLocationModal
                location={editingLocation}
                onClose={() => setEditingLocation(undefined)}
                onLocationEdit={setLocations.edit}
            />

            <DeleteLocationModal
                location={deletingLocation}
                onClose={() => setDeletingLocation(undefined)}
                onDelete={setLocations.delete}
            />
        </div>
    );
}

import { useCallback, useMemo, useState } from 'react';
import { Button, DropdownMenu, Form, ScrollArea } from ':components/shadcn';
import { useUser } from ':frontend/context/UserProvider';
import { type TeamMemberFE } from ':frontend/types/Team';
import localStorage from ':frontend/utils/localStorage';
import { CalendarCheck2Icon } from ':components/icons/basic';
import { useTranslation } from 'react-i18next';
import { Query } from ':frontend/utils/common';
import clsx from 'clsx';
import { PlaceholderDots } from './GoogleCalendarsFilter';
import { getPersonName } from ':utils/entity/person';

// For the team members, we store a set of disabled member ids. That's different from the google calendars, where we store a set of enabled calendar ids.
// The reason is that for google, we want to first fetch the events of enabled calendars, so we need to know their ids. Only after that we fetch all calendars and, if needed, update the enabled calendars.

type TeamMemberCalendarsFilterProps = Readonly<{
    disabledIds: Set<string>;
    toggleMember: (member: TeamMemberFE, newValue: boolean) => void;
}>;

export function TeamMemberCalendarsFilter({ disabledIds, toggleMember }: TeamMemberCalendarsFilterProps) {
    const { teamMembers } = useUser();

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <Button variant='outline' size='small'>
                    <CalendarCheck2Icon />
                    <PlaceholderDots items={teamMembers.all} predicate={member => !disabledIds.has(member.id)} />
                </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className='p-0 w-[400px] overflow-hidden'>
                <ScrollArea className='max-h-[600px] flex flex-col'>
                    <DropdownInner disabledIds={disabledIds} toggleMember={toggleMember} />
                </ScrollArea>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

function DropdownInner({ disabledIds, toggleMember }: TeamMemberCalendarsFilterProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'calendar' });
    const [ query, setQuery ] = useState('');

    const { teamMembers } = useUser();

    const filteredMembers = useMemo(() => {
        const trimmed = query.trim();
        if (!trimmed)
            return teamMembers.all;

        const filterQuery = new Query(...query.split(' '));
        return teamMembers.all.filter(member => member.query.match(filterQuery));
    }, [ teamMembers.all, query ]);

    return (
        <div className='p-2 flex flex-col gap-1'>
            <Form.Input
                value={query}
                onChange={e => setQuery(e.target.value)}
                placeholder={t('team-member-query-placeholder')}
                size='compact'
                className='mb-1'
            />
            {filteredMembers.map(member => {
                const isEnabled = !disabledIds.has(member.id);
                const name = getPersonName(member);

                return (
                    <div key={member.id} className={clsx('px-3 py-2 flex items-center gap-2 rounded transition-colors', isEnabled && 'bg-primary-50')}>
                        <div className='size-3 rounded-full' style={{ backgroundColor: `#${member.color}` }} />
                        <div className='flex-1 truncate'>
                            {name}
                        </div>
                        <Form.Switch
                            label={name}
                            labelClassName='sr-only'
                            checked={isEnabled}
                            onCheckedChange={value => toggleMember(member, value)}
                        />
                    </div>
                );
            })}

        </div>
    );
}

type UseTeamMemberCalendarsFilterReturn = {
    disabledIds: Set<string>;
    toggleMember: (member: TeamMemberFE, newValue: boolean) => void;
}

export function useTeamMemberCalendarsFilter(): UseTeamMemberCalendarsFilterReturn {
    const [ disabledIds, setDisabledIds ] = useState(() => getDisabledMemberIds());

    const toggleMember = useCallback((member: TeamMemberFE, newValue: boolean) => {
        setDisabledIds(updateDisabledId(member.id.toString(), newValue));
    }, []);

    return {
        disabledIds,
        toggleMember,
    };
}

const FILTER_KEY = 'team_members_filter';

type MemberFilter = string[];

function updateDisabledId(id: string, idValue: boolean): (oldValue: Set<string>) => Set<string> {
    return (oldValue: Set<string>) => {
        const newValue = computeNewDisabledIds(oldValue, id, idValue);
        if (oldValue !== newValue)
            cacheDisabledId(id, idValue);

        return newValue;
    };
}

function computeNewDisabledIds(oldValue: Set<string>, id: string, idValue: boolean): Set<string> {
    if (!idValue) {
        if (oldValue.has(id))
            return oldValue;

        const newValue = new Set(oldValue);
        newValue.add(id);

        return newValue;
    }

    if (!oldValue.has(id))
        return oldValue;

    const newValue = new Set(oldValue);
    newValue.delete(id);

    return newValue;
}

function cacheDisabledId(id: string, idValue: boolean) {
    const filter = localStorage.get<MemberFilter>(FILTER_KEY) ?? [];
    const newFilter = idValue
        ? filter.filter(disabledId => disabledId !== id)
        : (filter.includes(id) ? filter : [ ...filter, id ]);

    localStorage.set(FILTER_KEY, newFilter);
}

function getDisabledMemberIds(): Set<string> {
    const filter = localStorage.get<MemberFilter>(FILTER_KEY) ?? [];
    return new Set(filter);
}

import { type ReactNode, useCallback, useMemo } from 'react';
import { Select, type SelectConfig } from ':components/shadcn';
import { getAllTaxRatesForCountries, getTaxRate, type TaxRateFE } from ':utils/money';
import { useMaster } from ':frontend/context/UserProvider';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { type TFunction } from 'i18next';
import { type Id } from ':utils/id';
import type { CountryCode } from ':utils/i18n';
import { type SingleValue } from ':components/shadcn';

type Option = {
    value: Id;
    label: ReactNode;
};

type Group = {
    options: Option[];
    label: string;
};

function vatToOption(vat: TaxRateFE): Option {
    return {
        value: vat.id,
        label: <span className={clsx(!vat.isEnabled && 'text-secondary')}>{vat.label}</span>,
    };
}

type PlainVatSelectProps = Readonly<{
    vatId?: Id;
    onChange: (vatId?: Id) => void;
    /** Use custom country instead of the one from settings. Useful when both country and vat are being edited at the same time. */
    country?: CountryCode;
    className?: string;
    id?: string;
    immutableProps?: SelectConfig<Option>;
    isDisabled?: boolean;
}>;

function PlainVatSelect({ vatId, onChange, country, className, immutableProps, ...rest }: PlainVatSelectProps) {
    const { t } = useTranslation('common', { keyPrefix: 'select' });
    const handleOnChange = useCallback((option: SingleValue<Option>) => onChange(option !== null ? option.value : undefined), [ onChange ]);

    const { teamSettings } = useMaster();
    const finalCountry = country ?? teamSettings.country;
    const innerOptions = useMemo(() => createOptions(finalCountry, t), [ finalCountry, t ]);

    return (
        <Select
            immutableProps={{ valueRight: true, ...immutableProps }}
            placeholder={t('vat-placeholder')}
            value={vatId !== undefined ? vatToOption(getTaxRate(vatId)) : undefined}
            onChange={handleOnChange}
            options={innerOptions}
            className={clsx('tabular-nums', className)}
            isSearchable={false}
            {...rest}
        />
    );
}

function createOptions(country: CountryCode, t: TFunction): (Option | Group)[] {
    const taxRates = getAllTaxRatesForCountries([ country ]);
    const output: (Option | Group)[] = taxRates.filter(taxRate => taxRate.isEnabled).map(vatToOption);

    if (output.length === taxRates.length)
        return output;

    const disabled = taxRates.filter(taxRate => !taxRate.isEnabled).map(vatToOption);
    output.push({
        label: t('disabled-vat-group-label'),
        options: disabled,
    });

    return output;
}

type VatSelectProps = Readonly<{
    value?: TaxRateFE;
    onChange: (value?: TaxRateFE) => void;
    country?: CountryCode;
    className?: string;
    id?: string;
    immutableProps?: SelectConfig<Option>;
    isDisabled?: boolean;
}>;

export function VatSelect({ value, onChange, ...rest }: VatSelectProps) {
    const handleOnChange = useCallback((value?: Id) => onChange(value ? getTaxRate(value) : undefined), [ onChange ]);

    return (
        <PlainVatSelect
            vatId={value?.id}
            onChange={handleOnChange}
            {...rest}
        />
    );
}

type ControlledVatSelectProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    country?: CountryCode;
    className?: string;
    immutableProps?: SelectConfig<Option>;
};

export function ControlledVatSelect<TFieldValues extends FieldValues>({ control, name, country, className, immutableProps }: ControlledVatSelectProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value: Id, onChange: (value?: Id) => void } }) => {
        return (
            <PlainVatSelect
                vatId={field.value}
                onChange={field.onChange}
                country={country}
                className={className}
                immutableProps={immutableProps}
            />
        );
    }, [ country, className ]);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
        />
    );
}

import { forwardRef, type ElementRef, type ComponentPropsWithoutRef, type ComponentProps, type ReactNode } from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from './utils';

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = forwardRef<
    ElementRef<typeof TooltipPrimitive.Content>,
    ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
    <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={cn(
            'z-50 overflow-hidden text-left leading-4 rounded-md border bg-white px-3 py-1.5 text-sm text-secondary-900 shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            className,
        )}
        {...props}
    />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

type TooltipProps = ComponentProps<typeof TooltipContent> & {
    /** if falsy, don't show any tooltip */
    tooltipText: ReactNode;
    onClick?: () => void;
};

/** children for TooltipTrigger, other props for TooltipContent */
function Tooltip({ tooltipText, onClick, children, ...contentProps }: TooltipProps) {
    return (
        <TooltipProvider>
            <TooltipPrimitive.Root>
                <TooltipTrigger type='button' onClick={onClick}>
                    {children}
                </TooltipTrigger>
                {tooltipText && (
                    <TooltipContent {...contentProps}>
                        {tooltipText}
                    </TooltipContent>
                )}
            </TooltipPrimitive.Root>
        </TooltipProvider>
    );
}

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };

import { Form } from ':components/shadcn';
import { useForm } from 'react-hook-form';
import { AppUserSettingsFE, TeamSettingsFE } from ':frontend/types/settings';
import { SpinnerButton } from '../common';
import { useTranslation } from 'react-i18next';
import { ControlledCountrySelect, ControlledTimezoneSelect } from ':frontend/components/forms';
import type { CountryCode, TimezoneCode } from ':utils/i18n';
import type { AppUserSettingsEdit } from ':utils/entity/settings';
import { useMaster, useUser } from ':frontend/context/UserProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import type { CurrencyId, TaxRateId } from ':utils/id';
import { TeamMemberRole } from ':utils/entity/team';
import { ControlledCurrencySelect } from '../forms/CurrencySelect';
import { InfoTooltip } from '../common/InfoTooltip';
import { ControlledVatSelect } from '../forms/VatSelect';
import { getDefaultTaxRate } from ':utils/money';
import { useTransform } from ':frontend/utils/forms';

type GeneralPreferencesFormData = {
    timezone: TimezoneCode;
    /** For freelancers, this is the same as the team country. */
    country: CountryCode;
    /** Specifically for the masters. */
    teamCountry?: CountryCode;
    currencyId?: CurrencyId;
    taxRateId?: TaxRateId;
};

export function GeneralPreferencesForm() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const { addAlert } = useNotifications();
    const userContext = useUser();
    const { settings, setSettings } = userContext;

    const { control, handleSubmit, watch, setValue } = useForm<GeneralPreferencesFormData>({ defaultValues: {
        timezone: settings.timezone,
        country: settings.country,
        teamCountry: userContext.role === TeamMemberRole.master ? userContext.teamSettings.country : undefined,
        currencyId: userContext.role !== TeamMemberRole.scheduler ? userContext.teamSettings.currency.id : undefined,
        taxRateId: userContext.role !== TeamMemberRole.scheduler ? userContext.teamSettings.taxRate.id : undefined,
    } });

    const updateAppUserSettingsMutation = trpc.user.updateAppUserSettings.useMutation();

    function onPreferencesSubmit(edit: AppUserSettingsEdit) {
        updateAppUserSettingsMutation.mutate(edit, {
            onError: () => {
                setSettings({ ...settings });
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                setSettings(AppUserSettingsFE.fromServer(response.appUserSettings));
                if (userContext.role !== TeamMemberRole.scheduler)
                    userContext.setTeamSettings(TeamSettingsFE.fromServer(response.teamSettings!));

                addAlert(createTranslatedSuccessAlert('pages:settings.general.preferences-success'));
            },
        });
    }

    // The tax rate options and selected value are automatically adjusted based on the team settings country.
    const countryForTaxRate = userContext.role === TeamMemberRole.master ? watch('teamCountry') : watch('country');

    return (
        <Form.Root onSubmit={handleSubmit(onPreferencesSubmit)} className='space-y-8'>
            <div className='space-y-4'>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <div>
                        <Form.Label>{t('timezone-label')}</Form.Label>
                        <ControlledTimezoneSelect
                            control={control}
                            name='timezone'
                        />
                    </div>
                    
                    <div>
                        <Form.Label>{t('country-label')}</Form.Label>
                        <ControlledCountrySelect
                            control={control}
                            name='country'
                            onChange={() => setValue('taxRateId', getDefaultTaxRate().id)}
                        />
                    </div>
                </div>

                {/*
                    The point is not to disable the locale select, but to kinda hide the fact that we currently support only two languages.
                    <div>
                        <Form.Label>{t('locale-label')}</Form.Label>
                        <ControlledLocaleSelect
                            control={control}
                            name='locale'
                            type='base'
                        />
                    </div>
                */}

                {userContext.role === TeamMemberRole.master && (
                    <div>
                        <Form.Label>{t('teamCountry-label')}</Form.Label>
                        <ControlledCountrySelect
                            control={control}
                            name='teamCountry'
                            onChange={() => setValue('taxRateId', getDefaultTaxRate().id)}
                        />
                    </div>
                )}

                {userContext.role !== TeamMemberRole.scheduler && (
                    <div className='grid grid-flow-col auto-cols-fr gap-4'>
                        <div>
                            <Form.Label>{t(`currency-label-${userContext.role}`)}</Form.Label>
                            <ControlledCurrencySelect
                                control={control}
                                name='currencyId'
                            />
                        </div>

                        {userContext.teamSettings.isTaxesEnabled && (
                            <div>
                                <Form.Label>
                                    {t(`taxRate-label-${userContext.role}`)}
                                    <InfoTooltip text={t('taxRate-tooltip')} className='ml-2' />
                                </Form.Label>
                                <ControlledVatSelect
                                    control={control}
                                    name='taxRateId'
                                    country={countryForTaxRate}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div>
                <SpinnerButton
                    type='submit'
                    size='small'
                    className='w-full'
                    isFetching={updateAppUserSettingsMutation.isPending}
                >
                    {t('save-button')}
                </SpinnerButton>
            </div>
        </Form.Root>
    );
}

type PaymentsPrefferencesFormData = {
    dueDays: number;
}
export function PaymentsPreferencesForm() {
    const { t } = useTranslation('pages', { keyPrefix: 'payments.preferences' });
    const { teamSettings, setTeamSettings } = useMaster();
    const { addAlert } = useNotifications();
    const updateTeamPreferencesMutation = trpc.team.updateTeamPreferences.useMutation();

    function onPreferencesSubmit(data: PaymentsPrefferencesFormData) {
        updateTeamPreferencesMutation.mutate({ dueDays: data.dueDays }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                setTeamSettings(TeamSettingsFE.fromServer(response));
                addAlert(createTranslatedSuccessAlert('pages:payments.preferences.success-alert'));
            },
        });
    }

    const { register, handleSubmit, setValue } = useForm<PaymentsPrefferencesFormData>({ defaultValues: {
        dueDays: teamSettings.dueDays,
    } });
    const { registerPositiveInteger } = useTransform(register, setValue);

    return (
        <Form.Root onSubmit={handleSubmit(onPreferencesSubmit)} className='mt-4'>
            <Form.Input
                label={t(`label`)}
                {...registerPositiveInteger('dueDays')}
            />
            <div className='mt-8'>
                <div>
                    <SpinnerButton
                        type='submit'
                        size='small'
                        className='w-full'
                        isFetching={updateTeamPreferencesMutation.isPending}
                    >
                        {t('save-button')}
                    </SpinnerButton>
                </div>
            </div>
        </Form.Root>
    );
}

import { forwardRef, type ComponentProps } from 'react';
import { cn } from './utils';

const sizeClasses = {
    xs: 'w-4 h-4 border-[2px]',
    sm: 'w-5 h-5 border-[2px]',
    md: 'w-6 h-6 border-[2px]',
    lg: 'w-8 h-8 border-[3px]',
    xl: 'w-10 h-10 border-[3px]',
} as const;

type SizeProps = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type SpinnerProps = ComponentProps<'div'> & {
    size?: SizeProps;
    /** Any tailwind border class (e.g., border-primary). */
    colorClass?: string;
};

// inspired by https://mynaui.com/components/spinner
export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(({
    size = 'lg',
    colorClass = 'border-secondary',
    className,
    ...divProps
}, ref) => {
    return (
        <div
            aria-label='Loading...'
            role='status'
            className={cn(
                colorClass,
                'animate-spin rounded-full border-b-transparent',
                sizeClasses[size],
                className,
            )}
            ref={ref}
            {...divProps}
        />
    );
});

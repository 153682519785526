import { useTranslation } from 'react-i18next';
import { Modal } from ':components/shadcn';
import { createTranslatedSuccessAlert } from ':frontend/components/notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import ClientForm from ':frontend/components/client/ClientForm';
import { routesFE } from ':utils/routes';
import { createErrorAlert } from ':frontend/components/notifications/TranslatedAlertMessage';
import type { ClientWithProfileInit } from ':utils/entity/client';
import { trpc } from ':frontend/context/TrpcProvider';

type NewClientModalProps = Readonly<{
    onClose(): void;
}>;

export function NewClientModal({ onClose }: NewClientModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'newClient.modal' });
    const { addAlert } = useNotifications();

    const createClientMutation = trpc.$client.createClient.useMutation();
    const trpcUtils = trpc.useUtils();

    function onSubmit(init: ClientWithProfileInit) {
        createClientMutation.mutate(init, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
            },
            onSuccess: async response => {
                addAlert(createTranslatedSuccessAlert('common:clientCreatedAlert', { links: {
                    a: routesFE.clients.detail.resolve({ id: response.id, key: 'overview' }),
                } }));

                trpcUtils.$client.getClientInfos.setData({}, prev => [ ...(prev ?? []), response ]);
                onClose();
            },
        });
    }

    return (
        <Modal.Root
            open
            onOpenChange={open => !open && onClose()}
        >
            <Modal.Content closeButton={t('close-button')} className='gap-6'>
                <Modal.Header>
                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                        {t('title')}
                    </Modal.Title>
                </Modal.Header>

                <ClientForm
                    onSubmit={onSubmit}
                    isFetching={createClientMutation.isPending}
                />
            </Modal.Content>
        </Modal.Root>
    );
}

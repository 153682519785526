import { useState, useEffect, useMemo } from 'react';
import { getSlotMetrics } from './utils/TimeSlots';
import { TimeGutterCell } from './columnCell';
import { type DateTime } from 'luxon';
import { localizer } from './utils/common';

/**
 * Since the TimeGutter only displays the 'times' of slots in a day, and is separate
 * from the Day Columns themselves, we check to see if the range contains an offset difference
 * and, if so, change the beginning and end 'date' by a day to properly display the slots times
 * used.
 */
function adjustForDST(min: DateTime, max: DateTime) {
    if (localizer.getTimezoneOffset(min) !== localizer.getTimezoneOffset(max)) {
        return {
            start: localizer.add(min, -1, 'day'),
            end: localizer.add(max, -1, 'day'),
        };
    }
    return { start: min, end: max };
}

type TimeGutterProps = Readonly<{
    min: DateTime;
    max: DateTime;
}>;

export function TimeGutter({ min, max }: TimeGutterProps) {
    const { start, end } = useMemo(() => adjustForDST(min, max),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ min?.toISO(), max?.toISO() ],
    );
    const [ slotMetrics, setSlotMetrics ] = useState(getSlotMetrics({
        min: start,
        max: end,
    }));

    useEffect(() => {
        if (!slotMetrics)
            return;

        setSlotMetrics(slotMetrics.update({
            min: start,
            max: end,
        }));
    /**
     * We don't want this to fire when slotMetrics is updated as it would recursively bomb
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ start?.toISO(), end?.toISO() ]);

    return (
        <div className='sticky left-0 bg-secondary-50 z-10 min-w-20 w-20 text-right grid grid-flow-row auto-rows-[64px] divide-y *:border-secondary-100 border-r border-secondary-100'>
            {(slotMetrics.groups as DateTime[][]).map((group, index) => {
                return (
                    <TimeGutterCell
                        key={index}
                        group={group}
                    />
                );
            })}
        </div>
    );
}

import { SortOrder } from ':utils/common';
import { getCommonProps, type IconProps, type IconType } from './common';
import { AscendingSortingIcon, DescendingSortingIcon } from './basic';

type SwitchIconProps = Readonly<{
    checked?: boolean;
    className?: string;
}>

export function SwitchIcon({ checked, className }: SwitchIconProps) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' className={className}>
            {checked ? (
                <path d='M11.333 5.5 6.75 10.083 4.667 8'/>
            ) : (
                <path d='m10.75 5.25-5.5 5.5m0-5.5 5.5 5.5'/>
            )}

        </svg>
    );
}

export function SwitchLinkIcon({ checked, className }: SwitchIconProps) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none' className={className}>
            <path d='m12.325 8.519-1.68-1.68a2.75 2.75 0 0 0-3.89 3.89l1.68 1.68a2.75 2.75 0 0 0 3.89-3.89' fill='currentColor' fillOpacity='.3'/>
            <path d='M7.511 6.31c-.274.13-.53.31-.757.536l-.007.008a2.75 2.75 0 0 0 0 3.889l1.691 1.692a2.75 2.75 0 0 0 3.89 0l.007-.008a2.75 2.75 0 0 0 0-3.89l-.724-.723M8.289 9.69c.274-.131.53-.31.757-.537l.007-.008a2.75 2.75 0 0 0 0-3.89l-1.691-1.69a2.75 2.75 0 0 0-3.89 0l-.007.007a2.75 2.75 0 0 0 0 3.889l.724.724' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
            {!checked && (
                <path d='M10.528 3.917l.583-1.75m.972 3.306 1.75-.584m-9.916 5.639-1.75.583m3.306.972-.584 1.75' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
            )}
        </svg>
    );
}

type CheckboxIconProps = Readonly<{
    isIndeterminate?: boolean;
    className?: string;
}>;

export function CheckboxIcon({ isIndeterminate, className }: CheckboxIconProps) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none' className={className}>
            {isIndeterminate ? (
                <path d='M1 5h8' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round'/>
            ) : (
                <path d='M2.222 5.222 3.89 6.89 7.778 3' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round'/>
            )}
        </svg>
    );
}

type RadioCheckIconProps = Readonly<{
    className?: string;
}>;

export function RadioCheckIcon({ className }: RadioCheckIconProps) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none' className={className}>
            <path d='M14.755 7.417 9.75 14.083a1.25 1.25 0 01-.91.497l-.09.003c-.33 0-.648-.13-.883-.365l-2.505-2.5a1.25 1.25 0 111.77-1.768l1.486 1.483 4.14-5.516a1.25 1.25 0 111.998 1.502z' fill='currentColor'/>
        </svg>
    );
}

type SortOrderIconProps = IconProps & {
    orderBy?: SortOrder;
};

export function SortOrderIcon({ orderBy, ...iconProps }: SortOrderIconProps) {
    // The default value (undefined) is ascending.
    return (orderBy ?? SortOrder.Ascending) === SortOrder.Ascending ? <AscendingSortingIcon {...iconProps} /> : <DescendingSortingIcon {...iconProps} />;
}

export const CircleEmptyIcon = (props: IconProps) => (<svg {...getCommonProps(props, 16)}>
    <circle cx='8' cy='8' r='7' stroke='currentColor' strokeWidth='2' fill='none' />
</svg>);

export const CircleSemiFullIcon = (props: IconProps) => (<svg {...getCommonProps(props, 16)}>
    <circle cx='8' cy='8' r='7' stroke='currentColor' strokeWidth='2' fill='none' />
    <path d='M8 5c0-.552-.455-1.012-.99-.876a4 4 0 0 0 0 7.752c.535.136.99-.324.99-.876z' stroke='none' fill='currentColor' />
</svg>);

export const CircleMostlyFullIcon = (props: IconProps) => (<svg {...getCommonProps(props, 16)}>
    <circle cx='8' cy='8' r='7' stroke='currentColor' strokeWidth='2' fill='none' />
    <path d='M5 8c-.552 0-1.012.455-.876.99A4 4 0 1 0 8.99 4.124C8.455 3.988 8 4.448 8 5v2a1 1 0 0 1-1 1z' stroke='none' fill='currentColor' />
</svg>);

export const CircleFullIcon = (props: IconProps) => (<svg {...getCommonProps(props, 16)}>
    <circle cx='8' cy='8' r='7' stroke='currentColor' strokeWidth='2' fill='none' />
    <circle cx='8' cy='8' r='4' />
</svg>);

export const customIcons: IconType[] = [
    (props: IconProps) => <SwitchIcon {...props} />,
    (props: IconProps) => <SwitchIcon {...props} checked />,
    (props: IconProps) => <CheckboxIcon {...props} />,
    (props: IconProps) => <CheckboxIcon {...props} isIndeterminate />,
    (props: IconProps) => <SortOrderIcon {...props} />,
    (props: IconProps) => <SortOrderIcon {...props} orderBy={SortOrder.Descending} />,
    CircleEmptyIcon,
    CircleSemiFullIcon,
    CircleMostlyFullIcon,
    CircleFullIcon,
];

import { useMemo } from 'react';
import { MoneyDisplayString } from ':components/custom';
import { useTranslation } from 'react-i18next';
import { itemsDeletable, type OrderFormState, type UseOrderDispatch, type UseOrderState } from './useOrder';
import { Form, Card, Button } from ':components/shadcn';
import { transformToPriceNegative } from ':utils/math';
import { VatSelect } from '../forms/VatSelect';
import { type FormPath } from ':frontend/utils/updator';
import { CalendarIcon, Trash2Icon, PlusIcon } from ':components/icons/basic';
import { DateTimeDisplay } from '../common';
import type { EditableEventItem, EventOrderItemFE } from ':frontend/types/orders/EventOrderItem';

type OrderItemsListEventProps = Readonly<{
    items: EventOrderItemFE[];
}>;

export function OrderItemsListEvent({ items }: OrderItemsListEventProps) {
    const { t } = useTranslation('components', { keyPrefix: 'orderItemsList' });

    const showVat = useMemo(() => items.some(item => !item.vat.isZero), [ items ]);

    return (
        <div className='space-y-4'>
            <div className='font-semibold text-lg'>{t('event-title')}</div>

            <div className='space-y-2'>
                {items.map(item => (
                    <OrderItemsListEventRow key={item.id} item={item} showVat={showVat} />
                ))}
            </div>
        </div>
    );
}

type OrderItemsListEventRowProps = Readonly<{
    item: EventOrderItemFE;
    showVat: boolean;
}>;

function OrderItemsListEventRow({ item, showVat }: OrderItemsListEventRowProps) {
    return (
        <Card key={item.id}>
            <div className='w-full'>
                <div className='flex items-center justify-between'>
                    <div className='font-semibold text-lg'>{item.title}</div>

                    <div className='flex items-center gap-8 whitespace-nowrap'>
                        {showVat && <div className='font-semibold'>{item.vat.label}</div>}

                        <div className='font-semibold whitespace-nowrap'>
                            <MoneyDisplayString money={item.unitPrice} />
                        </div>
                    </div>
                </div>

                <Card.Divider />

                <div className='flex items-center gap-2'>
                    <CalendarIcon size='md' /> <DateTimeDisplay dateTime={item.event.start} />
                </div>
            </div>
        </Card>
    );
}

type OrderItemsListEventFormProps = Readonly<{
    state: UseOrderState;
    form: OrderFormState;
    dispatch: UseOrderDispatch;
}>;

export function OrderItemsListEventForm({ state, form, dispatch }: OrderItemsListEventFormProps) {
    const { t } = useTranslation('components', { keyPrefix: 'orderItemsList' });

    const showVat = useMemo(() => state.order.items.some(item => !item.vat.isZero), [ state.order.items ]);

    const deletable = itemsDeletable(form);

    return (
        <div className='space-y-4'>
            <div className='font-semibold text-lg'>{t('event-title')}</div>

            <div className='space-y-2'>
                {form.eventItems.map((item, index) => (
                    <CustomItemRowForm
                        key={item.id}
                        dispatch={dispatch}
                        item={item}
                        index={index}
                        showVat={showVat}
                        deletable={deletable}
                    />
                ))}
            </div>
        </div>
    );
}

type CustomItemRowFormProps = Readonly<{
    dispatch: UseOrderDispatch;
    item: EditableEventItem;
    index: number;
    showVat: boolean;
    deletable: boolean;
}>;

function CustomItemRowForm({ dispatch, item, index, showVat, deletable }: CustomItemRowFormProps) {
    const { t: to } = useTranslation('components', { keyPrefix: 'orderItemsList' });

    function input(field: FormPath<EditableEventItem>, value: unknown) {
        dispatch({ type: 'input', field: `eventItems.${index}.${field}`, value });
    }

    return (
        <Card key={item.id} className='flex items-center gap-8'>
            <div className='grow'>
                <div className='max-sm:space-y-4 sm:flex sm:items-center sm:justify-between sm:gap-4'>
                    <div className='font-semibold text-lg'>{item.title}</div>

                    <div className='flex items-center gap-4'>
                        {showVat && (
                            <div className='w-20'>
                                <VatSelect
                                    immutableProps={{ menuAlignment: 'top', size: 'compact' }}
                                    value={item.vat}
                                    onChange={value => value && input('vat', value)}
                                    isDisabled={item.isDeleted}
                                />
                            </div>
                        )}

                        <div className='w-24'>
                            <Form.Input
                                type='number'
                                size='compact'
                                value={item.unitPrice}
                                onChange={e => input('unitPrice', transformToPriceNegative(e.target.value))}
                                disabled={item.isDeleted}
                            />
                        </div>
                    </div>
                </div>

                <Card.Divider />

                <div className='flex items-center gap-2'>
                    <CalendarIcon size='md' /> <DateTimeDisplay dateTime={item.event.start} />
                </div>
            </div>

            {!item.isDeleted ? (
                <Button variant='transparent' size='exact' className='shrink-0' disabled={!deletable} onClick={() => input('isDeleted', true)} aria-label={to('delete-aria', { name: item.title })}>
                    <Trash2Icon />
                </Button>
            ) : (
                <Button variant='transparent' size='exact' className='shrink-0' onClick={() => input('isDeleted', false)} aria-label={to('add-aria', { name: item.title })}>
                    <PlusIcon />
                </Button>
            )}
        </Card>
    );
}

import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import ClaimUrl from ':frontend/pages/auth/ClaimUrl';
import RegisterGoogleCallback from ':frontend/pages/auth/RegisterGoogleCallback';
import Login from ':frontend/pages/auth/Login';
import ResetPassword from ':frontend/pages/auth/ResetPassword';
import Register from ':frontend/pages/auth/Register';

export default function UnauthorizedRoutes() {
    const location = useLocation();

    return (
        <Routes>
            <Route path={routesFE.login.index.path} element={<Login />} />
            <Route path={routesFE.register.claimUrl} element={<ClaimUrl />} />
            <Route path={routesFE.register.google.path} element={<RegisterGoogleCallback />} />
            <Route path={routesFE.register.register.path} element={<Register />} />
            <Route path={routesFE.resetPassword} element={<ResetPassword />} />
            <Route
                path='*'
                element={
                    <Navigate
                        to={routesFE.login.index.path}
                        state={{
                            from: location,
                        }}
                        replace
                    />
                }
            />
        </Routes>
    );
}

import { forwardRef, type ComponentProps } from 'react';
import { cn } from './utils';
import { ChevronLeftIcon, ChevronRightIcon, CircleDotsIcon } from ':components/icons/basic';
import { Button, type ButtonProps, buttonVariants } from './Button';

// FIXME translations

const Pagination = ({ className, ...props }: ComponentProps<'nav'>) => (
    <nav
        role='navigation'
        aria-label='pagination'
        className={cn('mx-auto flex w-full justify-end', className)}
        {...props}
    />
);
Pagination.displayName = 'Pagination';

const PaginationContent = forwardRef<
    HTMLUListElement,
    ComponentProps<'ul'>
>(({ className, ...props }, ref) => (
    <ul
        ref={ref}
        className={cn('flex flex-row items-center gap-2', className)}
        {...props}
    />
));
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = forwardRef<
    HTMLLIElement,
    ComponentProps<'li'>
>(({ className, ...props }, ref) => (
    <li ref={ref} className={cn('', className)} {...props} />
));
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
    isActive?: boolean;
} & Pick<ButtonProps, 'size'> &
    ComponentProps<'a'>

const PaginationLink = ({
    className,
    isActive,
    size = 'medium',
    ...props
}: PaginationLinkProps) => (
    <a
        aria-current={isActive ? 'page' : undefined}
        className={cn(
            buttonVariants({
                variant: isActive ? 'outline' : 'ghost',
                size,
            }),
            className,
        )}
        {...props}
    />
);
PaginationLink.displayName = 'PaginationLink';

type PaginationButtonProps = {
    isActive?: boolean;
} & ButtonProps;

const PaginationButton = ({
    isActive,
    className,
    ...props
}: PaginationButtonProps) => (
    <Button
        className={cn('items-center aspect-square p-0 size-9 text-base', className)}
        aria-current={isActive ? 'page' : undefined}
        variant={isActive ? 'primary' : 'outline'}
        {...props}
    />
);
PaginationLink.displayName = 'PaginationLink';

const PaginationPrevious = ({
    className,
    ...props
}: PaginationButtonProps) => (
    <PaginationButton
        aria-label='Go to previous page'
        size='medium'
        variant='secondary'
        className={cn('h-9 w-auto px-4', className)}
        {...props}
    >
        <ChevronLeftIcon size='md' />
        <span className='sr-only'>Previous</span>
    </PaginationButton>
);
PaginationPrevious.displayName = 'PaginationPrevious';

const PaginationNext = ({
    className,
    ...props
}: PaginationButtonProps) => (
    <PaginationButton
        aria-label='Go to next page'
        size='medium'
        variant='secondary'
        className={cn('h-9 w-auto px-4', className)}
        {...props}
    >
        <ChevronRightIcon size='md' />
        <span className='sr-only'>Previous</span>
    </PaginationButton>
);
PaginationNext.displayName = 'PaginationNext';

const PaginationEllipsis = ({
    className,
    ...props
}: ComponentProps<'span'>) => (
    <span
        aria-hidden
        className={cn('flex h-9 w-9 items-center justify-center', className)}
        {...props}
    >
        <CircleDotsIcon className='h-4 w-4' />
        <span className='sr-only'>More pages</span>
    </span>
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

export {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationButton,
    PaginationNext,
    PaginationPrevious,
};

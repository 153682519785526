import { type Path } from 'react-hook-form';
import type { ZodSchema } from 'zod';

type FormObject = Record<string, unknown>;
export type FormErrors = Record<string, string | undefined>;

function errorsToUndefinedOrEmpty(errors: FormErrors | undefined): FormErrors | undefined {
    const isErrorsEmpty = !errors || !Object.values(errors).some(e => e);
    return isErrorsEmpty ? undefined : errors;
}

type UpdatorData<TState extends FormObject> = { form: TState, formErrors?: FormErrors };

/**
 * true - the value is valid
 * string - the value is invalid and the string is the error message translation id
*/
type ValidationFunction<TState extends FormObject> = (value: unknown, form: TState) => true | string;

type RecursiveFormObject = Record<string, unknown> | unknown[];

export class Updator<TState extends FormObject> {
    private constructor(
        private readonly formState: TState,
        private readonly path: string,
        private readonly value: unknown,
    ) {}

    static update<TState extends FormObject>(
        data: UpdatorData<TState>,
        path: FormPath<TState>,
        value: unknown,
        rules?: RulesDefinition<TState>,
    ): UpdatorData<TState> {
        const updator = new Updator(data.form, path, value);
        const parsedPath = path.split('.');

        const form = updator.recursiveUpdate(data.form, parsedPath, rules) as TState;
        const formErrors = errorsToUndefinedOrEmpty(updator.validate(data.formErrors));

        return { form, formErrors };
    }

    private recursiveUpdate(object: RecursiveFormObject, path: (string | number)[], rules: RulesDefinition<TState> | undefined): RecursiveFormObject {
        const field = path[0];
        const rest = path.slice(1);
        const isLast = rest.length === 0;

        if (!Array.isArray(object)) {
            const innerRules = rules?.[field];

            if (isLast && innerRules)
                this.validationFunction = innerRules as ValidationFunction<TState>;

            return {
                ...object,
                [field]: isLast ? this.value : this.recursiveUpdate(object[field] as RecursiveFormObject, rest, innerRules as RulesDefinition<TState> | undefined),
            };
        }

        if (isLast && rules)
            this.validationFunction = rules as unknown as ValidationFunction<TState>;

        const newArray = [ ...object ];
        newArray[field as number] = isLast ? this.value : this.recursiveUpdate(object[field as number] as RecursiveFormObject, rest, rules);

        return newArray;
    }

    private validationFunction?: ValidationFunction<TState>;

    private validate(errors?: FormErrors): FormErrors | undefined {
        if (!this.validationFunction)
            return errors;

        const result = this.validationFunction(this.value, this.formState);
        const error = result === true ? undefined : result;

        return { ...errors, [this.path]: error };
    }
}

export class Validator<TState extends FormObject> {
    private constructor(
        private readonly formState: TState,
        private readonly errors: FormErrors = {},
    ) {}

    static validate<TState extends FormObject>(
        form: TState,
        rules: RulesDefinition<TState>,
    ): FormErrors | undefined {
        const validator = new Validator(form);
        validator.recursiveValidate(form, '', rules);

        return errorsToUndefinedOrEmpty(validator.errors);
    }

    private recursiveValidate(object: RecursiveFormObject, path: string, rules: RulesDefinition<TState>): void {
        if (object instanceof Array) {
            for (let i = 0; i < object.length; i++) {
                const childObject = object[i] as RecursiveFormObject;
                const childPath = `${path}.${i}`;
                this.recursiveValidate(childObject, childPath, rules);
            }

            return;
        }

        for (const key in rules) {
            if (!(key in object)) {
                console.warn(`Rule field '${key}' not found in the form state`, object);
                continue;
            }

            const childRules = rules[key];
            const childPath = path ? `${path}.${key}` : key;

            if (!(childRules instanceof Function)) {
                const childObject = object[key] as RecursiveFormObject;
                this.recursiveValidate(childObject, childPath, childRules!);
                continue;
            }

            const value = object[key];
            const result = childRules(value, this.formState);

            if (result !== true)
                this.errors[childPath] = result;
        }
    }
}

// Paths
// The default one is from react-hook-form. It produces strings like 'items.0.product.name'.
// The second one is custom for validation. It skips the array indices, so it looks like 'items.product.name'.
// It works even with multiple dimensions - 'items.0.1.2.product.name' will become 'items.product.name'.

export type FormPath<TState extends FormObject> = Path<TState>;

// The custom path isn't needed anymore, but it's just too cute to be removed.

type NestedPath<K extends string, TChild> = TChild extends FormObject
    ? `${K}` | `${K}.${ValidationPath<TChild>}`
    : TChild extends ReadonlyArray<infer V>
        ? NestedPath<K, V>
        : `${K}`;

type ValidationPath<TParent extends FormObject> = {
    [K in keyof TParent]-?: NestedPath<K & string, TParent[K]>;
}[keyof TParent];

// Validation rules

type NestedRules<TState extends FormObject, TChild> = TChild extends FormObject
    ? RulesDefinition<TChild> | ValidationFunction<TState>
    : TChild extends ReadonlyArray<infer V>
        ? NestedRules<TState, V>
        : ValidationFunction<TState>;

export type RulesDefinition<TState extends FormObject> = {
    [K in keyof TState]?: NestedRules<TState, TState[K]>;
};

// TODO We don't have any type for values - only the paths are checked. This should be doable.

// TODO Currently, some special types (like DateTime) extends the FormObject so the Path type continue exploring their internals. We shouldn't allow that.

export function zodRule(translationPrefix: string, zShape: ZodSchema): ValidationFunction<FormObject> {
    return (value: unknown) => {
        const result = zShape.safeParse(value);
        if (result.success)
            return true;

        // We use only the first one for now.
        const issue = result.error.issues[0];

        return translationPrefix + '.' + issue.message;
    };
}

import { Component } from 'react';
import clsx from 'clsx';
import { dndContext } from './dndContext';

export function withDragAndDrop(Calendar) {
    class DragAndDropCalendar extends Component {
        static defaultProps = { ...Calendar.defaultProps };

        constructor(...args) {
            super(...args);
            this.state = { interacting: false };
        }

        getDnDContextValue() {
            return {
                draggable: {
                    onStart: this.handleInteractionStart,
                    onEnd: this.handleInteractionEnd,
                    onBeginAction: this.handleBeginAction,
                    onDropFromOutside: this.props.onDropFromOutside,
                    dragFromOutsideItem: this.props.dragFromOutsideItem,
                    dragAndDropAction: this.state,
                },
            };
        }

        defaultOnDragOver = event => {
            event.preventDefault();
        };

        handleBeginAction = (event, action, direction) => {
            this.setState({ event, action, direction });
        };

        handleInteractionStart = () => {
            if (this.state.interacting === false)
                this.setState({ interacting: true });
        };

        handleInteractionEnd = interactionInfo => {
            const { action, event } = this.state;
            if (!action)
                return;

            this.setState({
                action: null,
                event: null,
                interacting: false,
                direction: null,
            });

            if (interactionInfo == null)
                return;

            interactionInfo.event = event;
            const { onEventDrop, onEventResize } = this.props;
            if (action === 'move' && onEventDrop)
                onEventDrop(interactionInfo);
            if (action === 'resize' && onEventResize)
                onEventResize(interactionInfo);
        };

        render() {
            const { selectable, ...props } = this.props;

            delete props.onEventDrop;
            delete props.onEventResize;
            props.selectable = selectable ? 'ignoreEvents' : false;

            const elementPropsWithDropFromOutside = this.props.onDropFromOutside
                ? {
                    onDragOver: this.props.onDragOver || this.defaultOnDragOver,
                }
                : {};

            const context = this.getDnDContextValue();
            return (
                <dndContext.Provider value={context}>
                    <Calendar
                        {...props}
                        elementProps={elementPropsWithDropFromOutside}
                    />
                </dndContext.Provider>
            );
        }
    }

    return DragAndDropCalendar;
}

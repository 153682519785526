import { createContext, type ReactNode, useContext, useState, useMemo, type SetStateAction, type Dispatch, type ComponentType, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { InvoicingProfileFE } from ':frontend/types/Invoicing';
import { AppUserFE } from ':frontend/types/AppUser';
import { setLocale, setTimezone } from ':frontend/types/i18n';
import { BankAccountFE } from ':frontend/types/BankAccount';
import { GoogleUser } from ':frontend/types/GoogleUser';
import { extract } from ':frontend/hooks/api/utils';
import { AppUserSettingsFE, TeamSettingsFE } from ':frontend/types/settings';
import { TeamFE, TeamMemberFE, TeamMembers } from ':frontend/types/Team';
import { SubscriptionFE } from ':frontend/types/Subscription';
import { ClientTagFE, sortClientTags } from ':frontend/types/ClientTag';
import { trpc } from './TrpcProvider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from ':frontend/context/AuthProvider';
import { routesFE } from ':utils/routes';
import { api } from ':frontend/utils/api';
import * as Sentry from '@sentry/react';
import Loading from ':frontend/pages/Loading';
import { TeamMemberRole } from ':utils/entity/team';
import { fixLocale } from ':utils/i18n';
import type { OnboardingStateOutput } from ':utils/entity/user';

const authorizedContext = createContext<UserContext | undefined>(undefined);

type UserProviderProps = Readonly<{
    children: ReactNode;
    role: TeamMemberRole;
}>;

export function UserProvider({ children, role }: UserProviderProps) {
    const appUser = trpc.user.getAppUser.useQuery();
    const appUserFE = useMemo(() => {
        if (!appUser.data)
            return;

        const output = AppUserFE.fromServer(appUser.data);
        Sentry.setUser({ id: output.id, email: output.email });

        return output;
    }, [ appUser.data ]);

    const appUserSettings = trpc.user.getAppUserSettings.useQuery();
    const appUserSettingsFE = useMemo(() => appUserSettings.data && AppUserSettingsFE.fromServer(appUserSettings.data), [ appUserSettings.data ]);

    useEffect(() => {
        if (appUserSettingsFE?.locale)
            setLocale(fixLocale(appUserSettingsFE.locale));
    }, [ appUserSettingsFE?.locale ]);

    useEffect(() => {
        if (appUserSettingsFE?.timezone)
            setTimezone(appUserSettingsFE.timezone);
    }, [ appUserSettingsFE?.timezone ]);

    const team = trpc.team.getTeam.useQuery();
    const teamFE = useMemo(() => team.data && TeamFE.fromServer(team.data), [ team.data ]);

    const teamMembers = trpc.team.getTeamMembers.useQuery();
    const teamMembersFE = useMemo(() => teamMembers.data && appUserFE && new TeamMembers(appUserFE, teamMembers.data.map(TeamMemberFE.fromServer)), [ appUserFE, teamMembers.data ]);

    const subscription = trpc.$subscription.getSubscription.useQuery();
    const subscriptionFE = useMemo(() => subscription.data && SubscriptionFE.fromServer(subscription.data), [ subscription.data ]);

    const onboarding = trpc.user.getOnboarding.useQuery().data;

    const googleUser = useGoogleUser();

    const commonDefaults: CommonDefaults | undefined = useMemo(() => {
        if (!teamFE || !teamMembersFE || !appUserFE || !appUserSettingsFE || !subscriptionFE || !onboarding || googleUser === undefined)
            return;

        return {
            role: TeamMemberRole.scheduler,
            team: teamFE,
            teamMembers: teamMembersFE,
            appUser: appUserFE,
            settings: appUserSettingsFE,
            onboarding,
            subscription: subscriptionFE ?? undefined,
            googleUser: googleUser ?? undefined,
        };
    }, [ teamFE, teamMembersFE, appUserFE, appUserSettingsFE, subscriptionFE, onboarding, googleUser ]);

    return role === TeamMemberRole.scheduler ? (
        <SchedulerProvider defaults={commonDefaults}>
            { children }
        </SchedulerProvider>
    ) : (
        <MasterProvider role={role} defaults={commonDefaults}>
            { children }
        </MasterProvider>
    );
}

type CommonDefaults = SchedulerDefaults

type SchedulerProviderProps = Readonly<{
    defaults: CommonDefaults | undefined;
    children: ReactNode;
}>;

function SchedulerProvider({ defaults, children }: SchedulerProviderProps) {
    if (!defaults)
        return <Loading />;

    return (
        <UserProviderLoaded defaults={defaults}>
            { children }
        </UserProviderLoaded>
    );
}

type MasterProviderProps = Readonly<{
    role: typeof TeamMemberRole.master | typeof TeamMemberRole.freelancer;
    defaults: CommonDefaults | undefined;
    children: ReactNode;
}>;

function MasterProvider({ role, defaults, children }: MasterProviderProps) {
    const teamSettings = trpc.team.getTeamSettings.useQuery();
    const teamSettingsFE = useMemo(() => teamSettings.data && TeamSettingsFE.fromServer(teamSettings.data), [ teamSettings.data ]);

    const profiles = trpc.invoicing.getInvoicingProfiles.useQuery();
    const profilesFE = useMemo(() => profiles.data?.map(InvoicingProfileFE.fromServer), [ profiles.data ]);

    const bankAccounts = trpc.money.getBankAccounts.useQuery();
    const bankAccountsFE = useMemo(() => bankAccounts.data?.map(BankAccountFE.fromServer), [ bankAccounts.data ]);

    const clientTags = trpc.$client.getClientTags.useQuery();
    const clientTagsFE = useMemo(() => clientTags.data && sortClientTags(clientTags.data.map(ClientTagFE.fromServer)), [ clientTags.data ]);

    const masterDefaults: MasterState | undefined = useMemo(() => {
        if (!defaults || !teamSettingsFE || !profilesFE || !bankAccountsFE || !clientTagsFE)
            return;

        return {
            ...defaults,
            role,
            teamSettings: teamSettingsFE,
            profiles: profilesFE,
            bankAccounts: bankAccountsFE,
            clientTags: clientTagsFE,
        };
    }, [ defaults, role, teamSettingsFE, profilesFE, bankAccountsFE, clientTagsFE ]);

    if (!masterDefaults)
        return <Loading />;

    return (
        <UserProviderLoaded defaults={masterDefaults}>
            { children }
        </UserProviderLoaded>
    );
}

type UserState = SchedulerState | MasterState;

type UserProviderLoadedProps = Readonly<{
    children: ReactNode;
    defaults: UserState;
}>;

function UserProviderLoaded({ children, defaults }: UserProviderLoadedProps) {
    // TODO This is wrong. The state should be updated when the defaults change - e.g., when we invalidate a query ...
    // We can also try to remove as many parts of the state as possible and move them to their respective components. We can create hooks for them ...
    // Here is an idea - hook that returns value and setValue. The setValue takes the raw data from server (so it can be used for mutations), but returns the FE value (so it can be used for alerts).
    const [ state, setState ] = useState<UserState>(defaults);

    useEffect(() => {
        // TODO This is just temporary.
        setState(defaults);
    }, [ defaults ]);

    const setters = useMemo(() => ({
        setAppUser: (input: SetStateAction<AppUserFE>) => setState(state => ({
            ...state, appUser: extract(input, state.appUser),
        })),
        setSettings: (input: SetStateAction<AppUserSettingsFE>) => setState(state => {
            const settings = extract(input, state.settings);
            // Synchronize locale with the settings.
            setTimezone(settings.timezone);
            setLocale(fixLocale(settings.locale));

            return { ...state, settings };
        }),
        setOnboarding: (input: SetStateAction<OnboardingStateOutput>) => setState(state => ({
            ...state, onboarding: extract(input, state.onboarding),
        })),
        setSubscription: (input: SetStateAction<SubscriptionFE>) => setState(state => ({
            ...state, subscription: extract(input, state.subscription),
        })),
        setTeam: (input: SetStateAction<TeamFE>) => setState(state => state && ({
            ...state, team: extract(input, state.team),
        })),
        setTeamSettings: (input: SetStateAction<TeamSettingsFE>) => setState(state => ({
            ...state, teamSettings: extract(input, (state as MasterState).teamSettings),
        })),
        setTeamMembers: (input: SetStateAction<TeamMembers>) => setState(state => ({
            ...state, teamMembers: extract(input, state.teamMembers),
        })),
        setProfiles: (input: SetStateAction<InvoicingProfileFE[]>) => setState(state => {
            const profiles = extract(input, (state as MasterState).profiles);
            return { ...state, profiles };
        }),
        setBankAccounts: (input: SetStateAction<BankAccountFE[]>) => setState(state => ({
            ...state, bankAccounts: extract(input, (state as MasterState).bankAccounts),
        })),
        setClientTags: (input: SetStateAction<ClientTagFE[]>) => setState(state => ({
            ...state, clientTags: extract(input, (state as MasterState).clientTags),
        })),
    }), []);

    const value = useMemo(() => ({
        ...state,
        ...setters,
    }), [ state, setters ]);

    return (
        <authorizedContext.Provider value={value}>
            { children }
        </authorizedContext.Provider>
    );
}

type SchedulerState = {
    role: typeof TeamMemberRole.scheduler;
    team: TeamFE;
    teamMembers: TeamMembers;
    appUser: AppUserFE;
    settings: AppUserSettingsFE;
    onboarding: OnboardingStateOutput;
    subscription: SubscriptionFE;
    googleUser: GoogleUser | undefined;
};

type SchedulerDefaults = SchedulerState;
export type SchedulerContext = SchedulerState & {
    setTeam: Dispatch<SetStateAction<TeamFE>>;
    setAppUser: Dispatch<SetStateAction<AppUserFE>>;
    setSettings: Dispatch<SetStateAction<AppUserSettingsFE>>;
    setOnboarding: Dispatch<SetStateAction<OnboardingStateOutput>>;
    setSubscription: Dispatch<SetStateAction<SubscriptionFE>>;
};

/**
 * Returns data only for user role scheduler. Throws error otherwise.
 */
export function useScheduler(): SchedulerContext  {
    const context = useContext(authorizedContext);
    if (context === undefined)
        throw new Error('useScheduler must be used within an AuthProvider');

    const schedulerContext = toScheduler(context);
    if (!schedulerContext)
        throw new Error(`useScheduler can't be used with a ${context.role} role`);

    return schedulerContext;
}

export function toScheduler(context: UserContext): SchedulerContext | undefined {
    return context.role === TeamMemberRole.scheduler ? context : undefined;
}

type MasterState = {
    role: typeof TeamMemberRole.master | typeof TeamMemberRole.freelancer;
    team: TeamFE;
    teamMembers: TeamMembers;
    appUser: AppUserFE;
    settings: AppUserSettingsFE;
    onboarding: OnboardingStateOutput;
    subscription: SubscriptionFE;
    googleUser: GoogleUser | undefined;
    teamSettings: TeamSettingsFE;
    profiles: InvoicingProfileFE[];
    bankAccounts: BankAccountFE[];
    clientTags: ClientTagFE[];
};

export type MasterContext = MasterState & {
    setTeam: Dispatch<SetStateAction<TeamFE>>;
    setTeamSettings: Dispatch<SetStateAction<TeamSettingsFE>>;
    setTeamMembers: Dispatch<SetStateAction<TeamMembers>>;
    setProfiles: Dispatch<SetStateAction<InvoicingProfileFE[]>>;
    setBankAccounts: Dispatch<SetStateAction<BankAccountFE[]>>;
    setClientTags: Dispatch<SetStateAction<ClientTagFE[]>>;
    setAppUser: Dispatch<SetStateAction<AppUserFE>>;
    setSettings: Dispatch<SetStateAction<AppUserSettingsFE>>;
    setOnboarding: Dispatch<SetStateAction<OnboardingStateOutput>>;
    setSubscription: Dispatch<SetStateAction<SubscriptionFE>>;
};

/**
 * Returns data only for user roles master or freelancer. Throws error otherwise.
 */
export function useMaster(): MasterContext {
    const context = useContext(authorizedContext);
    if (context === undefined)
        throw new Error('useMaster must be used within an AuthProvider');

    const masterContext = toMaster(context);
    if (!masterContext)
        throw new Error(`useMaster can't be used with a ${context.role} role`);

    return masterContext;
}

export function toMaster(context: UserContext): MasterContext | undefined {
    return (context.role === TeamMemberRole.master || context.role === TeamMemberRole.freelancer) ? context : undefined;
}

export function masterComponent<TProps>(Component: ComponentType<TProps>): ComponentType<TProps> {
    return function MasterComponentWrapper(props: TProps) {
        const masterContext = toMaster(useUser());
        if (!masterContext)
            return null;

        return <Component {...props} masterContext={masterContext} />;
    };
}

export type UserContext = SchedulerContext | MasterContext;

export function useUser(): UserContext {
    const context = useContext(authorizedContext);
    if (context === undefined)
        throw new Error('useUser must be used within an AuthProvider');

    return context;
}

async function fetchGoogleUser() {
    const response = await api.google.getUserInfo();
    if (!response.status)
        throw new Error('Google user info fetch failed');

    const googleUser = GoogleUser.fromServer(response.data);
    return googleUser;
}

function useGoogleUser() {
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const { auth } = useAuth();
    const shouldRefreshGoogle = !!searchParams.get('refresh-google');
    const shouldFetchGoogle = !!api.google.authorizer.getAuthorizationHeader();
    const googleQuery = useQuery({ queryKey: [ 'google-user' ], queryFn: fetchGoogleUser, enabled: shouldFetchGoogle });

    async function refreshGoogle() {
        await auth.refreshAccessToken();
        navigate(routesFE.integrations.path, { replace: true });
    }

    useEffect(() => {
        if (shouldRefreshGoogle)
            refreshGoogle();
    }, []);

    if (googleQuery.data)
        return googleQuery.data;

    if (shouldRefreshGoogle)
        return undefined;

    if (shouldFetchGoogle && googleQuery.isFetching)
        return undefined;

    return null;
}

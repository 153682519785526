import { type RefObject } from 'react';
import clsx from 'clsx';
import { DateContentRow } from './DateContentRow';
import { getNow } from './localizer';
import { localizer } from './utils/common';
import type { DateTime } from 'luxon';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import type { Selected } from ':frontend/pages/CalendarDetail';
import type { SlotInfo } from '.';
import { capitalize } from ':utils/common';
import type { Selectable } from './DayColumn';

type TimeGridHeaderProps = Readonly<{
    range: DateTime[];
    date: DateTime;
    events: CalendarEvent[];
    selected?: Selected;
    selectable?: Selectable;
    scrollRef: RefObject<HTMLDivElement>;
    onSelectEvent: (event: CalendarEvent) => void;
    onSelectSlot: (slots: DateTime[], slotInfo: SlotInfo) => void;
}>;

export function TimeGridHeader({ range, date, events, selected, selectable, scrollRef, onSelectEvent, onSelectSlot }: TimeGridHeaderProps) {
    return (
        <div ref={scrollRef} className='sticky top-0 z-20 flex w-fit min-w-full bg-white border-b border-secondary-100'>
            <div className='min-w-20 w-20 border-r border-secondary-100 shrink-0'>
                <TimegridRangeDisplay date={date} />
            </div>

            <div className='grow flex flex-col'>
                <div className={clsx('min-h-12 flex *:flex-1 *:min-w-32 divide-x *:border-secondary-100', range.length <= 1 && 'hidden')}>
                    {range.map(date => headerCell(date))}
                </div>

                <DateContentRow
                    isAllDay
                    minRows={0}
                    range={range}
                    events={events}
                    className='rbc-allday-cell relative w-full h-full content-box [&+&]:border-l border-secondary-100'
                    selectable={selectable}
                    selected={selected}
                    onSelect={onSelectEvent}
                    onSelectSlot={onSelectSlot}
                />
            </div>
        </div>
    );
}

function headerCell(date: DateTime) {
    const isToday = localizer.isSameDate(date, getNow());

    return (
        <div key={+date} className={'flex items-center justify-center select-none'}>
            <div>{capitalize(localizer.format(date, 'cccc'))}</div>

            <div className={clsx('w-9 h-7 leading-7 rounded-full text-center', isToday ? 'ml-2 bg-primary-50 text-primary' : 'font-semibold')}>
                {localizer.format(date, 'd')}
            </div>
        </div>
    );
}

type TimegridRangeDisplayProps = {
    date: DateTime;
};

function TimegridRangeDisplay({ date }: TimegridRangeDisplayProps) {
    const start = localizer.startOf(date, 'week');
    const end = localizer.endOf(date, 'week');

    // TODO make more effective
    const allDays = localizer.range(start, end);

    return <WeekRangeFormat start={start} end={allDays[allDays.length - 1]} />;
}

type WeekRangeFormatProps = {
    start: DateTime;
    end: DateTime;
};

function WeekRangeFormat({ start, end }: WeekRangeFormatProps) {
    const isSameYear = localizer.eq(start, end, 'year');
    // If the years aren't equal, the months are not equal as well.
    const isSameMonth = isSameYear && localizer.eq(start, end, 'month');

    return (
        <div className='h-full max-h-12 px-2 flex items-center justify-between'>
            <div className='font-semibold'>
                <div>{localizer.format(start, 'LLL')}</div>
                {!isSameMonth && (
                    <div>{localizer.format(end, 'LLL')}</div>
                )}
            </div>

            <div className='tabular-nums'>
                <div>{localizer.format(start, 'yyyy')}</div>

                {!isSameYear && (
                    <div>{localizer.format(end, 'yyyy')}</div>
                )}
            </div>
        </div>
    );
}

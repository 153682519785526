import { type OrderFE } from ':frontend/types/orders/Order';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from ':utils/entity/invoicing';
import { cva } from 'class-variance-authority';
import { cn } from ':components/shadcn/utils';
import { BankingMobileIcon } from ':components/icons/basic';
import type { IconType } from ':components/icons/common';
import { PayPalPIcon, StripeSIcon } from ':components/icons/logos';

const orderPaymentMethodBadgeVariants = cva('h-9 px-3 rounded-full flex items-center gap-2', {
    variants: {
        variant: {
            [PaymentMethod.bankTransfer]: 'text-warning-700 bg-warning-100',
            [PaymentMethod.noInvoice]: 'text-secondary-700 bg-secondary-100',
            [PaymentMethod.paypal]: 'text-primary-600 bg-primary-100',
            [PaymentMethod.stripe]: 'text-primary-700 bg-primary-100',
        },
    },
    defaultVariants: {
        variant: PaymentMethod.bankTransfer,
    },
});

const icons: Record<PaymentMethod, IconType|null> = {
    [PaymentMethod.bankTransfer]: BankingMobileIcon,
    [PaymentMethod.noInvoice]: null,
    [PaymentMethod.paypal]: PayPalPIcon,
    [PaymentMethod.stripe]: StripeSIcon,
};

type OrderPaymentMethodDisplayProps = Readonly<{
    order: OrderFE;
    className?: string;
}>;

export function OrderPaymentMethodBadge({ order, className }: OrderPaymentMethodDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'paymentMethodBadge' });

    const { paymentMethod } = order;
    const Icon = icons[paymentMethod];

    return (
        <div className={cn(orderPaymentMethodBadgeVariants({ variant: paymentMethod }), className)}>
            {Icon && <Icon size='md' /> } {t(paymentMethod)}
        </div>
    );
}


import { useEffect, useRef, useState } from 'react';
import { Button, Modal } from ':components/shadcn';
import { Link } from 'react-router-dom';
import { ErrorType, type PlanExceededError, type SubscriptionInvalidError, type SubscriptionError, isPlanExceededError } from ':frontend/types/Subscription';
import { Trans, useTranslation } from 'react-i18next';
import { emptyFunction } from ':frontend/utils/common';
import { routesFE } from ':utils/routes';
import { useCached } from ':components/hooks';
import { SpinnerButton } from '../common';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { trpc } from ':frontend/context/TrpcProvider';
import { TriangleWarningIconScaling } from ':components/icons/basic';

type PlanExceededErrorModalProps = Readonly<{
    error: PlanExceededError | undefined;
    onHide: () => void;
}>;

export function PlanExceededErrorModal({ error, onHide }: PlanExceededErrorModalProps) {
    return (
        <CommonModal error={error} onHide={onHide} />
    );
}

type UnselectablePlanErrorModalProps = Readonly<{
    error: SubscriptionInvalidError | undefined;
    onHide: () => void;
}>
export function UnselectablePlanErrorModal({ error, onHide }: UnselectablePlanErrorModalProps) {
    return (
        <CommonModal error={error} onHide={onHide}/>
    );
}

type SubscriptionEndedErrorModalProps = Readonly<{
    error: SubscriptionInvalidError | undefined;
}>;

export function SubscriptionEndedErrorModal({ error }: SubscriptionEndedErrorModalProps) {
    return (
        <CommonModal error={error} onHide={emptyFunction} />
    );
}

type CommonModalProps = Readonly<{
    error: SubscriptionError | undefined;
    onHide: () => void;
}>;

function CommonModal({ error, onHide }: CommonModalProps) {
    const cachedError = useCached(error);
    if (!cachedError)
        return null;

    return (
        <Modal.Root open={!!error}>
            <Modal.Content closeButton={null} className='items-center gap-6'>
                <ModalBody error={cachedError} onHide={onHide} />
            </Modal.Content>
        </Modal.Root>
    );
}

function ModalBody({ error, onHide }: CommonModalProps) {
    const isMasterOrFreelancer = !!toMaster(useUser());
    const { t } = useTranslation('common', { keyPrefix: isMasterOrFreelancer ? error?.type : 'subscription.schedulerError' });
    const [ isFetching, setIsFetching ] = useState(false);
    const subscriptionSessionPromiseRef = useRef<Promise<string>>();

    const createSubscriptionSessionMutation = trpc.$subscription.createSubscriptionSession.useMutation();

    useEffect(() => {
        if (error?.type !== ErrorType.PlanPending)
            return;

        subscriptionSessionPromiseRef.current = (async () => {
            try {
                const response = await createSubscriptionSessionMutation.mutateAsync();
                return response.continueUrl;
            }
            catch {
                return '';
            }
        })();
    }, [ error, createSubscriptionSessionMutation ]);

    async function openSubscriptionSession() {
        if (!subscriptionSessionPromiseRef.current)
            return;
        setIsFetching(true);
        window.location.href = await subscriptionSessionPromiseRef.current;
    }

    return (<>
        <Modal.Header className='text-center'>
            <TriangleWarningIconScaling size={60} className='mx-auto text-warning' />

            <Modal.Title className='mt-3'>{t('title')}</Modal.Title>

            <Modal.Description className='mt-1 leading-5 whitespace-pre-wrap'>
                {isPlanExceededError(error) ? (
                    <Trans>
                        {t('description-cart', { count: error.cart })}
                        {t('description-available', { count: error.available })}
                    </Trans>
                ) : t('description')}
            </Modal.Description>
        </Modal.Header>

        {isMasterOrFreelancer && (
            <Modal.Footer>
                {isPlanExceededError(error) ? (<>
                    <Button onClick={onHide} className='float-right'>
                        {t('hide-button')}
                    </Button>

                    <Link to={routesFE.settings.resolve({ key: 'subscription' })} className='float-left'>
                        <Button>{t('new-plan-button')}</Button>
                    </Link>
                </>) : error?.type === ErrorType.PlanPending ? (
                    <SpinnerButton isFetching={isFetching} onClick={openSubscriptionSession}>
                        {t('check-button')}
                    </SpinnerButton>
                ) : error?.type === ErrorType.PlanEnded ? (
                    <Link to={routesFE.settings.resolve({ key: 'subscription' })} className='float-left'>
                        <Button>{t('new-plan-button')}</Button>
                    </Link>
                ) : (
                    <Button onClick={onHide}>
                        {t('hide-button')}
                    </Button>
                )}
            </Modal.Footer>
        )}
    </>);
}

import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import MasterDashboard from ':frontend/pages/master/MasterDashboard';
import SchedulerDashboard from ':frontend/pages/scheduler/SchedulerDashboard';
import Clients from ':frontend/pages/client/Clients';
import ClientDetail from ':frontend/pages/client/ClientDetail';
import EventDetail from ':frontend/pages/event/EventDetail';
import Settings from ':frontend/pages/settings/Settings';
import OrderDetail from ':frontend/pages/orders/OrderDetail';
import CalendarDetail from ':frontend/pages/CalendarDetail';
import Orders from ':frontend/pages/orders/Orders';
import DevPage from ':frontend/components/dev/DevPage';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import TeamDetail from ':frontend/pages/TeamDetail';
import { OrdersExport } from ':frontend/pages/orders/OrdersExport';
import RegisterGoogleCallback from ':frontend/pages/auth/RegisterGoogleCallback';
import { StorePage } from ':frontend/pages/store/StorePage';
import { Products } from ':frontend/pages/products/Products';
import { NewProduct } from ':frontend/pages/products/NewProduct';
import { ProductDetail } from ':frontend/pages/products/ProductDetail';
import { DirectSalePage } from ':frontend/pages/direct-sale/DirectSalePage';

const enableDevPage = import.meta.env.DEV;

export default function AuthorizedRoutes() {
    const location = useLocation();
    const from = (location.state as { from: string | undefined })?.from ?? routesFE.root;
    const isMasterOrFreelancer = !!toMaster(useUser());

    return (
        <Routes>
            <Route path={routesFE.register.google.path}               element={<RegisterGoogleCallback />} />

            <Route path={routesFE.calendar}                           element={<CalendarDetail />} />

            <Route path={routesFE.clients.list.path}                       element={<Clients />} />
            <Route path={routesFE.clients.detail.path}                element={<ClientDetail />} />

            <Route path={routesFE.orders.list.path}                        element={<Orders />} />

            <Route path={routesFE.events.detail.path}                 element={<EventDetail />} />
            <Route path={routesFE.settings.path}                      element={<Settings />} />

            {isMasterOrFreelancer ? (<>
                <Route path={routesFE.dashboard.path}                      element={<MasterDashboard />} />

                <Route path={routesFE.products.list}                      element={<Products />} />
                <Route path={routesFE.products.new.path}                  element={<NewProduct />} />
                <Route path={routesFE.products.detail.path}               element={<ProductDetail />} />

                <Route path={routesFE.orders.detail.path}             element={<OrderDetail />} />
                <Route path={routesFE.orders.export}                  element={<OrdersExport />} />

                <Route path={routesFE.directSale.root.path}           element={<DirectSalePage />} />

                <Route path={routesFE.store.path}                     element={<StorePage />} />
                <Route path={routesFE.team}                           element={<TeamDetail />} />
            </>) : (<>
                <Route path={routesFE.dashboard.path}                      element={<SchedulerDashboard />} />
            </>)}

            {enableDevPage && <Route path={routesFE.dev} element={<DevPage />} />}

            <Route path='*' element={<Navigate replace to={from} />} />
        </Routes>
    );
}

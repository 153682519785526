import { forwardRef, type ReactNode, type HTMLAttributes } from 'react';
import { cn } from './utils';

type CardProps = HTMLAttributes<HTMLDivElement> & Readonly<{
    children?: ReactNode;
    className?: string;
}>;

const CardInner = forwardRef<HTMLDivElement, CardProps>(({ children, className, ...props }, ref) => {
    return (
        // TODO Why these arbitrary values for border color and shadow?
        <div className={cn('p-6 rounded-xl bg-white border border-[#e6e6e6] shadow-[0px_5px_15px_0px_rgba(0,0,0,0.05)]', className)} ref={ref} {...props}>
            {children}
        </div>
    );
});

type CardDividerProps = Readonly<{
    className?: string;
}>;

const Divider = ({ className } : CardDividerProps) => <div className={cn('border-b border-[#e6e6e6] my-5', className)} />;

type CardTitleProps = Readonly<{
    children?: ReactNode;
    className?: string;
}>;

const Title = ({ children, className } : CardTitleProps) => <h3 className={cn('text-lg text-secondary-700', className)}>{children}</h3>;

export const Card = Object.assign(CardInner, {
    Divider,
    Title,
});

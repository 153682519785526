import { LocationForm, type LocationFormData } from './LocationForm';
import { useTranslation } from 'react-i18next';
import { trpc } from ':frontend/context/TrpcProvider';
import { locationFormToUpsert } from './NewLocationModal';
import { Modal, Card } from ':components/shadcn';
import type { LocationOutput } from ':utils/entity/location';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { PinIcon } from ':components/icons/basic';

type EditLocationModalProps = Readonly<{
    location?: LocationOutput;
    onClose(): void;
    onLocationEdit(updatedLocation: LocationOutput): void;
}>;

export function EditLocationModal({ location, onClose, onLocationEdit }: EditLocationModalProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.locations.editModal' });
    const { addAlert } = useNotifications();

    const updateLocationMutation = trpc.location.updateLocation.useMutation();

    function onSubmit(data: LocationFormData) {
        if (!location)
            return;

        const upsert = locationFormToUpsert(data);
        updateLocationMutation.mutate({ ...upsert, id: location.id }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
                onClose();
            },
            onSuccess: response => {
                onLocationEdit(response);
                addAlert(createTranslatedSuccessAlert('common:locationSavedAlert'));
                onClose();
            },
        });
    }

    return (
        <Modal.Root open={!!location} onOpenChange={open => !open && onClose()}>
            <Modal.Content className='gap-6' closeButton={t('cancel-button')}>
                <Modal.Header>
                    <Modal.Title className='flex items-center gap-2'>
                        <PinIcon className='h-5 text-primary' />
                        <span>{t('title')}</span>
                    </Modal.Title>
                </Modal.Header>

                <Card.Divider className='my-0' />

                <LocationForm
                    input={location ?? {}}
                    isFetching={updateLocationMutation.isPending}
                    onSubmit={onSubmit}
                    typeIsConstant
                />
            </Modal.Content>
        </Modal.Root>
    );
}

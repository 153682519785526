export { default as localizer } from '../localizer';

export enum NavigateDirection {
    Prev = 'prev',
    Next = 'next',
    Today = 'today',
}

// TODO move to some context?
// TODO fix - currently not working everywhere
export const showMultiDayTimes = false;

// How many slots there are in one section.
export const timeslots = 4;
// How much time (in minutes) each slot has.
export const step = 15;

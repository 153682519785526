import { useCallback, useMemo, useRef } from 'react';
import { DateTime } from 'luxon';
import { capitalize } from ':utils/common';
import ReactDatePicker, {  type ReactDatePickerCustomHeaderProps, type ReactDatePickerProps } from 'react-datepicker';
import { Controller, type Control, type FieldPath, type FieldValues, type RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getI18nLocale } from ':utils/i18n';
import { ChevronLeftIcon, ChevronRightIcon } from ':components/icons/basic';
import { optional } from ':utils/common';

const DEFAULTS = {
    timeIntervals: 5,
    formatWeekDay: (dayName: string) => capitalize(dayName.slice(0, 2)),
    renderCustomHeader: CustomHeader,
};

function CustomHeader(props: Readonly<ReactDatePickerCustomHeaderProps>) {
    const date = DateTime.fromJSDate(props.monthDate);

    return (
        <div className='flex justify-between items-center'>
            <MonthLabel date={date} />

            <div className='flex gap-2 items-center'>
                <span
                    className='cursor-pointer select-none'
                    onClick={() => props.decreaseMonth()}
                >
                    <ChevronLeftIcon size='xs' />
                </span>

                <span
                    className='cursor-pointer select-none'
                    onClick={() => props.increaseMonth()}
                >
                    <ChevronRightIcon size='xs' />
                </span>
            </div>
        </div>
    );
}

type MonthLabelProps = Readonly<{
    date: DateTime;
}>;

export function MonthLabel({ date }: MonthLabelProps) {
    return (<div className='flex items-center gap-1'>
        <span className='font-semibold text-lg text-secondary-900'>{capitalize(date.toFormat('LLLL'))}</span>
        <span className='font-semibold text-lg text-secondary-400'>{date.toFormat('yyyy')}</span>
    </div>);
}


type DatePickerType = 'all' | 'date' | 'time';

// FIXME dateFormat: we probably want to use a custom input that accepts a DateTime to show it correctly
// this approach always uses the English format even for other users
function getTypeOptions(type?: DatePickerType) {
    if (type === 'date') {
        return {
            dateFormat: 'LLL d, yyyy',
            showTimeSelect: false,
        };
    }

    if (type === 'time') {
        return {
            dateFormat: 'h:mm a',
            showTimeSelect: true,
            showTimeSelectOnly: true,
        };
    }

    return {
        dateFormat: 'LLL d, yyyy, h:mm a',
        showTimeSelect: true,
    };
}

function useDefaults(isCustomInput: boolean, type?: DatePickerType) {
    const { t, i18n } = useTranslation('components', { keyPrefix: 'datePicker' });

    const defaults = useMemo(() => ({
        ...DEFAULTS,
        timeCaption: t('timeCaption'),
        locale: getI18nLocale(i18n),
        ...getTypeOptions(type),
        ...optional('dateFormat', isCustomInput ? ISO_FORMAT : undefined),
    }), [ t, type, i18n, isCustomInput ]);

    return defaults;
}

type DatePickerProps = Omit<ReactDatePickerProps<never, undefined>, 'selected' | 'onChange'> & {
    selected?: DateTime;
    onChange: (newValue?: DateTime) => void;
    onBlur?: () => void;
    type?: DatePickerType;
};

export function DatePicker({ selected, onChange, onBlur, type, className, customInput, ...rest }: DatePickerProps) {
    const innerOnChange = useCallback((newDate: Date | null) => {
        onChange(newDate ? localDateToUserDate(newDate) : undefined);
        onBlur?.();
    }, [ onChange, onBlur ]);

    const defaults = useDefaults(!!customInput, type);

    return (
        <ReactDatePicker
            {...defaults}
            {...rest}
            className={className}
            selected={selected ? userDateToLocalDate(selected) : null}
            onChange={innerOnChange}
            customInput={customInput}
        />
    );
}

const ISO_FORMAT = `yyyy-MM-dd'T'HH:mm:ss'Z'`;
const FORM_FORMAT = 'yyyy-MM-dd_HH:mm:ss';

function userDateToLocalDate(userDate: DateTime): Date {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return DateTime.fromFormat(userDate.toFormat(FORM_FORMAT), FORM_FORMAT, { zone: localTimezone }).toJSDate();
}

function localDateToUserDate(localDate: Date): DateTime {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return DateTime.fromFormat(DateTime.fromJSDate(localDate, { zone: localTimezone }).toFormat(FORM_FORMAT), FORM_FORMAT);
}

type PlainDatePickerProps = Omit<ReactDatePickerProps<never, undefined>, 'selected' | 'onChange'> & {
    selected: string;
    onChange: (newValue: string) => void;
    onBlur?: () => void;
    type?: DatePickerType;
};

function PlainDatePicker({ selected, onChange, onBlur, type, customInput, ...rest }: PlainDatePickerProps) {
    const ref = useRef<ReactDatePicker>(null);
    const innerOnChange = useCallback((newDate: Date | null) => {
        // If the new date isn't valid, we don't want to propagate the change.
        if (newDate) {
            onChange(localDateToUserString(newDate));
            onBlur?.();
        }
    }, [ onChange, onBlur ]);

    const defaults = useDefaults(!!customInput, type);

    return (
        <ReactDatePicker
            {...defaults}
            {...rest}
            selected={userStringToLocalDate(selected)}
            onChange={innerOnChange}
            customInput={customInput}
            ref={ref}
        />
    );
}

// The following process is needed because the date picker is oblivious of timezones.
// So we have to fake current time by moving by the timezone difference.
// There can be some bugs during the times when the difference changes (i.e., during the daylight time change), but this is such an edge case ...

export function dateToFormString(date: DateTime): string {
    return date.toFormat(FORM_FORMAT);
}

export function formStringToDate(formString: string): DateTime {
    return DateTime.fromFormat(formString, FORM_FORMAT);
}

/** The user input might be invalid. */
function userStringToLocalDate(userString: string): Date | undefined {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDateTime = DateTime.fromFormat(userString, FORM_FORMAT, { zone: localTimezone });
    return localDateTime.isValid ? localDateTime.toJSDate() : undefined;
}

function localDateToUserString(localDate: Date): string {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return DateTime.fromJSDate(localDate, { zone: localTimezone }).toFormat(FORM_FORMAT);
}

type ControlledDatePickerProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    disabled?: boolean;
    type?: DatePickerType;
    rules?: Omit<RegisterOptions<TFieldValues, FieldPath<TFieldValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
};

export function ControlledDatePicker<TFieldValues extends FieldValues>({ control, name, disabled, type, rules }: ControlledDatePickerProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value: string, onChange: (value: string ) => void, onBlur: () => void } }) => {
        return (
            <PlainDatePicker
                selected={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={disabled}
                type={type}
            />
        );
    }, [ disabled, type ]);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSelect}
            rules={rules}
        />
    );
}

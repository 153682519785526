import { Form } from ':components/shadcn';
import { type FieldValues, type UseFormReturn } from 'react-hook-form';
import { type SubscriberSettingsFE } from ':frontend/types/Invoicing';
import { addressToUpsert, defaultEditableAddress, type EditableAddress } from ':frontend/types/Address';
import { ControlledCountrySelect } from ':frontend/components/forms';
import { useTranslation } from 'react-i18next';
import { optionalStringToPut } from ':frontend/utils/common';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { EMAIL_SOFT_VALIDATION_PATTERN, canonizeEmail } from ':utils/forms';
import type { SubscriberSettingsUpsert } from ':utils/entity/invoicing';

type InputType = Omit<SubscriberSettingsFE, 'id'>;
export type FormDataType = EditableAddress & {
    cin: string;
    tin: string;
    invoicingEmail: string;
    hideEmailOnInvoice: boolean;
};

export function inputToForm(input: InputType): FormDataType {
    return {
        ...(input.address?.toEditable() ?? defaultEditableAddress),
        cin: input.cin ?? '',
        tin: input.tin ?? '',
        invoicingEmail: input.email ?? '',
        hideEmailOnInvoice: input.hideEmailOnInvoice,
        //currencyId: input.settings.currency.id,
    };
}

export function formToOutput(data: FormDataType): SubscriberSettingsUpsert {
    return {
        address: addressToUpsert(data),
        cin: optionalStringToPut(data.cin),
        tin: optionalStringToPut(data.tin),
        email: optionalStringToPut(canonizeEmail(data.invoicingEmail)),
        hideEmailOnInvoice: data.hideEmailOnInvoice,
    };
}

type SubscriberFormInnerProps<T extends FieldValues> = Readonly<{
    form: UseFormReturn<T>;
    isNew?: boolean;
}>;

export function SubscriberFormInner<T extends FormDataType>(props: SubscriberFormInnerProps<T>) {
    const { t } = useTranslation('components', { keyPrefix: 'invoicingForm' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });

    const form = props.form as unknown as UseFormReturn<FormDataType>;
    const { control, register, formState: { errors } } = form;


    const hideEmailOnInvoice = form.watch('hideEmailOnInvoice');

    return (
        <div className='space-y-8'>
            <div className='space-y-4'>
                <div className='space-y-2'>
                    <div>
                        <Form.Input
                            label={t('invoicing-email-label')}
                            {...register('invoicingEmail', {
                                pattern: {
                                    value: EMAIL_SOFT_VALIDATION_PATTERN,
                                    message: tf('email-invalid'),
                                },
                            })}
                            placeholder={t('invoicing-email-placeholder')}
                            type='email'
                            disabled={hideEmailOnInvoice}
                        />
                        <RHFErrorMessage errors={errors} name='invoicingEmail' />
                    </div>

                    <div className='flex items-center gap-2'>
                        <Form.Switch
                            label={t('hide-email-on-invoice-label')}
                            {...register('hideEmailOnInvoice')}
                        />
                    </div>
                </div>
            </div>

            <div className='space-y-4'>
                <h5 className='font-semibold text-lg'>{t('invoicing-company-title')}</h5>

                <div className='flex items-end gap-4'>
                    <div className='flex-1'>
                        <Form.Input
                            label={t('cin-label')}
                            {...register('cin')}
                            placeholder={t('cin-placeholder')}
                        />
                    </div>
                </div>

                <div>
                    <Form.Input
                        label={t('tin-label')}
                        {...register('tin')}
                        placeholder={t('tin-placeholder')}
                    />                    
                </div>
            </div>

            <div className='space-y-4'>
                <h5 className='font-semibold text-lg'>{t('invoicing-address-title')}</h5>

                <div>
                    <Form.Input
                        label={t('city-label')}
                        {...register('city')}
                        placeholder={t('city-placeholder')}
                    />
                </div>

                <div>
                    <Form.Input
                        label={t('line1-label')}
                        {...register('line1')}
                        placeholder={t('line1-placeholder')}
                    />
                </div>

                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                    <div>
                        <Form.Input
                            label={t('postal-code-label')}
                            {...register('postalCode')}
                            placeholder={t('postal-code-placeholder')}
                        />
                    </div>

                    <div>
                        <Form.Label>{t('country-label')}</Form.Label>
                        <ControlledCountrySelect
                            control={control}
                            name='country'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

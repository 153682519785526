import { type default as React, type Dispatch, type SetStateAction, useEffect, useMemo, useState } from 'react';
import { OrdersStatsFE } from ':frontend/types/orders/OrdersStats';
import { useTranslation } from 'react-i18next';
import { Doughnut, Line } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import { Chart as ChartJS, LineElement, PointElement, TimeScale, LinearScale, Filler, Tooltip as ChartJSTooltip, type ChartData, type ChartOptions } from 'chart.js';
import { MoneyDisplay } from ':components/custom';
import { BoxHeartIcon, CoinsIcon, TrophyIcon, BoxMinusIcon, ThumbsUpIcon, ChartComboIcon } from ':components/icons/basic';
import { moneyFromServer } from ':utils/money';
import { OrderCreatedFrom, OrderStatsRange, type OrderStatsInput } from ':utils/entity/order';
import { trpc } from ':frontend/context/TrpcProvider';
import { getEnumValues } from ':utils/common';
import { Spinner, StringSelect, Card } from ':components/shadcn';
import { cn } from ':components/shadcn/utils';
import { productStyles } from ':components/store/product/ProductCard';
import tailwindConfig from ':frontend/../tailwind.config';
import { priceFromServer, roundToFixedDecimal } from ':utils/math';
import { InfoTooltip } from '../common/InfoTooltip';
import { OrderState } from ':utils/entity/invoicing';
import { useUser } from ':frontend/context/UserProvider';
import { UpsellButton } from ':frontend/components/upsell/UpsellModal';
import clsx from 'clsx';
import { useTailwindMediaQuery } from ':frontend/hooks/useTailwindMediaQuery';


ChartJS.register(LineElement, PointElement, TimeScale, LinearScale, Filler, ChartJSTooltip);

export const PERIODS_COUNT = 4;

export function OrdersStatsDisplay() {
    const { subscription } = useUser();

    const [ rangeState, setRangeState ] = useState<OrderStatsInput>({
        range: OrderStatsRange.last7Days,
    });

    const rangeAvailable = subscription.restrictions.statsRange === 'any' || rangeState.range === OrderStatsRange.today || rangeState.range === OrderStatsRange.last7Days;

    const orderStatsQuery = trpc.order.getStats.useQuery(rangeState, { enabled: rangeAvailable });
    const trpcUtils = trpc.useUtils();

    const stats = useMemo(() => {
        if (!rangeAvailable)
            return OrdersStatsFE.createExample(rangeState.range);

        if (!orderStatsQuery.data)
            return;
        const newStats = OrdersStatsFE.fromServer(orderStatsQuery.data, rangeState.range);

        return newStats;
    }, [ orderStatsQuery.data, rangeAvailable, rangeState.range ]);

    useEffect(() => {
        // TODO this shouldn't be in a useEffect
        // move to event handler or configure the useQuery to invalidate automatically
        // is it even needed?
        trpcUtils.order.getStats.invalidate();
    }, [ rangeState ]);

    const showDesktopView = useTailwindMediaQuery({ minWidth: 'lg' });
    const showMobileView = !showDesktopView;

    return (
        <Card className='max-lg:p-0 max-lg:max-w-screen-md max-lg:bg-transparent max-lg:shadow-none max-lg:border-none'>
            <StatsHeader range={rangeState} setRange={setRangeState} />
            {stats ? (
                <div className='relative space-y-8'>
                    {!rangeAvailable && showMobileView && <StatsUpsell />}

                    <StatsCharts stats={stats} showUpsell={!rangeAvailable && showDesktopView} />
                    <div className='max-lg:hidden w-full border-b' />
                    <StatsProducts stats={stats} showUpsell={!rangeAvailable && showDesktopView} />
                </div>
            ) : (
                <div className='flex w-full h-full justify-center items-center'>
                    <Spinner />
                </div>
            )}
        </Card>
    );
}

type StatsInnerProps = Readonly<{
    stats: OrdersStatsFE;
    showUpsell?: boolean;
}>;

enum OrdersStatsMetric {
    visits = 'visits',
    leads = 'leads',
    ordersCount = 'ordersCount',
    ordersValue = 'ordersValue',
}

function StatsCharts({ stats, showUpsell }: StatsInnerProps) {
    const [ metric, setMetric ] = useState<OrdersStatsMetric>(OrdersStatsMetric.visits);

    return (
        <div className='relative flex flex-col gap-6'>
            {showUpsell && <StatsUpsell />}
            <div className='grid grid-cols-4 max-lg:grid-cols-2 gap-2 w-full'>
                <ChartSwitch number={stats.visits.length} type={OrdersStatsMetric.visits} active={metric === OrdersStatsMetric.visits} onClick={() => setMetric(OrdersStatsMetric.visits)} />
                <ChartSwitch number={stats.leads.length} type={OrdersStatsMetric.leads} active={metric === OrdersStatsMetric.leads} onClick={() => setMetric(OrdersStatsMetric.leads)} />
                <ChartSwitch number={stats.ordersCount} type={OrdersStatsMetric.ordersCount} active={metric === OrdersStatsMetric.ordersCount} onClick={() => setMetric(OrdersStatsMetric.ordersCount)} />
                <ChartSwitch number={<MoneyDisplay money={stats.ordersValue} noColor />} type={OrdersStatsMetric.ordersValue} active={metric === OrdersStatsMetric.ordersValue} onClick={() => setMetric(OrdersStatsMetric.ordersValue)} />
            </div>

            <ChartDisplay stats={stats} type={metric} />
        </div>
    );
}

type ChartSwitchProps = React.PropsWithChildren<{
    number: React.ReactNode;
    type: OrdersStatsMetric;
    active: boolean;
    onClick: () => void;
}>;

function ChartSwitch({ number, type, active, onClick, children }: ChartSwitchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay.metrics' });
    const { t: tt } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay.metricsTooltips' });

    const className = cn(
        'flex flex-row p-8 rounded-xl border max-lg:bg-white max-lg:px-0',
        active && 'group active bg-primary-50 border-primary-50 max-lg:border-primary',
        !active && 'border-secondary-100 bg-[#fbfafd] max-lg:border-white',
    );
    return (
        <button className={className} onClick={onClick}>
            <div className='flex flex-col flex-grow gap-2 items-start max-lg:items-center'>
                <span className='group-[.active]:text-primary text-2lg font-semibold max-lg:text-secondary-700'>{number}</span>
                <span className='group-[.active]:text-primary group-[.active]:opacity-100 text-secondary/75 flex gap-1 items-center'>
                    {t(type)}
                    <InfoTooltip text={tt(type)} side='bottom' />
                </span>
            </div>
            <div>{children}</div>
        </button>
    );
}

function ChartDisplay({ stats, type }: StatsInnerProps & { type: OrdersStatsMetric }) {
    const data = useMemo(() => {
        const { data } = getChartData(stats, type);

        return {
            // labels,
            datasets: [ {
                data,
                borderColor: tailwindConfig.theme.colors.primary.DEFAULT,
                fill: 'start',
                backgroundColor: context => {
                    const { ctx, chartArea } = context.chart;
                    if (!chartArea)
                        return;  // this happens on initial load

                    const height = chartArea.bottom - chartArea.top;
                    // TODO don't create the gradient object every time?
                    // see https://www.chartjs.org/docs/latest/samples/advanced/linear-gradient.html
                    const gradient = ctx.createLinearGradient(0, 0, 0, height);
                    gradient.addColorStop(0, 'rgba(128, 73, 236, 0.2)');
                    gradient.addColorStop(1, 'rgba(128, 73, 236, 0.1)');
                    return gradient;
                },
                pointStyle: false,
            } ],
        } as ChartData<'line', { x: string, y: number }[]>;
    }, [ stats, type ]);

    const options: ChartOptions<'line'> = {
        responsive: true,  // probably not needed for production
        scales: {
            x: {
                // or 'timeseries'?
                type: 'time',
                time: {
                    unit: stats.rangeType === OrderStatsRange.last12Months
                        ? 'month'
                        : stats.rangeType === OrderStatsRange.last7Days || stats.rangeType === OrderStatsRange.last30Days
                            ? 'day'
                            : 'hour',
                },
            },
            y: {
                min: 0,
                ticks: {
                    stepSize: 1,
                    maxTicksLimit: 6,
                },
            },
        },
    };

    return (
        <div className='max-lg:p-4 max-lg:rounded-lg bg-white'>
            <Line data={data} options={options} />
        </div>
    );
}

function getChartData(stats: OrdersStatsFE, type: OrdersStatsMetric) {
    if (stats.rangeType === OrderStatsRange.custom)
        throw new Error('Custom range not implemented');

    const groupedMetrics = new Map<string, number>();
    let dateFormat: string;

    // date formats compatible with Luxon + Chart.js:
    // https://github.com/chartjs/chartjs-adapter-luxon/blob/master/src/index.js#L4
    if (stats.rangeType === OrderStatsRange.last12Months) {
        dateFormat = 'yyyy-MM';  // Group by month
        let currentDate = stats.range.from;
        while (currentDate <= stats.range.to) {
            const key = currentDate.toFormat(dateFormat);
            groupedMetrics.set(key, 0);
            currentDate = currentDate.plus({ months: 1 });
        }
    }
    else if (stats.rangeType === OrderStatsRange.today) {
        dateFormat = 'HH'; // Group by hour
        let currentDate = stats.range.from;
        while (currentDate <= stats.range.to) {
            const key = currentDate.toFormat(dateFormat);
            groupedMetrics.set(key, 0);
            currentDate = currentDate.plus({ hours: 1 });
        }
    }
    else {
        dateFormat = 'yyyy-MM-dd';  // Group by day
        let currentDate = stats.range.from;
        while (currentDate <= stats.range.to) {
            const key = currentDate.toFormat(dateFormat);

            groupedMetrics.set(key, 0);
            currentDate = currentDate.plus({ days: 1 });
        }
    }

    switch (type) {
    case OrdersStatsMetric.visits:
        for (const visit of stats.visits) {
            const key = visit.createdAt.toFormat(dateFormat);
            groupedMetrics.set(key, (groupedMetrics.get(key) ?? 0) + 1);
        }
        break;
    case OrdersStatsMetric.leads:
        for (const lead of stats.leads) {
            const key = lead.createdAt.toFormat(dateFormat);
            groupedMetrics.set(key, (groupedMetrics.get(key) ?? 0) + 1);
        }
        break;
    case OrdersStatsMetric.ordersCount:
        for (const order of stats.orders) {
            const key = order.issueDate.toFormat(dateFormat);
            groupedMetrics.set(key, (groupedMetrics.get(key) ?? 0) + 1);
        }
        break;
    case OrdersStatsMetric.ordersValue:
        for (const order of stats.orders) {
            const key = order.issueDate.toFormat(dateFormat);
            groupedMetrics.set(key, (groupedMetrics.get(key) ?? 0) + priceFromServer(order.total));
        }
        break;
    }

    // Safari doesn't support Iterator.map() yet, the .map() must be on the array, not on the entries()
    const datapoints = [ ...groupedMetrics.entries() ].map(([ key, value ]) => ({ x: key, y: value }));
    return {
        data: datapoints,
        labels: datapoints.map(point => point.x),
    };
}

const DOUGHNUT_OPTIONS: ChartOptions<'doughnut'> = {
    plugins: {
        legend: { display: false }, // Hide legend.
    },
    events: [], // Disable all hover events (this also hides tooltips).
};

const PAID_COLOR = tailwindConfig.theme.colors.primary.DEFAULT;
const PENDING_COLOR = tailwindConfig.theme.colors.secondary[100];
const STORE_COLOR = '#b200cf';
const APP_COLOR = tailwindConfig.theme.colors.secondary[100];

// backlogged as of Dec 20, 2024
function OrdersValueStatsDisplay({ stats }: StatsInnerProps) {
    const notcancelledOrders = useMemo(() => stats.orders.filter(order => order.state !== OrderState.canceled), [ stats ]);

    const paidAmount = useMemo(() => notcancelledOrders.filter(order => order.state === OrderState.fulfilled).reduce((sum, order) => sum + order.total, 0), [ notcancelledOrders ]);
    const pendingAmount = useMemo(() => notcancelledOrders.filter(order => order.state === OrderState.new || order.state === OrderState.overdue).reduce((sum, order) => sum + order.total, 0), [ notcancelledOrders ]);

    const orderStateData = useMemo<ChartData<'doughnut', number[]>>(() => {
        const values = {
            data: [ paidAmount, pendingAmount ],
            backgroundColor: [
                PAID_COLOR,
                PENDING_COLOR,
            ],
        };

        return {
            datasets: [ {
                ...values,
                cutout: '75%',
                borderRadius: 99999,
            } ],
        };
    }, [ paidAmount, pendingAmount ]);

    const storeAmount = useMemo(() => notcancelledOrders.filter(order => order.createdFrom === OrderCreatedFrom.Store).reduce((sum, order) => sum + order.total, 0), [ notcancelledOrders ]);
    const appAmount = useMemo(() => notcancelledOrders.filter(order => order.createdFrom === OrderCreatedFrom.App).reduce((sum, order) => sum + order.total, 0), [ notcancelledOrders ]);

    const createdFromData = useMemo<ChartData<'doughnut', number[]>>(() => {
        const values = {
            data: [ storeAmount + 10000, appAmount ],
            backgroundColor: [
                STORE_COLOR,
                APP_COLOR,
            ],
        };

        return {
            datasets: [ {
                ...values,
                cutout: '75%',
                borderRadius: 99999,
            } ],
        };
    }, [ storeAmount, appAmount ]);

    return (
        <div className='flex h-20 gap-6'>
            <div className='flex flex-1 items-center gap-7'>
                <Doughnut data={orderStateData} options={DOUGHNUT_OPTIONS} />
                <div className='flex gap-3 w-[130px]'>
                    <DotIcon color={PAID_COLOR} />
                    <div className='flex flex-col gap-1'>
                        <MoneyDisplay className='text-lg' money={moneyFromServer(paidAmount, stats.currency)} />
                        <span className='text-secondary-400'>Paid</span>
                    </div>
                </div>
                <div className='flex gap-3'>
                    <DotIcon color={PENDING_COLOR} />
                    <div className='flex flex-col gap-1'>
                        <MoneyDisplay className='text-lg' money={moneyFromServer(pendingAmount, stats.currency)} />
                        <span className='text-secondary-400'>Pending</span>
                    </div>
                </div>
            </div>
            <div className='flex flex-1 items-center gap-7'>
                <Doughnut data={createdFromData} options={DOUGHNUT_OPTIONS} />
                <div className='flex gap-3 w-[130px]'>
                    <DotIcon color={STORE_COLOR} />
                    <div className='flex flex-col gap-1'>
                        <MoneyDisplay className='text-lg' money={moneyFromServer(storeAmount, stats.currency)} />
                        <span className='text-secondary-400'>Store sales</span>
                    </div>
                </div>
                <div className='flex gap-3'>
                    <DotIcon color={APP_COLOR} />
                    <div className='flex flex-col gap-1'>
                        <MoneyDisplay className='text-lg' money={moneyFromServer(appAmount, stats.currency)} />
                        <span className='text-secondary-400'>Direct sales</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function DotIcon({ color }: Readonly<{ color: React.CSSProperties['color'] }>) {
    return (
        <div className='w-2 h-4 justify-start items-center gap-2.5 inline-flex'>
            <div className='w-2 h-2 rounded-full' style={{ backgroundColor: color }} />
        </div>
    );
}

function StatsProducts({ stats, showUpsell }: StatsInnerProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay.productsTable' });

    const [ type, setType ] = useState(ProductSwitchType.mostProfitable);
    const sortedProducts = useMemo(() => sortProductsBySwitchType(stats.products, type), [ stats, type ]);

    return (
        <div className='space-y-6'>
            <div className='fl-hide-scrollbar overflow-x-auto -mx-4 lg:-mx-6'>
                <div className='flex flex-row mx-auto w-max gap-2.5 px-6'>
                    {getEnumValues(ProductSwitchType).map(switchType => (
                        <ProductSwitch key={switchType} type={switchType} isActive={type === switchType} onClick={() => setType(switchType)} />
                    ))}
                </div>
            </div>

            <div className='fl-hide-scrollbar overflow-x-auto -mx-4 lg:-mx-6'>
                <div className='relative min-w-[600px] px-6'>
                    {showUpsell && <StatsUpsell />}

                    <div className='flex flex-row w-full pb-5 px-6 opacity-70'>
                        <div className='w-2/6'>{t('product')}</div>
                        <div className='w-1/6 text-right'>{t('leads')}</div>
                        <div className='w-1/6 text-right'>{t('buys')}</div>
                        <div className='w-1/6 text-right'>{t('conversion')}</div>
                        <div className='w-1/6 text-right'>{t('revenue')}</div>
                    </div>

                    <div className='flex flex-col w-full rounded-2xl border bg-white'>
                        {sortedProducts.map(product => (
                            <div className='flex flex-row items-center w-full border-b py-5 px-6 last:border-b-0' key={product.id}>
                                <div className='w-2/6 flex gap-3 items-center'>
                                    {productStyles[product.type].icon({ size: 'md', className: 'shrink-0' })}
                                    <span className={clsx(productStyles[product.type].color, 'text-lg/5 truncate')}>
                                        {product.title}
                                    </span>
                                </div>
                                <div className='w-1/6 text-right'>{product.leads}</div>
                                <div className='w-1/6 text-right'>{product.buys}</div>
                                <div className='w-1/6 text-right'>{getConversion(product.buys, product.leads)}</div>
                                <div className='w-1/6 text-right'><MoneyDisplay money={moneyFromServer(product.revenue, stats.currency)} /></div>
                            </div>
                        ))}

                        {!sortedProducts.length && (
                            <div className='text-center py-4 text-lg/6 text-secondary-400'>{t('no-products-text')}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

function getConversion(buys: number, leads: number) {
    if (leads === 0 && buys === 0)
        return '\u2014';  // m-dash
    if (leads === 0 && buys !== 0)
        return '\u221e';  // infinity
    const rate = roundToFixedDecimal((buys / leads) * 100, 2);
    return `${rate} %`;
}

export function sortProductsBySwitchType<TProduct extends { revenue: number, buys: number }>(products: TProduct[], type: ProductSwitchType): TProduct[] {
    switch (type) {
    case ProductSwitchType.mostProfitable:
        return products.sort((a, b) => b.revenue - a.revenue);
    case ProductSwitchType.mostSelling:
        return products.sort((a, b) => b.buys - a.buys);
    case ProductSwitchType.leastProfitable:
        return products.sort((a, b) => a.revenue - b.revenue);
    case ProductSwitchType.leastSelling:
        return products.sort((a, b) => a.buys - b.buys);
    }
}

export enum ProductSwitchType {
    mostProfitable = 'mostProfitable',
    mostSelling = 'mostSelling',
    leastProfitable = 'leastProfitable',
    leastSelling = 'leastSelling',
}

export const productSwitchIcons = {
    [ProductSwitchType.mostProfitable]: <TrophyIcon size={12} />,
    [ProductSwitchType.mostSelling]: <BoxHeartIcon size={12} />,
    [ProductSwitchType.leastProfitable]: <CoinsIcon size={12} />,
    [ProductSwitchType.leastSelling]: <BoxMinusIcon size={12} />,
} as const;

type ProductSwitchProps = {
    type: ProductSwitchType;
    isActive: boolean;
    onClick: () => void;
};

function ProductSwitch({ type, isActive, onClick }: ProductSwitchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay.productSwitches' });

    const className = cn(
        'shrink-0 h-10 px-4 flex flex-row items-center gap-1 rounded-full max-lg:px-4b max-lg:border max-lg:bg-white',
        isActive ? 'lg:text-[#b36500] lg:bg-[#fff0d8] max-lg:border-primary' : 'text-secondary bg-secondary-50 max-lg:border-white',
    );

    // TODO traingle at the bottom

    return (
        <button className={className} onClick={onClick} disabled={isActive}>
            <span className='flex items-center gap-1'>{productSwitchIcons[type]} {t(type)}</span>
        </button>
    );
}

type StatsHeaderProps = Readonly<{
    range: OrderStatsInput;
    setRange: Dispatch<SetStateAction<OrderStatsInput>>;
}>;

function StatsHeader({ range, setRange }: StatsHeaderProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay' });
    const { t: tr } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay.range' });

    return (
        <div className='flex max-md:flex-col max-md:items-start items-center justify-between gap-4 mb-6 max-lg:mb-4'>
            <h1 className='flex gap-2 items-center font-semibold text-2xl max-lg:text-secondary-700'>
                <ThumbsUpIcon size={22} />
                {t('title')}
            </h1>

            <div className='w-full max-w-36'>
                <StringSelect
                    value={range.range}
                    onChange={value => value && value in OrderStatsRange && value !== OrderStatsRange.custom && setRange({ range: value } as OrderStatsInput)}
                    options={getEnumValues(OrderStatsRange).filter(value => value !== OrderStatsRange.custom)}
                    t={tr}
                    immutableProps={{
                        size: 'compact',
                        variant: 'outline-transparent',
                    }}
                    styles={{
                        menu(base) {
                            return {
                                ...base,
                                zIndex: 100,
                            };
                        },
                    }}
                />
            </div>
        </div>
    );
}

export function StatsUpsell() {
    const { t } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay' });

    return (
        <div className='absolute -inset-4 flex justify-center items-center bg-primary-200/10 backdrop-blur-md rounded-4xl z-50'>
            <Card className='flex flex-col items-center p-4 border-black/20 rounded-2xl w-[265px]'>
                <ChartComboIcon size={24} className='text-primary mb-3' />
                <span className='leading-[18px] text-center mb-4'>{t('upgrade-text')}</span>
                <UpsellButton text={t('upgrade-button')} className='w-full' />
            </Card>
        </div>
    );
}

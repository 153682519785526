import { ProductType } from ':utils/entity/product';
import { productStyles } from ':components/store/product/ProductCard';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from ':components/shadcn';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { ProductComingSoonBadge } from ':frontend/components/product/productForms';
import { cn } from ':components/shadcn/utils';
import { ArrowRightIcon, Box2PlusIcon } from ':components/icons/basic';
import { useToggle } from ':frontend/hooks';

type CreateProductCardProps = Readonly<{
    className?: string;
}>;

export function CreateProductCard({ className }: CreateProductCardProps) {
    const { t } = useTranslation('components', { keyPrefix: 'createProductCard' });

    const [ showNewProductModal, setShowNewProductModal ] = useToggle(false);

    return (
        <div className={cn('max-w-full w-[600px] bg-white p-6 rounded-2xl border-2 border-primary max-md:space-y-4 md:flex md:justify-between md:items-end', className)}>
            <div className='flex flex-col gap-3'>
                <Box2PlusIcon size={22} className='text-primary' />
                <span className='text-primary-800'>{t('card-title')}</span>
                <div className='flex gap-2 ml-2'>
                    {predefinedProducts.map(type => {
                        const Icon = productStyles[type].icon;
                        return (
                            <Icon key={type} size='sm' />
                        );
                    })}
                </div>
            </div>
            <Button variant='primary' onClick={setShowNewProductModal.true} className='w-full md:w-auto'>{t('create-button')}<ArrowRightIcon /></Button>
            <NewProductModal open={showNewProductModal} onClose={setShowNewProductModal.false} />
        </div>
    );
}

type NewProductModalProps = Readonly<{
    open: boolean;
    onClose: () => void;
}>;

export function NewProductModal({ open, onClose }: NewProductModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'createProductCard.typeModal' });

    return (
        <Modal.Root open={open} onOpenChange={open => !open && onClose()}>
            <Modal.Content className='max-w-fit gap-12 px-4 py-8 md:p-12' closeButton={t('cancel-button')}>
                <Modal.Header>
                    <Modal.Title className='text-4xl leading-10 text-center'>{t('title')}</Modal.Title>
                    <Modal.Description className='mt-2 text-md leading-5 text-center'>{t('description')}</Modal.Description>
                </Modal.Header>
                <ProductTypeSignpost />
            </Modal.Content>
        </Modal.Root>
    );
}

const comingSoonProducts: ProductType[] = [ ProductType.Membership ];

function ProductTypeSignpost() {
    return (
        <div className='max-w-[538px] w-full'>
            <div className='grid sm:grid-cols-2 gap-12'>
                {predefinedProducts.map(type => comingSoonProducts.includes(type) ? (
                    <ProductTypeTile key={type} type={type} />
                ) : (
                    <Link key={type} to={routesFE.products.new.resolve({ type })}>
                        <ProductTypeTile key={type} type={type} />
                    </Link>
                ))}
            </div>
            <div className='h-px w-full bg-secondary-100 my-8' />
            <div className='w-fit mx-auto'>
                <Link to={routesFE.products.new.resolve({ type: ProductType.Custom })}>
                    <ProductTypeTile type={ProductType.Custom} isCenter />
                </Link>
            </div>
        </div>
    );
}

const predefinedProducts: ProductType[] = [
    ProductType.Session,
    ProductType.Digital,
    ProductType.Bundle,
    ProductType.Lead,
    ProductType.Membership,
    ProductType.Link,
];

type ProductTypeTileProps = Readonly<{
    type: ProductType;
    isCenter?: boolean;
    onClick?: () => void;
}>;

function ProductTypeTile({ type, isCenter, onClick }: ProductTypeTileProps) {
    const { t } = useTranslation('components', { keyPrefix: `productTypeTile` });
    const { icon, color } = productStyles[type];
    const isComingSoon = comingSoonProducts.includes(type);

    return (
        <div className={clsx('group max-w-64 flex flex-col gap-3 items cursor-pointer', isComingSoon && 'pointer-events-none', isCenter && 'items-center')} onClick={onClick}>
            <div className='flex items-center gap-2 text-lg'>
                {icon({ size: 22 })}
                <span className={color}>{t(`${type}.title`)}</span>
            </div>
            <div className={clsx('text-secondary-400 group-hover:text-secondary-600', isCenter && 'text-center')}>{t(`${type}.description`)}</div>
            {isComingSoon && (
                <ProductComingSoonBadge type={type} />
            )}
        </div>
    );
}

/* eslint-disable react/prop-types */

import { Skeleton } from ':components/shadcn';
import { cn } from ':components/shadcn/utils';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

type TableProps = React.HTMLAttributes<HTMLTableElement> & {
    px?: 2 | 3 | 5;
    py?: 2 | 3 | 5;
    noLines?: boolean;
    noOuterLines?: boolean;
};

export function Table({ py, px, noLines, noOuterLines, className, children, ...rest }: TableProps) {
    return (
        <table className={cn(`
            w-full rounded-lg
            border-separate border border-solid border-spacing-0 border-secondary-200
            [&_tr_th]:border-b [&_tr_th]:border-b-secondary-200 [&_tr:not(:last-child)_td]:border-b [&_tr:not(:last-child)_td]:border-b-secondary-200
            [&_th]:p-4 [&_td]:p-4`, {
            '[&_th]:px-2 [&_td]:px-2': px === 2,
            '[&_th]:px-3 [&_td]:px-3': px === 3,
            '[&_th]:px-5 [&_td]:px-5': px === 5,
            '[&_th]:py-2 [&_td]:py-2': py === 2,
            '[&_th]:py-3 [&_td]:py-3': py === 3,
            '[&_th]:py-5 [&_td]:py-5': py === 5,
            'border-0 [&_tr:not(:last-child)_td]:border-b-0': noLines,
            'border-0': noOuterLines,
        }, className)} {...rest}>
            {children}
        </table>
    );
}

type HeaderProps = Readonly<React.HTMLAttributes<HTMLTableRowElement>>;

function Header({ children, ...rest }: HeaderProps) {
    return (
        <thead>
            <tr {...rest}>
                {children}
            </tr>
        </thead>
    );
}

type BodyProps = Readonly<React.HTMLAttributes<HTMLTableSectionElement>>;

function Body({ children, ...rest }: BodyProps) {
    return (
        <tbody {...rest}>
            {children}
        </tbody>
    );
}

type RowProps = Readonly<React.HTMLAttributes<HTMLTableRowElement>>;

function Row({ children, ...rest }: RowProps) {
    return (
        <tr {...rest}>
            {children}
        </tr>
    );
}

type ColWidth = 'fit' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 1.5;

// The widths have to be xplicitly defined because of the way Tailwind CSS works.
const widths: Record<ColWidth, string> = {
    fit: 'w-0',
    1: 'w-1/12',
    2: 'w-2/12',
    3: 'w-3/12',
    4: 'w-4/12',
    5: 'w-5/12',
    6: 'w-6/12',
    7: 'w-7/12',
    8: 'w-8/12',
    9: 'w-9/12',
    10: 'w-10/12',
    11: 'w-11/12',
    12: 'w-full',
    1.5: 'w-1/8',
};

type ColProps = React.DetailedHTMLProps<React.HTMLProps<HTMLTableCellElement>, HTMLTableCellElement> & {
    xs?: ColWidth;
    truncate?: boolean;
    link?: string;
};

function HeaderCol({ xs, truncate, link, className, children, ...rest }: ColProps) {
    const navigate = useNavigate();

    return (
        <th {...rest}
            className={clsx(`text-secondary-400 font-medium text-left`, xs && widths[xs], {
                'truncate': truncate,
                'cursor-pointer': link,
            }, className)}
            onClick={link ? () => {
                navigate(link);
            } : undefined}
        >
            {children}
        </th>
    );
}

function Col({ xs, truncate, link, className, children, ...rest }: ColProps) {
    const navigate = useNavigate();

    return (
        <td
            className={clsx(xs && widths[xs], {
                'truncate': truncate,
                'cursor-pointer': link,
            }, className)}
            onClick={link ? () => {
                navigate(link);
            } : undefined}
            {...rest}
        >
            {children}
        </td>
    );
}

type SkeletonProps = Readonly<{
    height: number;
}>;

function HeaderSkeleton({ height }: SkeletonProps) {
    return (
        <Table.Header>
            <Table.HeaderCol xs={2}><Skeleton height={height} /></Table.HeaderCol>
            <Table.HeaderCol xs={5}><Skeleton height={height} /></Table.HeaderCol>
            <Table.HeaderCol xs={2}><Skeleton height={height} /></Table.HeaderCol>
            <Table.HeaderCol xs={3}><Skeleton height={height} /></Table.HeaderCol>
        </Table.Header>
    );
}

function RowSkeleton({ height }: SkeletonProps) {
    return (
        <Table.Row>
            <Table.Col xs={2}><Skeleton height={height} /></Table.Col>
            <Table.Col xs={5}><Skeleton height={height} /></Table.Col>
            <Table.Col xs={2}><Skeleton height={height} /></Table.Col>
            <Table.Col xs={3}><Skeleton height={height} /></Table.Col>
        </Table.Row>
    );
}

Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.HeaderCol = HeaderCol;
Table.Col = Col;
Table.HeaderSkeleton = HeaderSkeleton;
Table.RowSkeleton = RowSkeleton;

import { DateTime } from 'luxon';
import { getDefaultCurrency, type Money, moneyFromServer } from ':utils/money';
import { OrderCreatedFrom, OrderStatsRange, type OrderStatsOrder, type OrderStatsOutput, type OrderStatsProduct } from ':utils/entity/order';
import { capitalize, getEnumValues, type DateRange } from ':utils/common';
import type { CurrencyId } from ':utils/id';
import type { VisitOutput } from ':utils/entity/store';
import { OrderState } from ':utils/entity/invoicing';
import { ProductType } from ':utils/entity/product';

export class OrdersStatsFE {
    ordersValue!: Money;
    ordersCount!: number;

    private constructor(
        readonly visits: VisitOutput[],
        readonly leads: VisitOutput[],
        readonly orders: OrderStatsOrder[],
        readonly products: OrderStatsProduct[],
        /** Inclusive. */
        readonly range: DateRange,
        readonly rangeType: OrderStatsRange,
        readonly currency: CurrencyId,
    ) {
        const ordersValue = this.orders.reduce((total, order) => total + order.total, 0);
        this.ordersValue = moneyFromServer(ordersValue, currency);

        this.ordersCount = this.orders.length;
    }

    static fromServer(input: OrderStatsOutput, rangeType: OrderStatsRange): OrdersStatsFE {
        return new OrdersStatsFE(input.visits, input.leads, input.orders, input.products, input.range, rangeType, input.currency);
    }

    static createExample(rangeType: OrderStatsRange): OrdersStatsFE {
        const visits = rangeType === OrderStatsRange.last12Months ? createExampleYearData() : createExampleMonthData();
        const leadCount = Math.floor(visits.length / 2);
        const orderCount = Math.floor(visits.length / 4);
        const ordersValue = Math.floor(visits.length * 12 * 100);
        const orders = Array(orderCount) as OrderStatsOrder[];
        orders[0] = { issueDate: visits[0].createdAt, total: ordersValue, state: OrderState.new, createdFrom: OrderCreatedFrom.App };
        const products = createExampleProductData();
        return new OrdersStatsFE(
            visits,
            Array(leadCount) as VisitOutput[],
            orders,
            products,
            { from: visits[0].createdAt, to: visits[visits.length - 1].createdAt },
            rangeType,
            getDefaultCurrency().id,
        );
    }
}

function createExampleMonthData(): VisitOutput[] {
    const stats = [ 22, 27, 32, 35, 43, 49, 52, 59, 50, 67, 73, 78, 83, 89, 84, 98, 103, 109, 112, 118, 101, 110, 132, 139, 141, 148, 151, 155, 160, 168, 170 ];
    const start = DateTime.now().minus({ days: stats.length });
    const output: VisitOutput[] = [];

    for (let i = 0; i < stats.length; i++) {
        const count = stats[i];
        const date = start.plus({ days: i });
        for (let j = 0; j < count; j++)
            output.push({ createdAt: date });
    }

    return output;
}

function createExampleYearData(): VisitOutput[] {
    const stats = [ 22, 27, 43, 52, 61, 67, 78, 74, 70, 80, 90, 100, 120 ];
    const start = DateTime.now().minus({ months: stats.length });
    const output: VisitOutput[] = [];

    for (let i = 0; i < stats.length; i++) {
        const count = stats[i];
        const date = start.plus({ months: i });
        for (let j = 0; j < count; j++)
            output.push({ createdAt: date });
    }

    return output;
}

function createExampleProductData(): OrderStatsProduct[] {
    const output: OrderStatsProduct[] = [];

    for (const productType of getEnumValues(ProductType)) {
        output.push({
            id: 'example' + productType,
            type: productType,
            title: capitalize(productType),
            leads: Math.floor(Math.random() * 1000),
            buys: Math.floor(Math.random() * 1000),
            revenue: Math.floor(Math.random() * 1000 * 100),
        });
    }

    return output;
}

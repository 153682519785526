import { useToggle } from ':frontend/hooks';
import { useCallback, useState, type ChangeEvent } from 'react';
import { Button, Form, DropdownMenu } from ':components/shadcn';
import { HexColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import { cn } from ':components/shadcn/utils';
import { isColorTooLight } from ':utils/common';

type ColorPickerProps = Readonly<{
    /** Hex code (at most 6 characters). Might be invalid (i.e., shorter), because the user is still typing. */
    color: string;
    onChange: (color: string) => void;
}>;

export function ColorPicker({ color, onChange }: ColorPickerProps) {
    const pickerColor = toValidHex(color);

    const innerOnChange = useCallback((input: string) => {
        onChange(input.replace('#', ''));
    }, [ onChange ]);

    const onTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const hex = toHex(e.target.value);
        onChange(hex);
    }, [ onChange ]);

    return (
        <div className='p-4 w-fit bg-white rounded-lg' >
            <div className='w-[200px]'>
                <HexColorPicker
                    color={`#${pickerColor}`}
                    onChange={innerOnChange}
                />
                <Form.Input
                    value={color}
                    onChange={onTextChange}
                    size='compact'
                    className='mt-4'
                />
            </div>
        </div>
    );
}

function toHex(input: string): string {
    return input.replace(/[^0-9a-fA-F]/g, '').slice(0, 6);
}

/**
 * Returns the true color that will be used if the user decides to save the input.
 * We expect the input to be a valid hex, but we need to make sure it's 6 characters long.
 */
function toValidHex(rawHex: string): string {
    const hex = rawHex.toLowerCase();
    if (hex.length > 3)
        return hex.padEnd(6, '0');

    // If the hex is 3 characters long, it's the same as 6 characters where all characters are doubled.
    const pad3 = hex.padEnd(3, '0');
    return pad3[0] + pad3[0] + pad3[1] + pad3[1] + pad3[2] + pad3[2];
}

type ColorPickerModalProps = Readonly<{
    /** Hex code (6 characters). */
    color: string;
    onSave: (color: string) => void;
    className?: string;
}>;

export function ColorPickerDropdown({ color, onSave, className }: ColorPickerModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'colorPicker' });
    const [ show, setShow ] = useToggle(false);
    const [ innerColor, setInnerColor ] = useState(color);
    const validColor = toValidHex(innerColor);

    function open() {
        setInnerColor(color);
        setShow.true();
    }

    function save() {
        onSave(validColor);
        setShow.false();
    }

    return (
        <DropdownMenu.Root open={show} onOpenChange={newOpen => newOpen ? open() : save()}>
            <DropdownMenu.Trigger asChild>
                <button
                    className={cn(`
                        size-8 rounded
                        m-[2px] outline outline-2 outline-transparent hover:outline-primary-400 active:outline-primary
                    `,
                    show && 'outline-primary',
                    isColorTooLight(validColor) && 'border hover:border-none',
                    className,
                    )}
                    style={{ backgroundColor: `#${color}` }}
                >
                    <span className='sr-only'>{t('open-picker-button')}</span>
                </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className='p-0'>
                <ColorPicker
                    color={innerColor}
                    onChange={setInnerColor}
                />
                <div className='pb-4 px-4 flex justify-between'>
                    <Button variant='outline' size='tiny' onClick={setShow.false}>
                        {t('cancel-button')}
                    </Button>
                    <Button size='tiny' disabled={validColor === color} onClick={save}>
                        {t('save-button')}
                    </Button>
                </div>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

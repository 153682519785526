import { NavigateDirection } from ':frontend/lib/calendar/utils/common';
import { type DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { CalendarPlus2Icon, ChevronLeftIcon, ChevronRightIcon, SquareGrid1Icon, SquareGrid2Icon, SquareGrid3Icon } from ':components/icons/basic';
import { Views, type View } from ':frontend/lib/calendar/Views';
import { getNow } from ':frontend/lib/calendar/localizer';
import { ViewObjects } from ':frontend/lib/calendar/Calendar';
import { Button, Form } from ':components/shadcn';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { forwardRef, useId, type ElementRef } from 'react';
import { CalendarsFilter, type CalendarsFilterProps } from './CalendarsFilter';

type CalendarToolbarProps = Readonly<{
    date: DateTime;
    view: View;
    onView: (view: View) => void;
    onNavigate: (date: DateTime, view: View) => void;
    onNewEvent: () => void;
    calendarsProps: CalendarsFilterProps;
}>;

export function CalendarToolbar({ date, view, onView, onNavigate, onNewEvent, calendarsProps }: CalendarToolbarProps) {
    const { t } = useTranslation('components', { keyPrefix: 'calendar' });

    function navigate(direction: NavigateDirection) {
        const finalDate = direction === NavigateDirection.Today
            ? getNow()
            : ViewObjects[view].navigateTo(date, direction);

        onNavigate?.(finalDate, view);
    }

    return (
        <div className='h-[61px] border-b border-secondary-100'>
            <div className='fl-hide-scrollbar overflow-x-auto bg-white'>
                <div className='h-[60px] flex items-center justify-end lg:gap-4 w-fit lg:w-full'>
                    <div className='flex items-center justify-center gap-1'>
                        <Button variant='transparent' size='exact' className='size-9 [&_svg]:size-3' aria-label={t(`prev-${view}-button`)} onClick={() => navigate(NavigateDirection.Prev)}>
                            <ChevronLeftIcon />
                        </Button>

                        <Button variant='outline' size='small' onClick={() => navigate(NavigateDirection.Today)}>
                            {t('today')}
                        </Button>

                        <Button variant='transparent' size='exact' className='size-9 [&_svg]:size-3' aria-label={t(`next-${view}-button`)} onClick={() => navigate(NavigateDirection.Next)}>
                            <ChevronRightIcon />
                        </Button>
                    </div>


                    <div className='flex items-center justify-end gap-2 pr-4'>
                        <ViewSelect view={view} onView={onView} />

                        <CalendarsFilter {...calendarsProps} />

                        <Button variant='primary' size='small' onClick={onNewEvent}>
                            <CalendarPlus2Icon />{t('new-event-button')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}


const navigableViews = [ Views.WEEK, Views.AGENDA ] as const;
const viewIcons = {
    [Views.WEEK]: SquareGrid1Icon,
    [Views.MONTH]: SquareGrid2Icon,
    [Views.AGENDA]: SquareGrid3Icon,
} as const;

type ViewSelectProps = Readonly<{
    view: View;
    onView: (view: View) => void;
}>;

function ViewSelect({ view, onView }: ViewSelectProps) {
    return (
        <RadioGroup.Root
            value={view}
            onValueChange={onView}
            className='border rounded-full flex items-center p-px gap-px'
        >
            {navigableViews.map(name => (
                <ViewRadioItem key={name} view={name} />
            ))}
        </RadioGroup.Root>
    );
}

type ViewRadioItemProps = Readonly<{
    view: View;
}>;

const ViewRadioItem = forwardRef<
    ElementRef<typeof RadioGroup.Item>, ViewRadioItemProps
>(({ view,...props }, ref) => {
    const { t } = useTranslation('components', { keyPrefix: 'calendar' });
    const id = useId();
    const icon = viewIcons[view];

    return (
        <div>
            <RadioGroup.Item
                ref={ref}
                id={id}
                value={view}
                className={`
                    h-8 w-8 rounded-full flex items-center justify-center
                    hover:bg-primary-50 active:bg-primary-100 data-[state=checked]:bg-primary-50 data-[state=checked]:border data-[state=checked]:border-secondary-100 data-[state=checked]:text-primary
                    focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-300 focus-visible:ring-offset-2
                `}
                {...props}
            >
                {icon({ size: 'sm' })}
            </RadioGroup.Item>
            <Form.Label htmlFor={id} className='sr-only'>{t(view)}</Form.Label>
        </div>
    );
});

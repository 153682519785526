import { type Id, type CurrencyId } from ':utils/id';
import { floatToPercent, priceFromServer, roundMoney } from ':utils/math';
import type { CurrencyOutput, TaxRateOutput } from ':utils/entity/money';
import type { CountryCode, LocaleCode } from ':utils/i18n';
import { compareStringsAscii } from ':utils/common';

/**
 * Should be singleton (for each currency id).
 */
export class CurrencyFE {
    readonly country: CountryCode;

    private constructor(
        readonly id: CurrencyId,
        readonly label: string,
        readonly minimalAmount: number,
    ) {
        if (!CURRENCY_TO_COUNTRY[id]) {
            console.warn(`Country for currency ${id} not found.`);
            this.country = CURRENCY_TO_COUNTRY.EUR;
        }
        else {
            this.country = CURRENCY_TO_COUNTRY[id];
        }
    }

    displayFull(amount: number, locale: LocaleCode, compact?: boolean): string {
        return Intl.NumberFormat(locale, {
            style: 'currency',
            currency: this.id,
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: compact ? 0 : undefined,
        }).format(amount);
    }

    displaySymbol(locale: LocaleCode): string {
        return Intl.NumberFormat(locale, {
            style: 'currency',
            currency: this.id,
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(0).replace(/\d/g, '').trim();
    }

    isSymbolBefore(locale: LocaleCode): boolean {
        const cached = this.isSymbolBeforeCache.get(locale);
        if (cached !== undefined)
            return cached;

        const computed = this.computeIsSymbolBefore(locale);
        this.isSymbolBeforeCache.set(locale, computed);

        return computed;
    }

    private isSymbolBeforeCache: Map<LocaleCode, boolean> = new Map();

    private computeIsSymbolBefore(locale: LocaleCode): boolean {
        const parts = Intl.NumberFormat(locale, {
            style: 'currency',
            currency: this.id,
            currencyDisplay: 'code',
        }).formatToParts(69);

        return parts[0].type === 'currency';
    }

    static displayAmount(amount: number, locale: LocaleCode, compact?: boolean): string {
        return Intl.NumberFormat(locale, {
            minimumFractionDigits: compact ? 0 : undefined,
        }).format(amount);
    }

    static fromServer(input: CurrencyOutput): CurrencyFE {
        return new CurrencyFE(
            input.id,
            input.id, // TODO - i18n ?
            priceFromServer(input.stripeMinimalCharge),
        );
    }
}

const CURRENCY_TO_COUNTRY: Record<CurrencyId, CountryCode> = {
    EUR: 'EU',
    USD: 'US',
    CZK: 'CZ',
    GBP: 'GB',
    CHF: 'CH',
    SEK: 'SE',
    NZD: 'CK',
    AUD: 'AU',
    CAD: 'CA',
};

export type Money = {
    /** in whole currency (not cents) */
    amount: number;
    currency: CurrencyFE;
};

/**
 * Should be singleton (for each tax rate).
 */
export class TaxRateFE {
    private constructor(
        readonly id: Id,
        readonly label: string,
        readonly value: number,
        readonly isInclusive: boolean,
        readonly isEnabled: boolean,
        readonly country?: CountryCode, // If undefined, the tax rate is supposed to be for all countries
    ) {}

    static fromServer(input: TaxRateOutput): TaxRateFE {
        return new TaxRateFE (
            input.id,
            `${floatToPercent(input.value)} %`,
            input.value,
            input.inclusive,
            input.enabled,
            input.country,
        );
    }

    toKey(): string {
        return this.id;
    }

    // The tax rates are singletons so this is valid
    equals(other: TaxRateFE): boolean {
        return this === other;
    }

    get isZero(): boolean {
        return this.value === 0;
    }
}

export function moneyFromServer(amount: number, currencyId: CurrencyId): Money {
    const currency = getCurrency(currencyId);
    if (!currency)
        throw new Error(`Currency code ${currencyId} not found.`);

    return {
        amount: priceFromServer(amount),
        currency,
    };
}

const ALL_CURRENCIES_MAP: Map<CurrencyId, CurrencyFE> = new Map;

export function getAllCurrencies(): CurrencyFE[] {
    // this is very hacky, but we're planning to make currencies (and tax rates) a static JS object anyway
    return [
        ALL_CURRENCIES_MAP.get('USD')!,
        ALL_CURRENCIES_MAP.get('EUR')!,
        ALL_CURRENCIES_MAP.get('GBP')!,
        ALL_CURRENCIES_MAP.get('CAD')!,
        ALL_CURRENCIES_MAP.get('CHF')!,
        ALL_CURRENCIES_MAP.get('AUD')!,
        ALL_CURRENCIES_MAP.get('NZD')!,
        ALL_CURRENCIES_MAP.get('SEK')!,
        ALL_CURRENCIES_MAP.get('CZK')!,
    ];
}

export function addCurrency(currency: CurrencyFE) {
    if (ALL_CURRENCIES_MAP.has(currency.id))
        return;

    ALL_CURRENCIES_MAP.set(currency.id, currency);
}

export function getCurrency(id: CurrencyId): CurrencyFE {
    const currency = tryGetCurrency(id);
    if (!currency)
        throw new Error(`Currency with id ${id} not found.`);

    return currency;
}

export function tryGetCurrency(id: CurrencyId): CurrencyFE | undefined {
    return ALL_CURRENCIES_MAP.get(id);
}

export function compareCurrencies(a: CurrencyFE, b: CurrencyFE): number {
    return compareStringsAscii(a.id, b.id);
}

export function getDefaultCurrency(): CurrencyFE {
    return getAllCurrencies()[0];
}

export function timesBy(money: Money, multiplier: number): Money {
    return {
        amount: money.amount * multiplier,
        currency: money.currency,
    };
}

export function divideBy(money: Money, divisor: number): Money {
    return {
        amount: money.amount / divisor,
        currency: money.currency,
    };
}

export function isUnderMinimalAmount(money: Money): boolean {
    return money.amount < money.currency.minimalAmount;
}

export { priceFromServer, priceToServer } from ':utils/math';

const ALL_TAX_RATES_MAP: Map<Id, TaxRateFE> = new Map;

export function getAllTaxRatesForCountries(countries: CountryCode[]): TaxRateFE[] {
    const output = [ getDefaultTaxRate() ];
    countries.forEach(country => getAllTaxRatesForOneCountryOnly(country).forEach(taxRate => output.push(taxRate)));

    return output;
}

function getAllTaxRatesForOneCountryOnly(country: CountryCode): TaxRateFE[] {
    return [ ...ALL_TAX_RATES_MAP.values() ].filter(taxRate => taxRate.country === country).sort((a, b) => a.value - b.value);
}

export function addTaxRate(taxRate: TaxRateFE) {
    if (ALL_TAX_RATES_MAP.has(taxRate.id))
        return;

    ALL_TAX_RATES_MAP.set(taxRate.id, taxRate);
}

export function getTaxRate(id: Id): TaxRateFE {
    const taxRate = ALL_TAX_RATES_MAP.get(id);
    if (!taxRate)
        throw new Error(`Tax rate with id ${id} not found.`);

    return taxRate;
}

/**
 * The zero tax rate is supposed to be common for all countries so it is a natural fit for a default tax rate.
 */
let zeroTaxRate: TaxRateFE | undefined = undefined;

export function getDefaultTaxRate(): TaxRateFE {
    if (!zeroTaxRate) {
        const foundTaxRate = [ ...ALL_TAX_RATES_MAP.values() ].find(taxRate => !taxRate.country);
        if (!foundTaxRate)
            throw new Error('Default tax rate not found');

        zeroTaxRate = foundTaxRate;
    }

    return zeroTaxRate;
}

export function addVatAsNumber(amount: number, vat: TaxRateFE): { withoutVat: number, withVat: number, difference: number } {
    if (vat.isInclusive) {
        const withoutVat = roundMoney(amount / (1 + vat.value));
        const withVat = roundMoney(amount);
        const difference = roundMoney(withVat - withoutVat);

        return { withoutVat, withVat, difference };
    }
    else {
        const withoutVat = roundMoney(amount);
        const withVat = roundMoney(amount * (1 + vat.value));
        const difference = roundMoney(withVat - withoutVat);

        return { withoutVat, withVat, difference };
    }
}

export function addVat(base: Money, vat: TaxRateFE): { withoutVat: Money, withVat: Money, difference: Money } {
    const numberResult = addVatAsNumber(base.amount, vat);

    return {
        withoutVat: {
            amount: numberResult.withoutVat,
            currency: base.currency,
        },
        withVat: {
            amount: numberResult.withVat,
            currency: base.currency,
        },
        difference: {
            amount: numberResult.difference,
            currency: base.currency,
        },
    };
}

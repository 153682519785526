'use client';

import { forwardRef, type ButtonHTMLAttributes, type ComponentPropsWithoutRef, type ElementRef } from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { cn } from './utils';

const Root = TabsPrimitive.Root;

const List = forwardRef<
    ElementRef<typeof TabsPrimitive.List>,
    ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.List
        ref={ref}
        className={cn(
            'flex items-center justify-start flex-nowrap gap-2',
            className,
        )}
        {...props}
    />
));
List.displayName = TabsPrimitive.List.displayName;

const Trigger = forwardRef<
    ElementRef<typeof TabsPrimitive.Trigger>,
    ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Trigger
        ref={ref}
        className={cn(
            `px-3 py-2 rounded-md whitespace-nowrap leading-4 flex items-center gap-2 overflow-hidden hover:bg-primary-50
            data-[state=active]:pointer-events-none data-[state=active]:bg-primary-50 data-[state=active]:text-primary
            [&_svg]:shrink-0 shrink-0
            `,
            className,
        )}
        {...props}
    />
));
Trigger.displayName = TabsPrimitive.Trigger.displayName;

/**
 * This component looks exactly like the Trigger component, but it doesn't have any effects on tabs. It's just a normal button that looks like a tab trigger.
 * There are some differences (e.g., the tabs use mousedown or arrow keys, while the button uses click), so it's different from the UX perspective and the styles correspond to that (i.e., different color on active state).
 */
const Button = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(({ className, ...props }, ref) => (
    <button
        ref={ref}
        className={cn(
            `px-3 py-2 rounded-md whitespace-nowrap leading-4 flex items-center gap-2 overflow-hidden hover:bg-primary-50
            active:bg-primary-100 active:text-primary [&_svg]:shrink-0 shrink-0`,
            className,
        )}
        type='button'
        {...props}
    />
));

const Content = forwardRef<
    ElementRef<typeof TabsPrimitive.Content>,
    ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Content
        ref={ref}
        className={cn('focus-visible:outline-none', className)}
        {...props}
    />
));
Content.displayName = TabsPrimitive.Content.displayName;

export const Tabs = {
    Root,
    List,
    Trigger,
    Button,
    Content,
};

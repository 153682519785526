import { type DateTime } from 'luxon';
import { type FilterDefinition, type FilterFunction, type FilterMenuProps, type Filter } from './FilterRow';
import { type OrderInfoFE } from ':frontend/types/orders/Order';
import { useTranslation } from 'react-i18next';
import { DateTimeDisplay } from '../DateTimeDisplay';
import { DatePicker } from ':frontend/components/forms';
import { Button, DropdownMenu } from ':components/shadcn';
import { CalendarCheckInIcon, CalendarCheckOutIcon, XmarkIcon } from ':components/icons/basic';

const orderCreatedAfterFilterName = 'orderCreatedAfter';
const orderCreatedBeforeFilterName = 'orderCreatedBefore';

type FilterState = DateTime | undefined;

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <DatePicker
            selected={state}
            onChange={setState}
            type='date'
            inline
        />
    );
}

function createFilterRowMenu(name: typeof orderCreatedAfterFilterName | typeof orderCreatedBeforeFilterName) {
    return ({ state, setState }: FilterMenuProps<FilterState>) => {
        const { t } = useTranslation('common', { keyPrefix: 'filters' });

        return (
            // TODO shadcn
            // <DropdownMenu.Root autoClose='outside'>
            <DropdownMenu.Root>
                <div className='relative'>
                    <DropdownMenu.Trigger asChild>
                        <Button variant='outline' className={state ? 'border-primary text-primary hover:border-primary pr-10' : undefined}>
                            <div className='flex items-center gap-2'>
                                {name === orderCreatedBeforeFilterName && <CalendarCheckInIcon size='md' />}
                                {name === orderCreatedAfterFilterName && <CalendarCheckOutIcon size='md' />}

                                {state && <DateTimeDisplay date dateTime={state} />}
                                {!state && t(`${name}.menu-button`)}
                            </div>
                        </Button>
                    </DropdownMenu.Trigger>

                    {state && (
                        <div className='absolute top-1/2 -translate-y-1/2 right-4 text-primary cursor-pointer hover:opacity-80' onClick={() => setState(undefined)}>
                            <XmarkIcon size='md' />
                        </div>
                    )}
                </div>

                <DropdownMenu.Content
                    className='p-0 overflow-visible border-0 rounded-none bg-transparent shadow-none outline-none'
                    side='bottom'
                >
                    <DatePicker
                        selected={state}
                        onChange={setState}
                        type='date'
                        inline
                    />
                </DropdownMenu.Content>
            </DropdownMenu.Root>
        );
    };
}

function FilterItemBadge() {
    return null;
}

function remove(): FilterState {
    return undefined;
}

function toItems(state: FilterState): FilterState[] {
    if (!state)
        return [];
    return [ state ];
}

function createCreatedAfterFilterFunction(state: FilterState): FilterFunction<OrderInfoFE> {
    if (!state)
        return () => true;

    return (data: OrderInfoFE) => state.startOf('day') <= data.createdAt;
}

function createCreatedBeforeFilterFunction(state: FilterState): FilterFunction<OrderInfoFE> {
    if (!state)
        return () => true;

    return (data: OrderInfoFE) => data.createdAt < state.plus({ days: 1 }).startOf('day');
}

function createdAfterToServer(state: FilterState): DateTime | undefined {
    if (!state)
        return undefined;
    return state.startOf('day').toUTC();
}

function createdBeforeToServer(state: FilterState): DateTime | undefined {
    if (!state)
        return undefined;
    return state.plus({ days: 1 }).startOf('day').toUTC();
}

const OrderCreatedAfterFilterTyped: FilterDefinition<FilterState, FilterState, OrderInfoFE> = {
    name: orderCreatedAfterFilterName,
    defaultValues: undefined,
    FilterToggleMenu,
    FilterRowMenu: createFilterRowMenu(orderCreatedAfterFilterName),
    FilterItemBadge,
    remove,
    toItems,
    createFilterFunction: createCreatedAfterFilterFunction,
    toServer: createdAfterToServer,
};

const OrderCreatedBeforeFilterTyped: FilterDefinition<FilterState, FilterState, OrderInfoFE> = {
    name: orderCreatedBeforeFilterName,
    defaultValues: undefined,
    FilterToggleMenu,
    FilterRowMenu: createFilterRowMenu(orderCreatedBeforeFilterName),
    FilterItemBadge,
    remove,
    toItems,
    createFilterFunction: createCreatedBeforeFilterFunction,
    toServer: createdBeforeToServer,
};

export const OrderCreatedAfterFilter = OrderCreatedAfterFilterTyped as Filter;
export const OrderCreatedBeforeFilter = OrderCreatedBeforeFilterTyped as Filter;

import { Button, Modal } from ':components/shadcn';
import { type BlockerModalControl } from '../hooks/useBlocker';
import { useTranslation } from 'react-i18next';
import { DoorOpenIcon } from ':components/icons/basic';

type BlockNavigationModalProps = Readonly<{
    control: BlockerModalControl;
}>;

export function BlockNavigationModal({ control }: BlockNavigationModalProps) {
    const { t } = useTranslation('common', { keyPrefix: 'blockNavigationModal' });

    return (
        <Modal.Root open={control.show} onOpenChange={open => !open && control.stay()}>
            <Modal.Content className='max-w-md p-8 gap-6' closeButton={t('stay-button')}>
                <Modal.Header className='items-center'>
                    <DoorOpenIcon size={32} className='text-primary' />
                    <Modal.Title className='mt-5 text-center text-2xl leading-7'>{t('title')}</Modal.Title>
                    <Modal.Description className='mt-3 text-center text-lg leading-7'>{t('text')}</Modal.Description>
                </Modal.Header>

                <Modal.Footer className='grid grid-cols-1 sm:grid-cols-2'>
                    <Button onClick={control.stay} variant='secondary' className='w-full sm:w-auto'>
                        {t('stay-button')}
                    </Button>

                    <Button onClick={control.exit} variant='primary' className='w-full sm:w-auto'>
                        {t('exit-button')}
                    </Button>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}

import { useMemo } from 'react';
import { MoneyDisplay } from ':components/custom';
import { Form } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { isParticipantBillable, type EditablePayingParticipant } from ':frontend/types/EventParticipant';
import { type Money, type CurrencyFE, addVatAsNumber, type TaxRateFE } from ':utils/money';
import type { UseEventDispatch, UseEventState } from './useEvent';
import { toNumber } from ':utils/math';
import { useMaster } from ':frontend/context/UserProvider';
import { VatSelect } from '../forms/VatSelect';

type PriceSummaryProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
    totalEvents: number;
}>;

export function EventPriceSummary({ state, dispatch, totalEvents }: PriceSummaryProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });
    const { teamSettings } = useMaster();
    const payment = state.payment!;

    const summary = useMemo(() => payment?.clients && computeEventPriceSummary(payment.clients, totalEvents), [ payment?.clients, totalEvents ]);
    if (!summary)
        return null;

    if (summary === 'infinity') {
        return (
            <div className='text-center text-secondary-400'>
                {t('inifinite-events')}
            </div>
        );
    }

    return (
        <div className='ps-2 space-y-2'>
            {teamSettings.isTaxesEnabled && (
                <div className='flex items-center gap-2'>
                    <Form.Label className='m-0'>{t('vat-label')}</Form.Label>

                    <VatSelect
                        immutableProps={{ size: 'exact', menuOverflow: 'x', variant: 'transparent', menuAlignment: 'top' }}
                        value={payment.vat}
                        onChange={value => value && dispatch({ type: 'payment', operation: 'vat', value })}
                    />

                    <div className='grow' />

                    <MoneyDisplay money={summary.vatBreakdown.difference} className='font-medium' />
                </div>
            )}

            {!summary.isOnlyZeroVat && (
                <div className='flex justify-between text-secondary-500'>
                    {t('without-vat-label')}
                    <MoneyDisplay money={summary.vatBreakdown.withoutVat} className='font-medium' />
                </div>
            )}

            <div className='flex justify-between font-semibold'>
                {t('total-label')}
                <MoneyDisplay money={summary.vatBreakdown.withVat} />
            </div>
        </div>
    );
}

type PriceSummary = {
    vatBreakdown: VatSummary;
    isOnlyZeroVat: boolean;
};

type VatSummary = {
    vat: TaxRateFE;
    withoutVat: Money;
    withVat: Money;
    difference: Money;
};

function computeEventPriceSummary(clients: EditablePayingParticipant[], totalEvents: number): PriceSummary | 'infinity' | undefined {
    if (clients.length === 0)
        return undefined;

    if (!isFinite(totalEvents))
        return 'infinity';

    const currency = clients[0].currency;
    const vat = clients[0].vat;

    let totalWithoutVat = 0;
    let totalWithVat = 0;
    let totalDifference = 0;
    clients.forEach(client => {
        const { withoutVat, withVat, difference } = addVatAsNumber(toNumber(client.price), vat);
        totalWithoutVat += withoutVat;
        totalWithVat += withVat;
        totalDifference += difference;
    });

    return {
        vatBreakdown: {
            vat,
            withoutVat: { amount: totalWithoutVat * totalEvents, currency },
            withVat: { amount: totalWithVat * totalEvents, currency },
            difference: { amount: totalDifference * totalEvents, currency },
        },
        isOnlyZeroVat: vat.isZero,
    };
}

type OrderSummaryProps = Readonly<{
    state: UseEventState;
}>;

export function OrderSummary({ state }: OrderSummaryProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventPayment' });
    const payment = state.payment!;

    const summary = useMemo(() => computeOrderPriceSummary(payment.clients), [ payment.clients ]);
    if (!summary)
        return null;

    return (
        <div className='pl-2 space-y-2'>
            {!summary.isAllPaid && (
                <div className='flex justify-between text-secondary-500'>
                    {t('paid-label')}
                    <MoneyDisplay money={summary.paid} className='font-medium' />
                </div>
            )}

            <div className='flex justify-between font-semibold'>
                {t('total-label')}
                <MoneyDisplay money={summary.total} />
            </div>
        </div>
    );
}

function computeOrderPriceSummary(clients: EditablePayingParticipant[]) {
    if (clients.length === 0)
        return undefined;

    const currency = clients[0].currency;
    const paidClients = clients.filter(c  => c.original?.isPaid);
    const billableClients = clients.filter(c => !c.original || isParticipantBillable(c.original));

    return {
        isBillable: billableClients.length > 0,
        isAllPaid: paidClients.length === clients.length,
        paid: sumWithVat(paidClients, currency),
        total: sumWithVat(clients, currency),
    };
}

function sumWithVat(clients: EditablePayingParticipant[], currency: CurrencyFE) {
    const amount = clients.reduce((ans, p) => ans + addVatAsNumber(toNumber(p.price), p.vat).withVat, 0);
    return { amount, currency };
}

import { CurrencyFE, type Money } from ':utils/money';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { getI18nLocale } from ':utils/i18n';
import { cn } from ':components/shadcn/utils';

type MoneyDisplayProps = Readonly<{
    money: Money;
    noColor?: boolean;
    className?: string;
}>;

export function MoneyDisplay({ money, noColor, className }: MoneyDisplayProps) {
    return (
        <span className={cn(getClass(money.amount, noColor), className)}>
            <MoneyDisplayString money={money} />
        </span>
    );
}

export function MoneyDisplayString({ money }: {money: Money}) {
    const { i18n } = useTranslation();

    return money.currency.displayFull(money.amount || 0, getI18nLocale(i18n), true);
}

type MoneyAmountDisplayProps = Readonly<{
    amount: number;
    noColor?: boolean;
    className?: string;
}>;

export function MoneyAmountDisplay({ amount, noColor, className }: MoneyAmountDisplayProps) {
    const { i18n } = useTranslation();

    return (
        <span className={cn(getClass(amount, noColor), className)}>
            {CurrencyFE.displayAmount(amount || 0, getI18nLocale(i18n), true)}
        </span>
    );
}

function getClass(amount: number, noColor?: boolean) {
    const isColor = !noColor && (isNaN(amount) || amount < 0);
    return clsx('font-semibold whitespace-nowrap tabular-nums', isColor && 'text-danger');
}

type CurrencyDisplayProps = Readonly<{
    currency: CurrencyFE;
    className?: string;
    children: React.ReactNode;
}>;

export function CurrencyDisplay({ currency, className, children }: CurrencyDisplayProps) {
    const { i18n } = useTranslation();
    const isSymbolBefore = currency.isSymbolBefore(getI18nLocale(i18n));

    return (<>
        {!isSymbolBefore && children}
        <span className={className}>
            {currency.displaySymbol(getI18nLocale(i18n))}
        </span>
        {isSymbolBefore && children}
    </>);
}

type PercentDisplayProps = Readonly<{
    amount: number;
    noColor?: boolean;
    className?: string;
    padToAmount?: number;
}>;

export function PercentDisplay({ amount, noColor, className, padToAmount }: PercentDisplayProps) {
    const { i18n } = useTranslation();
    const padding = padToAmount ? ''.padStart(padToAmount.toFixed(0).length - (amount || 0).toFixed(0).length, '0') : undefined;

    return (
        <span className={cn(getClass(amount, noColor), className)}>
            {padding && (<span aria-hidden className='invisible'>{padding}</span>)}
            {CurrencyFE.displayAmount(amount || 0, getI18nLocale(i18n), true)} %
        </span>
    );
}

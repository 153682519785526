import { useCallback, useMemo, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Form, Modal, Card, Tooltip } from ':components/shadcn';
import { DateTimeDisplay, SpinnerButton } from ':frontend/components/common';
import { DEFAULT_PREFIX, OrderFE, canTransition, type OrderTransitionFE } from ':frontend/types/orders/Order';
import { OrderStateBadgeOval } from ':frontend/components/orders/OrderStateBadge';
import { OrderPaymentMethodBadge } from ':frontend/components/orders/OrderPaymentMethodBadge';
import { Trans, useTranslation } from 'react-i18next';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { INDEX_DISPLAY_DIGITS, InvoiceNumberDisplay, getInvoiceNumber } from ':frontend/components/orders/invoiceNumber';
import { ClientIconLink } from ':frontend/components/client/ClientIconLink';
import { EditableTextInput } from ':frontend/components/forms/EditableTextInput';
import { createActionState, useToggle } from ':frontend/hooks';
import { useCached } from ':components/hooks';
import { useOrder, type OrderFormState, type UseOrderDispatch, type UseOrderState, computeFullPrefix, type OrderFormWithInvoice, hasInvoice } from ':frontend/components/orders/useOrder';
import { type TFunction } from 'i18next';
import { CountrySelect, DatePicker } from ':frontend/components/forms';
import { transformToPositiveIntegerOrZero } from ':utils/math';
import { type DateTime } from 'luxon';
import clsx from 'clsx';
import { HEADER_FOOTER_MAX_LENGTH } from ':frontend/components/settings/PersonalizationForm';
import { TranslatedErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { routesBE, routesFE } from ':utils/routes';
import { CondensedInvoiceToggle } from ':frontend/components/forms/CondensedInvoiceToggle';
import { type EmailPreviewState } from ':frontend/components/orders/checkout/useCheckout';
import { EmailPreviewForm } from ':frontend/components/orders/checkout/CheckoutEmailPreview';
import { trpc } from ':frontend/context/TrpcProvider';
import { isStripeOrder } from ':utils/entity/order';
import { OrderTransition, PaymentMethod, DocumentType } from ':utils/entity/invoicing';
import type { PreselectCustom } from '../direct-sale/NewCustomOrderTab';
import { Trash2Icon, InvoiceIcon, PaperPlane1Icon, Check1Icon, XmarkIcon, ChevronLeftIcon, Copy1Icon, CircleInfoIcon, Pen2Icon } from ':components/icons/basic';
import { MoneyDisplayString } from ':components/custom';
import { useMaster } from ':frontend/context/UserProvider';
import { OrderItemsListCustom, OrderItemsListCustomForm } from ':frontend/components/orders/OrderItemsListCustom';
import { OrderItemsListProduct, OrderItemsListProductForm } from ':frontend/components/orders/OrderItemsListProduct';
import { OrderItemsListEvent, OrderItemsListEventForm } from ':frontend/components/orders/OrderItemsListEvent';
import { Topbar } from ':frontend/components/Layout';
import { type LogFE } from ':frontend/types/Log';

// TODO unify the initial fetching with the rest of the hook? Or not?
export default function OrderDetail() {
    const { id } = useParams();

    const orderOutput = trpc.order.getOrder.useQuery({ id: id! });

    const order = useMemo(() => {
        if (!orderOutput.data)
            return;

        return OrderFE.fromServer(orderOutput.data);
    }, [ orderOutput.data ]);

    if (!order)
        return null;

    return (
        <OrderDetailInner order={order} />
    );
}

type OrderDetailInnerProps = Readonly<{
    order: OrderFE;
}>;

function OrderDetailInner({ order }: OrderDetailInnerProps) {
    const { state, dispatch } = useOrder(order);
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });
    const { addAlert } = useNotifications();

    const editFromStart = useRef<HTMLDivElement>(null);

    const { isTaxesEnabled } = useMaster().teamSettings;

    const updateOrderMutation = trpc.order.updateOrder.useMutation();
    // TODO move to reducer
    const syncTitle = useCallback(async (newTitle: string) => {
        if (newTitle === '')
            return false;

        try {
            const response = await updateOrderMutation.mutateAsync({ id: state.order.id, title: newTitle });
            dispatch({ type: 'reset', order: OrderFE.fromServer(response) });
            addAlert(createTranslatedSuccessAlert('pages:orderDetail.editTitleSuccessAlert'));

            return true;
        }
        catch {
            addAlert(createErrorAlert(updateOrderMutation.error));

            return false;
        }
    }, [ state.order.id, addAlert, dispatch, updateOrderMutation ]);

    const phoneNumber = state.order.client.phoneNumber;
    const { totalVat, commonTaxRate } = useMemo(() => ({
        totalVat: state.order.computeTotalVat(),
        commonTaxRate: state.order.computeCommonTaxRate(),
    }), [ state.order ]);

    const showVat = !!totalVat.amount;

    const invoiceData = [
        ...(state.order.invoice ? [ { key: 'identification', data: <InvoiceNumberDisplay invoice={state.order.invoice} /> } ] : []),
        { key: 'issued-on', data: <DateTimeDisplay date dateTime={state.order.issueDate} /> },
        { key: 'due-by', data: <DateTimeDisplay date dateTime={state.order.dueDate} /> },
        ...(isTaxesEnabled ? [ { key: 'taxable-transaction', data: <DateTimeDisplay date dateTime={state.order.taxDate} /> } ] : []),
    ];

    return (<>
        <Topbar isStatic>
            <div className='grow max-w-[1200px] mx-auto py-2 flex items-center justify-between gap-2'>
                <Link to={routesFE.orders.list.path} className='px-3 py-2 rounded-md leading-4 flex items-center gap-2 hover:bg-primary-50 whitespace-nowrap'>
                    <ChevronLeftIcon size='md' /> <span className='max-md:hidden'>{t('back-to-list')}</span>
                </Link>

                <div className='flex items-center gap-2'>
                    {order.documentType === DocumentType.Invoice && !state.form && (
                        <Button
                            variant='outline'
                            size='small'
                            onClick={() => {
                                dispatch({ type: 'form', operation: 'edit' });

                                setTimeout(() => {
                                    editFromStart.current?.scrollIntoView({ behavior: 'smooth' });
                                }, 200);
                            }}
                        >
                            <Pen2Icon size='lg' />

                            {t('edit-form-button')}
                        </Button>
                    )}

                    {state.form && (<>
                        <SpinnerButton
                            variant='primary'
                            size='small'
                            onClick={() => dispatch({ type: 'sync', operation: 'save', fid: FID_SAVE })}
                            fetching={state.sync?.fetching}
                            fid={FID_SAVE}
                            disabled={!!state.formErrors}
                        >
                            <Check1Icon size='lg' />
                            {t('save-form-button')}
                        </SpinnerButton>

                        <Button
                            variant='secondary'
                            size='small'
                            onClick={() => dispatch({ type: 'form', operation: 'discard' })}
                        >
                            <XmarkIcon size='lg' />
                            {t('discard-form-button')}
                        </Button>
                    </>)}

                    {!state.form && (<>
                        {state.order.invoice && (<>
                            <a href={routesBE.public.document.resolve({ id: state.order.id })} target='_blank' rel='noreferrer' className='no-underline'>
                                <Button variant='outline' size='small'>
                                    <InvoiceIcon size='lg' />
                                    {t('view-invoice-button')}
                                </Button>
                            </a>

                            <SendNotificationModal state={state} dispatch={dispatch} />

                            <a href={routesBE.public.document.resolve({ id: state.order.id })} download={getInvoiceNumber(state.order.invoice) + '.pdf'} className='no-underline'>
                                <Button variant='outline' size='small'>
                                    <InvoiceIcon size='lg' />

                                    {t('download-invoice-button')}
                                </Button>
                            </a>
                        </>)}

                        <DuplicateOrderButton order={state.order} />
                    </>)}

                    <DeleteOrderModal state={state} dispatch={dispatch} />
                </div>
            </div>
        </Topbar>

        {/* Info */}
        <div className='py-10 px-4 fl-main-scroller'>
            <div className='max-w-[800px] mx-auto w-full space-y-11'>
                <div className='max-sm:space-y-2 sm:flex sm:justify-between sm:items-center sm:gap-4 w-full'>
                    <ClientIconLink
                        client={state.order.client}
                        className='max-w-lg bg-secondary-800 text-white p-1.5 pr-3 rounded-full hover:no-underline hover:opacity-80'
                        iconClassName='bg-white text-secondary-800'
                    />

                    <div className='bg-secondary-100 rounded-full h-8 flex items-center px-3 shrink-0'>
                        <DateTimeDisplay dateTime={state.order.createdAt} />
                    </div>
                </div>

                <div className='space-y-1'>
                    <EditableTextInput
                        className='flex justify-center text-secondary-900'
                        inputClassName='h-8 text-base'
                        viewClassName='text-xl leading-8 font-semibold truncate'
                        value={state.order.title}
                        syncFunction={syncTitle}
                        disabled={!!state.form}
                    />

                    <div className='flex gap-4 items-center justify-center'>
                        <Button variant='transparent' className='min-w-0' onClick={() => {
                            navigator.clipboard.writeText(state.order.client.email);
                            addAlert(createTranslatedSuccessAlert('pages:client.email-copy-success'));
                        }}>
                            <span className='leading-5 truncate'>
                                {state.order.client.email}
                            </span>

                            <span className='opacity-40'><Copy1Icon size='xs' /></span>
                        </Button>

                        {phoneNumber && (
                            <Button variant='transparent' className='min-w-0' onClick={() => {
                                navigator.clipboard.writeText(phoneNumber);
                                addAlert(createTranslatedSuccessAlert('pages:client.phone-number-copy-success'));
                            }}>
                                <span className='leading-5 truncate'>
                                    {phoneNumber}
                                </span>

                                <span className='opacity-40'><Copy1Icon size='xs' /></span>
                            </Button>
                        )}
                    </div>
                </div>

                <div>
                    <Card className='max-lg:space-y-4 lg:flex lg:items-center lg:gap-8 relative z-40'>
                        <div className='flex items-center gap-8 max-lg:justify-between'>
                            <div className='space-y-2'>
                                <div className='text-xl font-semibold text-secondary-900'>
                                    <MoneyDisplayString money={state.order.price} />
                                </div>

                                <div className='flex items-center gap-2'>
                                    <div className='text-secondary-400'>
                                        {t('total-price')}
                                    </div>

                                    <Tooltip tooltipText={t('total-price-tooltip')} side='left'>
                                        <CircleInfoIcon size='md' className='opacity-40' />
                                    </Tooltip>
                                </div>
                            </div>

                            {!showVat && (
                                <div className='space-y-2'>
                                    <div className='text-xl font-semibold text-secondary-900'>
                                        <MoneyDisplayString money={totalVat} />
                                    </div>

                                    <div className='flex items-center gap-2'>
                                        <div className='text-secondary-400'>
                                            {commonTaxRate && `${commonTaxRate.label} `}
                                            {t('total-vat')}
                                        </div>

                                        <Tooltip tooltipText={t('total-vat-tooltip')} side='left'>
                                            <CircleInfoIcon size='md' className='opacity-40' />
                                        </Tooltip>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className='max-lg:hidden grow' />

                        <div className='max-md:space-y-2 md:flex md:items-center md:gap-2'>
                            {!state.form && state.order.paymentMethod === PaymentMethod.bankTransfer && (<>
                                <TransitionOrderButtons state={state} dispatch={dispatch} />

                                <div className='max-md:hidden w-[2px] h-6 bg-secondary-100 mx-4' />
                            </>)}

                            <div className='w-full flex flex-wrap items-center gap-2'>
                                <OrderPaymentMethodBadge order={state.order} />

                                <OrderStateBadgeOval order={state.order} />
                            </div>
                        </div>
                    </Card>

                    {state.order.documentType === DocumentType.Invoice && (
                        <div
                            className={clsx('-mt-6 p-6 pt-12 z-40 bg-secondary-100 rounded-b-lg grid grid-cols-1 gap-4', {
                                'sm:grid-cols-2': invoiceData.length === 2,
                                'sm:grid-cols-3': invoiceData.length === 3,
                                'sm:grid-cols-2 lg:grid-cols-4': invoiceData.length === 4,
                            })}
                        >
                            {invoiceData.map(info => (
                                <div key={info.key} className='space-y-2'>
                                    <div className='font-semibold text-lg'>{info.data}</div>

                                    <div className='flex items-center gap-2'>
                                        <div className='font-semibold text-secondary-400'>{t(`info.${info.key}-label`)}</div>

                                        <Tooltip tooltipText={t(`info.${info.key}-tooltip`)} side='left'>
                                            <CircleInfoIcon size='md' className='opacity-40' />
                                        </Tooltip>
                                    </div>
                                </div>
                            ))}

                        </div>
                    )}
                </div>

                {/* Items + Form */}
                <div>
                    <div ref={editFromStart} className='relative -top-10' />

                    <div className='space-y-8'>
                        {state.order.getProductItems().length > 0 && (
                            !state.form ? (
                                <OrderItemsListProduct
                                    items={state.order.getProductItems()}
                                />
                            ) : (
                                <OrderItemsListProductForm
                                    state={state}
                                    form={state.form}
                                    dispatch={dispatch}
                                />
                            )
                        )}

                        {state.order.getEventItems().length > 0 && (
                            !state.form ? (
                                <OrderItemsListEvent
                                    items={state.order.getEventItems()}
                                />
                            ) : (
                                <OrderItemsListEventForm
                                    state={state}
                                    form={state.form}
                                    dispatch={dispatch}
                                />
                            )
                        )}

                        {state.order.getCustomItems().length > 0 && (
                            !state.form ? (
                                <OrderItemsListCustom
                                    items={state.order.getCustomItems()}
                                />
                            ) : (
                                <OrderItemsListCustomForm
                                    state={state}
                                    form={state.form}
                                    dispatch={dispatch}
                                />
                            )
                        )}

                        {state.form && state.order.invoice && (
                            <Card>
                                <OrderForm state={state} dispatch={dispatch} />
                            </Card>
                        )}
                    </div>
                </div>

                {state.order.invoice && !!state.order.logs.length && (
                    <OrderLogsList order={state.order} />
                )}
            </div>
        </div>
    </>);
}

type OrderLogsListProps = Readonly<{
    order: OrderFE;
}>;

function OrderLogsList({ order }: OrderLogsListProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });

    return (
        <div className='space-y-4'>
            <h2 className='font-semibold text-lg'>{t('logs-title')}</h2>

            <div className='p-6 border border-secondary-200 rounded-lg space-y-1'>
                {order.logs
                    .map((log, index) => (
                        <OrderLogsRow key={log.id} log={log} position={order.logs.length - index} />
                    ))
                    .flatMap((e, index) => [ e, <div key={`divider_${index}`} className='h-4 w-[2px] bg-secondary-200 ml-[11px]' /> ])
                    .slice(0, -1)
                }
            </div>
        </div>
    );
}

type OrderLogsRowProps = Readonly<{
    log: LogFE;
    position: number;
}>;

function OrderLogsRow({ log, position }: OrderLogsRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'historyLogs' });

    return (
        <div className='flex gap-4 items-center'>
            <div className='size-6 flex items-center justify-center font-semibold text-sm rounded-full bg-secondary-200 shrink-0'>
                {position}.
            </div>

            <div className='max-sm:space-y-2 sm:flex sm:gap-4 sm:items-center'>
                <div>
                    <Trans
                        t={t}
                        i18nKey={log.translationId}
                        values={log.data}
                        components={{
                            b: <span className='font-semibold' />,
                        }}
                    />
                </div>

                <div className='text-secondary-400 text-sm'><DateTimeDisplay dateTime={log.createdAt} /></div>
            </div>
        </div>
    );
}

type StateDispatchProps = Readonly<{
    state: UseOrderState;
    dispatch: UseOrderDispatch;
}>;

function TransitionOrderButtons({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });
    const transitionOrder = useCallback((transition: OrderTransitionFE) => dispatch({ type: 'sync', operation: 'transition', transition, fid: transition }), [ dispatch ]);

    if (isStripeOrder(state.order))
        return null;

    return (<>
        {canTransition(state.order, OrderTransition.Fulfill) &&
            <SpinnerButton
                variant='outline'
                size='small'
                onClick={() => transitionOrder(OrderTransition.Fulfill)}
                fetching={state.sync?.fetching}
                fid={OrderTransition.Fulfill}
                className='max-md:w-full'
            >
                {t('fulfill-order-button')}
            </SpinnerButton>
        }

        {canTransition(state.order, OrderTransition.Unfulfill) &&
            <SpinnerButton
                variant='outline'
                size='small'
                onClick={() => transitionOrder(OrderTransition.Unfulfill)}
                fetching={state.sync?.fetching}
                fid={OrderTransition.Unfulfill}
                className='max-md:w-full'
            >
                {t('unfulfill-order-button')}
            </SpinnerButton>
        }
    </>);
}

function SendNotificationModal({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail.sendNotificationModal' });
    const { sendNotification, order, sync } = state;
    const cached = useCached(sendNotification);
    const isFetching = !!sync?.fetching;

    return (<>
        <Modal.Root
            open={!!sendNotification}
            // FIXME if email preview, don't close the modal, just change the phase
            onOpenChange={() => dispatch({ type: 'sendNotification', operation: 'close' })}
        >
            {cached && (<>
                {cached.phase === 'emailPreview' ? (
                    <EmailPreview emailPreview={cached.emailPreview} dispatch={dispatch} />
                ) : (<>
                    <Modal.Content className='gap-6' closeButton={t('cancel-button')}>
                        <Modal.Header>
                            <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                                {t('title')}
                            </Modal.Title>
                        </Modal.Header>

                        <div className='text-center'>
                            {t(`text${order.isNotificationSent ? '-again' : ''}`)}
                        </div>

                        <div className='flex justify-center'>
                            <Button
                                variant='outline'
                                size='tiny'
                                onClick={() => dispatch({ type: 'sendNotification', operation: 'emailPreview' })}
                            >
                                <PaperPlane1Icon size='md' />
                                {t('preview-email-button')}
                            </Button>
                        </div>

                        <Modal.Footer className='grid grid-cols-2 gap-4'>
                            <Button
                                variant='outline'
                                onClick={() => dispatch({ type: 'sendNotification', operation: 'close' })}
                                disabled={isFetching}
                            >
                                {t('cancel-button')}
                            </Button>

                            <SpinnerButton
                                onClick={() => dispatch({
                                    type: 'sync',
                                    operation: 'sendNotification',
                                    fid: FID_SEND_NOTIFICATION,
                                })}
                                fetching={state.sync?.fetching}
                                fid={FID_SEND_NOTIFICATION}

                            >
                                {t('send-button')}
                            </SpinnerButton>
                        </Modal.Footer>
                    </Modal.Content>
                </>)}
            </>)}
        </Modal.Root>

        <Button
            variant='outline'
            size='small'
            onClick={() => dispatch({ type: 'sendNotification', operation: 'open' })}
        >
            {order.isNotificationSent ? (<>
                <Check1Icon size='lg' />

                {t('open-again-button')}
            </>) : (<>
                <PaperPlane1Icon size='lg' />

                {t('open-button')}
            </>)}
        </Button>
    </>);
}

//! xs={auto} ==== css= width: fit-content;
const FID_SEND_NOTIFICATION = 'send-notification';

type EmailPreviewProps = Readonly<{
    emailPreview: EmailPreviewState;
    dispatch: UseOrderDispatch;
}>;

function EmailPreview({ emailPreview, dispatch }: EmailPreviewProps) {
    const { t } = useTranslation('components', { keyPrefix: 'checkout.emailPreview' });
    const isChanged = emailPreview.isChanged;

    return (
        <Modal.Content className='max-w-[800px]' closeButton={t('back-button')}>
            <Modal.Header>
                <Modal.Title>{t('title')}</Modal.Title>
            </Modal.Header>

            <EmailPreviewForm state={emailPreview} dispatch={dispatch} />

            <Modal.Footer>
                {isChanged && (
                    <Button variant='outline' onClick={() => dispatch({ type: 'emailPreview', operation: 'reset' })}>
                        {t('reset-button')}
                    </Button>
                )}
                <Button onClick={() => dispatch({ type: 'sendNotification', operation: 'overview' })}>
                    {t('back-button')}
                </Button>
            </Modal.Footer>
        </Modal.Content>
    );
}

const FID_SAVE = 'save';

function DeleteOrderModal({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail.deleteOrderModal' });
    const [ showModal, setShowModal ] = useToggle(false);
    const isFetching = !!state.sync?.fetching;

    return (<>
        <Button
            variant='outline'
            size='small'
            onClick={setShowModal.true}
        >
            <Trash2Icon size='lg' className='text-danger-500' />
        </Button>

        <Modal.Root
            open={showModal}
            onOpenChange={open => !isFetching && !open && setShowModal.false()}
        >
            <Modal.Content className='gap-6' closeButton={t('cancel-button')}>
                <Modal.Header className='space-y-4'>
                    <Trash2Icon size={32} className='mx-auto text-danger-500 stroke-2' />

                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>
                        {t('title')}
                    </Modal.Title>
                </Modal.Header>

                <div className='text-center'>
                    {t('text')}
                </div>

                <Modal.Footer className='grid grid-cols-2 gap-4'>
                    <Button variant='secondary' onClick={setShowModal.false}>
                        {t('cancel-button')}
                    </Button>

                    <SpinnerButton
                        variant='danger'
                        onClick={() => dispatch({
                            type: 'sync',
                            operation: 'delete',
                            fid: FID_DELETE,
                        })}
                        fetching={state.sync?.fetching}
                        fid={FID_DELETE}
                    >
                        {t('confirm-button')}
                    </SpinnerButton>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    </>);
}

const FID_DELETE = 'delete';

function DuplicateOrderButton({ order }: Readonly<{ order: OrderFE }>) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail' });

    const isEvents = useMemo(() => order.getEventItems().length > 0, [ order ]);
    if (isEvents)
        return null;

    return (
        <Link
            to={routesFE.directSale.custom}
            state={createActionState<PreselectCustom>('preselectCustom', { orderId: order.id })}
            className='no-underline'
        >
            <Button
                variant='outline'
                size='small'
            >
                <InvoiceIcon size={22} />
                {t('duplicate-order-button')}
            </Button>
        </Link>
    );
}

function OrderForm({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'orderDetail.form' });
    const { order, form } = state;

    const dispatchDate = useMemo(() => ({
        issue: (value?: DateTime) => value && dispatch({ type: 'input', field: 'issueDate', value }),
        due: (value?: DateTime) => value && dispatch({ type: 'input', field: 'dueDate', value }),
        tax: (value?: DateTime) => value && dispatch({ type: 'input', field: 'taxDate', value }),
    }), [ dispatch ]);

    // TODO use form items instead
    const showTaxDate = useMemo(() => state.order.items.some(i => !i.vat.isZero), [ state.order.items ]);

    if (!form)
        return null;

    return (
        <div className='space-y-12'>
            {hasInvoice(form) && (
                <div className='space-y-4'>
                    <h3 className='font-semibold text-lg'>{t('settings-title')}</h3>

                    {invoiceNumberInput(form, state, dispatch, t)}

                    {!isStripeOrder(order) && (
                        <div className='grid grid-cols-1 sm:grid-cols-2 items-end gap-4'>
                            <div>
                                <Form.Input
                                    size='compact'
                                    label={t('variable-symbol-label')}
                                    className='tabular-nums'
                                    value={form.invoice.variableSymbol}
                                    onChange={event => dispatch({ type: 'input', field: 'invoice.variableSymbol', value: event.target.value })}
                                />
                            </div>

                            {/* This button is not supported anymore, the funcionality is kept because I think there'a a high chance we will want it again
                            form.invoice.variableSymbol !== form.invoice.generatedVariableSymbol && (
                                <Button
                                    variant='outline' size='medium' className='w-fit'
                                    onClick={() => dispatch({ type: 'form', operation: 'generateVariableSymbol' })}
                                >
                                    {t('generate-variable-symbol-button')}
                                </Button>
                            ) */}
                        </div>
                    )}
                </div>
            )}

            <div className='space-y-4'>
                <h3 className='font-semibold text-lg'>{t('dates-title')}</h3>

                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                    <div className='space-y-2'>
                        <Form.Label>{t('issue-date-label')}</Form.Label>

                        <div>
                            <DatePicker
                                selected={form.issueDate}
                                onChange={dispatchDate.issue}
                                type='date'
                                wrapperClassName='w-full'
                                className='w-full px-4 h-10 border-secondary-100 border rounded-lg hover:border-primary'
                            />
                        </div>
                    </div>

                    <div className='space-y-2'>
                        <Form.Label>{t('due-date-label')}</Form.Label>

                        <div>
                            <DatePicker
                                selected={form.dueDate}
                                onChange={dispatchDate.due}
                                type='date'
                                wrapperClassName='w-full'
                                className='w-full px-4 h-10 border-secondary-100 border rounded-lg hover:border-primary'
                            />
                        </div>
                    </div>
                </div>

                {showTaxDate && (
                    <div className='space-y-2'>
                        <Form.Label>{t('tax-date-label')}</Form.Label>

                        <div>
                            <DatePicker
                                selected={form.taxDate}
                                onChange={dispatchDate.tax}
                                type='date'
                                wrapperClassName='w-full'
                                className='w-full px-4 h-10 border-secondary-100 border rounded-lg hover:border-primary'
                            />
                        </div>
                    </div>
                )}
            </div>

            <div className='space-y-4'>
                <h3 className='font-semibold text-lg'>{t('subscriber-label')}</h3>

                {identityForm(state, dispatch, 'subscriber', t)}
            </div>

            <div className='space-y-4'>
                <h3 className='font-semibold text-lg'>{t('supplier-title')}</h3>

                {identityForm(state, dispatch, 'supplier', t)}
            </div>

            <div className='space-y-4'>
                <h3 className='font-semibold text-lg'>{t('custom-keys-title')}</h3>

                {customKeysForm(form, dispatch, t)}
            </div>

            <div className='space-y-4'>
                <h3 className='font-semibold text-lg'>{t('invoice-look-title')}</h3>

                {invoiceLookForm(form, dispatch, t)}
            </div>
        </div>
    );
}

function invoiceNumberInput(form: OrderFormWithInvoice, state: UseOrderState, dispatch: UseOrderDispatch, t: TFunction) {
    const isChangeable = state.masterContext.subscription.restrictions.invoicing.customLogo;

    function updateIndex(value: string) {
        const parsed = transformToPositiveIntegerOrZero(value);
        if (parsed.toString().length > INDEX_DISPLAY_DIGITS)
            return;

        dispatch({ type: 'input', field: 'invoice.index', value: parsed });
    }

    const fullPrefix = computeFullPrefix(form, state.masterContext);
    const error = state.error?.numberAlreadyUsed;
    const displayError = error?.prefix === fullPrefix && error?.index === form.invoice.index;

    return (<>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <div>
                <Form.Input
                    size='compact'
                    label={t('invoice-form-prefix-label')}
                    value={isChangeable ? form.invoice.formPrefix : DEFAULT_PREFIX}
                    onChange={event => dispatch({ type: 'input', field: 'invoice.formPrefix', value: event.target.value })}
                    disabled={!isChangeable}
                />
            </div>

            <div>
                <Form.Input
                    size='compact'
                    label={t('invoice-index-label')}
                    value={form.invoice.index}
                    onChange={event => updateIndex(event.target.value)}
                />
            </div>
        </div>

        {displayError && (
            <TranslatedErrorMessage translationId={'common:form.invoice-number-already-used'} />
        )}
    </>);
}

function identityForm(state: UseOrderState, dispatch: UseOrderDispatch, type: 'supplier' | 'subscriber', t: TFunction) {
    const form = state.form;
    if (!form)
        return null;

    const identity = form[type];

    return (<>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <div>
                <Form.Input
                    size='compact'
                    label={t('name-company-label')}
                    value={identity.name}
                    onChange={e => dispatch({ type: 'input', field: `${type}.name`, value: e.target.value })}
                    placeholder={t('name-company-label')}
                />
                <TranslatedErrorMessage translationId={state.formErrors?.[`${type}.name`]} />
            </div>

            <div>
                <Form.Input
                    size='compact'
                    label={t('email-label')}
                    value={identity.email}
                    onChange={e => dispatch({ type: 'input', field: `${type}.email`, value: e.target.value })}
                    placeholder={t('email-label')}
                />
            </div>
        </div>

        <div>
            <Form.Input
                size='compact'
                label={t('line1-label')}
                value={identity.address.line1}
                onChange={e => dispatch({ type: 'input', field: `${type}.address.line1`, value: e.target.value })}
                placeholder={t('line1-label')}
            />
        </div>

        <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
            <div>
                <Form.Input
                    size='compact'
                    label={t('city-label')}
                    value={identity.address.city}
                    onChange={e => dispatch({ type: 'input', field: `${type}.address.city`, value: e.target.value })}
                    placeholder={t('city-label')}
                />
            </div>

            <div>
                <Form.Input
                    size='compact'
                    label={t('postal-code-label')}
                    value={identity.address.postalCode}
                    onChange={e => dispatch({ type: 'input', field: `${type}.address.postalCode`, value: e.target.value })}
                    placeholder={t('postal-code-label')}
                />
            </div>

            <div>
                <Form.Label>{t('country-label')}</Form.Label>
                <CountrySelect
                    immutableProps={{ size: 'compact' }}
                    value={identity.address.country}
                    onChange={value => dispatch({ type: 'input', field: `${type}.address.country`, value })}
                    placeholder={t('country-label')}
                    isClearable
                />
            </div>
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <div>
                <Form.Input
                    size='compact'
                    label={t('cin-label')}
                    value={identity.cin}
                    onChange={e => dispatch({ type: 'input', field: `${type}.cin`, value: e.target.value })}
                    placeholder={t('cin-label')}
                />
            </div>

            <div>
                <Form.Input
                    size='compact'
                    label={t('tin-label')}
                    value={identity.tin}
                    onChange={e => dispatch({ type: 'input', field: `${type}.tin`, value: e.target.value })}
                    placeholder={t('tin-label')}
                />
            </div>
        </div>
    </>);
}

function customKeysForm(form: OrderFormState, dispatch: UseOrderDispatch, t: TFunction) {
    return (<>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <div>
                <Form.Input
                    size='compact'
                    label={t('custom-key-1-label')}
                    value={form.customFields.customKey1}
                    onChange={e => dispatch({ type: 'input', field: `customFields.customKey1`, value: e.target.value })}
                    placeholder={t('custom-key-1-label')}
                />
            </div>

            <div>
                <Form.Input
                    size='compact'
                    label={t('custom-value-1-label')}
                    value={form.customFields.customValue1}
                    onChange={e => dispatch({ type: 'input', field: `customFields.customValue1`, value: e.target.value })}
                    placeholder={t('custom-value-1-label')}
                />
            </div>
        </div>

        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
            <div>
                <Form.Input
                    size='compact'
                    label={t('custom-key-2-label')}
                    value={form.customFields.customKey2}
                    onChange={e => dispatch({ type: 'input', field: `customFields.customKey2`, value: e.target.value })}
                    placeholder={t('custom-key-2-label')}
                />
            </div>

            <div>
                <Form.Input
                    size='compact'
                    label={t('custom-value-2-label')}
                    value={form.customFields.customValue2}
                    onChange={e => dispatch({ type: 'input', field: `customFields.customValue2`, value: e.target.value })}
                    placeholder={t('custom-value-2-label')}
                />
            </div>
        </div>
    </>);
}

function invoiceLookForm(form: OrderFormState, dispatch: UseOrderDispatch, t: TFunction) {
    return (<>
        <div>
            <Form.Label>{t('condensed-invoice-label')}</Form.Label>
            <CondensedInvoiceToggle
                immutableProps={{ size: 'compact' }}
                value={form.isCondensedInvoice}
                onChange={value => dispatch({ type: 'input', field: 'isCondensedInvoice', value })}
            />
        </div>

        <div>
            <Form.Textarea
                label={`${t('header-label')} (${form.customFields.header?.length ?? 0}/${HEADER_FOOTER_MAX_LENGTH})`}
                labelClassName='tabular-nums'
                placeholder={t('header-label')}
                minRows={2}
                value={form.customFields.header}
                onChange={e => dispatch({ type: 'input', field: 'customFields.header', value: e.target.value })}
                aria-describedby='header-textarea'
            />
        </div>

        <div>
            <Form.Textarea
                label={`${t('footer-label')} (${form.customFields.footer?.length ?? 0}/${HEADER_FOOTER_MAX_LENGTH})`}
                labelClassName='tabular-nums'
                placeholder={t('footer-label')}
                minRows={2}
                value={form.customFields.footer}
                onChange={e => dispatch({ type: 'input', field: 'customFields.footer', value: e.target.value })}
                aria-describedby='footer-textarea'
            />
        </div>
    </>);
}

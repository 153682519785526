import { type CalendarEvent, isGoogleEvent, isDraftEvent, type GoogleEventResource, type FullEventResource } from ':frontend/types/calendar/Calendar';
import { isGoogleEventTransparent } from ':frontend/types/calendar/Google';
import { isEventTransparent } from ':frontend/types/Event';
import { DateTime } from 'luxon';
import type { CSSProperties } from 'react';
import { isColorDark, MILLISECONDS_IN_SECOND, secondsToMinutes, shadeColor } from ':utils/common';
import { cn } from ':components/shadcn/utils';

const WHITE = 'ffffff';
const BODY = '5f5572';
const FINISHED_BODY = shadeColor(BODY, -0.32);
export const DEFAULT_CALENDAR_COLOR = '8457c8';

export function getEventStyle(event: CalendarEvent, showMembers: boolean, selected?: boolean): { className?: string, style?: CSSProperties } {
    const commonClassName = 'w-full rounded-sm cursor-pointer transition-opacity';

    if (isDraftEvent(event)) {
        return {
            className: cn(commonClassName, 'bg-secondary-400/50'),
        };
    }

    const isFinished = event.end <= DateTime.now();
    const typedEvent = event as CalendarEvent<FullEventResource | GoogleEventResource>;
    const common = getCommon(typedEvent, showMembers);

    const baseColor = isFinished ? shadeColor(common.baseColor, -0.50) : common.baseColor;
    const isTransparent = common.isTransparent;

    const backgroundColor = isTransparent ? WHITE : baseColor;

    let textColor = baseColor;
    if (!isTransparent) {
        if (isColorDark(baseColor))
            textColor = WHITE;
        else
            textColor = isFinished ? FINISHED_BODY : BODY;
    }

    return {
        className: cn(commonClassName, 'bg-[--fl-event-bg] text-[--fl-event-text]',
            isTransparent ? 'border border-[--fl-event-text] line-through' : 'outline outline-1 outline-secondary-50',
        ),
        style: {
            '--fl-event-bg': '#' + backgroundColor,
            '--fl-event-text': '#' + textColor,
        },
    };
}

export function getEventSize(event: CalendarEvent): number {
    if (event.isAllDay)
        return Infinity;

    const durationInMinutes = secondsToMinutes((event.end.toMillis() - event.start.toMillis()) / MILLISECONDS_IN_SECOND);
    return Math.floor(durationInMinutes / MINUTES_PER_LINE);
}

function getCommon(event: CalendarEvent<FullEventResource | GoogleEventResource>, showMembers: boolean): { baseColor: string, isTransparent: boolean } {
    if (isGoogleEvent(event)) {
        return {
            baseColor: event.resource.calendar.color,
            isTransparent: isGoogleEventTransparent(event.resource.event),
        };
    }

    const resource = event.resource as FullEventResource;

    return {
        baseColor: showMembers
            ? resource.teamMember.color
            : resource.calendar?.color ?? DEFAULT_CALENDAR_COLOR,
        isTransparent: isEventTransparent(resource.event),
    };
}

const MINUTES_PER_LINE = 15;

import { useReducer } from 'react';
import { useMaster, type MasterContext } from ':frontend/context/UserProvider';
import { type ProductItemInit, determineSendNotification, type DiscountItem, type FECustomItemInit, type CustomOrderInit, type ProductOrderInit, type EventOrderInitFE, type EventItemsForClient, type EventParticipantItem } from ':frontend/types/orders/Order';
import { type Money, type CurrencyFE, isUnderMinimalAmount } from ':utils/money';
import { type TypedAction } from ':frontend/utils/common';
import { roundMoney } from ':utils/math';
import { getClientIdentifier, getParticipantEmail, getParticipantLocale, type Participant, getClientOrContact } from ':frontend/types/EventParticipant';
import localStorage from ':frontend/utils/localStorage';
import i18next from ':frontend/types/i18n';
import { getPersonName } from ':utils/entity/person';
import { Updator, Validator, type RulesDefinition } from ':frontend/utils/updator';
import { isEmail } from ':utils/forms';
import { type InvoicePreviewInit } from './InvoicePreviewModal';
import { type EventFE } from ':frontend/types/Event';
import { type ClientInfoFE } from ':frontend/types/Client';
import { type TeamMemberFE } from ':frontend/types/Team';
import { type Id } from ':utils/id';
import { computeIfAbsent } from ':utils/common';
import { emailVariables, type EmailVariable } from ':utils/entity/order';
import { PaymentMethod } from ':utils/entity/invoicing';
import { isCurrencySupported } from ':frontend/types/BankAccount';

export enum CheckoutType {
    Custom = 'custom',
    Product = 'product',
    Event = 'event',
}

export type CheckoutInput = CustomCheckoutInput | ProductCheckoutInput | EventCheckoutInput;

export type CustomCheckoutInput = {
    type: CheckoutType.Custom;
    client: Participant;
    dueDays?: number;
    currency: CurrencyFE;
    items: FECustomItemInit[];
    discount?: Discount;
};

type ProductCheckoutInput = {
    type: CheckoutType.Product;
    guest: Participant;
    client: Participant;
    scheduler?: TeamMemberFE;
    items: ProductItemInit[];
    discount?: Discount;
};

type EventCheckoutInput = {
    type: CheckoutType.Event;
    forClients: EventItemsForClient[];
};

export type Discount = {
    /** Float (not in percent). */
    title: string;
    amount: number;
};

export const SEND_NOTIFICATION_KEY = 'checkout_send_notification';

export function useCheckout(input: CheckoutInput) {
    const masterContext = useMaster();
    const [ state, dispatch ] = useReducer(checkoutReducer, { input, masterContext }, computeInitialState);

    return {
        state,
        dispatch,
    };
}

export type CheckoutState = {
    cache: CheckoutCache;
    input: CheckoutInput;
    phase: CheckoutPhase;
    invoicePreview?: InvoicePreviewInit;
    overview: {
        form: CheckoutOverviewForm;
        formErrors?: Record<string, string | undefined>;
        wasSubmitted?: boolean;
    };
    emailPreview: EmailPreviewState;
};

type CheckoutOverviewForm = {
    paymentMethod: PaymentMethod | undefined;
    sendNotification: boolean;
};

export type EmailPreviewState = {
    isMultipleClients: boolean;
    /** If true, an invoice should be issued. Otherwise a receipt will do. */
    isInvoicing: boolean;
    form: EmailData;
    formErrors?: Record<string, string | undefined>;
    wasSubmitted?: boolean;
    defaults: EmailData;
    isChanged: boolean;
    unusedVariables: EmailVariable[];
};

function computeInitialState({ input, masterContext }: { input: CheckoutInput, masterContext: MasterContext }): CheckoutState {
    const cache = computeCache(input);
    // There are exactly two states - noInvoice (if it's free) or everything else (if it isn't).
    const paymentMethod = cache.paymentOptions === undefined
        ? PaymentMethod.noInvoice
        : getSupportedPaymentMethod(cache.paymentOptions, cache.currencyIds, masterContext);
    const sendNotification = determineSendNotification(paymentMethod) ?? localStorage.get<boolean>(SEND_NOTIFICATION_KEY) ?? true;

    return {
        cache,
        input,
        phase: CheckoutPhase.Overview,
        overview: {
            form: { paymentMethod, sendNotification },
        },
        emailPreview: computeInitialEmailPreviewState(cache.clients, masterContext),
    };
}

export enum CheckoutPhase {
    Overview = 'overview',
    EmailPreview = 'emailPreview',
}

export type CheckoutAction = PhaseAction | OverviewAction | ValidationAction | InvoicePreviewAction | EmailPreviewAction;

function checkoutReducer(state: CheckoutState, action: CheckoutAction): CheckoutState {
    console.log('Reduce:', state, action);

    switch (action.type) {
    case 'phase':
        return phase(state, action);
    case 'overview':
        return overview(state, action);
    case 'validation':
        return validation(state, action);
    case 'invoicePreview':
        return invoicePreview(state, action);
    case 'emailPreview':
        return { ...state, emailPreview: emailPreviewReducer(state.emailPreview, action) };
    }
}

type PhaseAction = TypedAction<'phase', {
    phase: CheckoutPhase;
}>;

function phase(state: CheckoutState, action: PhaseAction): CheckoutState {
    if (action.phase === CheckoutPhase.EmailPreview)
        return { ...state, phase: action.phase, emailPreview: { ...state.emailPreview, formErrors: undefined, wasSubmitted: false } };

    const emailPreview = validateEmailPreview(state.emailPreview);
    const phase = emailPreview.formErrors ? state.phase : action.phase;

    return { ...state, emailPreview, phase };
}

type OverviewAction = TypedAction<'overview', {
    field: 'paymentMethod';
    value: PaymentMethod;
} | {
    field: 'sendNotification';
    value: boolean;
}>;

function overview(state: CheckoutState, action: OverviewAction): CheckoutState {
    const { form, formErrors } = Updator.update(state.overview, action.field, action.value, state.overview.wasSubmitted ? overviewRules : undefined);
    if (action.field === 'paymentMethod')
        form.sendNotification = determineSendNotification(action.value) ?? form.sendNotification;

    return { ...state, overview: { ...state.overview, form, formErrors } };
}

const overviewRules: RulesDefinition<CheckoutState['overview']['form']> = {
    paymentMethod: (value: unknown) => !!value || 'common:form.payment-method-required',
};

type ValidationAction = TypedAction<'validation', {
    formErrors: Record<string, string | undefined>;
}>;

function validation(state: CheckoutState, { formErrors }: ValidationAction): CheckoutState {
    return { ...state, overview: { ...state.overview, formErrors, wasSubmitted: true } };
}

// TODO This is just a hack because the whole sync function is in the component, not here. Not ideal.
export function validateOverview(state: CheckoutState): Record<string, string | undefined> | undefined {
    return Validator.validate(state.overview.form, overviewRules);
}

type InvoicePreviewAction = TypedAction<'invoicePreview', {
    invoice?: InvoiceSummary;
}>;

function invoicePreview(state: CheckoutState, { invoice }: InvoicePreviewAction): CheckoutState {
    if (!invoice)
        return { ...state, invoicePreview: undefined };

    return { ...state, invoicePreview: createInvoicePreviewInit(invoice, state.input, state.overview.form) };
}

function createInvoicePreviewInit(invoice: InvoiceSummary, input: CheckoutInput, form: CheckoutOverviewForm): InvoicePreviewInit {
    const common = {
        client: invoice.client,
        discountItems: invoice.discountItems,
        // the BE creates a dummy bank account if none for the currency is available
        paymentMethod: form.paymentMethod ?? PaymentMethod.bankTransfer,
        notification: undefined,
    };

    if (input.type === CheckoutType.Custom) {
        const init: CustomOrderInit = {
            ...common,
            dueDays: input.dueDays,
            // TODO this "as"
            items: invoice.items as FECustomItemInit[],
        };
        return { type: input.type, init };
    }

    if (input.type === CheckoutType.Product) {
        const init: ProductOrderInit = {
            ...common,
            guest: input.guest,
            // TODO this "as"
            items: invoice.items as ProductItemInit[],
            scheduler: input.scheduler,
        };
        return { type: input.type, init };
    }

    const init: EventOrderInitFE = {
        paymentMethod: common.paymentMethod,
        notification: common.notification,
        forClients: [ {
            info: getClientOrContact(invoice.client) as ClientInfoFE,
            participants: (invoice.items as EventParticipantItem[]),
        } ],
    };
    return { type: input.type, init };
}

export function createCustomInvoicePreviewInit(input: CustomCheckoutInput, paymentMethod: PaymentMethod): CustomOrderInit {
    const invoice = computeCustomInvoice(input.items, input.client, input.discount);
    const invoiceInit = createInvoicePreviewInit(invoice, input, { paymentMethod, sendNotification: true });
    return invoiceInit.init as CustomOrderInit;
}

// Email preview

export function computeInitialEmailPreviewState(clients: Participant[], { appUser, settings, teamSettings }: MasterContext): EmailPreviewState {
    const isInvoicing = teamSettings.isInvoicingEnabled;
    //
    // TODO multiple clients
    //

    const client = clients[0];
    // Here we specifically want the client locale, not the "fixLocale".
    const lng = getParticipantLocale(client, settings);
    const user = getPersonName(appUser);

    const isMultipleClients = clients.length > 1;

    const prefix = `common:emailPreview.${isInvoicing ? 'invoice' : 'receipt'}`;
    const emailData: EmailData = {
        email: isMultipleClients ? '' : getParticipantEmail(client),
        cc: '',
        subject: i18next.t(`${prefix}.subject`, { lng, user }),
        body: i18next.t(`${prefix}.body`, {
            lng,
            user,
            ...emailVariables,
        }),
    };

    return {
        isMultipleClients,
        isInvoicing,
        form: emailData,
        defaults: emailData,
        isChanged: false,
        unusedVariables: [],
    };
}

export type EmailPreviewAction = TypedAction<'emailPreview', {
    operation: 'reset';
} | {
    field: 'email' | 'cc' | 'subject' | 'body';
    value: string;
}>;

type EmailData = {
    email: string;
    cc: string;
    subject: string;
    body: string;
};

export function emailPreviewReducer(state: EmailPreviewState, action: EmailPreviewAction): EmailPreviewState {
    if ('operation' in action) {
        return {
            ...state,
            form: state.defaults,
            formErrors: undefined,
            isChanged: false,
            unusedVariables: [],
        };
    }

    const { form, formErrors } = Updator.update(state, action.field, action.value, state.wasSubmitted ? getEmailRules(state.isMultipleClients) : undefined);

    return {
        ...state,
        form,
        formErrors,
        isChanged: isEmailPreviewChanged(form, state.defaults),
        unusedVariables: action.field === 'body' ? computeUnusedVariables(form.body, state.isInvoicing) : state.unusedVariables,
    };
}

export function validateEmailPreview(state: EmailPreviewState): EmailPreviewState {
    const formErrors = Validator.validate(state.form, getEmailRules(state.isMultipleClients));

    return { ...state, formErrors, wasSubmitted: true };
}

function getEmailRules(isMultipleClients: boolean): RulesDefinition<EmailData> {
    return isMultipleClients ? emailRulesCommon : emailRulesFull;
}

const emailRulesCommon: RulesDefinition<EmailData> = {
    cc: (value: unknown) => {
        const emails = parseCcEmails('' + value);
        if (emails.length > 3)
            return 'common:form.cc-more-than-3';

        for (let i = 0; i < emails.length; i++) {
            if (!isEmail(emails[i]))
                return `common:form.cc-${i}-invalid`;
        }

        return true;
    },
    subject: (value: unknown) => value !== '' || 'common:form.subject-required',
    body: (value: unknown) => value !== '' || 'common:form.body-required',
};

const emailRulesFull: RulesDefinition<EmailData> = {
    email: (value: unknown) => {
        if (value === '')
            return 'common:form.email-required';

        return isEmail('' + value) || 'common:form.email-invalid';
    },
    ...emailRulesCommon,
};

export function parseCcEmails(value: string): string[] {
    return value
        .split('\n')
        .map(line => line.trim())
        .filter(line => line !== '');
}

function isEmailPreviewChanged(data: EmailData, defaults: EmailData): boolean {
    return data.email !== defaults.email
        || data.cc !== defaults.cc
        || data.subject !== defaults.subject
        || data.body !== defaults.body;
}

function computeUnusedVariables(body: string, isInvoicing: boolean): EmailVariable[] {
    return [
        isInvoicing ? emailVariables.invoiceLink : emailVariables.receiptLink,
        emailVariables.paymentLink,
    ].filter(variable => !body.includes(variable));
}

export type CheckoutCache = {
    items: CacheItem[];
    clients: Participant[];
    currencyIds: Id[];
    invoicesForClients: InvoicesForClient[];
    overviewForCurrencies: OverviewForCurrency[];
    /** If undefined, no selection is available so the noInvoice option has to be used instead. */
    paymentOptions: PaymentMethod[] | undefined;
};

export type CacheItem = FECustomItemInit | ProductItemInit | CacheEventItem;

function computeCache(input: CheckoutInput): CheckoutCache {
    switch (input.type) {
    case CheckoutType.Custom:
        return computeCustomCache(input);
    case CheckoutType.Product:
        return computeProductCache(input);
    case CheckoutType.Event:
        return computeEventCache(input);
    }
}

// The order of the payment options is important. We want the payment gateways to be first and bank transfer last.
const defaultPaymentOptions = [ PaymentMethod.stripe, PaymentMethod.paypal, PaymentMethod.bankTransfer ];

type InvoiceSummary = {
    type: CheckoutType;
    client: Participant;
    // TODO participants
    items: (FECustomItemInit | ProductItemInit | EventParticipantItem)[];
    price: Money;
    discountAmount: number;
    discountItems: DiscountItem[];
};

type InvoicesForClient = { client: Participant, invoices: InvoiceSummary[] };
type OverviewForCurrency = { totalPrice: Money, totalDiscount: Money, invoices: InvoiceSummary[] };

// Custom

function computeCustomCache(input: CustomCheckoutInput): CheckoutCache {
    const invoice = computeCustomInvoice(input.items, input.client, input.discount);
    const overview = {
        totalPrice: invoice.price,
        totalDiscount: { amount: invoice.discountAmount, currency: invoice.price.currency },
        invoices: [ invoice ],
    };

    return {
        items: input.items,
        currencyIds: [ input.currency.id ],
        clients: [ input.client ],
        invoicesForClients: [ {
            client: input.client,
            invoices: [ invoice ],
        } ],
        overviewForCurrencies: [ overview ],
        // We don't have to check for the free option here, because this is already checked in the custom order form.
        // The reason is that it doesn't make sense to have a custom order without invoice.
        paymentOptions: defaultPaymentOptions,
    };
}

function computeCustomInvoice(items: FECustomItemInit[], client: Participant, discount: Discount | undefined): InvoiceSummary {
    // TODO vat here?
    const currency = items[0].unitPrice.currency;
    const amountWithoutDiscount = items.reduce((ans, item) => ans + item.unitPrice.amount * item.quantity, 0);
    const discountItems = discount ? createDiscountItems(items, discount) : [];
    const discountAmount = discountItems.reduce((ans, item) => ans + item.price.amount, 0);
    const totalAmount = amountWithoutDiscount + discountAmount;

    return {
        type: CheckoutType.Custom,
        client,
        items,
        price: { amount: totalAmount, currency },
        discountAmount,
        discountItems,
    };
}

// Products

function computeProductCache(input: ProductCheckoutInput): CheckoutCache {
    const invoicesForClients = computeProductInvoices(input.items, input.client, input.discount);
    const allInvoices = invoicesForClients.flatMap(forClient => forClient.invoices);
    const overviewForCurrencies = computeInvoiceOverviews(allInvoices);
    const currencyIds = overviewForCurrencies.map(overview => overview.totalPrice.currency.id);

    // The overview can be empty because we filter out products without pricing.
    const isTooCheap = overviewForCurrencies.length === 0 || overviewForCurrencies.some(forCurrency => isUnderMinimalAmount(forCurrency.totalPrice));
    const paymentOptions = isTooCheap ? undefined : defaultPaymentOptions;

    return {
        items: input.items,
        currencyIds,
        clients: invoicesForClients.map(forClient => forClient.client),
        invoicesForClients,
        overviewForCurrencies,
        paymentOptions,
    };
}

function computeProductInvoices(allItems: ProductItemInit[], client: Participant, discount: Discount | undefined): InvoicesForClient[] {
    const nonFreeItems = allItems.filter(item => item.price);
    const itemsByCurrency = new Map<Id, ProductItemInit[]>();
    nonFreeItems.forEach(item => computeIfAbsent(itemsByCurrency, item.price!.currency.id, () => []).push(item));

    // TODO vat here?

    const invoicesWithoutClient = [ ...itemsByCurrency.values() ].map(items => {
        const currency = items[0].price!.currency;
        const amountWithoutDiscount = items.reduce((ans, item) => ans + item.price!.amount, 0);
        const discountItems = discount ? createDiscountItems(items, discount) : [];
        const discountAmount = discountItems.reduce((ans, item) => ans + item.price.amount, 0);
        const totalAmount = amountWithoutDiscount + discountAmount;

        return {
            items,
            price: { amount: totalAmount, currency },
            discountAmount,
            discountItems,
        };
    });

    const invoices = invoicesWithoutClient.map(invoice => ({ type: CheckoutType.Product, client, ...invoice }));
    return [ { client, invoices } ];
}

function computeInvoiceOverviews(allInvoices: InvoiceSummary[]): OverviewForCurrency[] {
    const overview = new Map<Id, InvoiceSummary[]>();
    allInvoices.forEach(invoice => computeIfAbsent(overview, invoice.price.currency.id, () => []).push(invoice));

    return [ ...overview.values() ].map(invoices => {
        const currency = invoices[0].price.currency;
        const amount = invoices.reduce((ans, invoice) => ans + invoice.price.amount, 0);
        const discountAmount = invoices.reduce((ans, invoice) => ans + invoice.discountAmount, 0);

        return {
            totalPrice: { amount, currency },
            totalDiscount: { amount: discountAmount, currency },
            invoices,
        };
    });
}

type DiscountableItem = FECustomItemInit | ProductItemInit;

/**
 * All items are expected to share the same currency.
 */
function createDiscountItems(allItems: DiscountableItem[], discount: Discount): DiscountItem[] {
    const commonItems = allItems.map(getCommonItem).filter((item): item is FECustomItemInit => !!item);
    const itemsByVat = new Map<Id, FECustomItemInit[]>();
    commonItems.forEach(item => computeIfAbsent(itemsByVat, item.vat.id, () => []).push(item));

    return [ ...itemsByVat.values() ].map(items => {
        const totalAmount = items.reduce((ans, item) => ans + item.unitPrice.amount * item.quantity, 0);
        const discountAmount = totalAmount * discount.amount;

        return {
            price: {
                amount: roundMoney(-discountAmount),
                currency: items[0].unitPrice.currency,
            },
            vat: items[0].vat,
            title: discount.title,
        };
    });
}

function getCommonItem(item: DiscountableItem): FECustomItemInit | undefined {
    if ('product' in item) {
        if (!item.price)
            return;

        return {
            unitPrice: item.price,
            quantity: 1,
            vat: item.vat,
            title: item.product.title,
        };
    }

    return item;
}

type CacheEventItem = {
    event: EventFE;
    clients: ClientInfoFE[];
};

function computeEventCache({ forClients }: EventCheckoutInput): CheckoutCache {
    const invoicesForClients = forClients.map(computeEventInvoicesForClient);
    const clients = invoicesForClients.map(forClient => forClient.client);

    const allInvoices = invoicesForClients.flatMap(forClient => forClient.invoices);
    const overviewForCurrencies = computeInvoiceOverviews(allInvoices);
    const currencyIds = overviewForCurrencies.map(overview => overview.totalPrice.currency.id);

    const isTooCheap = overviewForCurrencies.some(forCurrency => isUnderMinimalAmount(forCurrency.totalPrice));
    const paymentOptions = isTooCheap ? undefined : defaultPaymentOptions;

    return {
        // TODO ?
        items: [],
        clients,
        currencyIds,
        invoicesForClients,
        overviewForCurrencies,
        paymentOptions,
    };
}

function computeEventInvoicesForClient({ info, participants }: EventItemsForClient): InvoicesForClient {
    const itemsByCurrency = new Map<Id, EventParticipantItem[]>();

    participants.forEach(p => computeIfAbsent(itemsByCurrency, p.participant.payment.price.currency.id, () => []).push(p));

    const client: Participant = { info, identifier: getClientIdentifier(info) };

    const invoices: InvoiceSummary[] = [ ...itemsByCurrency.values() ].map(items => {
        const currency = items[0].participant.payment.price.currency;
        const amount = items.reduce((ans, item) => ans + item.participant.payment.price.amount, 0);

        return {
            type: CheckoutType.Event,
            client,
            items,
            price: { amount, currency },
            discountAmount: 0,
            discountItems: [],
        };
    });

    return { client, invoices };
}

/**
 * Returns the first supported payment method from the available ones.
 * @param available The available payment methods.
 * @param currencyIds The currency Ids for which the payment method should be supported.
 */
export function getSupportedPaymentMethod(available: PaymentMethod[], currencyIds: Id[], masterContext: MasterContext): PaymentMethod | undefined {
    for (const method of available) {
        if (isPaymentMethodSupported(method, currencyIds, masterContext))
            return method;
    }
}

export function isPaymentMethodSupported(method: PaymentMethod, currencyIds: Id[], masterContext: MasterContext): boolean {
    switch (method) {
    case PaymentMethod.bankTransfer:
        return currencyIds.every(id => isCurrencySupported(masterContext.bankAccounts, id));
    case PaymentMethod.stripe:
        return masterContext.team.isStripeConnected;
    case PaymentMethod.paypal:
        return masterContext.team.isPaypalConnected;
    default:
        return true;
    }
}

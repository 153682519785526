import { useEffect, useReducer, type Dispatch } from 'react';
import { type EventFE, type EventSync, type EventEditFE, SyncType, type EventDelete, type EventUpdate, type EventTransitionFE, type EventCreate, type EventInitFE, eventCreateToServer, eventUpdateToServer, eventTransitionToServer, type EventUpdateObject, type ProductEventInitFE, productEventToServer, EventsOperationOutputFE } from ':frontend/types/Event';
import { type EventParticipantFE, type PayingParticipant, type Participant, getClientIdentifier, type EditablePayingParticipant } from ':frontend/types/EventParticipant';
import { type Id } from ':utils/id';
import type { Selected } from ':frontend/pages/CalendarDetail';
import { DateTime } from 'luxon';
import { type TypedAction, roundDateToMinutes } from ':frontend/utils/common';
import type { ClientInfoFE } from ':frontend/types/Client';
import { toMaster, useUser, type UserContext } from ':frontend/context/UserProvider';
import useNotifications from ':frontend/context/NotificationProvider';
import { createErrorAlert, createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { type CurrencyFE, type TaxRateFE } from ':utils/money';
import { useNavigate } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { createActionState } from ':frontend/hooks';
import { Updator } from ':frontend/utils/updator';
import { ProductOrderItemFE, type GenericProductItem } from ':frontend/types/orders/ProductOrderItem';
import { createDraftEvent, type CalendarEvent, type DraftEventResource } from ':frontend/types/calendar/Calendar';
import { type TeamMemberFE } from ':frontend/types/Team';
import { computeEventDuration, type EventRemoval, type EventTransition, RecurrenceRange } from ':utils/entity/event';
import { trpc } from ':frontend/context/TrpcProvider';
import { toLimitedCount, type Recurrence } from ':utils/recurrence';
import type { PreselectEventOrder } from '../orders/useEventOrder';

export type UpdateEventsAction = {
    type: 'create' | 'update' | 'delete';
    events: EventFE[];
};

/**
 * This hook doesn't consider changes to the input. So, if the input changes, the component should be unmounted and mounted again.
 */
export function useEvent(input: Selected, onClose: (action?: UpdateEventsAction) => void, addClients: (clients: ClientInfoFE[]) => void) {
    const userContext = useUser();
    const { settings } = userContext;
    const [ state, dispatch ] = useReducer(eventReducer, { input, userContext }, computeInitialState);

    // When the userContext changes, we have to update it.
    useEffect(() => {
        dispatch({ type: 'userContext', userContext });
    }, [ userContext ]);

    // If the component should be closed, we call the onClose callback, then mark it as closed.
    useEffect(() => {
        if (state.close) {
            if (state.close.result)
                onClose(state.close.result);
            else if (state.update?.event)
                onClose({ type: 'update', events: [ state.update.event ] });
            else
                onClose();
        }
    }, [ state.close ]);

    useEffect(() => {
        if (state.sync?.fetching) {
            const sync = state.sync.eventSync;

            switch (sync.type) {
            case SyncType.Create:
                syncCreate(sync);
                break;
            case SyncType.Update:
            case SyncType.Transition:
                syncUpdateOrTransition(sync, state.event!);
                break;
            case SyncType.Delete:
                syncDelete(sync, state.event!);
                break;
            }
        }
    }, [ !!state.sync?.fetching ]);

    const { addAlert } = useNotifications();

    const createEventMutation = trpc.event.createEvent.useMutation();

    function syncCreate(action: EventCreate) {
        const data = eventCreateToServer(action, settings);
        createEventMutation.mutate(data, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
                dispatch({ type: 'error', operation: 'other', value: error.data });
            },
            onSuccess: response => {
                const result = EventsOperationOutputFE.fromServer(response);

                addAlert(createTranslatedSuccessAlert('common:eventsCreatedAlert', { count: result.events.length }));
                addClients(result.newClients);
                dispatch({ type: 'close', result: { type: 'create', events: result.events } });

                // The same as below, we navigate to the event order. This is not ideal (we should be able to open the checkout modal here), but it's the best we can do now.
                if (action.data.isBillLater) {
                    navigate(routesFE.directSale.event, { state: createActionState<PreselectEventOrder>('preselectEventOrder', {
                        eventIds: result.events.map(e => e.id),
                    }) });
                }
            },
        });
    }

    const updateEventMutation = trpc.event.updateEvent.useMutation();

    const navigate = useNavigate();

    function syncUpdateOrTransition(action: EventUpdate | EventTransitionFE, event: EventFE) {
        // TODO fix deleting whole payment
        // Now, we should enable it only if there are no orders yet.

        const data = action.type === SyncType.Transition
            ? eventTransitionToServer(action)
            : eventUpdateToServer(action, settings);
        updateEventMutation.mutate(data, {
            onError: error => {
                addAlert(createTranslatedErrorAlert());
                dispatch({ type: 'error', operation: 'other', value: error.data });
            },
            onSuccess: response => {
                const result = EventsOperationOutputFE.fromServer(response);
                addAlert(createTranslatedSuccessAlert('components:eventSidebar.updateSuccessAlert'));

                if ('contactParticipants' in data && data.contactParticipants)
                    addClients(result.newClients);

                dispatch({ type: 'close', result: { type: 'update', events: result.events } });

                // If we updated because the user wanted to bill the event, we have to navigate to the event order so that the user can actually create the order.
                if (action.type === SyncType.Update && action.data.isBillLater) {
                    navigate(routesFE.directSale.event, { state: createActionState<PreselectEventOrder>('preselectEventOrder', {
                        eventIds: [ event.id ],
                    }) });
                }
            },
        });
    }

    const deleteEventMutation = trpc.event.deleteEvent.useMutation();

    function syncDelete(action: EventDelete, event: EventFE) {
        const removal: EventRemoval = {
            id: event.id,
            sendNotification: action.sendNotification,
            range: action.range ?? RecurrenceRange.This,
        };
        deleteEventMutation.mutate(removal, {
            onError: error => {
                addAlert(createTranslatedErrorAlert());
                dispatch({ type: 'error', operation: 'other', value: error.data });
            },
            onSuccess: response => {
                const deletedEvents = EventsOperationOutputFE.fromServer(response).events;
                addAlert(createTranslatedSuccessAlert('components:eventSidebar.deleteSuccessAlert', { count: deletedEvents.length }));

                dispatch({ type: 'close', result: { type: 'delete', events: deletedEvents } });
            },
        });
    }

    return {
        state,
        dispatch,
    };
}

export type UseEventState = {
    /** The original input passed from the calendar. */
    input: Selected;
    userContext: UserContext;
    /** The current event that is saved on BE. It should be updated whenever we synchronize with BE. */
    event?: EventFE;
    form: EventFormState;
    /** The event is being updated. E.g., we are waiting for the user to confirm if he wants to send the notification. */
    sync?: SyncState;
    /** The event has a payment set. */
    payment?: PaymentState;
    /** The event is closed. The close callback is fired when this phase begins. */
    close?: {
        /** The action will be returned to the calendar when the component is closed. */
        result?: UpdateEventsAction;
        // TODO is this still used?
        // TODO if yes, move it to the update object.
    };
    error?: ErrorState;
    /** Updates that should be propagated back when the event is closed. */
    update?: UpdateState;
    isConfirmClose?: boolean;
}

type EventFormState = {
    title: string;
    description: string;
    start: DateTime;
    end: DateTime;
    recurrence?: Recurrence;
    locationId?: Id;
    guests: Participant[];
    /** A way how to set notes on the event during its creation. */
    initialNotes: string;
    scheduler?: TeamMemberFE;
};

function computeInitialState({ input, userContext }: { input: Selected, userContext: UserContext }): UseEventState {
    if ('draft' in input) {
        return {
            input,
            userContext,
            form: computeInitialDraftForm(input.draft),
        };
    }

    const event = input.event.resource.event;
    const start = input.changes?.start ?? event.start;
    const end = input.changes?.end ?? event.end;
    const guests = event.guests.map(guest => ({ info: guest.client, identifier: getClientIdentifier(guest.client) }));

    return {
        input,
        userContext,
        event,
        form: {
            title: event.title,
            description: event.description,
            locationId: event.locationId,
            start,
            end,
            guests,
            initialNotes: '',
        },
        payment: computeInitialPaymentState(event, guests),
    };
}

function computeInitialDraftForm(draft: CalendarEvent<DraftEventResource>): EventFormState {
    return {
        title: '',
        description: '',
        start: draft.start,
        end: draft.end,
        guests: [],
        initialNotes: '',
        scheduler: undefined,
    };
}

export type UseEventDispatch = Dispatch<EventAction>;

type EventAction = CloseAction | UserContextAction | InputAction | SyncAction | PaymentAction | ErrorAction | UpdateAction | ConfirmCloseAction;

function eventReducer(state: UseEventState, action: EventAction): UseEventState {
    console.log('Reduce:', state, action);

    switch (action.type) {
    case 'close': return { ...state, sync: undefined, close: { result: action.result } };
    case 'userContext': return { ...state, userContext: action.userContext };
    case 'input': return input(state, action);
    case 'sync': return sync(state, action);
    case 'payment': return payment(state, action);
    case 'error': return error(state, action);
    case 'update': return update(state, action);
    case 'confirmClose': return confirmClose(state, action);
    }
}

// Close

type CloseAction = TypedAction<'close', {
    result?: UpdateEventsAction;
}>;

// UserContext

type UserContextAction = TypedAction<'userContext', {
    userContext: UserContext;
}>;

// Input

type InputAction = TypedAction<'input', {
    field: 'title' | 'description' | 'initialNotes';
    value: string;
} | {
    field: 'locationId';
    value: Id | undefined;
} | {
    field: 'start' | 'end';
    value: DateTime;
} | {
    field: 'recurrence';
    value?: Recurrence;
} | {
    field: 'guests';
    value: Participant[];
} | {
    field: 'scheduler';
    value: TeamMemberFE;
}>;

function input(state: UseEventState, action: InputAction): UseEventState {
    // If the title is valid, we reset the potential invalid title error.
    const error = (action.field === 'title' && action.value !== '') ? undefined : state.error;
    const duration = computeEventDuration(state.form);

    const { form } = Updator.update(state, action.field, action.value);
    const newState = { ...state, form, error };

    // When the start date changes, the duration should stay the same. Therefore, we have to change the end date as well.
    if (action.field === 'start')
        form.end = form.start.plus({ seconds: duration });
    // When the end date changes, the start date stays the same.

    const doUpdatePayment = action.field === 'guests' && state.payment?.isLinked;
    if (!doUpdatePayment)
        return newState;

    // If some of the clients are already in an order, we can't change them.
    // TODO move this to some central "what can be edited" variable.
    const isInOrder = state.event?.clients.some(c => c.isInOrder);
    if (isInOrder)
        return newState;

    return { ...newState, payment: createLinkedPayment(newState) };
}

// Synchronization

const FID_NONE = 'none';

export type SyncState = {
    /** The event is being saved. The request is fired when this becomes true. This is an identifier of the button that caused the fetch. */
    fetching?: string;
    eventSync: EventSync;
    phase: SyncModal;
    modalData: ModalData;
};

export enum SyncModal {
    Notification = 'notification',
    Recurrence = 'recurrence',
    ConfirmDelete = 'confirmDelete',
    /** @deprecated Not possible. */
    ConfirmInvoice = 'confirmInvoice',
    /** We don't need any new information, therefore we can start fetching without any modal. */
    None = 'none',
}

/** This needs to be filled before we can start fetching. */
type ModalData = {
    /** @deprecated Always true. */
    isInvoiceConfirmed?: boolean;
    range?: RecurrenceRange;
    isSendNotification?: boolean;
    isDeleteConfirmed?: boolean;
}

type SyncAction = TypedAction<'sync', {
    operation: SyncOperation;
    fid?: string;
}>;

export type SyncOperation = {
    type: 'start';
    isBillLater: boolean;
    sendNotification?: boolean;
} | {
    type: 'transition';
    transition: EventTransition;
} | {
    type: 'delete';
} | ModalSyncOperation;

function sync(state: UseEventState, { operation, fid }: SyncAction): UseEventState {
    fid = fid ?? FID_NONE;

    // If we are already fetching (or closing), we don't do anything.
    if (state.sync?.fetching || state.close)
        return state;

    if (operation.type === 'start')
        return createOrUpdateSync(fid, state, operation.isBillLater, operation.sendNotification);

    if (operation.type === 'transition' || operation.type === 'delete')
        return deleteOrTransitionSync(fid, state, operation);

    return modalSync(fid, state, operation);
}

function createOrUpdateSync(fid: string, state: UseEventState, isBillLater: boolean, sendNotification?: boolean): UseEventState {
    const change = getChange(state);
    if (change.isInvalid)
        return { ...state, error: { invalidForm: 'title' } };

    // This should not happen, the buttons should be disabled. However, ...
    if (change.type === 'none')
        return state;

    const modalData: ModalData = {
        isSendNotification: sendNotification ?? (!change.isPublic ? false : undefined),
        isInvoiceConfirmed: !change.isInvoice,
        isDeleteConfirmed: true,
    };

    if (change.type === 'create') {
        const data = createInitObject(state, isBillLater);
        const eventSync: EventSync = { type: SyncType.Create, data };
        modalData.range = RecurrenceRange.This;

        return startSync(fid, eventSync, state, modalData);
    }

    if (!state.event)
        throw new Error('Invalid event state');

    const data = createEditObject(state.event, state, isBillLater);
    const eventSync: EventSync = { type: SyncType.Update, data };
    modalData.range = state.event.recurrenceIndex === undefined ? RecurrenceRange.This : undefined;

    return startSync(fid, eventSync, state, modalData);
}

type ChangeState = {
    type: 'create' | 'update' | 'none';
    /** The form is in an invalid state. */
    isInvalid: boolean;
    isPublic: boolean;
    isPayment: boolean;
    isNewGuests: boolean;
    /**
     * There is a change that will affect an existing invoice.
     * @deprecated This shouldn't be possible in the current display (the price of clients with orders can't be edited). So this is always false.
     */
    isInvoice: boolean;
};

export function getChange(state: UseEventState): ChangeState {
    const { form, payment } = state;
    const isInvalid = form.title === '';

    const event = state.event;
    if (!event) {
        const isPayment = !!payment?.clients?.length;
        if (isFormInDefaultState(form) && !isPayment)
            return { type: 'none', isInvalid, isPublic: false, isPayment, isNewGuests: false, isInvoice: false };

        const isPublic = form.guests.length > 0;
        return { type: 'create', isInvalid, isPublic, isPayment, isNewGuests: isPublic, isInvoice: false };
    }

    const privateChanged = isClientsChanged(event.clients, payment?.clients ?? []);
    const publicDataChanged =
        form.title !== event.title
        || form.description !== event.description
        || !form.start.equals(event.start)
        || !form.end.equals(event.end)
        || form.locationId !== event.locationId;
    const { isGuestsChanged, isNewGuests } = getGuestChange(event.guests, form.guests);

    const isAny = privateChanged || publicDataChanged || isGuestsChanged;
    const isPublic = (publicDataChanged && form.guests.length > 0) || isGuestsChanged;
    const isPayment = privateChanged;
    const isInvoice = !!payment?.clients.some(fc => fc.original && fc.original.isInOrder && fc.original.payment.price.amount !== fc.price);
    const type = isAny ? 'update' : 'none';

    return { type, isInvalid, isPublic, isPayment, isNewGuests, isInvoice };
}

function isFormInDefaultState(form: EventFormState) {
    return form.title === ''
        && form.description === ''
        && !form.recurrence
        && !form.locationId
        && form.guests.length === 0;
}

function getGuestChange(oldGuests: EventParticipantFE[], formGuests: Participant[]): { isGuestsChanged: boolean, isNewGuests: boolean } {
    // TODO we should probably find out which guests changed and then send the notification only to them.
    const guestsIds = new Set(oldGuests.map(g => g.client.id));
    const isNewGuests = formGuests.some(fg => !guestsIds.has(fg.identifier));
    const isGuestsChanged = isNewGuests || oldGuests.length !== formGuests.length;

    return { isGuestsChanged, isNewGuests };
}

function isClientsChanged(clients: PayingParticipant[], formClients: EditablePayingParticipant[]) {
    if (clients.length !== formClients.length)
        return true;

    const clientsById = new Map(clients.map(c => [ c.client.id, c ]));
    return formClients.some(fc => {
        const client = clientsById.get(fc.identifier);
        return !client
            || client.payment.price.amount !== fc.price
            || client.payment.price.currency !== fc.currency
            || client.payment.vat !== fc.vat;
    });
}

function startSync(fid: string, eventSync: EventSync, state: UseEventState, modalData: ModalData): UseEventState {
    const phase = getNextModalPhase(modalData);

    const sync: SyncState = phase === SyncModal.None
        ? { eventSync: mergeModalData(eventSync, modalData), modalData, phase, fetching: fid }
        : { eventSync, modalData, phase };

    return { ...state, sync };
}

/** Decides which modal should be shown based on the modal data. */
function getNextModalPhase(modalData: ModalData): SyncModal {
    // First, we ask whether the user is ok with the fact that the invoice will be changed together with the event.
    if (!modalData.isInvoiceConfirmed)
        return SyncModal.ConfirmInvoice;

    // Then we ask about the range.
    if (!modalData.range)
        return SyncModal.Recurrence;

    // Then we ask about sending the notification.
    if (modalData.isSendNotification === undefined)
        return SyncModal.Notification;

    // If we are deleting, we have to show at least one modal.
    if (!modalData.isDeleteConfirmed)
        return SyncModal.ConfirmDelete;

    // No need to ask anything. We can start fetching.
    return SyncModal.None;
}

function mergeModalData(eventSync: EventSync, modalData: ModalData): EventSync {
    return {
        ...eventSync,
        range: modalData.range,
        sendNotification: modalData.isSendNotification,
    };
}

function createInitObject({ form, payment }: Pick<UseEventState, 'form' | 'payment'>, isBillLater: boolean): EventInitFE {
    return {
        title: form.title,
        description: form.description,
        locationId: form.locationId,
        start: form.start,
        end: form.end,
        recurrence: form.recurrence,
        guests: form.guests,
        clients: payment?.clients ?? [],
        initialNotes: form.initialNotes,
        isBillLater,
        scheduler: form.scheduler,
    };
}

function createEditObject(event: EventFE, { form, payment }: UseEventState, isBillLater: boolean): EventEditFE {
    return {
        event,
        title: form.title,
        description: form.description,
        locationId: form.locationId,
        start: form.start,
        end: form.end,
        guests: form.guests,
        clients: payment?.clients ?? [],
        isBillLater,
    };
}

function deleteOrTransitionSync(fid: string, state: UseEventState, operation: { type: 'transition', transition: EventTransition } | { type: 'delete' }): UseEventState {
    const event = state.event;
    if (!event)
        throw new Error('Invalid event state');

    const isInvoiceConfirmed = true;
    const range = event.recurrenceIndex === undefined ? RecurrenceRange.This : undefined;

    if (operation.type === 'transition') {
        const eventSync: EventSync = { type: SyncType.Transition, data: { event, transition: operation.transition } };
        return startSync(fid, eventSync, state, {
            isSendNotification: event.guests.length > 0,
            isInvoiceConfirmed,
            range,
            isDeleteConfirmed: true,
        });
    }

    // It has to be delete. For it we don't want to send notifications..
    const eventSync: EventSync = { type: SyncType.Delete, data: undefined };
    return startSync(fid, eventSync, state, {
        isSendNotification: false,
        isInvoiceConfirmed,
        range,
        isDeleteConfirmed: false,
    });
}

type ModalSyncOperation = {
    type: SyncModal.ConfirmInvoice | SyncModal.ConfirmDelete;
    result: 'back' | 'confirm';
} | {
    type: SyncModal.Recurrence;
    result: 'back' | RecurrenceRange;
} | {
    type: SyncModal.Notification;
    result: 'back' | 'send' | 'dont-send';
};

function modalSync(fid: string, state: UseEventState, { type, result }: ModalSyncOperation): UseEventState {
    if (result === 'back')
        return { ...state, sync: undefined };

    // Some invalid state - it shouldn't be possible to get here.
    if (!state.sync)
        return state;

    const modalData = { ...state.sync.modalData };

    // Any modal is enough to confirm deletion.
    modalData.isDeleteConfirmed = true;

    if (type === SyncModal.ConfirmInvoice)
        modalData.isInvoiceConfirmed = true;
    else if (type === SyncModal.Recurrence)
        modalData.range = result;
    else if (type === SyncModal.Notification)
        modalData.isSendNotification = result === 'send';

    const phase = getNextModalPhase(modalData);
    const sync: SyncState = phase === SyncModal.None
        // If we start fetching, we keep the old phase so that the modal stays open.
        ? { ...state.sync, modalData, eventSync: mergeModalData(state.sync.eventSync, modalData), fetching: fid }
        : { ...state.sync, modalData, phase };

    return { ...state, sync };
}

// Payment

export type PaymentState = {
    isLinked: boolean;
    clients: EditablePayingParticipant[];
    price: number | '';
    currency: CurrencyFE;
    vat: TaxRateFE;
};

type PaymentAction = TypedAction<'payment', {
    operation: 'create';
} | {
    operation: 'link';
    value: boolean;
} | {
    operation: 'price';
    value: number | '';
} | {
    operation: 'currency';
    value: CurrencyFE;
} | {
    operation: 'vat';
    value: TaxRateFE;
} | {
    operation: 'clients';
    value: Participant[];
} | {
    operation: 'clientPrice';
    client: Participant;
    value: number | '';
} | {
    operation: 'clientRemove';
    client: Participant;
}>;

function payment(state: UseEventState, action: PaymentAction): UseEventState {
    return { ...state, payment: onlyPayment(state, action) };
}

function onlyPayment(state: UseEventState, action: PaymentAction): PaymentState | undefined {
    const operation = action.operation;
    if (operation === 'create') {
        const linkedState = createLinkedPayment(state);
        return state.form.guests.length > 0
            ? linkedState
            // If there are no guests, the default option is unlinked payment.
            : { ...linkedState, clients: [], isLinked: false };
    }

    const payment = state.payment;
    if (!payment)
        return undefined; // TODO error

    switch (operation) {
    case 'link': {
        return action.value
            ? createLinkedPayment(state)
            // The most common use case is that the user wants to select only one client.
            // Therefore, when unlinking, we want to remove all current clients.
            : { ...payment, clients: [], isLinked: false };
    }
    case 'price': {
        const price = action.value;
        // We have to update the price of the clients (if they are linked).
        const clients = payment.isLinked
            ? payment.clients.map(c => ({ ...c, price }))
            : payment.clients;
        return { ...payment, price, clients };
    }
    case 'currency': {
        const currency = action.value;
        // We have to update the currency of the clients (even if they are not linked, because the currency can't be changed individually).
        const clients = payment.clients.map(c => ({ ...c, currency }));
        return { ...payment, currency, clients };
    }
    case 'vat': {
        const vat = action.value;
        // We have to update the vat of the clients (even if they are not linked, because the vat can't be changed individually).
        const clients = payment.clients.map(c => ({ ...c, vat }));
        return { ...payment, vat, clients };
    }
    case 'clients': {
        // If there are no clients, we don't want to force any specific currency so that the client's currency can be used.
        // The second condition is here because if there were no clients and then multiple were added all at once, they might have different currencies. So, in that case, we want to use the default currency (it shouldn't be possible, so this is just to be sure).
        const initialCurrency = (payment.clients.length > 0 || action.value.length > 1) ? payment.currency : undefined;
        const masterContext = toMaster(state.userContext);
        if (!masterContext)
            throw new Error(`Invalid user role ${state.userContext.role}`);

        const clients = action.value.map(c => {
            // If the client exists, we use him.
            const client = payment.clients.find(client => client.identifier === c.identifier);
            if (client)
                return client;

            return newEditableParticipant(c, {
                price: 0,
                currency: initialCurrency ?? masterContext.teamSettings.currency,
                vat: payment.vat,
            });
        });

        // If the initial currency wasn't set, we let the first client decide it.
        const newCurrency = initialCurrency ?? clients[0]?.currency ?? payment.currency;

        return { ...payment, clients, currency: newCurrency };
    }
    case 'clientPrice': {
        const clients = payment.clients.map(c => c.identifier !== action.client.identifier ? c : { ...c, price: action.value });
        return { ...payment, clients };
    }
    case 'clientRemove': {
        const clients = payment.clients.filter(c => c.identifier !== action.client.identifier);
        // The clients can be unlinked this way.
        return { ...payment, clients, isLinked: false };
    }
    }
}

function createLinkedPayment({ form, payment, userContext }: UseEventState): PaymentState {
    const masterContext = toMaster(userContext);
    if (!masterContext)
        throw new Error(`Invalid user role ${userContext.role}`);

    const paymentData: PaymentDataInit = payment !== undefined
        ? { price: payment.price, currency: payment.currency, vat: payment.vat }
        : {
            price: 0,
            currency: masterContext.teamSettings.currency,
            vat: masterContext.teamSettings.taxRate,
        };

    // TODO Here we can find vat for each guest based on his invoicing profile. But we are not doing that yet.
    const clients = form.guests.map(guest => newEditableParticipant(guest, paymentData));

    return { isLinked: true, clients, ...paymentData };
}

function computeInitialPaymentState(event: EventFE, guests: Participant[]): PaymentState | undefined {
    if (event.clients.length === 0)
        return;

    const clients: EditablePayingParticipant[] = event.clients.map(payingParticipantToEditable);
    const firstClient = clients[clients.length - 1];

    return {
        isLinked: isPaymentLinked(guests, clients),
        clients,
        price: firstClient.price,
        currency: firstClient.currency,
        vat: firstClient.vat,
    };
}

function payingParticipantToEditable(participant: PayingParticipant): EditablePayingParticipant {
    return {
        info: participant.client,
        identifier: getClientIdentifier(participant.client),
        price: participant.payment.price.amount,
        currency: participant.payment.price.currency,
        vat: participant.payment.vat,
        original: participant,
    };
}

type PaymentDataInit = {
    price: number | '';
    currency: CurrencyFE;
    vat: TaxRateFE;
};

function newEditableParticipant(participant: Participant, data: PaymentDataInit): EditablePayingParticipant {
    return {
        ...participant,
        ...data,
        original: undefined,
    };
}

function isPaymentLinked(guests: Participant[], clients: EditablePayingParticipant[]): boolean {
    if (guests.length !== clients.length)
        return false;

    const price = clients[clients.length - 1].price;
    if (clients.some(c => c.price !== price))
        return false;

    const guestIds: Set<string> = new Set();
    guests.forEach(g => guestIds.add(g.identifier));

    return clients.every(c => guestIds.has(c.identifier));
}

// Error

type ErrorState = {
    invalidForm?: 'title';
    /** Fallback for all other errors. */
    other?: unknown;
};

type ErrorAction = TypedAction<'error', {
    operation: 'reset';
} | {
    operation: 'invalidForm';
    value: 'title' | undefined;
} | {
    operation: 'other';
    value: unknown | undefined;
}>;

function error(state: UseEventState, action: ErrorAction): UseEventState {
    switch (action.operation) {
    case 'reset': return { ...state, error: undefined };
    case 'invalidForm': return createErrorState(state, { invalidForm: action.value } );
    case 'other': return createErrorState(state, { other: action.value } );
    }
}

function createErrorState(state: UseEventState, error: ErrorState): UseEventState {
    // In any case except reset, we want to stop the current sync process so that there is no fetching modal displayed.
    return { ...state, sync: undefined, error };
}

// Update

type UpdateState = {
    /** The last version of the event there is. */
    event?: EventFE;
}

type UpdateAction = TypedAction<'update', {
    /** Which event should be updated. Usually it's the currently open one, but it can be the previous. */
    eventId: Id;
} & ({
    participant: EventParticipantFE;
} | {
    notes: string;
})>;

function update(state: UseEventState, action: UpdateAction): UseEventState {
    if (!state.event)
        return state;

    const lastEvent = state.update?.event ?? state.event;
    const updateObject = createUpdateObject(lastEvent, action);
    const updatedEvent = lastEvent.toUpdated(updateObject);
    const payment = updatePayment(state, action);

    if (!state.close && state.event?.id === action.eventId)
        return { ...state, payment, update: { event: updatedEvent } };

    // The event is not opened anymore. Probably because it was closed. Or another event was opened instead. But there is still an update to be propagated.
    // This is not the best, because we use the close action to propagate the update. But it's the easiest.
    return { ...state, payment, close: { result: { type: 'update', events: [ updatedEvent ] } } };
}

function createUpdateObject(event: EventFE, action: UpdateAction): EventUpdateObject {
    if ('notes' in action)
        return { notes: action.notes };

    const participant = action.participant;
    const guests = event.guests.map(guest => guest.id === participant.id ? participant : guest);
    // TODO paying - move the participant accordingly between categories.
    const clients = event.clients.map(client => client.id === participant.id ? participant : client) as PayingParticipant[];

    return { guests, clients };
}

function updatePayment({ payment }: UseEventState, action: UpdateAction): PaymentState | undefined {
    if (!payment || !('participant' in action) || !action.participant.payment)
        return payment;

    const update = action.participant as PayingParticipant;

    const clients = payment.clients.map(client => client.original?.id === update.id ? {
        ...payingParticipantToEditable(update),
        price: client.price,
    } : client);

    return { ...payment, clients };
}

// Confirm close

type ConfirmCloseAction = TypedAction<'confirmClose', {
    value: 'start' | 'back' | 'discard';
}>;

function confirmClose(state: UseEventState, action: ConfirmCloseAction): UseEventState {
    if (action.value === 'start') {
        const change = getChange(state);
        if (change.type === 'none')
            return { ...state, close: {} };

        return { ...state, isConfirmClose: true };
    }

    if (action.value === 'back')
        return { ...state, isConfirmClose: false };

    return { ...state, close: {}, isConfirmClose: false };
}

// Just event form

export type UseEventFormState = {
    event?: EventFE;
    form: EventFormState;
    error?: ErrorState;
};

export type UseEventFormDispatch = Dispatch<InputAction>;

// Just event modal

export type UseEventModalDispatch = Dispatch<SyncAction>;

// Event scheduling

export function useScheduling(input: GenericProductItem, client: ClientInfoFE, onClose: (productItem: GenericProductItem) => void, addClients: (clients: ClientInfoFE[]) => void) {
    const { settings } = useUser();
    const [ state, dispatch ] = useReducer(schedulingReducer, { input, client }, computeInitialSchedulingState);

    const { addAlert } = useNotifications();

    useEffect(() => {
        if (state.sync?.fetching)
            syncSchedule(state.sync.eventSync);
    }, [ !!state.sync?.fetching ]);

    const createProductEventMutation = trpc.event.createProductEvent.useMutation();

    function syncSchedule(action: ProductEventInitFE) {
        const data = productEventToServer(action, settings);
        createProductEventMutation.mutate(data, {
            onError: error => {
                addAlert(createErrorAlert(error.data));
                dispatch({ type: 'error', operation: 'other', value: error.data });
            },
            onSuccess: response => {
                const result = EventsOperationOutputFE.fromServer(response);

                addAlert(createTranslatedSuccessAlert('common:eventsCreatedAlert', { count: result.events.length }));
                addClients(result.newClients);
                const updatedProductItem = ProductOrderItemFE.updateFromEvents(action.data.productItem, result.events);
                onClose(updatedProductItem);
            },
        });
    }

    return {
        state,
        dispatch,
    };
}

export type UseSchedulingState = UseEventFormState & {
    sync?: SchedulingSyncState;
    productItem: GenericProductItem<true>;
};

function computeInitialSchedulingState({ input, client }: { input: GenericProductItem, client: ClientInfoFE }): UseSchedulingState {
    const start = roundDateToMinutes(DateTime.now(), 5);
    const end = start.plus({ minutes: input.sessionsDuration });
    const draft = createDraftEvent(start, end, '');

    const form = computeInitialDraftForm(draft);
    form.title = input.title;
    form.guests.push({ info: client, identifier: getClientIdentifier(client) });

    return {
        form,
        productItem: input,
    };
}

export type UseSchedulingDispatch = Dispatch<SchedulingAction>;

type SchedulingAction = InputAction | SyncAction | ErrorAction;

function schedulingReducer(state: UseSchedulingState, action: SchedulingAction): UseSchedulingState {
    console.log('Reduce:', state, action);

    switch (action.type) {
    case 'input': return schedulingInput(state, action);
    case 'sync': return schedulingSync(state, action);
    case 'error': return schedulingError(state, action);
    }
}

function schedulingInput(state: UseSchedulingState, action: InputAction): UseSchedulingState {
    // If the title is valid, we reset the potential invalid title error.
    const error = (action.field === 'title' && action.value !== '') ? undefined : state.error;
    const duration = computeEventDuration(state.form);

    const { form } = Updator.update(state, action.field, action.value);

    // When the start date changes, the duration should stay the same. Therefore, we have to change the end date as well.
    if (action.field === 'start')
        form.end = form.start.plus({ seconds: duration });
    // When the end date changes, the start date stays the same.

    return { ...state, form, error };
}

export type SchedulingSyncState = {
    /** The event is being saved. The request is fired when this becomes true. This is an identifier of the button that caused the fetch. */
    fetching?: string;
    eventSync: ProductEventInitFE;
    phase: SyncModal.None | SyncModal.Notification;
};

function schedulingSync(state: UseSchedulingState, { operation, fid }: SyncAction): UseSchedulingState {
    fid = fid ?? FID_NONE;

    // If we are already fetching (or closing), we don't do anything.
    // if (state.sync?.fetching || state.close)
    if (state.sync?.fetching)
        return state;

    if (operation.type === 'transition' || operation.type === 'delete')
        throw new Error('Invalid operation');

    if (operation.type === 'start') {
        const { form, productItem } = state;
        const limit = productItem.sessionsCount - productItem.scheduledCount;
        const eventSync: ProductEventInitFE = {
            type: SyncType.Schedule,
            data: {
                title: form.title,
                locationId: form.locationId,
                start: form.start,
                end: form.end,
                recurrence: toLimitedCount(form.recurrence, form.start, limit),
                guests: form.guests,
                productItem: state.productItem,
                scheduler: form.scheduler,
            },
            sendNotification: form.guests.length === 0 ? false : undefined,
        };

        return eventSync.sendNotification !== undefined
            ? { ...state, sync: { eventSync, phase: SyncModal.None, fetching: fid } }
            : { ...state, sync: { eventSync, phase: SyncModal.Notification } };
    }

    return schedulingModalSync(fid, state, operation);
}

function schedulingModalSync(fid: string, state: UseSchedulingState, { type, result }: ModalSyncOperation): UseSchedulingState {
    if (result === 'back')
        return { ...state, sync: undefined };

    // Some invalid state - it shouldn't be possible to get here.
    if (!state.sync)
        return state;

    if (type !== SyncModal.Notification)
        throw new Error('Invalid operation');

    const eventSync: ProductEventInitFE = { ...state.sync.eventSync, sendNotification: result === 'send' };

    return { ...state, sync: { ...state.sync, eventSync, fetching: fid } };
}

function schedulingError(state: UseSchedulingState, action: ErrorAction): UseSchedulingState {
    switch (action.operation) {
    case 'reset': return { ...state, error: undefined };
    case 'invalidForm': return createSchedulingErrorState(state, { invalidForm: action.value } );
    case 'other': return createSchedulingErrorState(state, { other: action.value } );
    }
}

function createSchedulingErrorState(state: UseSchedulingState, error: ErrorState): UseSchedulingState {
    // In any case except reset, we want to stop the current sync process so that there is no fetching modal displayed.
    return { ...state, sync: undefined, error };
}

import { useCallback, useMemo, type ReactNode } from 'react';
import { type CurrencyFE, getAllCurrencies, getCurrency } from ':utils/money';
import { Select, type SelectConfig } from ':components/shadcn';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { createOnChange, createValue, type OnChange, type Value } from ':frontend/utils/forms';
import { useTranslation } from 'react-i18next';
import { type Id } from ':utils/id';
import { countryToFlagUrl } from './CountrySelect';

type Option = {
    value: CurrencyFE;
    label: ReactNode;
};

function currencyToOption(currency: CurrencyFE): Option {
    const flagUrl = countryToFlagUrl.get(currency.country);

    return {
        value: currency,
        label: (
            <div className='flex items-center gap-2'>
                {flagUrl ? (
                    <img src={flagUrl} className='size-5 rounded-full object-cover border' />
                ) : (
                    <div className='size-5'/>
                )}
                {currency.label}
            </div>
        ),
    };
}

type CurrencySelectProps<IsMulti extends boolean> = Readonly<{
    value: Value<CurrencyFE, IsMulti>;
    onChange: OnChange<CurrencyFE, IsMulti>;
    options?: CurrencyFE[];
    isMulti?: IsMulti;
    className?: string;
    id?: string;
    immutableProps?: SelectConfig<Option>;
}>;

// TODO - there is a select first and plain select later, but in the TaxRateSelect it is reversed ...
export function CurrencySelect<IsMulti extends boolean = false>({ value, onChange, options, isMulti, className, ...rest }: CurrencySelectProps<IsMulti>) {
    const { t } = useTranslation('common', { keyPrefix: 'select' });
    const innerOptions = useMemo(() => {
        return (options ?? getAllCurrencies()).map(currency => currencyToOption(currency));
    }, [ options, t ]);

    const handleOnChange = useMemo(() => createOnChange(
        (option: Option) => option.value,
        isMulti,
        onChange,
    ), [ isMulti, onChange ]);

    const innerValue = useMemo(() => createValue(
        value => currencyToOption(value),
        isMulti,
        value,
    ), [ isMulti, value ]);

    return (
        <Select
            placeholder={t('currency-placeholder')}
            options={innerOptions}
            value={innerValue === undefined ? null : innerValue}
            onChange={handleOnChange}
            isMulti={isMulti}
            className={className}
            {...rest}
        />
    );
}

type PlainCurrencySelectProps<IsMulti extends boolean> = Readonly<{
    id: Value<Id, IsMulti>;
    onChange: OnChange<Id, IsMulti>;
    options?: CurrencyFE[];
    isMulti?: IsMulti;
    className?: string;
}>;

function PlainCurrencySelect<IsMulti extends boolean = false>({ id, onChange, options, isMulti, className }: PlainCurrencySelectProps<IsMulti>) {
    const handleOnChange = useMemo(() => createOnChange(
        (currency: CurrencyFE) => currency.id,
        isMulti,
        onChange,
    ), [ isMulti, onChange ]);

    const innerId = useMemo(() => createValue(
        getCurrency,
        isMulti,
        id,
    ), [ isMulti, id ]);

    return (
        <CurrencySelect
            value={innerId}
            onChange={handleOnChange}
            options={options}
            isMulti={isMulti}
            className={className}
        />
    );
}

type ControlledCurrencySelectProps<TFieldValues extends FieldValues, IsMulti extends boolean> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    options?: CurrencyFE[];
    isMulti?: IsMulti;
    className?: string;
};

export function ControlledCurrencySelect<TFieldValues extends FieldValues, IsMulti extends boolean>({ control, name, options, isMulti, className }: ControlledCurrencySelectProps<TFieldValues, IsMulti>) {
    const InnerSelect = useCallback(({ field }: { field: { value: Value<Id, IsMulti>, onChange: OnChange<Id, IsMulti> } }) => {
        return (
            <PlainCurrencySelect
                id={field.value}
                onChange={field.onChange}
                options={options}
                isMulti={isMulti}
                className={className}
            />
        );
    }, [ options, isMulti, className ]);

    return (
        <Controller
            control={control as Control<FieldValues>}
            name={name}
            render={InnerSelect}
        />
    );
}

import { Component, createRef } from 'react';
import clsx from 'clsx';
import chunk from 'lodash/chunk';
import { localizer, NavigateDirection } from './utils/common';
import * as animationFrame from 'dom-helpers/animationFrame';
import { DateContentRow } from './DateContentRow';
import { Header } from './Header';
import { Views } from './Views';

const eventsForWeek = (evts, start, end) => {
    const startDay = localizer.startOf(start, 'day');
    const endDay = localizer.startOf(end, 'day');
    return evts.filter(e => localizer.inRangeDay(e, startDay, endDay));
};

class Month extends Component {
    constructor(...args) {
        super(...args);

        this.state = {
            rowLimit: 5,
            needLimitMeasure: true,
            date: null,
        };
        this.containerRef = createRef();
        this.slotRowRef = createRef();

        this._bgRows = [];
        this._pendingSelection = [];
    }

    static getDerivedStateFromProps({ date }, state) {
        return {
            date,
            needLimitMeasure: localizer.neq(date, state.date, 'month'),
        };
    }

    componentDidMount() {
        let running;

        if (this.state.needLimitMeasure)
            this.measureRowLimit(this.props);

        window.addEventListener(
            'resize',
            (this._resizeListener = () => {
                if (!running) {
                    animationFrame.request(() => {
                        running = false;
            this.setState({ needLimitMeasure: true }) //eslint-disable-line
                    });
                }
            }),
            false,
        );
    }

    componentDidUpdate() {
        if (this.state.needLimitMeasure)
            this.measureRowLimit(this.props);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._resizeListener, false);
    }

    getContainer = () => {
        return this.containerRef.current;
    };

    render() {
        let { date, className } = this.props,
            month = localizer.visibleDays(date),
            weeks = chunk(month, 7);

        this._weekCount = weeks.length;

        return (
            <div
                className={clsx('rbc-month-view border border-secondary-100', className)}
                aria-label='Month View'
                ref={this.containerRef}
            >
                <div className='h-12 flex'>
                    {this.renderHeaders(weeks[0])}
                </div>
                {weeks.map(this.renderWeek)}
            </div>
        );
    }

    renderWeek = (week, weekIdx) => {
        let {
            events,
            selectable,
            selected,
            date,
        } = this.props;

        const { needLimitMeasure, rowLimit } = this.state;

        // let's not mutate props
        const weeksEvents = eventsForWeek(
            [ ...events ],
            week[0],
            week[week.length - 1],
        );

        weeksEvents.sort(localizer.sortEvents);

        return (
            <DateContentRow
                enablePlaceholders
                key={weekIdx}
                ref={weekIdx === 0 ? this.slotRowRef : undefined}
                container={this.getContainer}
                className='rbc-month-row relative flex flex-col [&+&]:border-t border-secondary-100 select-none cursor-pointer'
                date={date}
                range={week}
                events={weeksEvents}
                maxRows={rowLimit}
                selected={selected}
                selectable={selectable}
                renderHeader={this.readerDateHeading}
                renderForMeasure={needLimitMeasure}
                onShowMore={this.handleShowMore}
                onSelect={this.handleSelectEvent}
                onKeyPress={this.handleKeyPressEvent}
                onSelectSlot={this.handleSelectSlot}
            />
        );
    };

    readerDateHeading = ({ date, className, ...props }) => {
        let { date: currentDate } = this.props;
        let isOffRange = localizer.neq(date, currentDate, 'month');
        let isCurrent = localizer.isSameDate(date, currentDate);
        let label = localizer.fullFormat(date, 'dateFormat');

        return (
            <div
                {...props}
                className={clsx(className, isOffRange && 'text-secondary-300', isCurrent && 'rbc-current')}
            >
                <span className='rbc-date-header'>{label}</span>
            </div>
        );
    };

    renderHeaders(row) {
        let first = row[0];
        let last = row[row.length - 1];

        return localizer.range(first, last, 'day').map((day, index) => (
            <div key={'header_' + index} className='flex grow items-center justify-center border-b [&+&]:border-l border-secondary-100'>
                <Header date={day} />
            </div>
        ));
    }

    measureRowLimit() {
        this.setState({
            needLimitMeasure: false,
            rowLimit: this.slotRowRef.current.getRowLimit(),
        });
    }

    handleSelectSlot = (range, slotInfo) => {
        this.clearSelection();
        this.props.onNavigate(range[0], Views.WEEK);
    };

    // handleHeadingClick = (date, view, e) => {
    //     e.preventDefault();
    //     this.clearSelection();
    // };

    handleSelectEvent = (...args) => {
        this.clearSelection();
        this.props.onSelectEvent(...args);
    };

    handleKeyPressEvent = (...args) => {
        this.clearSelection();
    };

    handleShowMore = (events, date, cell, slot, target) => {
        //cancel any pending selections so only the event click goes through.
        this.clearSelection();
    };

    overlayDisplay = () => {
        this.setState({
            overlay: null,
        });
    };

    selectDates(slotInfo) {
        let slots = this._pendingSelection.slice();

        this._pendingSelection = [];

        slots.sort((a, b) => +a - +b);
        const start = slots[0];
        const end = slots[slots.length - 1].plus({ day: 1 });

        this.props.onSelectSlot({
            slots,
            start,
            end,
            action: slotInfo.action,
            bounds: slotInfo.bounds,
            box: slotInfo.box,
        });
    }

    clearSelection() {
        clearTimeout(this._selectTimer);
        this._pendingSelection = [];
    }
}

function getRange(date) {
    const start = localizer.firstVisibleDay(date);
    const end = localizer.lastVisibleDay(date);
    return localizer.range(start, end);
}

function navigateTo(date, direction) {
    switch (direction) {
    case NavigateDirection.Prev:
        return localizer.add(date, -1, 'month');

    case NavigateDirection.Next:
        return localizer.add(date, 1, 'month');

    default:
        return date;
    }
}

export const monthViewObject = {
    component: Month,
    navigateTo,
    getRange,
};

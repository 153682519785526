import type { StoreBio, StoreSocial } from ':utils/entity/store';
import type { FileOutput } from ':utils/entity/file';
import { routesFE } from ':utils/routes';
import { Button } from ':components/shadcn';
import clsx from 'clsx';
import { linkify } from ':utils/common';
import { Globe2Icon, GridPlus2Icon, InputPasswordEditIcon, Pen2Icon, PlusIcon, Video1Icon } from ':components/icons/basic';
import { socialIcons } from ':components/icons/logos';
import type { IconType } from ':components/icons/common';
import { useTranslation } from 'react-i18next';

export type StoreBioAction = {
    type: 'info' | 'image' | 'video';
} | {
    type: 'socials';
    /** If undefined, a new social should be created. */
    index: number | undefined;
};

export const STORE_IMAGE_CLASS = 'max-h-40 size-40 rounded-full bg-white border-2 border-primary shadow-[0px_15px_35px_0px_rgba(85,12,230,0.15)]';
// Two times the desired size - just to be sure.
export const STORE_IMAGE_MAX_SIZE = 40 * 4 * 2;

type StoreBioDisplayProps = Readonly<{
    bio: StoreBio;
    image: FileOutput | undefined;
    onClick?: (action: StoreBioAction) => void;
}>;

export function StoreBioDisplay({ bio, image, onClick }: StoreBioDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'storeBioDisplay' });

    const src = image
        ? routesFE.files.uploads(image.hashName)
        : routesFE.files.static('flowlance-icon.svg');

    return (
        <div className='flex flex-col items-center gap-5'>
            <div className='flex flex-col items-center relative'>
                <img src={src} className={STORE_IMAGE_CLASS} />
                {onClick && (
                    <div className='absolute left-[130px] top-[10px]'>
                        <EditButton
                            label={t('edit-image-button')}
                            onClick={() => onClick({ type: 'image' })}
                        />
                    </div>
                )}
                {onClick ? (
                    <Button variant='outline' size='small' className='rounded-full -mt-4' onClick={() => onClick({ type: 'video' })}>
                        <Video1Icon />{t(bio.videoUrl ? 'edit-video-button' : 'add-video-button')}
                    </Button>
                ) : (bio.videoUrl && (
                    <a href={linkify(bio.videoUrl)} target='_blank' rel='noreferrer' className='-mt-4'>
                        <Button variant='outline' size='small' className='rounded-full'>
                            <Video1Icon />{t('play-video-button')}
                        </Button>
                    </a>
                ))}
            </div>
            <div className='flex flex-col items-center gap-2'>
                <div className='flex items-center fl-store-text-base'>
                    <span className='font-semibold leading-none text-center fl-store-text-highlight text-2xl md:text-4xl'>
                        {bio.name}
                    </span>
                    {onClick && (
                        <EditButton
                            isTransparent
                            label={t('edit-info-button')}
                            onClick={() => onClick({ type: 'info' })}
                        />
                    )}
                </div>
                {bio.headline && (
                    <span className='fl-store-text-base'>{bio.headline}</span>
                )}
                {bio.description && (
                    <div className='pt-1 text-center whitespace-pre-line max-w-[400px] fl-store-text-base leading-5'>
                        {bio.description}
                    </div>
                )}
            </div>
            {onClick && (!bio.description || bio.socials.length === 0) && (
                <div className={clsx('flex flex-wrap justify-center gap-2', !bio.description && '-mt-2')}>
                    {!bio.description && (
                        <Button
                            variant='outline'
                            size='small'
                            className='rounded-full min-h-0'
                            onClick={() => onClick({ type: 'info' })}
                        >
                            <InputPasswordEditIcon />{t('add-description-button')}
                        </Button>
                    )}
                    {bio.socials.length === 0 && (
                        <Button
                            variant='outline'
                            size='small'
                            className='rounded-full min-h-0'
                            onClick={() => onClick({ type: 'socials', index: undefined })}
                        >
                            <GridPlus2Icon />{t('add-socials-button')}
                        </Button>
                    )}
                </div>
            )}
            {bio.socials.length > 0 && (
                <div className='flex flex-wrap items-center justify-center gap-1'>
                    {bio.socials.map((social, index) => (
                        <SocialButton key={index} social={social} onClick={onClick && (() => onClick({ type: 'socials', index }))} />
                    ))}
                    {onClick && (
                        <Button
                            aria-label={t('add-socials-button')}
                            variant='transparent'
                            size='exact'
                            className='fl-store-text-base size-10 [&_svg]:size-5'
                            onClick={() => onClick({ type: 'socials', index: undefined })}
                        >
                            <PlusIcon />
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}

type EditButtonProps = Readonly<{
    label: string;
    onClick: () => void;
    isTransparent?: boolean;
}>;

function EditButton({ label, onClick, isTransparent }: EditButtonProps) {
    return (
        <div className='relative size-0'>
            {isTransparent ? (
                <Button aria-label={label} variant='transparent' size='exact' className='absolute -top-4 size-8 fl-store-text-base' onClick={onClick}>
                    <Pen2Icon size='sm' />
                </Button>
            ) : (
                <Button aria-label={label} variant='outline' size='tiny' className='size-8' onClick={onClick}>
                    <Pen2Icon size='sm' />
                </Button>
            )}
        </div>
    );
}

type SocialButtonProps = Readonly<{
    social: StoreSocial;
    /** If not provided, a link will be created instead. */
    onClick: (() => void) | undefined;
}>;

function SocialButton({ social, onClick }: SocialButtonProps) {
    const icon = social.type === otherSocialIconId
        ? <Globe2Icon size={20} className='text-secondary-800'/>
        : socialIconsWithLabels[social.type].icon({ size: 20 });

    return onClick ? (
        <Button variant='outline' size='exact' className='size-10 [&_svg]:size-fit' onClick={onClick} aria-label={social.title}>
            {icon}
        </Button>
    ) : (
        <a href={linkify(social.url)} target='_blank' rel='noreferrer' aria-label={social.title}>
            <Button variant='outline' size='exact' className='size-10 [&_svg]:size-fit'>
                {icon}
            </Button>
        </a>
    );
}

type SocialIconWithLabel = {
    icon: IconType;
    label: string;
};

export const socialIconsWithLabels: Record<string, SocialIconWithLabel> = {
    facebook: { icon: socialIcons.FacebookIcon, label: 'Facebook' },
    instagram: { icon: socialIcons.InstagramIcon, label: 'Instagram' },
    twitter: { icon: socialIcons.TwitterIcon, label: 'X' },
    linkedin: { icon: socialIcons.LinkedInIcon, label: 'LinkedIn' },
    youtube: { icon: socialIcons.YouTubeIcon, label: 'YouTube' },
    pinterest: { icon: socialIcons.PinterestIcon, label: 'Pinterest' },
    medium: { icon: socialIcons.MediumIcon, label: 'Medium' },
    threads: { icon: socialIcons.ThreadsIcon, label: 'Threads' },
    whatsApp: { icon: socialIcons.WhatsAppIcon, label: 'WhatsApp' },
    tikTok: { icon: socialIcons.TikTokIcon, label: 'TikTok' },
    telegram: { icon: socialIcons.TelegramIcon, label: 'Telegram' },
};

export const otherSocialIconId = 'other';

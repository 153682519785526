import { memo } from 'react';
import { type EventFE } from ':frontend/types/Event';
import { useTranslation } from 'react-i18next';
import { CircleFullIcon, CircleSemiFullIcon, CircleEmptyIcon } from ':components/icons/custom';
import { isParticipantBillable } from ':frontend/types/EventParticipant';
import clsx from 'clsx';
import type { IconType } from ':components/icons/common';

type EventPaymentState = 'billable' | 'unpaid' | 'paid';

const stateDescriptions: Record<EventPaymentState, {
        colorClass: string;
        label: string;
        icon: IconType;
    }> = {
        'billable': { colorClass: 'text-secondary', label: 'billable', icon: CircleEmptyIcon },
        'unpaid': { colorClass: 'text-warning', label: 'unpaid', icon: CircleSemiFullIcon },
        'paid': { colorClass: 'text-success', label: 'paid', icon: CircleFullIcon },
    };

type EventPaymentStateBadgeProps = Readonly<{
    event: EventFE;
}>;

export const EventPaymentStateBadge = memo(EventPaymentStateBadgeRaw);

function EventPaymentStateBadgeRaw({ event }: EventPaymentStateBadgeProps) {
    const { t } = useTranslation('common', { keyPrefix: 'event.paymentState' });

    const { state, count } = computeState(event);
    if (state === null)
        return null;

    const description = stateDescriptions[state];

    return (
        <div className='flex items-center'>
            {description.icon({ size: 16, className: clsx(description.colorClass, 'mr-2') })}
            <span>{t(description.label, { count })}</span>
        </div>
    );
}

function computeState(event: EventFE): { state: EventPaymentState | null, count: number } {
    const payingCount = event.clients.length;
    if (payingCount === 0)
        // This should not happen since each event should have at least one billable participant.
        return { state: null, count: 0 };

    const paidCount = event.clients.filter(p => p.isPaid).length;
    if (paidCount === payingCount)
        return { state: 'paid', count: paidCount };

    const billableCount = event.clients.filter(isParticipantBillable).length;
    if (billableCount > 0)
        return { state: 'billable', count: billableCount };

    return { state: 'unpaid', count: payingCount - paidCount };
}

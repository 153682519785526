import { priceToServer, type TaxRateFE, type Money, type CurrencyFE, moneyFromServer, getTaxRate } from ':utils/money';
import type { RequiredBy } from ':utils/common';
import { ClientInfoFE, type ClientContact } from './Client';
import { type Id } from ':utils/id';
import type { LocaleCode } from ':utils/i18n';
import { type EventParticipantOutput, EventParticipantType, type ClientParticipantUpsert, type ContactParticipanUpsert, type EventParticipantOrder } from ':utils/entity/eventParticipant';
import type { ClientInit, ClientOrContactInit } from ':utils/entity/client';
import type { AppUserSettingsFE } from './settings';
import { OrderState, PaymentMethod } from ':utils/entity/invoicing';

export class EventParticipantFE {
    private constructor(
        readonly id: Id,
        readonly client: ClientInfoFE,
        readonly isGuest: boolean,
        readonly payment?: EventParticipantPayment,
        readonly order?: EventParticipantOrder,
    ) {}

    static fromServer(input: EventParticipantOutput) {
        return new EventParticipantFE(
            input.id,
            ClientInfoFE.fromServer(input.client),
            input.type === EventParticipantType.guest || input.type === EventParticipantType.both,
            EventParticipantPayment.fromServer(input),
            input.order,
        );
    }

    get isPaying(): boolean {
        return !!this.payment;
    }

    get isPaid(): boolean {
        return this.order?.state === OrderState.fulfilled;
    }

    get isInOrder(): boolean {
        return !!this.order;
    }

    get isInStripeOrder(): boolean {
        return this.order?.paymentMethod === PaymentMethod.stripe;
    }

    get documentId(): string | undefined {
        return (this.order?.paymentMethod !== PaymentMethod.noInvoice) ? this.order?.id : undefined;
    }
}

export class EventParticipantPayment {
    private constructor(
        readonly price: Money,
        readonly vat: TaxRateFE,
    ) {}

    static fromServer(input: EventParticipantOutput) {
        if (input.type === EventParticipantType.guest)
            return;

        if (input.price === undefined || !input.currency || !input.vat) {
            console.warn('EventParticipantPayment.fromServer: missing price, currency or vat', input);

            return undefined;
        }

        return new EventParticipantPayment(
            moneyFromServer(input.price, input.currency),
            getTaxRate(input.vat),
        );
    }
}

export type PayingParticipant = RequiredBy<EventParticipantFE, 'payment'>;

export function isParticipantBillable(participant: PayingParticipant): boolean {
    return participant.isPaying && !participant.isInOrder;
}

export type Participant = {
    identifier: string;
} & ({
    info: ClientInfoFE;
} | {
    contact: ClientContact;
});

export function getClientOrContact(participant: Participant): ClientInfoFE | ClientContact {
    return 'info' in participant ? participant.info : participant.contact;
}

export function getClientIdentifier(client: ClientInfoFE): string {
    return client.id;
}

export function getContactIdentifier(contact: ClientContact): string {
    return contact.canonicalEmail;
}

export function getParticipantName(participant: Participant): string {
    return getClientOrContact(participant).name;
}

export function getParticipantEmail(participant: Participant): string {
    return ('info' in participant ? participant.info : participant.contact).email;
}

export function getParticipantLocale(participant: Participant, settings: AppUserSettingsFE): LocaleCode {
    return ('info' in participant ? participant.info : settings).locale;
}

export type EditablePayingParticipant = Participant & {
    price: number | '';
    currency: CurrencyFE;
    vat: TaxRateFE;
    original: PayingParticipant | undefined;
};

export function clientOrContactToServer(participant: Participant, settings: AppUserSettingsFE): ClientOrContactInit {
    return 'info' in participant
        ? { id: participant.info.id }
        : { contact: clientContactToServer(participant.contact, settings) };
}

export function clientContactToServer(contact: ClientContact, settings: AppUserSettingsFE): ClientInit {
    return {
        name: contact.name,
        email: contact.canonicalEmail,
        timezone: settings.timezone,
        locale: settings.locale,
        country: settings.country,
    };
}

export function participantsToServer(clients: EditablePayingParticipant[], guests: Participant[], settings: AppUserSettingsFE): {
    clientParticipants: ClientParticipantUpsert[];
    contactParticipants: ContactParticipanUpsert[];
} {
    const participants: (ClientParticipantUpsert | ContactParticipanUpsert)[] = [];

    const clientIdentifiers = new Set(clients.map(c => c.identifier));
    const guestIdentifiers = new Set(guests.map(g => g.identifier));

    clients.forEach(c => {
        if (c.price === '')
            return;

        participants.push({
            ...clientOrContactToServer(c, settings),
            // If the client is also a guest, we include him here and we set his type to both.
            type: guestIdentifiers.has(c.identifier) ? EventParticipantType.both : EventParticipantType.client,
            payment: {
                price: priceToServer(c.price),
                currency: c.currency.id,
                vat: c.vat.id,
            },
        });
    });

    guests.forEach(g => {
        // If the guest is also a client, we already included him above.
        if (clientIdentifiers.has(g.identifier))
            return;

        participants.push({
            ...clientOrContactToServer(g, settings),
            type: EventParticipantType.guest,
        });
    });

    return {
        clientParticipants: participants.filter((p): p is ClientParticipantUpsert => 'id' in p),
        contactParticipants: participants.filter((p): p is ContactParticipanUpsert => 'contact' in p),
    };
}

import { PersonForm } from ':frontend/components/settings/PersonForm';
import { GeneralPreferencesForm } from ':frontend/components/settings/PreferencesForm';
import { PasswordForm } from ':frontend/components/settings/PasswordForm';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { useTranslation } from 'react-i18next';
import { Card, Skeleton } from ':components/shadcn';
import { StoreSlug } from ':frontend/components/settings/StoreSlug';
import { CustomDomain } from ':frontend/components/settings/CustomDomain';
import { TermsOfService } from ':frontend/components/settings/TermsOfService';
import { Globe2Icon, UserListIcon, Sliders3Icon, LockIcon } from ':components/icons/basic';
import { trpc } from ':frontend/context/TrpcProvider';

export function GeneralSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general' });
    const userContext = useUser();
    const isMasterOrFreelancer = !!toMaster(userContext);
    const store = trpc.store.getStore.useQuery().data;

    return (
        <div className='p-4 py-8 md:py-12 space-y-4 md:space-y-8'>
            {isMasterOrFreelancer && (<>
                <Card>
                    <Card.Title className='flex items-center gap-2'>
                        <Globe2Icon className='h-5 text-warning-500' />
                        <span>{t('storeSlug-section')}</span>
                    </Card.Title>
                    <Card.Divider />
                    {store ? (
                        <StoreSlug store={store} />
                    ) : (
                        <Skeleton height={136} />
                    )}
                </Card>

                <Card>
                    <CustomDomain />
                </Card>

                <Card>
                    {store ? (
                        <TermsOfService store={store} />
                    ) : (
                        <Skeleton className='h-8' />
                    )}
                </Card>
            </>)}

            <Card>
                <Card.Title className='flex items-center gap-2'>
                    <UserListIcon className='h-5 text-warning-500' />
                    <span>{t('personal-details-section')}</span>
                </Card.Title>
                <Card.Divider />
                <PersonForm />
            </Card>

            <Card>
                <Card.Title className='flex items-center gap-2'>
                    <Sliders3Icon className='h-5 text-warning-500' />
                    <span>{t('preferences-section')}</span>
                </Card.Title>
                <Card.Divider />
                <GeneralPreferencesForm />
            </Card>

            {!userContext.appUser.google.isAuth && (
                <Card>
                    <Card.Title className='flex items-center gap-2'>
                        <LockIcon className='h-5 text-warning-500' />
                        <span>{t('password-section')}</span>
                    </Card.Title>
                    <Card.Divider />
                    <PasswordForm />
                </Card>
            )}
        </div>
    );
}

import { abbreviateStringToLength } from ':frontend/utils/common';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { getLocationLink, getPlatformType, PlatformType, type LocationOutput } from ':utils/entity/location';
import { Gear1Icon, Trash2Icon } from ':components/icons/basic';
import { OnlineLocationIcon } from ':components/icons/location';
import { Button } from ':components/shadcn';

type LocationDisplayProps = Readonly<{
    location: LocationOutput;
    onEdit?: () => void;
    onDelete?: () => void;
    isCompact?: boolean;
    className?: string;
}>;

export function LocationDisplay({ location, onEdit, onDelete, isCompact, className }: LocationDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'locationDisplay' });

    return (
        <div className={clsx('p-5 flex max-md:flex-wrap items-center justify-between gap-4 border rounded-2xl select-none', className)}>
            <a href={getLocationLink(location)} target='_blank' rel='noreferrer' className='flex items-center gap-4 no-underline text-inherit hover:underline truncate'>
                <LocationIcon location={location} size={isCompact ? 18 : 32} className='shrink-0' />

                {isCompact ? (
                    <div className='leading-6 text-secondary-900'>{location.title}</div>
                ) : (
                    <div className='space-y-1 overflow-hidden'>
                        <h2 className='text-secondary-900 truncate'>{location.title}</h2>

                        <div className='text-secondary truncate'>{abbreviateStringToLength(location.value, 60)}</div>
                    </div>
                )}
            </a>

            <div className='flex items-center justify-end'>
                {onEdit && <Button variant='transparent' size='small' onClick={onEdit} className='text-primary text-sm'>
                    {t('edit-button')}
                </Button>}

                {onDelete && <Button variant='transparent' size='small' onClick={onDelete}>
                    <Trash2Icon className='h-3' />
                </Button>}
            </div>
        </div>
    );
}

type LocationIconProps = Readonly<{
    location: LocationOutput;
    size?: number;
    className?: string;
}>;

export function LocationIcon(props: LocationIconProps) {
    return PlatformTypeIcon({
        ...props,
        type: getPlatformType(props.location.platform),
    });
}

type PlatformTypeIconProps = Readonly<{
    type: PlatformType;
    size?: number;
    className?: string;
}>;

export function PlatformTypeIcon({ type, size, className }: PlatformTypeIconProps) {
    return type === PlatformType.custom
        ? <Gear1Icon size={size} className={className} />
        : <OnlineLocationIcon type={type} size={size} className={className} />;
}

'use client';

import { forwardRef, useId, useImperativeHandle, useRef, type ComponentPropsWithoutRef, type ElementRef, type InputHTMLAttributes, type ReactNode } from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import TextareaAutosize, { type TextareaAutosizeProps } from 'react-textarea-autosize';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as SwitchPrimitives from '@radix-ui/react-switch';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from './utils';
import { CheckboxIcon, RadioCheckIcon, SwitchLinkIcon, SwitchIcon } from ':components/icons/custom';
import { Slot } from '@radix-ui/react-slot';

// # Forms & labels
// All form components should have associated labels. It's nice to be able to click on the label and have the input focused (or toggle a checkbox). There are also accessibility reasons.
// However, there is a slight issue - the label needs a `htmlFor` attribute that matches the input's `id`. It's hard to ensure uniqueness of `id`s across the app, but we can (and should) use the react `useId` hook to generate them. But it isn't that simple, because it's a lot of pain to generate several ids in each form and then pass them to the inputs and labels.
// Luckily, in vast majority of cases, the labels were directly above the inputs (or after the checkboxes) and without any custom styling. So, we have the convenience `label` prop in all input elements. If it's provided, the label will be automatically generated with the correct `htmlFor` and `id` attributes.
// For edge cases, there is always the option to use the `<Label>` component directly instead of the prop.

// TODO Make the `htmlFor` attribute on labels required to ensure all labels have it. Before that, we should add the label prop to all other major inputs (custom select boxes, etc).

type FormProps = InputHTMLAttributes<HTMLFormElement>;

/** automatic formNoValidate */
const Root = forwardRef<HTMLFormElement, FormProps>(({ ...props }, ref) => (
    <form
        ref={ref}
        formNoValidate
        {...props}
    />
));

const RequiredIcon = () => {
    return (
        <span className='ml-1 mr-2 text-primary text-lg leading-3' aria-hidden='true'>*</span>
    );
};

type LabelProps = ComponentPropsWithoutRef<typeof LabelPrimitive.Root>;

const Label = forwardRef<ElementRef<typeof LabelPrimitive.Root>, LabelProps>(({ className, ...props }, ref) => (
    <LabelPrimitive.Root
        ref={ref}
        className={cn('mb-1 block leading-5 peer-disabled:opacity-70 peer-disabled:cursor-auto', className)}
        {...props}
    />
));
Label.displayName = LabelPrimitive.Root.displayName;

type DescriptionProps = ComponentPropsWithoutRef<'p'> & {
    id: string;
};

const Description = forwardRef<HTMLParagraphElement, DescriptionProps>(({ className, ...props }, ref) => {
    return (
        <p
            ref={ref}
            className={cn('text-secondary-300', className)}
            {...props}
        />
    );
});
Description.displayName = 'Description';

const inputVariants = cva(`w-full whitespace-nowrap placeholder:text-secondary-200
    focus-visible:outline-none focus-within:outline-none disabled:pointer-events-none
    `, {
    variants: {
        variant: {
            outline: 'bg-white disabled:bg-secondary-100 border border-secondary-100 hover:border-primary-200',
            ghost: 'bg-white disabled:bg-secondary-100 border border-white hover:border-primary-200',
            transparent: 'bg-transparent',
        },
        size: {
            default: 'px-4b h-13 rounded-lg',
            compact: 'px-4b h-10 rounded-md',
            exact: '',
        },
        node: {
            simple: 'block',
            complex: 'flex items-center',
        },
    },
    defaultVariants: {
        variant: 'outline',
        size: 'default',
    },
    compoundVariants: [ {
        variant: [ 'outline', 'ghost' ],
        node: 'simple',
        // Here we only have focus-visible, because it takes precedence over hover. However, focus-within does not, so we need to combine it with hover for the complex node.
        class: 'focus-visible:border-primary',
    }, {
        variant: [ 'outline', 'ghost' ],
        node: 'complex',
        class: 'focus-within:border-primary hover:focus-within:border-primary',
    } ],
});

export type InputVariantProps = VariantProps<typeof inputVariants>;

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & InputVariantProps    & {
    /** If not provided, the text will be used as default. */
    type?: 'text' | 'password' | 'email' | 'tel' | 'number';
    label?: ReactNode;
    labelClassName?: string;
    isError?: boolean;
    isValid?: boolean;
    /** Prepend text, icon, or similar stuff. The imput will become complex. */
    prefixNode?: ReactNode;
};

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, id, type = 'text', variant, size, label, labelClassName, isError, isValid, prefixNode, placeholder, ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;

    const innerRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => innerRef.current!, []);

    if (prefixNode) {
        return (<>
            {label && <Label className={cn(labelClassName)} htmlFor={finalId}>{label}</Label>}
            <div
                className={cn('cursor-text',
                    inputVariants({ variant, size, node: 'complex', className }),
                    isError && 'border-danger-500 hover:border-danger-500 focus-within:border-danger-500 hover:focus-within:border-danger-500',
                    isValid && 'border-success-400 hover:border-success-400 focus-within:border-success-400 hover:focus-within:border-success-400',
                )}
                onMouseDown={e => {
                    if (e.target !== innerRef.current) {
                        e.preventDefault();
                        innerRef.current?.focus();
                    }
                }}
            >
                {prefixNode}
                <input
                    ref={innerRef}
                    id={finalId}
                    type={type}
                    className='h-full w-full outline-none'
                    // There is a bug in safari (https://bugs.webkit.org/show_bug.cgi?id=142968 - from 2015 and still going, good job Apple, you are truly the best). If the input is completely empty, the input's bottom line is used as baseline. Non-empty placeholder fixes that.
                    placeholder={placeholder || ' '}
                    {...props}
                />
            </div>
        </>);
    }

    return (<>
        {label && <Label className={cn(labelClassName)} htmlFor={finalId}>{label}</Label>}
        <input
            ref={innerRef}
            id={finalId}
            type={type}
            className={cn(
                inputVariants({ variant, size, node: 'simple', className }),
                isError && 'border-danger-500 hover:border-danger-500 focus-visible:border-danger-500',
                isValid && 'border-success-400 hover:border-success-400 focus-visible:border-success-400',
            )}
            placeholder={placeholder}
            {...props}
        />
    </>);
});
Input.displayName = 'Input';

// The block is here to prevent browsers from putting a random vertical space below the textarea.
// The overflow-hidden is necessary to hide scrollbar, which would make the content smaller, thus forcing the text to wrap to more lines, thus necessitating more height, thus showing the scrollbar. Its a vicious cycle.
const textareaVariants = cva(`block w-full overflow-hidden placeholder:text-secondary-200 placeholder:whitespace-pre-line focus-visible:outline-none
    disabled:pointer-events-none
    `, {
    variants: {
        variant: {
            outline: 'bg-white disabled:bg-secondary-100 border border-secondary-100 hover:border-primary-200 focus-visible:border-primary',
            ghost: 'bg-white disabled:bg-secondary-100 border border-white hover:border-primary-200 focus-visible:border-primary',
            transparent: 'bg-transparent',
        },
        size: {
            // The textarea library does some shenanigans with the height but our desing is more important.
            // The values are also very custom in order to match the height of the Input component.
            compact: 'leading-5 px-4b !scroll-py-[9px] !py-[9px] rounded-md',
            default: 'leading-6 px-4b !scroll-py-[13px] !py-[13px] rounded-lg',
            exact: '',
        },
    },
    defaultVariants: {
        variant: 'outline',
        size: 'default',
    },
});

export type TextareaVariantProps = VariantProps<typeof textareaVariants>;

type TextareaProps = TextareaAutosizeProps & TextareaVariantProps & {
    label?: ReactNode;
    labelClassName?: string;
    isError?: boolean;
};

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, id, variant, size, label, labelClassName, isError, ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;

    return (<>
        {label && <Label className={cn(labelClassName)} htmlFor={finalId}>{label}</Label>}
        <TextareaAutosize
            ref={ref}
            id={finalId}
            className={cn(textareaVariants({ variant, size, className }), isError && 'border-danger-500')}
            {...props}
        />
    </>);
});

type CheckedState<T extends boolean> = T extends true ? CheckboxPrimitive.CheckedState : boolean;

type CheckboxProps<T extends boolean> = Omit<
    ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    'checked' | 'defaultChecked' | 'onCheckedChange'
> & {
    checked?: CheckedState<T>;
    defaultChecked?: CheckedState<T>;
    onCheckedChange?(checked: CheckedState<T>): void;
    label?: ReactNode;
    labelClassName?: string;
};

const Checkbox = forwardRef<ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps<false>>(({ className, id, label, labelClassName, ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;

    return (<>
        <CheckboxPrimitive.Root
            ref={ref}
            id={finalId}
            className={cn(`
                peer block h-4 w-4 shrink-0 rounded-xs border border-primary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50
                data-[state=checked]:bg-primary
                `, className,
            )}
            {...props}
        >
            <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
                <CheckboxIcon className='text-white' />
            </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {label && <Label htmlFor={finalId} className={cn('mb-0', labelClassName)}>{label}</Label>}
    </>);
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

const Threebox = forwardRef<ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps<true>>(({ className, id, label, labelClassName, ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;

    return (<>
        <CheckboxPrimitive.Root
            ref={ref}
            id={finalId}
            className={cn(`
                peer block h-4 w-4 shrink-0 rounded-xs border border-primary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50
                data-[state=checked]:bg-primary
                group data-[state=indeterminate]:bg-primary
                `, className,
            )}
            {...props}
        >
            <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
                <CheckboxIcon className='text-white hidden group-data-[state=checked]:block' />
                <CheckboxIcon className='text-white hidden group-data-[state=indeterminate]:block' isIndeterminate />
            </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {label && <Label htmlFor={finalId} className={cn('mb-0', labelClassName)}>{label}</Label>}
    </>);
});
Threebox.displayName = CheckboxPrimitive.Root.displayName;

type SwitchProps = ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    label?: ReactNode;
    labelClassName?: string;
    size?: 'default' | 'small';
    variant?: 'default' | 'link';
};

const Switch = forwardRef<ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(({ className, id, label, labelClassName, size, variant, checked, ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;
    const isSmall = size === 'small';
    const isLink = variant === 'link';

    return (<>
        <SwitchPrimitives.Root
            ref={ref}
            id={finalId}
            className={cn(`
                peer inline-flex shrink-0
                bg-secondary-200 data-[state=checked]:bg-primary
                items-center rounded-full border-2 border-transparent transition-colors
                focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-300 focus-visible:ring-offset-2
                disabled:opacity-50
                `, className,
            isSmall ? 'h-5 w-10' : 'h-8 w-14',
            )}
            checked={checked}
            {...props}
        >
            <SwitchPrimitives.Thumb
                className={cn('bg-white block rounded-full ring-0 transition-transform data-[state=unchecked]:translate-x-0 pointer-events-none',
                    isSmall ? 'size-4 data-[state=checked]:translate-x-5' : 'size-7 p-[6px] data-[state=checked]:translate-x-6',
                )}
            >
                {isLink ? (
                    <SwitchLinkIcon checked={checked} className='text-secondary-600' />
                ) : (
                    <SwitchIcon checked={checked} className='text-secondary-600' />
                )}
            </SwitchPrimitives.Thumb>
        </SwitchPrimitives.Root>
        {label && <Label htmlFor={finalId} className={cn('mb-0', labelClassName)}>{label}</Label>}
    </>);
});
Switch.displayName = SwitchPrimitives.Root.displayName;

type SwitchBetweenProps = Omit<ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>, 'checked' | 'onCheckedChange'> & {
    isRight?: boolean;
    onIsRightChange?: (isLeft: boolean) => void;
    labelLeft?: ReactNode;
    labelLeftClassName?: string;
    labelRight?: ReactNode;
    labelRightClassName?: string;
};

const SwitchBetween = forwardRef<ElementRef<typeof SwitchPrimitives.Root>, SwitchBetweenProps>(({ className, id, labelLeft, labelLeftClassName, labelRight, labelRightClassName, isRight, onIsRightChange, ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;

    return (<>
        {labelLeft && <Label htmlFor={finalId} className={cn('mb-0', labelLeftClassName)}>{labelLeft}</Label>}
        <SwitchPrimitives.Root
            ref={ref}
            id={finalId}
            className={cn(`
                peer inline-flex h-6 w-11 shrink-0
                bg-primary
                items-center rounded-full border-2 border-transparent transition-colors
                focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary-300 focus-visible:ring-offset-2
                disabled:opacity-50
                `, className,
            )}
            checked={isRight}
            onCheckedChange={onIsRightChange}
            {...props}
        >
            <SwitchPrimitives.Thumb
                className={cn('bg-white block h-5 w-5 rounded-full ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0 pointer-events-none',
                )}
            >
            </SwitchPrimitives.Thumb>
        </SwitchPrimitives.Root>
        {labelRight && <Label htmlFor={finalId} className={cn('mb-0', labelRightClassName)}>{labelRight}</Label>}
    </>);
});


type RadioGroupProps = ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>;

const RadioGroup = forwardRef<ElementRef<typeof RadioGroupPrimitive.Root>, RadioGroupProps>(({ className, ...props }, ref) => {
    return (
        <RadioGroupPrimitive.Root
            ref={ref}
            className={cn('flex flex-col gap-3', className)}
            {...props}
        />
    );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

// The default item has to have label, because there isn't any other information to be displayed.
// The custom item has some content so it can hide the label.

type RadioItemProps = ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
    label: ReactNode;
    labelClassName?: string;
    description?: ReactNode;
    direction?: 'row' | 'col';
};

const RadioItem = forwardRef<
    ElementRef<typeof RadioGroupPrimitive.Item>, RadioItemProps
>(({ id, label, labelClassName, description, className, direction = 'row', ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;
    const descriptionId = `${finalId}-description`;

    return (
        <div className={cn('flex items-center', direction === 'row' ? 'gap-3' : 'flex-col gap-2', className)}>
            <RadioGroupPrimitive.Item
                ref={ref}
                id={finalId}
                className={`
                    peer shrink-0 h-4 w-4 rounded-full border border-primary-400 hover:border-primary
                    focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-300 focus-visible:ring-offset-2
                    disabled:pointer-events-none disabled:opacity-20 disabled:border-primary-400
                `}
                {...props}
            >
                <RadioGroupPrimitive.Indicator className='flex items-center justify-center'>
                    <div className='h-2 w-2 rounded-full bg-primary' />
                </RadioGroupPrimitive.Indicator>
            </RadioGroupPrimitive.Item>
            <div className={cn('peer-disabled:[&>label]:opacity-70 peer-disabled:[&>label]:cursor-auto', direction === 'col' && 'text-center')}>
                <Label htmlFor={finalId} className={cn('leading-4 mb-0 cursor-pointer', labelClassName)}>{label}</Label>
                {description && <Description id={descriptionId}>{description}</Description>}
            </div>
        </div>
    );
});
RadioItem.displayName = RadioGroupPrimitive.Item.displayName;

const CustomRadioItem = forwardRef<
    ElementRef<typeof RadioGroupPrimitive.Item>, RadioItemProps
>(({ id, label, labelClassName, description, className, direction = 'row', children, ...props }, ref) => {
    const reactId = useId();
    const finalId = id ?? reactId;
    const descriptionId = `${finalId}-description`;

    return (
        <div className={cn('flex items-center', direction === 'row' ? 'gap-3' : 'flex-col gap-2', className)}>
            <RadioGroupPrimitive.Item
                ref={ref}
                id={finalId}
                className={`
                    peer shrink-0 self-stretch relative rounded [&>*]:data-[state=checked]:text-primary [&>*]:data-[state=checked]:outline-primary
                    focus-visible:ring-2 focus-visible:ring-primary-300 focus-visible:ring-offset-2
                    disabled:pointer-events-none disabled:opacity-20
                `}
                {...props}
            >
                {/* We use outline here because the border-transparent doesn't work correctly with elements with gradient. */}
                <Slot className='rounded m-[2px] outline outline-2 outline-transparent hover:text-primary-400 hover:outline-primary-400 active:text-primary active:outline-primary'>
                    {children}
                </Slot>
                <RadioGroupPrimitive.Indicator className='absolute -top-2 -right-2'>
                    <RadioCheckIcon className='rounded-full bg-primary text-white' />
                </RadioGroupPrimitive.Indicator>
            </RadioGroupPrimitive.Item>
            {description ? (
                <div className={cn('peer-disabled:[&>label]:opacity-70 peer-disabled:[&>label]:cursor-auto text-center', direction === 'col' && 'text-center')}>
                    {label && (
                        <Label htmlFor={finalId} className={cn('leading-4 mb-0 cursor-pointer text-secondary-300', labelClassName)}>{label}</Label>
                    )}
                    <Description id={descriptionId}>{description}</Description>
                </div>
            ) : (
                <Label htmlFor={finalId} className={cn('leading-4 mb-0 cursor-pointer text-secondary-300', labelClassName)}>{label}</Label>
            )}
        </div>
    );
});
CustomRadioItem.displayName = 'Custom' + RadioGroupPrimitive.Item.displayName;

export const Form = {
    Root,
    RequiredIcon,
    Label,
    Description,
    Input,
    Textarea,
    Checkbox,
    Threebox,
    Switch,
    SwitchBetween,
    RadioGroup,
    RadioItem,
    CustomRadioItem,
};

import { useCallback, useState } from 'react';
import { Button, Card } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { SpinnerButton } from ':frontend/components/common';
import { useMaster } from ':frontend/context/UserProvider';
import { useToggle } from ':frontend/hooks';
import { GeneralSupplierForm, type GeneralSupplierFormOutput } from ':frontend/components/settings/GeneralSupplierForm';
import { PersonalizationForm, type PersonalizationFormOutput } from ':frontend/components/settings/PersonalizationForm';
import { InvoicingProfileFE } from ':frontend/types/Invoicing';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { AddProfileButton } from './InvoicingProfilesSettings';
import { trpc } from ':frontend/context/TrpcProvider';
import { InvoicingProfileExampleModal } from ':frontend/components/orders/InvoicingProfileExampleModal';
import { LicenseIcon, ArrowsExpandDiagonal6Icon, ArrowsReduceDiagonal1Icon } from ':components/icons/basic';

export function InvoicingProfileSettings() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profile' });

    const [ showExampleModal, setShowExampleModal ] = useToggle(false);
    const [ showAdvancedSection, setShowAdvancedSection ] = useState(false);

    const { profiles, setProfiles } = useMaster();
    const profile = profiles[0];

    const setProfile = useCallback((profile: InvoicingProfileFE) => {
        setProfiles(oldProfiles => {
            const index = oldProfiles.findIndex(p => p.id === profile.id);
            const newProfiles = [ ...oldProfiles ];
            newProfiles[index] = profile;

            return newProfiles;
        });
    }, [ setProfiles ]);

    return (
        <>
            <InvoicingProfileExampleModal profile={showExampleModal ? profile : undefined} onHide={setShowExampleModal.false} />

            <div className='p-4 py-8 md:py-12 space-y-8'>
                <div className='flex items-center justify-between gap-4'>
                    <h1 className='text-2xl font-semibold leading-6'>{t('page-title')}</h1>

                    <AddProfileButton className='bg-transparent' />
                </div>

                <div className='space-y-4 md:space-y-8'>
                    <Card>
                        <div className='max-sm:space-y-4 sm:flex sm:items-center sm:justify-between sm:gap-4'>
                            <Card.Title className='flex items-center gap-2'>
                                <LicenseIcon className='shrink-0 text-warning-500' />
                                {t('general-section')}
                            </Card.Title>

                            <Button variant='white' size='small' onClick={setShowExampleModal.true}>
                                {t('example-invoice-button')}
                            </Button>
                        </div>

                        <Card.Divider />

                        <InvoicingProfileFormPart type='general' profile={profile} setProfile={setProfile} />
                    </Card>

                    <Card>
                        <div className='flex items-center justify-between cursor-pointer gap-4' onClick={() => setShowAdvancedSection(!showAdvancedSection)}>
                            <Card.Title className='flex items-center gap-2'>
                                <LicenseIcon className='shrink-0 text-warning-500' />
                                {t('advanced-section')}
                            </Card.Title>

                            <Button variant='transparent'>
                                {showAdvancedSection ? <ArrowsReduceDiagonal1Icon /> : <ArrowsExpandDiagonal6Icon />}
                            </Button>
                        </div>

                        {showAdvancedSection && (
                            <>
                                <Card.Divider />
                                <InvoicingProfileFormPart type='advanced' profile={profile} setProfile={setProfile} />
                            </>
                        )}
                    </Card>
                </div>
            </div>
        </>
    );
}

const formParts = {
    general: GeneralSupplierForm,
    advanced: PersonalizationForm,
};

type InvoicingProfileFormPartProps = {
    readonly type: keyof typeof formParts;
    readonly profile: InvoicingProfileFE;
    readonly setProfile: (newProfile: InvoicingProfileFE) => void;
};

export function InvoicingProfileFormPart({ type, profile, setProfile }: InvoicingProfileFormPartProps) {
    const { addAlert } = useNotifications();
    const [ isDirty, setIsDirty ] = useState(false);

    const updateInvoicingProfileMutation = trpc.invoicing.updateInvoicingProfile.useMutation();

    function onSubmit(edit: GeneralSupplierFormOutput | PersonalizationFormOutput) {
        updateInvoicingProfileMutation.mutate({ id: profile.id, ...edit }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: response => {
                setProfile(InvoicingProfileFE.fromServer(response));
                addAlert(createTranslatedSuccessAlert('pages:settings.invoicing-profile.save-success'));
            },
        });
    }

    const saveButton = useCallback(({ onClick }: { onClick: () => void }) => (
        <SaveButton onClick={onClick} isFetching={updateInvoicingProfileMutation.isPending} disabled={!isDirty} />
    ), [ updateInvoicingProfileMutation.isPending, isDirty ]);

    const InnerForm = formParts[type];

    return (
        <InnerForm
            onSubmit={onSubmit}
            input={profile}
            onChange={setIsDirty}
            saveButton={saveButton}
        />
    );
}

type SaveButtonProps = Readonly<{
    onClick: () => void;
    isFetching: boolean;
    disabled: boolean;
}>;

function SaveButton({ onClick, isFetching, disabled }: SaveButtonProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.invoicing-profile' });

    return (
        <SpinnerButton
            type='submit'
            isFetching={isFetching}
            variant='primary'
            onClick={onClick}
            disabled={disabled}
            className='mt-4 w-full'
        >
            {t('save-button')}
        </SpinnerButton>
    );
}

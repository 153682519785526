import memoize from 'memoize-one';
import { eventSegments, endOfRange, eventLevels } from './eventLevels';
import { localizer } from './common';

let isSegmentInSlot = (seg, slot) => seg.left <= slot && seg.right >= slot;

const isEqual = (a, b) => a[0].range === b[0].range && a[0].events === b[0].events;

export function getSlotMetrics() {
    return memoize(options => {
        const { range, events, maxRows, minRows, enablePlaceholders } = options;
        const { first, last } = endOfRange({ dateRange: range });

        const segments = events.map(event => eventSegments(event, range));

        const { levels, extra } = eventLevels(segments, Math.max(maxRows - 1, 1), enablePlaceholders);

        while (levels.length < minRows)
            levels.push([]);

        return {
            first,
            last,

            levels,
            extra,
            range,
            slots: range.length,

            clone(args) {
                const metrics = getSlotMetrics();
                return metrics({ ...options, ...args });
            },

            getDateForSlot(slotNumber) {
                return range[slotNumber];
            },

            getSlotForDate(date) {
                return range.find(r => localizer.isSameDate(r, date));
            },

            getEventsForSlot(slot) {
                return segments
                    .filter(seg => isSegmentInSlot(seg, slot))
                    .map(seg => seg.event);
            },

            continuesPrior(event) {
                return localizer.continuesPrior(event.start, first);
            },

            continuesAfter(event) {
                return localizer.continuesAfter(event.start, event.end, last);
            },
        };
    }, isEqual);
}

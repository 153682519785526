import { type Filter, type FilterDefinition, type FilterFunction, type FilterMenuProps } from './FilterRow';
import { DropdownMenu, Form, Button } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { type Id } from ':utils/id';
import { useMaster } from ':frontend/context/UserProvider';
import { ClientTagDisplay } from ':frontend/components/client/ClientTags';
import { emptyFunction } from ':frontend/utils/common';
import { Tag3Icon } from ':components/icons/basic';

const filterName = 'clientTag';

type FilterState = {
    [key: string]: boolean;
};

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    const { clientTags } = useMaster();

    return (
        <div className='p-1.5'>
            {clientTags.map(clientTag => (
                <div
                    key={clientTag.id}
                    className='flex items-center gap-4 px-4 py-3 rounded-sm cursor-pointer hover:bg-primary-100'
                    onClick={() => setState({ ...state, [clientTag.id]: !state[clientTag.id] })}
                >
                    {/**
                  * This is needed in order to make the checkbox clickable. Without this, when clicking on the checkbox, the state is changing, but the checkbox still looks the same. Only after changing the state on other order state, the checkbox will change.
                  * For some reason, nothing else really works. It is possible to use something like key={'' + state[orderState]} to force the component to re-render, but this seems unnecessary.
                  * It is probably caused by the whole Dropdown thing that somehow manipulates the orders.
                  */}
                    <Form.Checkbox checked={state[clientTag.id]}
                        onClick={e => {
                            setState({ ...state, [clientTag.id]: !state[clientTag.id] });
                            e.stopPropagation();
                        }}
                        onChange={emptyFunction}
                    />

                    <ClientTagDisplay tag={clientTag} />
                </div>
            ))}
        </div>
    );
}

function createFilterRowDropdown(filterName: string, ToggleMenu: React.FC<FilterMenuProps<FilterState>>): React.FC<FilterMenuProps<FilterState>> {
    return ({ state, setState }: FilterMenuProps<FilterState>) => {
        const { t } = useTranslation('common', { keyPrefix: 'filters' });
        const { clientTags } = useMaster();

        const selectedStates = clientTags.filter(clientTag => state[clientTag.id]);

        return (
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <Button variant='outline' className={selectedStates.length > 0 ? 'border-primary text-primary hover:border-primary order-2' : undefined}>
                        {selectedStates.length > 0 && selectedStates.map(clientTag => <ClientTagDisplay key={clientTag.id} tag={clientTag} />).flatMap((e, index) => [ e, <span key={`and_${index}`}>&</span> ]).slice(0, -1)}
                        {selectedStates.length === 0 && (
                            <div className='flex items-center gap-2'>
                                <Tag3Icon size='md' /> {t(`${filterName}.menu-button`)}
                            </div>
                        )}
                    </Button>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content side='bottom' align='start'>
                    <ToggleMenu state={state} setState={setState} />
                </DropdownMenu.Content>
            </DropdownMenu.Root>
        );
    };
}

function FilterItemBadge() {
    return null;
}

function remove(state: FilterState, item: Id): FilterState {
    return {
        ...state,
        [item]: false,
    };
}

function toItems(state: FilterState): Id[] {
    return Object.entries(state)
        .filter(([ , value ]) => value)
        .map(([ key ]) => key as Id);
}

function createFilterFunction(state: FilterState): FilterFunction<ClientInfoFE> {
    const selectedIds = toItems(state);

    if (selectedIds.length === 0)
        return () => true;

    return (data: ClientInfoFE) => selectedIds.every(id => data.tagIds.some(tagId => tagId === id));
}

function toServer(): undefined[] {
    return [];
}

const ClientTagFilter: FilterDefinition<Id, FilterState, ClientInfoFE> = {
    name: filterName,
    defaultValues: {},
    FilterToggleMenu,
    FilterRowMenu: createFilterRowDropdown(filterName, FilterToggleMenu),
    FilterItemBadge,
    remove,
    toItems,
    createFilterFunction,
    toServer: toServer as (state: FilterState, previous: unknown | undefined) => undefined[],
};

export default ClientTagFilter as Filter;

import { createContext } from 'react';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import { type DateTime } from 'luxon';

type InteractionInfo = {
    start: DateTime;
    end: DateTime;
    isAllDay?: boolean;
};

type ActionType = 'move' | 'resize';

export enum ActionDirection {
    Up = 'up',
    Down = 'down',
    Left = 'left',
    Right = 'right',
}

type DnDState = {
    event: CalendarEvent | null;
    action: ActionType | null;
    direction?: ActionDirection | null;
    interacting?: boolean;
};

type DnDContext = {
    onStart: () => void;
    onEnd: (info: InteractionInfo | null) => void;
    onBeginAction: (event: CalendarEvent, action: ActionType, direction?: ActionDirection) => void;
    onDropFromOutside: (info: InteractionInfo) => void;
    dragFromOutsideItem: () => void;
    dragAndDropAction: DnDState;
};

export const dndContext = createContext<{ draggable: DnDContext }>(null!);

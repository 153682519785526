import { TimeGrid } from './TimeGrid';
import { DateTime } from 'luxon';
import { localizer, NavigateDirection } from './utils/common';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import type { Selected } from ':frontend/pages/CalendarDetail';
import type { SlotInfo } from '.';
import type { Selectable } from './DayColumn';

type WeekViewProps = Readonly<{
    date: DateTime;
    events: CalendarEvent[];
    selected?: Selected;
    selectable?: Selectable;
    range: DateTime[];
    min: DateTime;
    max: DateTime;
    onSelectEvent: (event: CalendarEvent) => void;
    onSelectSlot: (slot: SlotInfo) => void;
}>;

function WeekView({ date, min, max, ...props }: WeekViewProps) {
    const range = getRange(date);

    return (
        <TimeGrid
            {...props}
            date={date}
            eventOffset={15}
            range={range}
            min={min ?? localizer.startOf(DateTime.now(), 'day')}
            max={max ?? localizer.endOf(DateTime.now(), 'day')}
        />
    );
}

function navigateTo(date: DateTime, direction: NavigateDirection): DateTime {
    switch (direction) {
    case NavigateDirection.Prev:
        return localizer.add(date, -1, 'week');

    case NavigateDirection.Next:
        return localizer.add(date, 1, 'week');

    default:
        return date;
    }
}

function getRange(date: DateTime): DateTime[] {
    const start = localizer.startOf(date, 'week');
    const end = localizer.endOf(date, 'week');

    return localizer.range(start, end);
}

export const weekViewObject = {
    component: WeekView,
    navigateTo,
    getRange,
};

import { type Filter, type FilterDefinition, type FilterFunction, type FilterMenuProps } from './FilterRow';
import { type EventFE, getAllEventStates } from ':frontend/types/Event';
import { EventStateBadge } from ':frontend/components/event/EventStateBadge';
import { Button, Form, DropdownMenu } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { compareArrays, emptyFunction } from ':frontend/utils/common';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import { EventState } from ':utils/entity/event';
import { enumFilterToArray } from ':utils/common';
import { CircleCopy1Icon } from ':components/icons/basic';

export const filterName = 'eventState';

type FilterState = {
    [key in EventState]: boolean;
};

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (<div className='p-1.5'>
        {getAllEventStates().map(eventState => (
            <div
                key={eventState}
                className='flex items-center gap-4 px-4 py-3 rounded-sm cursor-pointer hover:bg-primary-100'
                onClick={() => setState({ ...state, [eventState]: !state[eventState] })}
            >
                {/**
                  * This is needed in order to make the checkbox clickable. Without this, when clicking on the checkbox, the state is changing, but the checkbox still looks the same. Only after changing the state on other event state, the checkbox will change.
                  * For some reason, nothing else really works. It is possible to use something like key={'' + state[eventState]} to force the component to re-render, but this seems unnecessary.
                  * It is probably caused by the whole Dropdown thing that somehow manipulates the events.
                  */}
                <Form.Checkbox checked={state[eventState]}
                    onClick={e => {
                        setState({ ...state, [eventState]: !state[eventState] });
                        e.stopPropagation();
                    }}
                    onChange={emptyFunction}
                />
                <EventStateBadge state={eventState} />
            </div>
        ))}
    </div>);
}

function FilterItemBadge() {
    return null;
}

function remove(state: FilterState, item: EventState): FilterState {
    return {
        ...state,
        [item]: false,
    };
}

function toItems(state: FilterState): EventState[] {
    return Object.entries(state)
        .filter(([ , value ]) => value)
        .map(([ key ]) => key as EventState);
}

function createFilterFunction(state: FilterState): FilterFunction<EventFE> {
    if (toItems(state).length === 0)
        return () => true;

    return (data: EventFE) => !!state[data.state];
}

function createFilterRowDropdown(filterName: string, ToggleMenu: React.FC<FilterMenuProps<FilterState>>): React.FC<FilterMenuProps<FilterState>> {
    return ({ state, setState }: FilterMenuProps<FilterState>) => {
        const { t } = useTranslation('common', { keyPrefix: 'filters' });

        const selectedStates = getAllEventStates().filter(eventState => state[eventState]);

        return (
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <Button variant='outline' className={selectedStates.length > 0 ? 'border-primary text-primary hover:border-primary' : undefined}>
                        {selectedStates.length > 0 && selectedStates.map(eventState => <EventStateBadge key={eventState} state={eventState} />).flatMap((e, index) => [ e, <span key={`or_${index}`}>/</span> ]).slice(0, -1)}
                        {selectedStates.length === 0 && (
                            <div className='flex items-center gap-2'>
                                <CircleCopy1Icon size='md' /> {t(`${filterName}.menu-button`)}
                            </div>
                        )}
                    </Button>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content side='bottom' align='start'>
                    <ToggleMenu state={state} setState={setState} />
                </DropdownMenu.Content>
            </DropdownMenu.Root>
        );
    };
}

function toServer(state: FilterState, previous: string[] | undefined): string[] {
    const current = enumFilterToArray(state).sort();
    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

const EventStateFilter: FilterDefinition<EventState, FilterState, EventFE> = {
    name: filterName,
    defaultValues: {
        [EventState.ready]: false,
        [EventState.finished]: false,
        [EventState.canceled]: false,
    },
    FilterToggleMenu,
    FilterRowMenu: createFilterRowDropdown(filterName, FilterToggleMenu),
    FilterItemBadge,
    remove,
    toItems,
    createFilterFunction,
    toServer: toServer as (state: FilterState, previous: unknown | undefined) => string[],
};

export default EventStateFilter as Filter;

function createCalendarFilterFunction(state: FilterState): FilterFunction<CalendarEvent> {
    if (toItems(state).length === 0)
        return () => true;

    return (data: CalendarEvent) => data.resource.type !== 'event' || !!state[data.resource.event.state];
}

const CalendarEventStateFilterDefinition: FilterDefinition<EventState, FilterState, CalendarEvent> = {
    name: filterName,
    defaultValues: {
        [EventState.ready]: false,
        [EventState.finished]: false,
        [EventState.canceled]: false,
    },
    FilterToggleMenu,
    FilterRowMenu: createFilterRowDropdown(filterName, FilterToggleMenu),
    FilterItemBadge,
    remove,
    toItems,
    createFilterFunction: createCalendarFilterFunction,
    toServer: toServer as (state: FilterState, previous: unknown | undefined) => string[],
};

export const CalendarEventStateFilter = CalendarEventStateFilterDefinition as Filter;

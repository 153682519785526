import { useTranslation } from 'react-i18next';
import { Card, Form } from ':components/shadcn';
import type { IconType } from ':components/icons/common';
import { LanguageIcon, NotepadIcon } from ':components/icons/basic';
import clsx from 'clsx';
import { useCallback } from 'react';
import { trpc } from ':frontend/context/TrpcProvider';
import { useUpdating } from ':frontend/hooks';
import { TeamSettingsFE } from ':frontend/types/settings';
import { useMaster } from ':frontend/context/UserProvider';

export function ClientModules() {
    return (
        <div className='space-y-4 max-w-[800px] mx-auto'>
            <ModuleCard
                type='localization'
                Icon={LanguageIcon}
                colorClassNames={{ icon: 'text-danger-700', wrapper: 'bg-danger-100' }}
            />

            <ModuleCard
                type='notes'
                Icon={NotepadIcon}
                colorClassNames={{ icon: 'text-warning-700', wrapper: 'bg-warning-100' }}
            />
        </div>
    );
}

const typeToFlag = {
    'notes': 'isNotesEnabled',
    'localization': 'isLocalizationEnabled',
} as const;

type ModuleCardProps = Readonly<{
    type: keyof typeof typeToFlag;
    Icon?: IconType;
    colorClassNames?: {
        icon: string;
        wrapper: string;
    };
}>;

function ModuleCard({ type, Icon, colorClassNames = { icon: 'text-secondary-700', wrapper: 'bg-secondary-100' } }: ModuleCardProps) {
    const { t } = useTranslation('pages', { keyPrefix: `client.modules.${type}` });
    const flag = typeToFlag[type];
    const { teamSettings, setTeamSettings } = useMaster();

    const updateFlagMutation = trpc.team.updateTeamFlag.useMutation();

    const syncState = useCallback(async (newValue: boolean) => {
        try {
            const response = await updateFlagMutation.mutateAsync({ flag, value: newValue });
            setTeamSettings(TeamSettingsFE.fromServer(response));
            return true;
        }
        catch {
            return false;
        }
    }, [ flag, updateFlagMutation, setTeamSettings ]);

    const [ state, updateState, isUpdatingState ] = useUpdating(teamSettings[flag], syncState);

    return (
        <Card className='space-y-4'>
            <div className='flex flex-row items-center justify-between'>
                <div className='flex items-center justify-between gap-2'>
                    {Icon && (
                        <div
                            className={clsx(
                                'size-10 rounded-full flex items-center justify-center transition-colors duration-300',
                                { [colorClassNames.wrapper]: state, 'bg-secondary-100': !state })
                            }
                        >
                            <Icon className={clsx('h-5 w-auto transition-colors duration-300', { [colorClassNames.icon]: state, 'text-secondary-700': !state })} />
                        </div>
                    )}

                    <Card.Title className='capitalize'>{type}</Card.Title>
                </div>

                <Form.Switch
                    checked={state}
                    onCheckedChange={updateState}
                    disabled={isUpdatingState}
                />
            </div>

            <p className='mt-4 mb-0 leading-5'>{t('description')}</p>
        </Card>
    );
}

import { useMemo } from 'react';
import { MoneyDisplayString } from ':components/custom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, Card, Skeleton } from ':components/shadcn';
import { ProductOrderItemFE, type GenericProductItem } from ':frontend/types/orders/ProductOrderItem';
import { productStyles } from ':components/store/product/ProductCard';
import { getRandomSkeletonArray } from ':utils/math';
import { type Id } from ':utils/id';
import { MoneyBillsDollarIcon, CalendarPlus1Icon, SectionIcon, Trash2Icon } from ':components/icons/basic';
import { ClientIconLink } from '../client/ClientIconLink';
import { timesBy } from ':utils/money';

type ProductItemTileProps = Readonly<{
    productItem: GenericProductItem<true>;
    onSchedule?: (productItem: GenericProductItem) => void;
    onRemove: (productItem: GenericProductItem) => void;
    hideClientId?: Id;
}>;

export function ProductItemTile({ productItem, onSchedule, onRemove, hideClientId }: ProductItemTileProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productDisplay' });

    const styles = useMemo(() => productStyles[productItem.type], [ productItem ]);
    const toSchedule = productItem.sessionsCount - productItem.scheduledCount;

    return (
        <Card
            className={clsx(
                `space-y-4 w-full ${styles.border} [&:hover_.product-remove]:opacity-100`,
                ProductOrderItemFE.isCompleted(productItem) ? 'bg-secondary-100' : 'bg-white',
            )}
        >
            <div className='flex justify-between items-start'>
                <div className='text-lg'><styles.icon /></div>

                <div
                    className='product-remove opacity-0 transition-opacity rounded-full p-2.5 bg-secondary-50 hover:bg-secondary-100 cursor-pointer'
                    onClick={() => onRemove(productItem)}
                >
                    <Trash2Icon size='sm' />
                </div>
            </div>

            <h3 className={`truncate font-semibold text-lg ${styles.color}`}>{productItem.title}</h3>

            <div className='flex items-center gap-4 text-sm text-secondary-600'>
                {productItem.unitPrice && (
                    <div className='flex items-center gap-1'>
                        <MoneyBillsDollarIcon size='xs' />

                        <MoneyDisplayString money={timesBy(productItem.unitPrice, productItem.quantity)} />
                    </div>
                )}

                <div className='text-sm text-secondary-600 flex items-center gap-1'>
                    <SectionIcon size='xs' />

                    <span>
                        {t('sessions-count-label', { count: productItem.sessionsCount })}
                    </span>
                </div>
            </div>

            {productItem.guest.id !== hideClientId && (
                <div>
                    <ClientIconLink client={productItem.guest} />
                </div>
            )}

            {toSchedule > 0 ? (
                <div className='flex items-center justify-between'>
                    <div className='text-secondary-600'>
                        {t('to-schedule-count-label', { count: toSchedule })}
                    </div>

                    <Button variant='primary' size='tiny' onClick={() => onSchedule?.(productItem)}>
                        <CalendarPlus1Icon size='md' />{t('schedule-item-button')}
                    </Button>
                </div>
            ) : (
                <div className='text-sm text-secondary-600'>{t('all-scheduled-label')}</div>
            )}
        </Card>
    );
}

type ProductSkeletonsProps = Readonly<{
    variant: 'product' | 'product-item';
}>;

export function ProductTileSkeletons({ variant }: ProductSkeletonsProps) {
    const skeletonArray = useMemo(() => getRandomSkeletonArray(1, 4), []);

    return (<>
        {skeletonArray.map(key => (
            <ProductTileSkeleton key={key} variant={variant} />
        ))}
    </>);
}

function ProductTileSkeleton({ variant }: ProductSkeletonsProps) {
    const height = variant === 'product' ? 240 : 285;

    return (
        <div className={clsx(variant === 'product' ? 'h-[240px] w-[240px] p-5' : 'h-[285px] w-[285px] p-5', 'p-0')}>
            <Skeleton className='rounded-2xl' height={height} />
        </div>
    );
}

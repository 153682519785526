import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from './utils';
import { CommonCloseButton, type CloseButtonProps } from './Modal';

const Root = SheetPrimitive.Root;

const Trigger = SheetPrimitive.Trigger;

const Close = SheetPrimitive.Close;

const Portal = SheetPrimitive.Portal;

const Overlay = forwardRef<
    ElementRef<typeof SheetPrimitive.Overlay>,
    ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
    <SheetPrimitive.Overlay
        className={cn(
            'fixed inset-0 z-50 bg-black/10  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
            className,
        )}
        {...props}
        ref={ref}
    />
));
Overlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = cva('fixed z-50 rounded-2xl bg-white transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out duration-300 focus-visible:outline-none', {
    variants: {
        side: {
            top: 'inset-x-3 top-3 data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
            bottom: 'inset-x-3 bottom-3 data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
            left: 'inset-y-3 left-3 w-[calc(100vw-24px)] sm:w-3/4 data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left',
            right: 'inset-y-3 right-3 w-[calc(100vw-24px)] sm:w-3/4  data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right',
        },
    },
    defaultVariants: {
        side: 'right',
    },
});

type ContentProps = ComponentPropsWithoutRef<typeof SheetPrimitive.Content> & CloseButtonProps & VariantProps<typeof sheetVariants>;

const Content = forwardRef<ElementRef<typeof SheetPrimitive.Content>, ContentProps>(({ side, className, closeButton, closeDisabled, children, ...props }, ref) => (
    <Portal>
        <Overlay />
        <SheetPrimitive.Content
            ref={ref}
            className={cn(sheetVariants({ side }), className)}
            {...props}
        >
            {children}
            {closeButton !== null && (
                <CommonCloseButton closeButton={closeButton} closeDisabled={closeDisabled} />
            )}
        </SheetPrimitive.Content>
    </Portal>
));
Content.displayName = SheetPrimitive.Content.displayName;

export const Sheet = {
    Root,
    Portal,
    Overlay,
    Trigger,
    Close,
    Content,
    Title: SheetPrimitive.Title,
    Description: SheetPrimitive.Description,
};

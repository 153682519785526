import { type DateTime } from 'luxon';
import { DateTimeDisplay } from ':frontend/components/common';

type DayColumnCellProps = Readonly<{
    group: DateTime[];
}>;

export function DayColumnCell({ group }: DayColumnCellProps) {
    return (
        <div>
            {group.map((_, index) => (
                <div key={index} />
            ))}
        </div>
    );
}

type TimeGutterCellProps = Readonly<{
    group: DateTime[];
}>;

export function TimeGutterCell({ group }: TimeGutterCellProps) {
    const datetime = group[0];

    return (
        <div className='py-3 px-2'>
            <DateTimeDisplay dateTime={datetime} time />
        </div>
    );
}

import { type ReactNode, useCallback, useMemo, useState } from 'react';
import { Button, Form, Select, type SingleValue } from ':components/shadcn';
import type { UseProductOrderDispatch, UseProductOrderState } from './useProductOrder';
import { useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { TranslatedErrorMessage } from '../forms/ErrorMessage';
import { SingleContinuousParticipantSelect } from '../client/ContinuousParticipantSelect';
import { TeamMemberSelect } from '../team/TeamMemberSelect';
import { useMaster } from ':frontend/context/UserProvider';
import { TeamMemberRole } from ':utils/entity/team';
import type { ProductOutput } from ':utils/entity/product';
import { CirclePlusIcon, InvoiceDollarIcon } from ':components/icons/basic';
import { ProductInvoiceItemDisplay, ProductSelectOption } from ':components/store/product/ProductCard';
import { DiscountInput } from './DiscountInput';
import { computProductPriceSummary, PriceSummaryDisplay } from './PriceSummary';

type ProductOrderFormProps = Readonly<{
    products: ProductOutput[];
    clients: ClientInfoFE[];
    state: UseProductOrderState;
    dispatch: UseProductOrderDispatch;
}>;

export function ProductOrderForm({ products, clients, state, dispatch }: ProductOrderFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.product' });
    const items = state.form.items;
    const { teamSettings, role } = useMaster();
    const isTeamMaster = role === TeamMemberRole.master;
    const priceSummary = computProductPriceSummary(state.form);

    return (
        <Form.Root className='space-y-3'>
            <div>
                <Form.Label className='text-lg font-semibold mb-3'>{t('guest-label')}</Form.Label>
                <SingleContinuousParticipantSelect
                    isCreatable
                    clients={clients}
                    value={state.form.guest}
                    onChange={value => dispatch({ type: 'input', field: 'guest', value })}
                />
                <TranslatedErrorMessage translationId={state.formErrors?.['guest']} />
            </div>

            {teamSettings.isInvoicingEnabled && (
                state.form.isLinked ? (
                    <Button variant='outline' size='small' className='mt-1 w-full' onClick={() => dispatch({ type: 'input', field: 'isLinked', value: false })}>
                        <InvoiceDollarIcon />{t('unlink-button')}
                    </Button>
                ) : (<>
                    <div className='pt-3'>
                        <Form.Label className='font-semibold mb-2'>{t('client-label')}</Form.Label>
                        <SingleContinuousParticipantSelect
                            isCreatable
                            clients={clients}
                            value={state.form.client}
                            onChange={value => dispatch({ type: 'input', field: 'client', value })}
                        />
                        <TranslatedErrorMessage translationId={state.formErrors?.['client']} />
                    </div>
                </>)
            )}

            {isTeamMaster && (<>
                <div className='pt-3'>
                    <Form.Label className='font-semibold mb-2'>{t('scheduler-label')}</Form.Label>
                    <TeamMemberSelect
                        value={state.form.scheduler}
                        onChange={value => dispatch({ type: 'input', field: 'scheduler', value })}
                    />
                    <TranslatedErrorMessage translationId={state.formErrors?.['scheduler']} />
                </div>
            </>)}

            <Form.Label className='font-semibold mt-3 -mb-1'>{t('items-label')}</Form.Label>

            {items.map((item, index) => (
                <ProductInvoiceItemDisplay
                    key={index}
                    product={item.product}
                    price={item.price}
                    vat={item.vat}
                    onRemove={items.length > 1
                        ? () => dispatch({ type: 'form', operation: 'removeItem', index })
                        : undefined
                    }
                />
            ))}

            <ProductSelect products={products} dispatch={dispatch} />

            <DiscountInput discount={state.form.discount} onChange={value => dispatch({ type: 'input', field: 'discount', value })} />

            <div className='mt-4 sm:px-6'>
                <PriceSummaryDisplay summary={priceSummary} />
            </div>
        </Form.Root>
    );
}

type ProductSelectProps = Readonly<{
    products: ProductOutput[];
    dispatch: UseProductOrderDispatch;
}>;

function ProductSelect({ products, dispatch }: ProductSelectProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.product' });
    const [ isShow, setIsShow ] = useState(false);
    const productOptions = useMemo(() => products.map(productToOption), [ products ]);
    const selectProduct = useCallback((option: SingleValue<ProductOption>) => {
        if (!option)
            return;

        dispatch({ type: 'form', operation: 'addItem', product: option.value });
        setIsShow(false);
    }, [ dispatch ]);

    if (!isShow) {
        return (
            <Button variant='outline' size='medium' className='w-full' onClick={() => setIsShow(true)}>
                <CirclePlusIcon />{t('add-product-button')}
            </Button>
        );
    }

    return (
        <Select
            immutableProps={{ size: 'compact', CustomOption: ProductSelectOption }}
            placeholder={t('add-product-placeholder')}
            options={productOptions}
            value={null as SingleValue<ProductOption>}
            onChange={selectProduct}
            menuIsOpen
            autoFocus
            onBlur={() => setIsShow(false)}
        />
    );
}

type ProductOption = {
    label: ReactNode;
    value: ProductOutput;
};

function productToOption(product: ProductOutput): ProductOption {
    return {
        label: product.title,
        value: product,
    };
}

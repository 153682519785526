import { Form } from ':components/shadcn';
import { type FieldValues, type UseFormReturn } from 'react-hook-form';
import { type InvoicingProfileFE, type InvoicingOverrideFE, type InvoicingOverrideToServer } from ':frontend/types/Invoicing';
import { useTranslation } from 'react-i18next';
import { HEADER_FOOTER_MAX_LENGTH, useCustomFieldsValidation } from './PersonalizationForm';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { useTransform } from ':frontend/utils/forms';
import { ControlledLocaleSelect } from '../forms';
import { type ClientInfoFE } from ':frontend/types/Client';
import { ControlledCondensedInvoiceToggle } from '../forms/CondensedInvoiceToggle';
import type { LocaleCode } from ':utils/i18n';

export type InvoicingOverrideFormData = {
    dueDays: number | '';
    isCondensedInvoice: boolean;
    header: string;
    footer: string;
    customKey1: string;
    customValue1: string;
    customKey2: string;
    customValue2: string;
    locale: LocaleCode<'invoice'>;
};

export function inputToForm(input: InvoicingOverrideFE, profile: InvoicingProfileFE, client: ClientInfoFE): InvoicingOverrideFormData {
    return {
        dueDays: client.dueDays ?? profile.dueDays,
        isCondensedInvoice: input.isInvoicingOverride ?? profile.isCondensedInvoice,
        header: input.header ?? profile.header ?? '',
        footer: input.footer ?? profile.footer ?? '',
        customKey1: input.customKey1 ?? profile.customKey1 ?? '',
        customValue1: input.customValue1 ?? profile.customValue1 ?? '',
        customKey2: input.customKey2 ?? profile.customKey2 ?? '',
        customValue2: input.customValue2 ?? profile.customValue2 ?? '',
        locale: input.locale ?? profile.locale,
    };
}

export function formToOutput(data: InvoicingOverrideFormData): InvoicingOverrideToServer {
    return {
        dueDays: data.dueDays !== '' ? data.dueDays : undefined,
        condensedInvoice: data.isCondensedInvoice,
        header: data.header.trim(),
        footer: data.footer.trim(),
        customKey1: data.customKey1.trim(),
        customValue1: data.customValue1.trim(),
        customKey2: data.customKey2.trim(),
        customValue2: data.customValue2.trim(),
        locale: data.locale,
    };
}

type InvoicingOverrideFormInnerProps<T extends FieldValues> = Readonly<{
    form: UseFormReturn<T>;
    isNew?: boolean;
    profile: InvoicingProfileFE;
}>;

export function InvoicingOverrideFormInner<T extends InvoicingOverrideFormData>(props: InvoicingOverrideFormInnerProps<T>) {
    const { t } = useTranslation('components', { keyPrefix: 'invoicingForm' });
    const form = props.form as unknown as UseFormReturn<InvoicingOverrideFormData>;
    const { control, register, setValue, formState: { errors }, watch } = form;
    const { registerPositiveInteger } = useTransform(register, setValue);
    const headerLength = watch('header').length;
    const footerLength = watch('footer').length;

    const { validateCustomKey1, validateCustomKey2 } = useCustomFieldsValidation(form);

    return (
        <div className='space-y-8'>
            <div className='space-y-4'>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                    <div>
                        <Form.Input
                            label={t('dueDays-label')}
                            {...registerPositiveInteger('dueDays')}
                        />
                        <RHFErrorMessage errors={errors} name='dueDays' />
                    </div>

                    <div>
                        <Form.Label>{t('locale-label')}</Form.Label>
                        <ControlledLocaleSelect
                            control={control}
                            name='locale'
                            type='invoice'
                            placeholder={t('locale-placeholder')}
                        />
                    </div>
                </div>

                <div>
                    <Form.Label>{t('condensed-invoice-label')}</Form.Label>
                    <ControlledCondensedInvoiceToggle
                        control={control}
                        name='isCondensedInvoice'
                    />
                </div>

                <div>
                    <Form.Textarea
                        label={`${t('header-label')} (${headerLength}/${HEADER_FOOTER_MAX_LENGTH})`}
                        labelClassName='tabular-nums'
                        placeholder={t('header-placeholder')}
                        minRows={2}
                        {...register('header', { maxLength: HEADER_FOOTER_MAX_LENGTH })}
                        aria-describedby='header-textarea'
                    />
                </div>

                <div>
                    <Form.Textarea
                        label={`${t('footer-label')} (${footerLength}/${HEADER_FOOTER_MAX_LENGTH})`}
                        labelClassName='tabular-nums'
                        placeholder={t('footer-placeholder')}
                        minRows={2}
                        {...register('footer', { maxLength: HEADER_FOOTER_MAX_LENGTH })}
                        aria-describedby='footer-textarea'
                    />
                </div>
            </div>

            <div className='space-y-4'>
                <h5 className='font-semibold text-lg'>{t('custom-fields-title')}</h5>

                <div className='leading-5'>{t('custom-fields-description')}</div>

                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                    <div>
                        <Form.Input
                            label={t('custom-key-1-label')}
                            {...register('customKey1', { validate: validateCustomKey1 })}
                            placeholder={t('custom-key-1-placeholder')}
                        />
                        <RHFErrorMessage errors={errors} name='customKey1' />
                    </div>

                    <div>
                        <Form.Input
                            label={t('custom-value-1-label')}
                            {...register('customValue1')}
                            placeholder={t('custom-value-1-placeholder')}
                        />
                    </div>
                </div>

                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                    <div>
                        <Form.Input
                            label={t('custom-key-2-label')}
                            {...register('customKey2', { validate: validateCustomKey2 })}
                        />
                        <RHFErrorMessage errors={errors} name='customKey2' />
                    </div>

                    <div>
                        <Form.Input
                            label={t('custom-value-2-label')}
                            {...register('customValue2')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

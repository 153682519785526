import { useEditing, type SyncFunction, EditingPhase } from ':frontend/hooks';
import { Button, Form, Spinner, type InputVariantProps, type TextareaVariantProps } from ':components/shadcn';
import clsx from 'clsx';
import { Pen2Icon } from ':components/icons/basic';
import { cn } from ':components/shadcn/utils';

type EditableTextInputProps = InputVariantProps & Readonly<{
    value: string;
    syncFunction: SyncFunction<string>;
    className?: string;
    inputClassName?: string;
    viewClassName?: string;
    disabled?: boolean;
}>;

export function EditableTextInput({ variant, size, value, syncFunction, className, inputClassName, viewClassName, disabled }: EditableTextInputProps) {
    const { state: { value: innerValue, phase }, setValue, doUpdate, setEditing } = useEditing(value, syncFunction);

    return (
        <div className={cn('flex items-center justify-center gap-1', className)}>
            {phase === EditingPhase.View && (<>
                <span className={viewClassName}>{innerValue}</span>

                {disabled ? (
                    <div className='w-5' />
                ) : (
                    <Button variant='transparent' size='exact' className='w-5' onClick={setEditing}>
                        <Pen2Icon />
                    </Button>
                )}
            </>)}

            {phase === EditingPhase.Editing && (
                <Form.Input
                    variant={variant}
                    size={size}
                    className={inputClassName}
                    autoFocus
                    value={innerValue}
                    onKeyDown={event => event.key === 'Enter' && doUpdate()}
                    onChange={e => setValue(e.target.value)}
                    onBlur={() => doUpdate()}
                />
            )}

            {phase === EditingPhase.Updating && (<>
                <span className={clsx('opacity-80', viewClassName)}>{innerValue}</span>
                <Spinner size='sm' className='opacity-80' />
            </>)}
        </div>
    );
}

type EditableTextareaProps = TextareaVariantProps & Readonly<{
    value: string;
    syncFunction: SyncFunction<string>;
    className?: string;
    textareaClassName?: string;
    viewClassName?: string;
    disabled?: boolean;
}>;

export function EditableTextarea({ variant, size, value, syncFunction, className, textareaClassName, viewClassName, disabled }: EditableTextareaProps) {
    const { state: { value: innerValue, phase }, setValue, doUpdate, setEditing } = useEditing(value, syncFunction);

    return (
        <div className={cn('flex items-center justify-center gap-1', className)}>
            {phase === EditingPhase.View && (<>
                <span className={viewClassName}>{innerValue}</span>

                {disabled ? (
                    <div className='w-5' />
                ) : (
                    <Button variant='transparent' size='exact' className='w-5' onClick={setEditing}>
                        <Pen2Icon />
                    </Button>
                )}
            </>)}

            {phase === EditingPhase.Editing && (
                <Form.Textarea
                    variant={variant}
                    size={size}
                    className={textareaClassName}
                    autoFocus
                    value={innerValue}
                    onKeyDown={event => event.key === 'Enter' && !event.shiftKey && doUpdate()}
                    onChange={e => setValue(e.target.value)}
                    onBlur={() => doUpdate()}
                />
            )}

            {phase === EditingPhase.Updating && (<>
                <span className={clsx('opacity-80', viewClassName)}>{innerValue}</span>
                <Spinner size='sm' className='opacity-80' />
            </>)}
        </div>
    );
}

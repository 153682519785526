import { useTranslation } from 'react-i18next';
import { Card } from ':components/shadcn';
import { ClientFormPart, ClientUpdateType } from ':frontend/components/client/ClientFormPart';
import { type NavigateFunction } from 'react-router-dom';
import type { ClientFE } from ':frontend/types/Client';

type ClientPreferencesProps = Readonly<{
    client: ClientFE;
    navigateUnblocked: NavigateFunction;
    invalidateClient: () => void;
    isDirty: boolean;
    setIsDirty: (isDirty: boolean) => void;
}>;

export default function ClientPreferences({ client, invalidateClient, isDirty, setIsDirty }: ClientPreferencesProps) {
    const { t } = useTranslation('pages');

    return (
        <div className='space-y-8 max-w-[800px] mx-auto'>
            <h1 className='text-2xl/6 font-semibold'>{t('client.preferences.page-title', { clientName: client.name })}</h1>

            <Card className='space-y-6'>
                <ClientFormPart client={client} onUpdate={invalidateClient} isDirty={isDirty} setIsDirty={setIsDirty} type={ClientUpdateType.Preferences} />
            </Card>
        </div>
    );
}

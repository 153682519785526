import { type ClientInfoFE } from ':frontend/types/Client';
import { useTranslation } from 'react-i18next';
import { Card } from ':components/shadcn';
import { EventsTable } from ':frontend/components/event/EventsTable';
import { CalendarIcon } from ':components/icons/basic';

type ClientEventsProps = Readonly<{
    client: ClientInfoFE;
}>;

export default function ClientOrders({ client }: ClientEventsProps) {
    const { t } = useTranslation('pages');

    return (
        <Card className='space-y-6 max-lg:bg-transparent max-lg:p-0 max-lg:border-none max-lg:shadow-none'>
            <Card.Title className='flex items-center gap-2'>
                <CalendarIcon size='lg' />{t('client.events.page-title', { clientName:client.name })}
            </Card.Title>

            <EventsTable client={client} />
        </Card>
    );
}

import { type DateTime } from 'luxon';
import { capitalize } from ':utils/common';
import { localizer } from './utils/common';

// TODO Move this to Month once it's converted to TypeScript.

type HeaderProps = Readonly<{
    date: DateTime;
}>;

export function Header({ date }: HeaderProps) {
    return (
        <div className='flex items-baseline justify-center gap-4 select-none'>
            <div className='font-normal'>{capitalize(localizer.format(date, 'cccc'))}</div>
        </div>
    );
}

import { type CSSProperties } from 'react';
import { EventWrapper } from './dragAndDrop/EventWrapper';
import { getEventSize, getEventStyle } from './eventStyle';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import { useUser } from ':frontend/context/UserProvider';
import { TeamMemberRole } from ':utils/entity/team';
import { cn } from ':components/shadcn/utils';
import { DateTime } from 'luxon';

type TimeGridEventProps = {
    event: CalendarEvent;
    isPreview?: boolean;
    selected?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    onDoubleClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    style: CSSProperties & {
        height: string | number;
        top: string | number;
        width: string | number;
        xOffset: number;
    };
    className?: string;
};

export function TimeGridEvent(props: TimeGridEventProps) {
    const { style, className, event, selected, onClick, onDoubleClick, onKeyPress } = props;

    const isMaster = useUser().role === TeamMemberRole.master;
    const userProps = getEventStyle(event, isMaster, selected);
    const { height, top, width, xOffset } = style;
    const eventStyle = {
        ...userProps.style,
        top: stringifyPercent(top),
        width: stringifyPercent(width),
        height: stringifyPercent(height),
        left: stringifyPercent(xOffset),
    };

    const { start, end } = event;
    const lines = getEventSize(event);
    const startTime = start.toLocaleString(DateTime.TIME_SIMPLE);
    const endTime = end.toLocaleString(DateTime.TIME_SIMPLE);

    return (
        <EventWrapper {...props} type='time'>
            <div
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                style={eventStyle}
                onKeyPress={onKeyPress}
                title={`${startTime} - ${endTime}, ${event.title}`}
                className={cn(
                    'rbc-event absolute min-h-4 max-h-full px-2 overflow-hidden',
                    selected && 'shadow',
                    className,
                    userProps.className,
                    lines < 2 && 'text-sm',
                    lines === 2 && 'py-1 leading-5',
                    lines > 2 && 'py-1 leading-5 flex flex-col',
                )}
            >
                {lines > 2 ? (<>
                    <div className='shrink overflow-hidden break-words'>
                        <EventTitle event={event} />
                    </div>
                    <div className='font-normal text-sm/4 whitespace-nowrap'>
                        {`${startTime} - ${endTime}`}
                    </div>
                </>) : (
                    <div className='whitespace-nowrap'>
                        <EventTitle event={event} />
                        <span className='font-normal text-sm'>{`, ${startTime}`}</span>
                    </div>
                )}
            </div>
        </EventWrapper>
    );
}

function stringifyPercent(value: string | number) {
    return typeof value === 'string' ? value : value + '%';
}

type EventTitleProps = Readonly<{
    event: CalendarEvent;
}>;

export function EventTitle(props: EventTitleProps) {
    if (props.event.resource.type !== 'event')
        return props.event.title;

    const { event } = props.event.resource;

    return (<>
        {event.isInPackage ? (
            <span className='whitespace-nowrap'>{event.packageProgress}{' '}</span>
        ) : event.recurrenceIndex !== undefined ? (
            <span className='whitespace-nowrap'>#{event.recurrenceIndex + 1}{' '}</span>
        ) : null}
        {event.title}
    </>);
}

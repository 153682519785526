import { ApiAuthorizer, createApiObject } from './types';
import superjson from 'superjson';
import type { Result } from ':frontend/types/result';
import type { AppUserGoogleInit } from ':utils/entity/user';
import type { VersionOutput } from ':frontend/components/notifications/NewVersionNotification';
import type { OrderInit, ExportOrdersQuery } from ':utils/entity/order';
import { routesBE } from ':utils/routes';

const publicApiObject = createApiObject();

const authorizer = new ApiAuthorizer();
const privateApiObject = createApiObject({
    authorizer,
});

type GoogleAuthFromServer = {
    redirectUrl: string;
};

function googleRegistration(query: AppUserGoogleInit): Promise<Result<GoogleAuthFromServer>> {
    return publicApiObject.POST<GoogleAuthFromServer, AppUserGoogleInit>(routesBE.google.oauth, query);
}

function googleLogin(): Promise<Result<GoogleAuthFromServer>> {
    return publicApiObject.POST<GoogleAuthFromServer>(routesBE.google.oauth);
}

function googleIntegration(): Promise<Result<GoogleAuthFromServer>> {
    return privateApiObject.POST<GoogleAuthFromServer>(routesBE.google.integration);
}

function getVersion(signal?: AbortSignal): Promise<Result<VersionOutput | null>> {
    return publicApiObject.GET<VersionOutput | null>(routesBE.public.version, {}, { signal });
}

function exportOrders(query: ExportOrdersQuery): Promise<Result<Blob>> {
    const serialized = superjson.serialize(query) as { json: unknown, meta: unknown };
    return privateApiObject.POST<Blob>(routesBE.private.ordersExport, serialized, { responseType: 'blob' });
}

function getInvoicePreview(query: OrderInit, signal?: AbortSignal): Promise<Result<Blob>> {
    return privateApiObject.POST<Blob>(routesBE.private.invoicePreview, query, { responseType: 'blob', signal });
}

function getInvoicingProfileExample(id: string, signal?: AbortSignal): Promise<Result<Blob>> {
    return privateApiObject.GET<Blob>(routesBE.private.invoicingProfileExample.resolve({ id }), {}, { responseType: 'blob', signal });
}

export const backendApi = {
    authorizer,
    googleRegistration,
    googleLogin,
    googleIntegration,
    getVersion,
    exportOrders,
    getInvoicePreview,
    getInvoicingProfileExample,
};

import { useCallback, useEffect, useState, type FocusEvent } from 'react';
import { Button, Form, Spinner, Modal } from ':components/shadcn';
import { type EventFE, EventsOperationOutputFE, MAX_NOTES_LENGTH } from ':frontend/types/Event';
import { useTranslation } from 'react-i18next';
import { EditingPhase, useEditing, useToggle, type UseToggleSet } from ':frontend/hooks';
import { useCached } from ':components/hooks';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from '../notifications';
import { SpinnerButton } from '../common';
import { type ClientInfoFE } from ':frontend/types/Client';
import type { UseEventDispatch, UseEventState } from './useEvent';
import { Link } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { trpc } from ':frontend/context/TrpcProvider';
import { RecurrenceRange } from ':utils/entity/event';
import { ArrowsExpandDiagonal6Icon, ArrowsReduceDiagonal1Icon, Check1Icon, NotepadIcon, PaperPlane1Icon } from ':components/icons/basic';

type StateDispatchProps = Readonly<{
    state: UseEventState;
    dispatch: UseEventDispatch;
}>;

export function EventNotes({ state, dispatch }: StateDispatchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes' });
    const isInitiallyVisible = !!state.event?.notes;
    const [ isVisible, setIsVisible ] = useState(isInitiallyVisible);

    return (
        <div>
            <div className='flex items-center gap-2 cursor-pointer' onClick={() => setIsVisible(!isVisible)}>
                <NotepadIcon className='text-warning-500' />

                <h3 className='grow text-secondary-700'>{t('title')}</h3>

                <Button variant='transparent' size='exact' aria-label={t(isVisible ? 'hide-aria' : 'show-aria')}>
                    {isVisible ? <ArrowsReduceDiagonal1Icon /> : <ArrowsExpandDiagonal6Icon />}
                </Button>
            </div>

            <div className='mt-4 w-full h-px bg-secondary-100' />

            {isVisible && (
                <div className='py-2'>
                    {state.event ? (
                        <EditableEventNotes state={state} dispatch={dispatch} isInitiallyVisible={isInitiallyVisible} />
                    ) : (
                        <InitialEventNotes state={state} dispatch={dispatch} isInitiallyVisible={isInitiallyVisible} />
                    )}
                </div>
            )}
        </div>
    );
}

function InitialEventNotes({ state, dispatch, isInitiallyVisible }: StateDispatchProps & { isInitiallyVisible: boolean }) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes' });
    const [ isFocus, setIsFocus ] = useToggle(false);

    return (
        <div>
            <Form.Textarea
                autoFocus={!isInitiallyVisible}
                className='bg-warning-100'
                label={t('label')}
                labelClassName='sr-only'
                placeholder={t('placeholder')}
                minRows={3}
                maxRows={isFocus ? undefined : 3}
                value={state.form.initialNotes}
                onChange={e => dispatch({ type: 'input', field: 'initialNotes', value: e.target.value })}
                onBlur={setIsFocus.false}
                onFocus={setIsFocus.true}
            />
        </div>
    );
}

function maxLengthRule(value: string) {
    return value.length <= MAX_NOTES_LENGTH;
}

function EditableEventNotes({ state, dispatch, isInitiallyVisible }: StateDispatchProps & { isInitiallyVisible: boolean }) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes' });
    const [ isFocus, setIsFocus ] = useToggle(false);
    const event = state.update?.event ?? state.event!;
    const onUpdate = useCallback((updatedEvent: EventFE) => dispatch({ type: 'update', notes: updatedEvent.notes, eventId: updatedEvent.id }), [ dispatch ]);
    const { innerValue, phase, setValue, onBlur } = useEditableEventNotes(event, onUpdate, setIsFocus);

    const isUpdating = phase === EditingPhase.Updating;

    return (
        <div className='relative'>
            <Form.Textarea
                autoFocus={!isInitiallyVisible}
                className='bg-warning-100'
                label={t('label')}
                labelClassName='sr-only'
                placeholder={t('placeholder')}
                minRows={3}
                maxRows={isFocus ? undefined : 3}
                value={innerValue}
                onChange={e => setValue(e.target.value)}
                onBlur={onBlur}
                disabled={isUpdating}
                onFocus={setIsFocus.true}
            />

            <div className='absolute right-2 bottom-2 '>
                {isUpdating ? (
                    <Spinner size='xs' />
                ) : (
                    <Check1Icon size='sm' />
                )}
            </div>

            {event.guests.length === 1 && phase === EditingPhase.View && (
                <Link
                    to={routesFE.clients.detail.resolve({ id: event.guests[0].client.id, key: 'notes' }, { '#fragment': event.id })}
                    className='absolute right-2 top-2 text-inherit'
                >
                    <Button variant='transparent' size='exact' className='fl-blur-button-target'>
                        <PaperPlane1Icon size='sm' />
                    </Button>
                </Link>
            )}
        </div>
    );
}

function useEditableEventNotes(event: EventFE, onUpdate: (event: EventFE) => void, setIsFocus: UseToggleSet) {
    const { addAlert } = useNotifications();

    const updateEventMutation = trpc.event.updateEvent.useMutation();

    const syncNotes = useCallback(async (notes: string) => {
        try {
            const response = await updateEventMutation.mutateAsync({ id: event.id, range: RecurrenceRange.This, sendNotification: false, notes });
            const newEvent = EventsOperationOutputFE.fromServer(response).events.find(e => e.id === event.id);
            if (!newEvent)
                return false;

            onUpdate(newEvent);
            addAlert(createTranslatedSuccessAlert('components:eventNotes.update-success'));

            return true;
        }
        catch {
            addAlert(createTranslatedErrorAlert());
            return false;
        }
    }, [ addAlert, onUpdate, event.id, updateEventMutation ]);

    const { state: { value: innerValue, phase }, setValue, doUpdate } = useEditing(event.notes, syncNotes, { rule: maxLengthRule });

    async function onBlur(event: FocusEvent) {
        const isTargetButton = event.relatedTarget?.classList.contains('fl-blur-button-target');
        if (isTargetButton) {
            // If the user clicks on the delete button, we want to fire only the button's action.
            setIsFocus.false();
            return;
        }

        await doUpdate();
        setIsFocus.false();
    }

    return { innerValue, phase, setValue, onBlur, doUpdate };
}

type SendNotesButtonProps = Readonly<{
    event: EventFE;
    client: ClientInfoFE;
    className?: string;
    disabled?: boolean;
}>;

export function SendNotesButton({ event, client, className, disabled }: SendNotesButtonProps) {
    const [ showModal, setShowModal ] = useToggle(false);

    return (<>
        <SendNotesModal
            event={showModal ? event : undefined}
            client={client}
            onHide={setShowModal.false}
        />

        <Button
            variant='transparent'
            className={className}
            onClick={setShowModal.true}
            disabled={disabled}
            size='exact'
        >
            <PaperPlane1Icon size='md' />
        </Button>
    </>);
}

type SendNotesModalProps = Readonly<{
    event?: EventFE;
    client: ClientInfoFE;
    onHide: () => void;
}>;

function SendNotesModal({ event, client, onHide }: SendNotesModalProps) {
    const { t } = useTranslation('components', { keyPrefix: 'eventNotes.sendNotesModal' });

    const cached = useCached(event);
    const [ isFetching, setIsFetching ] = useState(false);
    const [ message, setMessage ] = useState(cached?.notes ?? '');
    const [ isForAll, setIsForAll ] = useState(true);

    useEffect(() => {
        if (event) {
            setMessage(event.notes);
        }
        else {
            setIsFetching(false);
            setIsForAll(true);
        }
    }, [ event ]);

    const sendNotesMutation = trpc.event.sendEventNotes.useMutation();
    const { addAlert } = useNotifications();

    if (!cached)
        return null;

    // The first one might be a client, the rest have to be guests.
    const firstGuest = [ ...cached.guests, ...cached.clients ].find(p => p.client.id === client.id)!;
    const restGuests = cached.guests.filter(p => p.client.id !== client.id);

    function sendNotes() {
        const participantIds = isForAll
            ? [ firstGuest.id, ...restGuests.map(p => p.id) ]
            : [ firstGuest.id ];

        setIsFetching(true);
        sendNotesMutation.mutate({ participantIds, message }, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: () => {
                addAlert(createTranslatedSuccessAlert('components:eventNotes.sendNotesModal.success'));
                onHide();
            },
            onSettled: () => {
                setIsFetching(false);
            },
        });
    }

    return (
        <Modal.Root open={!!event} onOpenChange={open => !open && onHide()}>
            <Modal.Content className='border-0 gap-4' closeButton={t('cancel-button')} closeDisabled={isFetching}>
                <Modal.Header>
                    <Modal.Title className='text-3xl leading-9 text-center font-semibold text-secondary-900'>{t('title')}</Modal.Title>
                </Modal.Header>

                <div className='leading-6 text-lg flex justify-center gap-2'>
                    <div>{t('to-label')}</div>

                    <div className='font-semibold'>
                        <div>{firstGuest.client.email}</div>
                        {isForAll && restGuests.map(p => (
                            <div key={p.id}>{p.client.email}</div>
                        ))}
                    </div>
                </div>

                {restGuests.length > 0 && (
                    <div className='flex items-center gap-2'>
                        <Form.Switch label={t('isForAll-label')} checked={isForAll} onCheckedChange={setIsForAll} />
                    </div>
                )}

                <div>
                    <Form.Label>{t('message-label')}</Form.Label>
                    <Form.Textarea
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        minRows={3}
                    />
                </div>


                <Modal.Footer className='flex flex-col gap-2'>
                    <SpinnerButton
                        onClick={sendNotes}
                        variant='primary'
                        isFetching={isFetching}
                    >
                        {t('send-button')}
                    </SpinnerButton>

                    <Button variant='outline' onClick={onHide}>
                        {t('cancel-button')}
                    </Button>
                </Modal.Footer>
            </Modal.Content>
        </Modal.Root>
    );
}

import { routeToDisplayString, routesFE } from ':utils/routes';
import { pricingFromServer, ProductType, type ProductOutput, type ProductPricing } from ':utils/entity/product';
import type { IconType } from ':components/icons/common';
import { LinkProductIcon, DigitalProductIcon, LeadProductIcon, MembershipProductIcon, BundleProductIcon, SessionProductIcon, CustomProductIcon } from ':components/icons/store';
import { CircleHalfDottedClockIcon, CircleMinusIcon, MoneyBillsDollarIcon, NumberInputIcon } from ':components/icons/basic';
import clsx from 'clsx';
import { forwardRef, type HTMLAttributes, type ReactNode } from 'react';
import { MoneyDisplay } from ':components/custom';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import { locationPlatformTranslation, simpleLocationIcon } from ':components/icons/location';
import { Button, Card, Skeleton } from ':components/shadcn';
import { linkify, secondsToMinutes } from ':utils/common';
import type { LocationOutput } from ':utils/entity/location';
import type { Id } from ':utils/id';
import type { FileData } from ':utils/entity/file';
import type { Money, TaxRateFE } from ':utils/money';
import { cn } from ':components/shadcn/utils';

// Make sure the PublicDisplay and PhonePreview functions are always in sync.
// The reason is that it's much easier to have two functions than to somehow simulate different screen size.

type ProductPublicDisplayProps = Readonly<{
    product: ProductOutput;
    actionComponent: ReactNode;
    editComponent?: ReactNode;
}>;

export function ProductPublicDisplay({ product, actionComponent, editComponent }: ProductPublicDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const styles = productStyles[product.type];
    const attributes = renderProductAttributes(product, t);

    // FIXME will routesFE.files.uploads work in the store?
    return (
        <div className='fl-store-font fl-store-card fl-store-shadow bg-white p-6 w-full max-w-[600px] flex flex-col gap-4'>
            {product.thumbnail && (
                <img className='rounded-full w-14 h-14' src={routesFE.files.uploads(product.thumbnail.hashName)} />
            )}
            <div className='flex items-center justify-between gap-2'>
                <h3 className='flex items-center gap-2 overflow-hidden'>
                    {styles.icon({ size: 22, className: 'shrink-0' })}
                    <span className={clsx(styles.color, 'text-lg/5 font-semibold truncate')}>
                        {product.title}
                    </span>
                </h3>
                {editComponent}
            </div>
            {product.description && (
                <p className='leading-5 whitespace-pre-line'>
                    {product.description}
                </p>
            )}
            <div className='flex max-sm:flex-col flex-wrap gap-4 sm:gap-2 sm:items-center overflow-hidden empty:hidden'>
                <ProductPricingBadge pricing={product.pricing} type={product.type} />

                {attributes.length > 0 && (<>
                    <div className='sm:hidden w-full h-px bg-secondary-100' />
                    {...attributes}
                </>)}

                {(product.type !== ProductType.Link || !product.isHideButton) && (
                    <div className='min-w-0 grow flex'>
                        <div className='grow max-sm:hidden' />
                        <Button asChild className='fl-store-button max-sm:w-full truncate' size='small'>
                            {actionComponent}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

export type ProductPreview = ProductWithAttributes & {
    id: Id;
    title: string;
    pricing?: ProductPricing;
    thumbnail?: FileData;
    description?: string;
    buttonText: string;
};

/**
 * Copy of the previous function but only for phones. And with preview data.
 */
export function ProductPhonePreviewDisplay({ product }: Readonly<{ product: ProductPreview }>) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const styles = productStyles[product.type];
    const attributes = renderProductAttributes(product, t);

    return (
        <div className='fl-store-font fl-store-card fl-store-shadow bg-white p-6 w-full max-w-[600px] flex flex-col gap-4'>
            {product.thumbnail && (
                <img className='rounded-full w-14 h-14' src={product.thumbnail.dataUrl} />
            )}
            <div>
                <h3 className='flex items-center gap-2'>
                    {styles.icon({ size: 22, className: 'shrink-0' })}
                    <span className={clsx(styles.color, 'text-lg/5 font-semibold truncate')}>
                        {product.title}
                    </span>
                </h3>
            </div>
            {product.description && (
                <p className='leading-5 whitespace-pre-line'>
                    {product.description}
                </p>
            )}
            <div className='flex flex-col flex-wrap gap-2 overflow-hidden empty:hidden'>
                <ProductPricingBadge pricing={product.pricing} type={product.type} />

                {attributes.length > 0 && (<>
                    <div className='w-full h-px bg-secondary-100' />
                    {...attributes}
                </>)}

                {(product.type !== ProductType.Link || !product.isHideButton) && (
                    <div className='grow flex'>
                        <div className='grow max-sm:hidden' />
                        <Button className='fl-store-button w-full' size='small'>
                            {product.buttonText}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

type ProductDirectSaleDisplayProps = HTMLAttributes<HTMLDivElement> & Readonly<{
    product: ProductOutput;
    topRight?: ReactNode;
    bottomRight?: ReactNode;
}>;

export const ProductDisplay = forwardRef<HTMLDivElement, ProductDirectSaleDisplayProps>(({ product, topRight, bottomRight, className, ...props }, ref) => {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const styles = productStyles[product.type];
    const attributes = renderProductAttributes(product, t);

    return (
        <Card className={cn('w-full h-[250px] rounded-2xl flex flex-col gap-4', className)} ref={ref} {...props}>
            <div className='w-full flex items-center justify-between'>
                <h3 className='min-w-0 flex items-center gap-2'>
                    {styles.icon({ size: 22, className: 'shrink-0' })}
                    <span className={clsx(styles.color, 'text-lg/5 font-semibold truncate')}>
                        {product.title}
                    </span>
                </h3>
                {topRight}
            </div>

            {attributes.length > 0 && (<>
                <div className='w-full h-px bg-secondary-100' />
                {...attributes}
            </>)}

            <div className='grow' />

            <div className='h-9 flex items-center w-full'>
                <ProductPricingBadge pricing={product.pricing} type={product.type} />
                <div className='grow' />
                {bottomRight}
            </div>
        </Card>
    );
});

export function ProductDirectSaleSkeleton() {
    return <Skeleton className='w-full h-[250px]' />;
}

type ProductForInvoiceItem = ProductWithAttributes & {
    title: string;
};

type ProductInvoiceItemDisplayProps = Readonly<{
    product: ProductForInvoiceItem;
    onRemove?: () => void;
    price: Money | undefined;
    vat: TaxRateFE;
}>;

export function ProductInvoiceItemDisplay({ product, onRemove, price, vat }: ProductInvoiceItemDisplayProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const styles = productStyles[product.type];
    const attributes = renderProductAttributes(product, t);

    return (
        <Card className='flex items-center gap-4'>
            <div className='grow min-w-0 space-y-4'>
                <div className='max-md:space-y-2 md:flex md:items-center md:justify-between md:gap-4'>
                    <h3 className='min-w-0 w-full flex items-center gap-2'>
                        {styles.icon({ size: 22, className: 'shrink-0' })}

                        <div className={clsx(styles.color, 'text-lg/5 font-semibold truncate')}>
                            {product.title}
                        </div>
                    </h3>

                    <ProductPricingLabel type={product.type} price={price} vat={vat} />
                </div>

                {attributes.length > 0 && (<>
                    <div className='w-full h-px bg-secondary-100' />

                    <div className='max-sm:space-y-2 sm:h-4 sm:flex sm:items-center sm:gap-4'>
                    {...attributes}
                    </div>
                </>)}
            </div>

            {onRemove && (
                <Button variant='transparent' size='exact' className='shrink-0' onClick={onRemove} aria-label={t('remove-button')}>
                    <CircleMinusIcon />
                </Button>
            )}
        </Card>
    );
}

export function ProductCheckoutItemDisplay({ product }: Readonly<{ product: ProductOutput }>) {
    const styles = productStyles[product.type];

    return (
        <div className='w-full bg-white border p-6 rounded-2xl shadow-[0px_5px_15px_0px_rgba(0,0,0,0.05)] max-md:space-y-2 md:flex md:items-center md:justify-between md:gap-4'>
            <h3 className='min-w-0 w-full flex items-center gap-2'>
                {styles.icon({ size: 22, className: 'shrink-0' })}

                <div className={clsx(styles.color, 'text-lg/5 font-semibold truncate')}>
                    {product.title}
                </div>
            </h3>

            <ProductPricingLabel type={product.type} pricing={product.pricing} />
        </div>
    );
}

export function ProductSelectOption({ data: { value: product } }: Readonly<{ data: { value: ProductOutput }} >) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });
    const styles = productStyles[product.type];
    const attributes = renderProductAttributes(product, t);

    return (
        // There's already some padding from the default option component.
        <div className='grow overflow-hidden py-2 rounded'>
            <div className='flex items-center justify-between gap-4'>
                <div className='min-w-0 flex items-center justify-between gap-2'>
                    {styles.icon({ size: 'sm', className: 'shrink-0' })}
                    <span className={clsx(styles.color, 'text-lg/5 font-semibold truncate')}>
                        {product.title}
                    </span>
                </div>
                <ProductPricingLabel type={product.type} pricing={product.pricing} />
            </div>
            {attributes.length > 0 && (
                <div className='mt-1 h-4 flex items-center gap-3'>
                {...attributes}
                </div>
            )}
        </div>
    );
}

function styledIcon(icon: IconType, type: ProductType): ReactNode {
    return icon({ size: 'md', className: productStyles[type].iconColor });
}

type ProductPricingBadgeProps = Readonly<{
    pricing: ProductPricing | undefined;
    type: ProductType;
    className?: string;
}>;

function ProductPricingBadge({ pricing, type, className }: ProductPricingBadgeProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });

    if (type === ProductType.Link)
        return null;

    const outerClassName = clsx('w-fit flex items-center gap-2 py-2 px-4 rounded-full border', productStyles[type].border, className);

    if (!pricing) {
        return (
            <div className={outerClassName}>
                <div className='text-secondary-600'>{t('free-price')}</div>
            </div>
        );
    }

    const { price, originalPrice } = pricingFromServer(pricing);

    // TODO membership - use /mo for month, /wk for week.
    // /m., /t. should be ok for cz. Or just use the english variant?

    return (
        <div className={outerClassName}>
            {styledIcon(MoneyBillsDollarIcon, type)}
            {originalPrice && <MoneyDisplay money={originalPrice} className='line-through text-secondary-400' />}
            <MoneyDisplay money={price} />
        </div>
    );
}

type ProductPricingLabelProps = Readonly<{
    type: ProductType;
    className?: string;
} & ({
    pricing: ProductPricing | undefined;
} | {
    price: Money | undefined;
    vat: TaxRateFE;
})>;

export function ProductPricingLabel({ type, className, ...rest }: ProductPricingLabelProps) {
    const { t } = useTranslation('components', { keyPrefix: 'productPublicDisplay' });

    if (type === ProductType.Link)
        return null;

    let price: Money | undefined;
    let vat: TaxRateFE | undefined;

    if ('price' in rest) {
        price = rest.price;
        vat = rest.vat;
    }
    else if (rest.pricing) {
        const parsed = pricingFromServer(rest.pricing);
        price = parsed.price;
        vat = parsed.vat;
    }

    if (!price || price.amount === 0) {
        return (
            <div className={className}>
                <div className='font-semibold'>{t('free-price')}</div>
            </div>
        );
    }

    return (
        <div className={clsx('flex items-center', className)}>
            {vat && !vat.isZero && (
                <div className='font-semibold mr-9'>{vat.label}</div>
            )}
            <MoneyDisplay money={price} />
        </div>
    );
}

type ProductWithAttributes = {
    type: ProductType;
    sessionsCount?: number;
    sessionsDuration?: number;
    location?: LocationOutput;
    url?: string;
    isHideUrl?: boolean;
    isHideButton?: boolean;
    // TODO add more
};

export function renderProductAttributes(product: ProductWithAttributes, t: TFunction): ReactNode[] {
    const styles = productStyles[product.type];
    const common: { icon: IconType, text: string }[] = [];

    if (product.sessionsCount)
        common.push({ icon: NumberInputIcon, text: t('sessionsCount', { count: product.sessionsCount }) });
    if (product.sessionsDuration)
        common.push({ icon: CircleHalfDottedClockIcon, text: t(product.type === ProductType.Session ? 'duration' : 'duration-per-session', { count: secondsToMinutes(product.sessionsDuration) }) });
    if (product.location)
        common.push({ icon: simpleLocationIcon(product.location), text: locationPlatformTranslation(product.location, t) });

    // TODO Digital product stuff (but not url, that's private).

    const output: ReactNode[] = common.map(({ icon, text }, i) => (
        <div key={i} className='min-w-0 flex gap-1 items-center'>
            {icon({ size: 'sm', className: clsx('shrink-0', styles.iconColor) })}
            <span className='truncate'>{text}</span>
        </div>
    ));

    // Link product shows url (that's public, because there's no price). Digital product doesn't, because its url is private.
    if (!product.isHideUrl && product.url && product.type === ProductType.Link) {
        output.push(
            <LinkWithFavicon key='url' type={product.type} url={product.url} />,
        );
    }

    return output;
}

function LinkWithFavicon({ type, url }: Readonly<{ type: ProductType, url: string }>) {
    // If the site isn't indexed by google, it won't show here. So that might be a wake-up call for our users.
    // However, we should probably add some warning.
    return (
        <a href={linkify(url)} target='_blank' rel='noreferrer'
            className={clsx('w-fit max-w-full flex items-center gap-1 py-2 px-4 rounded-full border hover:underline', productStyles[type].border)}
        >
            <img src={`https://www.google.com/s2/favicons?domain=${url}`} alt='' className='shrink-0 size-4' />
            <div className='truncate'>{routeToDisplayString(url)}</div>
        </a>
    );
}

export const productStyles: {
    [key in ProductType]: {
        icon: IconType;
        iconColor: string;
        color: string;
        bg: string;
        border: string;
    };
} = {
    [ProductType.Session]: { icon: SessionProductIcon, iconColor: 'text-[#c48f00]', color: 'text-[#6b4e00]', bg: 'bg-[#ffeab3]', border: 'border-[#e7d299]' },
    [ProductType.Bundle]: { icon: BundleProductIcon, iconColor: 'text-[#b200cf]', color: 'text-[#5c006b]', bg: 'bg-[#f3d9f8]', border: 'border-[#e099ec]' },
    [ProductType.Digital]: { icon: DigitalProductIcon, iconColor: 'text-[#00cf5f]', color: 'text-[#006b31]', bg: 'bg-[#d9f8e7]', border: 'border-[#99ecbf]' },
    [ProductType.Lead]: { icon: LeadProductIcon, iconColor: 'text-[#4b4da3]', color: 'text-[#31326b]', bg: 'bg-[#e4e4f1]', border: 'border-[#b7b8da]' },
    [ProductType.Membership]: { icon: MembershipProductIcon, iconColor: 'text-[#96031a]', color: 'text-[#6b0213]', bg: 'bg-[#dfb3ba]', border: 'border-[#d59aa3]' },
    [ProductType.Link]: { icon: LinkProductIcon, iconColor: 'text-[#009c80]', color: 'text-[#006956]', bg: 'bg-[#b2e1d9]', border: 'border-[#99d7cc]' },
    [ProductType.Custom]: { icon: CustomProductIcon, iconColor: 'text-[#b20baa]', color: 'text-[#690664]', bg: 'bg-[#f3d9f8]', border: 'border-[#e09ddd]' },
};

import { LockIcon } from ':components/icons/basic';
import { Form } from ':components/shadcn';
import { useTranslation } from 'react-i18next';

export function CustomDomain() {
    const { t } = useTranslation('pages', { keyPrefix: 'settings.general.customDomain' });

    return (
        <div className='max-sm:space-y-4 sm:flex sm:items-center sm:justify-between sm:gap-4'>
            <div className='flex items-center gap-2.5 h-full'>
                <Form.Switch label={t('label')} disabled />
            </div>

            <div className='inline-flex items-center justify-center gap-2 text-primary bg-primary-50 px-5 py-2 rounded-full select-none'>
                <LockIcon size='sm' />
                {t('coming-soon')}
            </div>
        </div>
    );
}

import { forwardRef, useCallback, useMemo, useState } from 'react';
import { ClientInfoFE } from ':frontend/types/Client';
import { ClientTagFE, sortClientTags } from ':frontend/types/ClientTag';
import { Button, DropdownMenu, Form } from ':components/shadcn';
import { type Id } from ':utils/id';
import { useMaster } from ':frontend/context/UserProvider';
import { useTranslation } from 'react-i18next';
import { useToggle } from ':frontend/hooks';
import { SpinnerButton } from '../common';
import { trpc } from ':frontend/context/TrpcProvider';
import { Trash2Icon, XmarkIcon, Check1Icon, DotsIcon } from ':components/icons/basic';
import { UpsellModal } from '../upsell/UpsellModal';
import { cn } from ':components/shadcn/utils';

type ClientTagButtonProps = Readonly<{
    tag: { name: string, color: string };
    onClick?: () => void;
}>;

export const ClientTagButton = forwardRef<HTMLButtonElement, ClientTagButtonProps>(({ tag, onClick }, ref) => {
    return (
        <button
            ref={ref}
            className='font-semibold px-2 py-[2px] text-sm rounded-full whitespace-nowrap tracking-wide hover:opacity-80 active:opacity-60'
            style={{ backgroundColor: `#${tag.color}` }}
            onClick={onClick}
        >
            {tag.name}
        </button>
    );
});

type ClientTagDisplayProps = Readonly<{
    tag: { name: string, color: string };
}>;

export function ClientTagDisplay({ tag }: ClientTagDisplayProps) {
    return (
        <div className='font-semibold px-2 py-[2px] text-sm rounded-full whitespace-nowrap tracking-wide' style={{ backgroundColor: `#${tag.color}` }}>
            {tag.name}
        </div>
    );
}

type ClientTagsProps = Readonly<{
    client: ClientInfoFE;
    setClient: (client: ClientInfoFE) => void;
    className?: string;
}>;

export function ClientTags({ client, setClient, className }: ClientTagsProps) {
    const { clientTags } = useMaster();
    // All tags are ordered the same way, i.e., by their ids.
    const tags = useMemo(() => clientTags.filter(tag => client.tagIds.some(tagId => tag.id === tagId)), [ clientTags, client.tagIds ]);

    const [ show, setShow ] = useToggle(false);
    const [ showUpsell, setShowUpsell ] = useToggle(false);
    const [ editedTagId, setEditedTagId ] = useState<Id>();

    const onEditClose = useCallback(() => setEditedTagId(undefined), []);

    return (<>
        <div className={cn('flex items-center justify-center gap-1 flex-wrap', className)}>
            {tags.map(tag => (
                <DropdownMenu.Root key={tag.id} open={editedTagId === tag.id} onOpenChange={onEditClose}>
                    <DropdownMenu.Trigger asChild>
                        <ClientTagButton key={tag.id} tag={tag} onClick={() => setEditedTagId(tag.id)} />
                    </DropdownMenu.Trigger>

                    <DropdownMenu.Content className='p-0'>
                        {editedTagId === tag.id && (
                            <EditTagMenu client={client} setClient={setClient} tag={tag} onClose={onEditClose} />
                        )}
                    </DropdownMenu.Content>
                </DropdownMenu.Root>
            ))}


            <DropdownMenu.Root open={show} onOpenChange={setShow.value}>
                <DropdownMenu.Trigger asChild>
                    <button className='px-2 h-5 text-sm rounded-md whitespace-nowrap bg-secondary-100 hover:opacity-80'>Add tag</button>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content className='p-0 md:min-w-[300px] max-w-[80vw]'>
                    {show && (
                        <AddTagMenu client={client} setClient={setClient} tags={tags} onClose={setShow.false} onError={setShowUpsell.true} />
                    )}
                </DropdownMenu.Content>
            </DropdownMenu.Root>

        </div>

        <UpsellModal isOpen={showUpsell} onClose={setShowUpsell.false} />
    </>);
}

type AddTagMenuProps = Readonly<{
    client: ClientInfoFE;
    setClient: (client: ClientInfoFE) => void;
    tags: ClientTagFE[];
    onClose: () => void;
    onError: () => void;
}>;

function AddTagMenu({ client, setClient, tags, onClose, onError }: AddTagMenuProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientTags' });
    const { clientTags, setClientTags } = useMaster();
    const [ query, setQuery ] = useState('');
    const [ fetching, setFetching ] = useState<string>();
    const createTagMutation = trpc.$client.createClientTag.useMutation();
    const updateTagsMutation = trpc.$client.updateClientsTags.useMutation();

    const availableTags = useMemo(() => clientTags.filter(tag => !tags.some(clientTag => clientTag.id === tag.id)), [ clientTags, tags ]);
    const filteredTags = useMemo(() =>
        query.trim().length === 0
            ? availableTags
            : availableTags.filter(tag => tag.name.toLowerCase().includes(query.toLowerCase())),
    [ availableTags, query ]);
    const trimmedTags = useMemo(() => filteredTags.slice(0, MAX_DISPLAYED_TAGS), [ filteredTags ]);

    function createTag(input: string) {
        setFetching(FID_CREATE);
        createTagMutation.mutate({ name: input.trim(), color: newTagColor, clientId: client.id }, {
            onSuccess: response => {
                const newTag = ClientTagFE.fromServer(response);
                setClientTags(oldTags => sortClientTags([ ...oldTags, newTag ]));

                client.tagIds.push(newTag.id);
                setClient(client);

                onClose?.();
            },
            onError: onError,
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    const newTagColor = useMemo(() => findNextColor(clientTags), [ clientTags ]);
    const isNewEnabled = query.trim().length > 0 && !filteredTags.some(tag => tag.name === query);

    function addTag(tag: ClientTagFE) {
        setFetching(tag.id);
        updateTagsMutation.mutate({ clientId: client.id, tagId: tag.id, type: 'add' }, {
            onSuccess: response => {
                const updatedClient = ClientInfoFE.fromServer(response);
                setClient(updatedClient);
                onClose?.();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    return (
        <div className='p-2 flex flex-col gap-1 items-stretch'>
            <Form.Input
                size='compact'
                value={query}
                onChange={e => setQuery(e.target.value)}
                placeholder={t('query-placeholder')}
                autoFocus
            />

            {trimmedTags.map(tag => (
                <SpinnerButton
                    key={tag.id}
                    variant='transparent'
                    size='exact'
                    className='h-9 px-3 rounded-sm justify-start hover:bg-primary-50 active:bg-primary-100'
                    onClick={() => addTag(tag)}
                    fetching={fetching}
                    fid={tag.id}
                >
                    <ClientTagDisplay tag={tag} />
                </SpinnerButton>
            ))}

            {filteredTags.length > MAX_DISPLAYED_TAGS && (
                <div className='text-secondary flex justify-center'>
                    <DotsIcon size='md' />
                </div>
            )}

            {isNewEnabled && (
                <SpinnerButton
                    variant='transparent'
                    size='exact'
                    className='h-9 px-3 rounded-sm justify-start hover:bg-primary-50 active:bg-primary-100'
                    onClick={() => createTag(query)}
                    fetching={fetching}
                    fid={FID_CREATE}
                >
                    {t('create-button')}
                    <ClientTagDisplay tag={{ name: query, color: newTagColor }} />
                </SpinnerButton>
            )}
        </div>
    );
}

const MAX_DISPLAYED_TAGS = 5;
const FID_CREATE = 'create';

type EditTagMenuProps = Readonly<{
    client: ClientInfoFE;
    setClient: (client: ClientInfoFE) => void;
    tag: ClientTagFE;
    onClose: () => void;
}>;

function EditTagMenu({ client, setClient, tag, onClose }: EditTagMenuProps) {
    const { t } = useTranslation('components', { keyPrefix: 'clientTags' });
    const { t: tc } = useTranslation('common', { keyPrefix: 'colors' });
    const { setClientTags } = useMaster();

    const [ formName, setFormName ] = useState(tag.name);
    const [ formHex, setFormHex ] = useState(tag.color);

    const [ fetching, setFetching ] = useState<string>();

    const updateTagMutation = trpc.$client.updateClientTag.useMutation();
    const deleteTagMutation = trpc.$client.deleteClientTag.useMutation();
    const updateTagsMutation = trpc.$client.updateClientsTags.useMutation();

    function removeTag() {
        setFetching(FID_REMOVE);
        updateTagsMutation.mutate({ clientId: client.id, tagId: tag.id, type: 'remove' }, {
            // TODO handle error
            onSuccess: response => {
                const newClient = ClientInfoFE.fromServer(response);
                setClient(newClient);
                onClose?.();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    function updateTag() {
        setFetching(FID_UPDATE);
        updateTagMutation.mutate({ id: tag.id, name: formName.trim(), color: formHex }, {
            // TODO handle error
            onSuccess: response => {
                const updatedTag = ClientTagFE.fromServer(response);
                setClientTags(oldTags => sortClientTags(oldTags.map(oldTag => oldTag.id === tag.id ? updatedTag : oldTag)));
                onClose?.();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    function deleteTag() {
        setFetching(FID_DELETE);
        deleteTagMutation.mutate({ id: tag.id }, {
            // TODO handle error
            onSuccess: () => {
                setClientTags(oldTags => oldTags.filter(oldTag => oldTag.id !== tag.id));
                onClose?.();
            },
            onSettled: () => {
                setFetching(undefined);
            },
        });
    }

    const isChange = getChange(tag, formName, formHex);

    return (
        <div className='p-3 space-y-4 max-w-[80vw] md:max-w-[400px]'>
            <div className='space-y-2'>
                <Form.Input
                    label={t('name-label')}
                    size='compact'
                    value={formName}
                    onChange={e => setFormName(e.target.value)}
                    placeholder={t('name-placeholder')}
                    autoFocus
                />

                <div>
                    <Form.Label className='pb-1 m-0'>{t('color-label')}</Form.Label>

                    <div className='flex flex-wrap gap-1'>
                        {Object.entries(PREDEFINED_COLORS).map(([ color, hex ]) => (
                            <Button variant='outline' key={color} size='tiny' className='gap-2 px-2' onClick={() => setFormHex(hex)}>
                                <div className='size-4 rounded-md' style={{ backgroundColor: `#${hex}` }} />

                                <span className='whitespace-nowrap'>{tc(color)}</span>

                                {formHex === hex && (
                                    <Check1Icon />
                                )}
                            </Button>
                        ))}
                    </div>
                </div>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
                <SpinnerButton
                    variant='danger'
                    size='tiny'
                    onClick={removeTag}
                    fetching={fetching}
                    fid={FID_REMOVE}
                >
                    <XmarkIcon size='md' /> {t('remove-button')}
                </SpinnerButton>

                <SpinnerButton
                    variant='danger'
                    size='tiny'
                    onClick={deleteTag}
                    fetching={fetching}
                    fid={FID_DELETE}
                >
                    <Trash2Icon />{t('delete-button')}
                </SpinnerButton>

                {isChange && (
                    <SpinnerButton
                        variant='primary'
                        size='tiny'
                        onClick={updateTag}
                        fetching={fetching}
                        fid={FID_UPDATE}
                    >
                        {t('update-button')}
                    </SpinnerButton>
                )}
            </div>
        </div>
    );
}

const FID_REMOVE = 'remove';
const FID_DELETE = 'delete';
const FID_UPDATE = 'update';

function getChange(tag: ClientTagFE, name?: string, hex?: string): boolean {
    if (!name || !hex)
        return false;

    return tag.name !== name || tag.color !== hex;
}

const PREDEFINED_COLORS = {
    'purple': 'd5bce8',
    'blue': 'b6f1fe',
    'cyan': '88f8f8',
    'green': 'a9f9cd',
    'yellow': 'f9face',
    'orange': 'fac19d',
    'red': 'f88888',
    'pink': 'f7bfee',
} as const;

function findNextColor(tags: ClientTagFE[]): string {
    const usedColors = tags.map(tag => tag.color);
    const allColors = Object.values(PREDEFINED_COLORS);
    const availableColors = allColors.filter(color => !usedColors.includes(color));
    return availableColors[0] ?? allColors[0];
}

import { backendApi } from './backendApi';
import { googleApi } from './googleApi';
import { createApiObject } from './types';

function prepareAbort(): [ AbortSignal, () => void ] {
    const controller = new AbortController();

    return [
        controller.signal,
        () => {
            controller.abort();
        },
    ];
}

const authApi = createApiObject();

export const api = {
    prepareAbort,
    // Backend routes that can't go through trpc or are public (version ...).
    backend: backendApi,
    // Auth routes are handled independently by AuthManager, here is just the raw api object.
    auth: {
        $object: authApi,
    },
    // Google apis (like piss).
    google: googleApi,
};

export * from './types';

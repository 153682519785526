import { useCallback } from 'react';
import { Controller, type Control, type FieldPath, type FieldValues } from 'react-hook-form';
import { ImageInput, type FileInputValue } from ':components/custom';
import { Form, type TranslationFunction } from ':components/shadcn';
import type { ControlledRules } from ':frontend/utils/forms';
import { StringSelect } from ':components/shadcn';

// Wrappers for react-hook-form.

type ControlledImageInput<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    imageClass?: string;
};

export function ControlledImageInput<TFieldValues extends FieldValues>({ control, name, imageClass }: ControlledImageInput<TFieldValues>) {
    // TODO undefined doesn't work here because of the null-undefined problem.
    const InnerInput = useCallback(({ field }: { field: { value: FileInputValue, onChange: (value: FileInputValue ) => void } }) => {
        return (
            <ImageInput
                value={field.value}
                onChange={field.onChange}
                imageClass={imageClass}
            />
        );
    }, []);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerInput}
        />
    );
}

type ControlledSwitchProps<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>;
    name: FieldPath<TFieldValues>;
    label?: string;
    disabled?: boolean;
};

export function ControlledSwitch<TFieldValues extends FieldValues>({ control, name, label, disabled = false }: ControlledSwitchProps<TFieldValues>) {
    const InnerSwitch = useCallback(({ field }: { field: { value: boolean, onChange: (value: boolean) => void } }) => {
        return (
            <Form.Switch
                checked={field.value}
                onCheckedChange={disabled ? undefined : field.onChange}
                label={label}
                disabled={disabled}
            />
        );
    }, [ label, disabled ]);

    return (
        <Controller
            control={control}
            name={name}
            render={InnerSwitch}
        />
    );
}


type ControlledStringSelectProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>> = {
    control: Control<TFieldValues>;
    name: TName;
    rules?: ControlledRules<TFieldValues, TName>;
    options: string[];
    t: TranslationFunction;
    placeholder?: string;
    isSearchable?: boolean;
};

export function ControlledStringSelect<TFieldValues extends FieldValues>({ control, name, rules, options, t, placeholder, isSearchable }: ControlledStringSelectProps<TFieldValues>) {
    const InnerSelect = useCallback(({ field }: { field: { value?: string, onChange: (value?: string) => void } }) => {
        return (
            <StringSelect
                {...field}
                // The field actually contains the 'ref' property, but we don't want it.
                ref={null}
                options={options}
                t={t}
                placeholder={placeholder}
                isSearchable={isSearchable}
            />
        );
    }, [ options, t, placeholder, isSearchable ]);

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={InnerSelect}
        />
    );
}

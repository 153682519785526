import { useCallback, useEffect, useRef, useState, type ReactNode, type UIEvent } from 'react';
import { useToggle } from ':frontend/hooks';
import clsx from 'clsx';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { FlowlanceIcon } from ':components/icons/logos';
import { useTranslation } from 'react-i18next';
import { toMaster, useUser } from ':frontend/context/UserProvider';
import { cn } from ':components/shadcn/utils';
import { routesFE, routesStore } from ':utils/routes';
import { useAuth } from ':frontend/context/AuthProvider';
import { TeamMemberRole } from ':utils/entity/team';
import { Button, DropdownMenu } from ':components/shadcn';
import type { IconType } from ':components/icons/common';
import { Box2Icon, CalendarIcon, CircleDotsIcon, Copy1Icon, Eye2Icon, FindReplaceIcon, Gear1Icon, Palette1Icon, RectLogoutIcon, SidebarLeft2ShowIcon, SidebarLeft3HideIcon, Sliders3Icon, Tag3Icon, UserGroupIcon, Users1Icon, ViewAll1Icon, WalletContentIcon } from ':components/icons/basic';
import { signal } from '@preact/signals-react';
import { trpc } from ':frontend/context/TrpcProvider';
import { createTranslatedSuccessAlert } from './notifications';
import useNotifications from ':frontend/context/NotificationProvider';
import { getPersonName } from ':utils/entity/person';
import { useTailwindMediaQuery } from ':frontend/hooks/useTailwindMediaQuery';

const CHANGELOG_LINK = 'https://flowlance.canny.io/changelog';

type LayoutProps = Readonly<{
    children: ReactNode;
}>;

export function Layout({ children }: LayoutProps) {
    const showSidebar = useTailwindMediaQuery({ minWidth: 'md' });

    return (
        <div className='min-w-80 h-screen w-screen flex pb-20 md:pb-0'>
            {showSidebar && <Sidebar />}
            {!showSidebar && <BottomBar />}

            <div className='grow min-w-0 max-w-full flex flex-col bg-secondary-50'>
                {children}
            </div>
        </div>
    );
}

//
//
// SIDEBAR
//
//

function Sidebar() {
    const [ isCollapsed, setIsCollapsed ] = useToggle(false);
    const userContext = useUser();
    const { role, team } = userContext;

    return (
        // Nice.
        <div className={clsx('shrink-0 h-full bg-white transition-all border-r border-secondary-50',
            isCollapsed ? 'fl-sidebar-collapsed w-[69px]' : 'fl-sidebar-expanded w-[216px]',
        )}>
            <div className='h-full flex flex-col p-4 gap-1'>
                <div className='flex justify-between overflow-hidden'>
                    <Link to={routesFE.root} className='leading-6 w-9 min-w-9 p-[6px] rounded-md hover:bg-primary-50 active:bg-primary-100'>
                        <FlowlanceIcon size={24} cut />
                    </Link>
                    <Button size='small' variant='ghost' className={clsx('[&_svg]:size-5 px-2 text-secondary-300 transition-opacity', isCollapsed && 'opacity-0')} onClick={setIsCollapsed.true}>
                        <SidebarLeft3HideIcon />
                    </Button>
                </div>
                <div className='h-9'>
                    <Button size='small' variant='ghost' className={clsx('[&_svg]:size-5 px-2 text-secondary-300 transition-opacity', !isCollapsed && 'opacity-0 pointer-events-none')} onClick={setIsCollapsed.false}>
                        <SidebarLeft2ShowIcon />
                    </Button>
                </div>
                {role !== TeamMemberRole.freelancer && (
                    <span className='mb-4 font-bold text-primary text-center whitespace-nowrap text-2lg'>
                        {!isCollapsed ? team.title : team.title.slice(0, 1)}
                    </span>
                )}
                <UpsellButton isCollapsed={isCollapsed} />
                <div className='flex flex-col gap-1'>
                    {sidebarOverviewMenuItems.filter(item => !item.roles || item.roles.includes(role)).map(item => (
                        <SidebarMenuLink key={item.nameTranslationId} item={item} isCollapsed={isCollapsed} />
                    ))}
                </div>
                <div className='grow' />
                <div className='flex flex-col gap-1'>
                    <CopyStoreUrlButton />
                    <UserMenu />
                </div>
            </div>
        </div>
    );
}

function UserMenu({ isCollapsed }: Readonly<{ isCollapsed?: boolean }>) {
    const { t } = useTranslation('pages', { keyPrefix: 'mainMenu' });
    const { auth } = useAuth();
    const { appUser } = useUser();

    const [ isOpen, setIsOpen ] = useToggle(false);

    return (
        <DropdownMenu.Root onOpenChange={setIsOpen.value} open={isOpen}>
            <DropdownMenu.Trigger asChild>
                <div className={cn('p-2 rounded-md leading-4 flex items-center gap-2 cursor-pointer overflow-hidden hover:bg-primary-50 active:bg-primary-100',
                    isCollapsed && 'width-5',
                )} >
                    <span className='min-w-5'>
                        <Sliders3Icon size={20} />
                    </span>
                    <span className='text-nowrap'>
                        {t('settings-profile')}
                    </span>
                </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content side='top' className='ml-4 w-[220px] flex flex-col gap-6 p-6 rounded-lg'>
                <div className='flex gap-2 items-center'>
                    {/* The image isn't loading sometimes?
                    {!!googleUser?.pictureUrl && (
                        <img src={googleUser.pictureUrl} alt='Profile picture' className='size-12 rounded-full' />
                    )} */}
                    <div className='space-y-0.5 overflow-hidden'>
                        <p className='text-secondary-900 font-semibold truncate'>{getPersonName(appUser)}</p>
                        <p className='text-secondary-400 font-semibold truncate'>{appUser.email}</p>
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <Link to={routesFE.settings.resolve({ key: 'general' })}>
                        <DropdownMenu.Item className='flex items-center gap-1 focus:text-secondary-900 focus:bg-transparent cursor-pointer px-0 py-1.5'>
                            <Gear1Icon size={14} />
                            {t('settings')}
                        </DropdownMenu.Item>
                    </Link>
                    <a href={CHANGELOG_LINK} target='_blank' rel='noreferrer'>
                        <DropdownMenu.Item className='flex items-center gap-1 focus:text-secondary-900 focus:bg-transparent cursor-pointer px-0 py-1.5'>
                            <FindReplaceIcon size={14} />
                            {t('news-updates')}
                        </DropdownMenu.Item>
                    </a>
                    <DropdownMenu.Separator className='w-full my-0.5' />
                    <button onClick={() => auth.logout()} className='flex items-center gap-1 hover:text-secondary-900 select-none py-1'>
                        <RectLogoutIcon size={14} />
                        {t('logout')}
                    </button>
                </div>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

type SidebarMenuLinkProps = Readonly<{
    item: SidebarMenuItemBase;
    isCollapsed?: boolean;
}>;

function SidebarMenuLink({ item, isCollapsed }: SidebarMenuLinkProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'mainMenu' });
    const location = useLocation();
    const isCurrent = item.to === location.pathname;
    const isMatched = isMenuItemMatched(item, location.pathname);

    return (
        <Link
            className={cn('p-2 rounded-md leading-4 flex items-center gap-2 overflow-hidden hover:bg-primary-50 active:bg-primary-100',
                // If we are on the current page, we can't click on it again.
                isCurrent && 'pointer-events-none',
                // However, if we are on a subpage but not on of the main pages, we can click on the link to go back to the corresponding main page. So the link is only highlighted, but not disabled.
                isMatched && 'bg-primary-50 text-primary',
                isCollapsed && 'width-5',
            )}
            to={item.to}
        >
            <span className='min-w-5'>
                {item.icon({ size: 20 })}
            </span>
            <span className='text-nowrap'>
                {t(item.nameTranslationId)}
            </span>
        </Link>
    );
}

type SidebarMenuItemBase = Readonly<{
    nameTranslationId: string;
    to: string;
    match?: string | string[];
    icon: IconType;
    roles?: TeamMemberRole[];
}>;

const sidebarOverviewMenuItems: SidebarMenuItemBase[] = [ {
    nameTranslationId: 'dashboard',
    icon: ViewAll1Icon,
    to: routesFE.dashboard.path,
}, {
    nameTranslationId: 'products',
    icon: Box2Icon,
    to: routesFE.products.list,
}, {
    nameTranslationId: 'store',
    icon: Palette1Icon,
    to: routesFE.store.resolve({ key: 'overview' }),
    roles: [ TeamMemberRole.master, TeamMemberRole.freelancer ],
    match: [ routesFE.store.path ],
}, {
    nameTranslationId: 'clients',
    icon: Users1Icon,
    to: routesFE.clients.list.path,
    match: [ routesFE.clients.detail.path ],
}, {
    nameTranslationId: 'calendar',
    icon: CalendarIcon,
    to: routesFE.calendar,
}, {
    nameTranslationId: 'orders',
    icon: Tag3Icon,
    to: routesFE.orders.list.path,
    match: routesFE.orders.detail.path,
}, {
    nameTranslationId: 'direct-sale',
    icon: WalletContentIcon,
    to: routesFE.directSale.product,
    match: routesFE.directSale.root.path,
}, {
    nameTranslationId: 'team',
    icon: UserGroupIcon,
    to: routesFE.team,
    roles: [ TeamMemberRole.master ],
} ];

//
//
// BOTTOM BAR
//
//

function BottomBar() {
    const { t } = useTranslation('pages', { keyPrefix: 'mainMenu' });

    const { auth } = useAuth();
    const userContext = useUser();
    const { role } = userContext;

    const { storeUrl } = useStoreUrlLink();
    const [ showMenu, setShowMenu ] = useState(false);

    const showStore = bottomBarOverviewMenuItems['store'].roles?.includes(role);

    return (<>
        <div
            className={cn('fixed z-50 bottom-0 left-0 right-0 h-20 bg-primary-50 grid grid-cols-5',
                showStore ? 'grid-cols-5' : 'grid-cols-4',
                // For smallchat. If the bottom bar becomes collapsible, this needs to be adjusted.
                'fl-bottombar-expanded',
                // Again, smallchat. We want to show it only in menu.
                showMenu && 'fl-bottombar-menu-open',
            )}
            onClick={() => showMenu && setShowMenu(false)}
        >
            <BottomBarMenuLink item={bottomBarOverviewMenuItems['dashboard']} />

            <a
                href={storeUrl}
                target='_blank'
                className='h-full w-full leading-4 text-sm sm:text-base flex flex-col items-center justify-center gap-2 overflow-hidden hover:bg-primary-50 active:bg-primary-100' rel='noreferrer'
            >
                <Eye2Icon size={20} />

                <span>
                    {t('preview')}
                </span>
            </a>

            {showStore && <BottomBarMenuLink item={bottomBarOverviewMenuItems['store']} />}

            <BottomBarMenuLink item={bottomBarOverviewMenuItems['direct-sale']} />

            <button
                onClick={() => setShowMenu(!showMenu)}
                className={cn('h-full w-full leading-4 text-sm sm:text-base flex flex-col items-center justify-center gap-2 overflow-hidden hover:bg-primary-50 active:bg-primary-100',
                    showMenu && 'bg-primary-50 text-primary',
                )}
            >
                <CircleDotsIcon size={20} />

                <span>
                    {t('menu')}
                </span>
            </button>
        </div>

        {showMenu && (
            <div className='fixed z-50 bottom-20 left-0 right-0 top-0 bg-white px-4 py-8 overflow-y-scroll' onClick={() => setShowMenu(false)}>
                <UpsellButton isFullWidth />

                <div className='grid grid-cols-2 sm:grid-cols-3 gap-2'>
                    {bottomBarGridItems.filter(item => !item.roles || item.roles.includes(role)).map(item => (
                        <BottomBarGridLink key={item.nameTranslationId} item={item} />
                    ))}

                    <a
                        href={CHANGELOG_LINK}
                        target='_blank'
                        className='w-full aspect-square leading-4 flex flex-col items-center rounded-lg border border-secondary-100 justify-center gap-4 overflow-hidden hover:bg-primary-50 active:bg-primary-100' rel='noreferrer'>
                        <FindReplaceIcon size={20} />

                        <span>
                            {t('news-updates')}
                        </span>
                    </a>
                </div>

                <Button variant='secondary' size='small' onClick={() => auth.logout()} className='w-full mt-4'>
                    <RectLogoutIcon size={14} />
                    {t('logout')}
                </Button>
            </div>
        )}
    </>);
}

type BottomBarLinkProps = Readonly<{
    item: BottomBarMenuItemBase;
}>;

function BottomBarMenuLink({ item }: BottomBarLinkProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'mainMenu' });
    const location = useLocation();
    const isCurrent = item.to === location.pathname;
    const isMatched = isMenuItemMatched(item, location.pathname);

    return (
        <Link
            className={cn('h-full w-full text-sm sm:text-base leading-4 flex flex-col items-center justify-center gap-2 overflow-hidden hover:bg-primary-50 active:bg-primary-100',
                // If we are on the current page, we can't click on it again.
                isCurrent && 'pointer-events-none',
                // However, if we are on a subpage but not on of the main pages, we can click on the link to go back to the corresponding main page. So the link is only highlighted, but not disabled.
                isMatched && 'bg-primary-50 text-primary',
            )}
            to={item.to}
        >
            {item.icon({ size: 20 })}

            <span className='text-nowrap'>
                {t(item.nameTranslationId)}
            </span>
        </Link>
    );
}

function BottomBarGridLink({ item }: BottomBarLinkProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'mainMenu' });
    const location = useLocation();
    const isCurrent = item.to === location.pathname;
    const isMatched = isMenuItemMatched(item, location.pathname);

    return (
        <Link
            className={cn('w-full aspect-square leading-4 flex flex-col items-center rounded-lg border border-secondary-100 justify-center gap-4 overflow-hidden hover:bg-primary-50 active:bg-primary-100',
                // If we are on the current page, we can't click on it again.
                isCurrent && 'pointer-events-none',
                // However, if we are on a subpage but not on of the main pages, we can click on the link to go back to the corresponding main page. So the link is only highlighted, but not disabled.
                isMatched && 'bg-primary-50 text-primary',
            )}
            to={item.to}
        >
            {item.icon({ size: 20 })}

            <span className='text-nowrap'>
                {t(item.nameTranslationId)}
            </span>
        </Link>
    );
}

type BottomBarMenuItemBase = Readonly<{
    nameTranslationId: string;
    to: string;
    match?: string | string[];
    icon: IconType;
    roles?: TeamMemberRole[];
}>;

const bottomBarOverviewMenuItems: {[key:string]:BottomBarMenuItemBase} = {
    'dashboard': {
        nameTranslationId: 'dashboard',
        icon: ViewAll1Icon,
        to: routesFE.dashboard.path,
    },
    'store': {
        nameTranslationId: 'store',
        icon: Palette1Icon,
        to: routesFE.store.resolve({ key: 'overview' }),
        roles: [ TeamMemberRole.master, TeamMemberRole.freelancer ],
        match: [ routesFE.store.path ],
    },
    'direct-sale': {
        nameTranslationId: 'direct-sale',
        icon: WalletContentIcon,
        to: routesFE.directSale.product,
        match: routesFE.directSale.root.path,
    },
};

const bottomBarGridItems: BottomBarMenuItemBase[] = [ {
    nameTranslationId: 'products',
    icon: Box2Icon,
    to: routesFE.products.list,
}, {
    nameTranslationId: 'clients',
    icon: Users1Icon,
    to: routesFE.clients.list.path,
    match: [ routesFE.clients.detail.path ],
}, {
    nameTranslationId: 'calendar',
    icon: CalendarIcon,
    to: routesFE.calendar,
}, {
    nameTranslationId: 'orders',
    icon: Tag3Icon,
    to: routesFE.orders.list.path,
    match: routesFE.orders.detail.path,
}, {
    nameTranslationId: 'settings',
    icon: Gear1Icon,
    to: routesFE.settings.resolve({ key: 'general' }),
}, {
    nameTranslationId: 'team',
    // TODO
    icon: Sliders3Icon,
    to: routesFE.team,
    roles: [ TeamMemberRole.master ],
} ];

function getMatchPaths(item: SidebarMenuItemBase): string[] {
    if (!item.match)
        return [ item.to ];
    if (typeof item.match === 'string')
        return [ item.to, item.match ];

    return [ item.to, ...item.match ];
}

function isMenuItemMatched(item: SidebarMenuItemBase, pathName: string): boolean {
    return getMatchPaths(item).some(path => matchPath(path, pathName));
}

function UpsellButton({ isCollapsed, isFullWidth }: Readonly<{ isCollapsed?: boolean, isFullWidth?: boolean }>) {
    const { t } = useTranslation('pages', { keyPrefix: 'mainMenu' });

    const userContext = useUser();
    const { subscription } = userContext;
    const isMasterOrFreelancer = !!toMaster(userContext);

    const showUpgradeButton = isMasterOrFreelancer && !subscription.isPro;

    if (!showUpgradeButton)
        return null;

    return (
        <Button asChild size='small' className={cn('mb-4 px-0 text-clip', isFullWidth && 'w-full')}>
            <Link to={routesFE.settings.resolve({ key: 'subscription' })}>
                {!isCollapsed ? t('upgrade-to-pro') : '🚀'}
            </Link>
        </Button>
    );
}

function CopyStoreUrlButton() {
    const { t } = useTranslation('pages', { keyPrefix: 'mainMenu' });
    const { addAlert } = useNotifications();

    const { storeUrl } = useStoreUrlLink();

    async function copyStoreUrl() {
        if (!storeUrl)
            return;

        await navigator.clipboard.writeText(storeUrl);
        addAlert(createTranslatedSuccessAlert('common:copied-to-clipboard'));
    }

    return (
        // Pixel-perfect alignment based on the text size. If anything changes, this has to be adjusted.
        <Button variant='outline' size='small' className={clsx('px-0 overflow-hidden justify-start')} onClick={copyStoreUrl}>
            <div className='w-full px-[9px] flex items-center gap-[15px]'>
                <Copy1Icon />
                {t('copy-store-url')}
            </div>
        </Button>
    );
}

function useStoreUrlLink() {
    const store = trpc.store.getStore.useQuery();

    if (!store.data)
        return { storeUrl: '' };


    const storeUrl = routesStore.root.absoluteResolve({ store: store.data.slug });

    return { storeUrl };
}

// This is the almighty Topbar that changes color when the Content is scrolled.
// To achieve this effect, both components need to be used together.
// More precisely, if the Content doesn't need to scroll, or the Topbar has always the same color, it can be used without Content.
// Nevertheless, there is no real downside in using both.

const isScrolledSignal = signal(false);

type TopbarProps = Readonly<{
    children?: ReactNode;
    isStatic?: boolean;
}>;

export function Topbar({ children, isStatic }: TopbarProps) {
    return (
        <div className={clsx('fl-hide-scrollbar overflow-x-auto h-[61px] px-4 md:px-6 flex items-center justify-between gap-4 flex-nowrap border-b border-secondary-50 transition-[background-color] ', isStatic || isScrolledSignal.value ? 'bg-white' : 'bg-secondary-50')}>
            {children}
        </div>
    );
}

export function TabsTopbar({ children }: { children: ReactNode }) {
    return (
        <div className={clsx('fl-hide-scrollbar overflow-x-auto h-[61px] px-4 md:px-6 flex items-center justify-between gap-4 flex-nowrap border-b border-secondary-50 bg-white')}>
            {children}
        </div>
    );
}

export function TopHeader({ children }: { children: ReactNode }) {
    return (
        <div className='md:hidden flex justify-between gap-4 items-center px-4 py-2 bg-white border-b border-secondary-100'>
            <FlowlanceIcon size={40} className='shrink-0' />

            {children}
        </div>
    );
}

type ContentProps = Readonly<{
    children?: ReactNode;
}>;

export function Content({ children }: ContentProps) {
    const scrollerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // We have to reset the signal when the component is unmounted so that the new page won't start with colored topbar (each page starts at the top).
        return () => {
            isScrolledSignal.value = false;
        };
    }, []);

    const handleScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
        const isScrolledNow = !!(e.target as unknown as { scrollTop: number }).scrollTop;

        if (isScrolledSignal.peek() === isScrolledNow)
            return;

        isScrolledSignal.value = isScrolledNow;
    }, []);

    return (
        <div className='fl-main-scroller' onScroll={handleScroll} ref={scrollerRef}>
            {children}
        </div>
    );
}

import { Component } from 'react';
import clsx from 'clsx';
import { EventRowMixin } from './EventRowMixin';

export class EventRow extends Component {
    render() {
        let {
            segments,
            slotMetrics: { slots },
            className,
        } = this.props;

        let lastEnd = 1;

        return (
            <div className={clsx(className, 'flex')}>
                {segments.reduce((row, { event, left, right, span, isPlaceholder }, li) => {
                    let key = '_lvl_' + li;
                    let gap = left - lastEnd;

                    const content = isPlaceholder ? <div className='min-h-5' /> : EventRowMixin.renderEvent(this.props, event);

                    if (gap)
                        row.push(EventRowMixin.renderSpan(slots, gap, `${key}_gap`));

                    row.push(EventRowMixin.renderSpan(slots, span, key, content));

                    lastEnd = right + 1;

                    return row;
                }, [])}
            </div>
        );
    }
}

EventRow.defaultProps = {
    ...EventRowMixin.defaultProps,
};

import { useCallback, useMemo, useState } from 'react';
import { DefaultFilterItemBadge, type Filter, type FilterDefinition, type FilterFunction, type FilterItemBadgeProps, type FilterMenuProps } from './FilterRow';
import { DropdownMenu } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { type ClientInfoFE } from ':frontend/types/Client';
import { compareArrays } from ':frontend/utils/common';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import { SingleContinuousParticipantSelect } from ':frontend/components/client/ContinuousParticipantSelect';
import { getParticipantName, type Participant } from ':frontend/types/EventParticipant';

export const filterName = 'eventParticipant';

type FilterState = {
    selected: Participant[];
    all: ClientInfoFE[];
};

function FilterToggleMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <DropdownMenu.Item className='p-4 bg-white'>
            <InnerFilter state={state} setState={setState} />
        </DropdownMenu.Item>
    );
}

function FilterRowMenu({ state, setState }: FilterMenuProps<FilterState>) {
    return (
        <div className='max-md:w-full md:w-[300px]'>
            <InnerFilter state={state} setState={setState} />
        </div>
    );
}

function InnerFilter({ state, setState }: FilterMenuProps<FilterState>) {
    const availableClients = useMemo(() => state.all.filter(client => !state.selected.some(
        participant => 'info' in participant && participant.info.id === client.id,
    )), [ state ]);
    const [ value, setValue ] = useState<Participant>();

    const handleOnChange = useCallback((participant?: Participant) => {
        if (!participant)
            return;

        setState({ ...state, selected: [ ...state.selected, participant ] });
        setValue(undefined);
    }, [ state, setState ]);

    return (
        <SingleContinuousParticipantSelect
            // This forces the element to re-render when a new item is added. The reason is that we want to reset the filter options (so that the user can't select the same client twice). However, this is (most probably) not possible with AsyncSelect, so we have to force a re-render.
            key={state.selected.length}
            immutableProps={{ size: 'compact' }}
            clients={availableClients}
            value={value}
            onChange={handleOnChange}
            autoFocus
        />
    );
}

function FilterItemBadge({ item, onClose }: FilterItemBadgeProps<Participant>) {
    const { t } = useTranslation('common', { keyPrefix: `filters.${filterName}` });
    const name = getParticipantName(item);

    return (
        <DefaultFilterItemBadge item={item} onClose={onClose}>
            <span className='max-w-40 truncate'>
                {t('badge-label', { name })}
            </span>
        </DefaultFilterItemBadge>
    );
}

function remove(state: FilterState, item: Participant): FilterState {
    return {
        ...state,
        selected: state.selected.filter(participant => participant.identifier !== item.identifier),
    };
}

function toItems(state: FilterState): Participant[] {
    return state.selected;
}

function createFilterFunction(state: FilterState): FilterFunction<CalendarEvent> {
    if (state.selected.length === 0)
        return () => true;

    const set = new Set<string>(state.selected.map(participant => participant.identifier));

    return (data: CalendarEvent) => {
        if (data.resource.type === 'event') {
            return data.resource.event.guests.some(participant => set.has(participant.client.id))
                || data.resource.event.clients.some(participant => set.has(participant.client.id));
        }

        if (data.resource.type === 'google')
            return data.resource.event.guests.some(participant => set.has(participant));

        return true;
    };
}

type ClientParticipant = { info: ClientInfoFE, identifier: string };

function toServer(state: FilterState, previous: string[] | undefined): string[] {
    const current = state.selected
        .filter((participant): participant is ClientParticipant => 'info' in participant)
        .map(participant => participant.info.id).sort();

    return previous && compareArrays(current, previous)
        ? previous
        : current;
}

function createFilter(allClients: ClientInfoFE[]): FilterDefinition<Participant, FilterState, CalendarEvent> {
    return {
        name: filterName,
        defaultValues: {
            all: allClients,
            selected: [],
        },
        FilterToggleMenu,
        FilterRowMenu,
        FilterItemBadge,
        remove,
        toItems,
        createFilterFunction,
        toServer: toServer as (state: FilterState, previous: unknown | undefined) => string[],
    };
}

export default createFilter as (allClients: ClientInfoFE[]) => Filter;

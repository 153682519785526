import clsx from 'clsx';
import { Tooltip } from ':components/shadcn';
import { CircleInfoIcon } from ':components/icons/basic';

type InfoTooltipProps = Readonly<{
    text: string;
    side?: 'top' | 'right' | 'bottom' | 'left';
    className?: string;
}>;

export function InfoTooltip({ text, side, className }: InfoTooltipProps) {
    return (
        <div className={clsx('inline-block relative w-0 h-3', className)}>
            <div className='absolute top-[-3px]'>
                <Tooltip
                    tooltipText={text}
                    side={side}
                    className='whitespace-pre-line bg-white text-secondary-700 font-medium opacity-100'
                >
                    <span className='inline-block size-4 text-secondary-400'>
                        <CircleInfoIcon size='sm' className='select-none cursor-pointer' />
                    </span>
                </Tooltip>
            </div>
        </div>
    );
}

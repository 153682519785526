import { useCallback, useMemo, useState, type ReactNode } from 'react';
import { type StoreOutput, type StoreSocial } from ':utils/entity/store';
import { otherSocialIconId, socialIconsWithLabels, STORE_IMAGE_CLASS, STORE_IMAGE_MAX_SIZE, StoreBioDisplay, type StoreBioAction } from ':components/store/StoreBioDisplay';
import { Button, Card, Form, Modal } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import { useCached } from ':components/hooks';
import { SpinnerButton } from ':frontend/components/common';
import { trpc } from ':frontend/context/TrpcProvider';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedErrorAlert, createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { useForm } from 'react-hook-form';
import { RHFErrorMessage } from ':frontend/components/forms/ErrorMessage';
import { optionalStringToPut } from '../../utils/common';
import { ControlledStringSelect } from ':frontend/components/forms/controlled';
import { fileDataToServer } from ':utils/entity/file';
import { CroppedImageInput, type FileInputValue } from ':components/custom';
import { ProductPublicDisplay } from ':components/store/product/ProductCard';
import type { ProductOrderEdit, ProductOutput } from ':utils/entity/product';
import { getStoreStyles } from ':components/store/utils';
import { ChartComboIcon, Globe2Icon, SortBottomToTop1Icon, SquareWandSparkleIcon, TrashXmarkIcon } from ':components/icons/basic';
import { CreateProductCard } from ':frontend/components/product/CreateProductCard';
import { closestCenter, DndContext, DragOverlay, type DragEndEvent, type DragStartEvent, type UniqueIdentifier } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { cn } from ':components/shadcn/utils';
import type { Id } from ':utils/id';
import { ProductEditLink } from '../products/Products';
import { useUser } from ':frontend/context/UserProvider';
import { UpsellButton } from ':frontend/components/upsell/UpsellModal';
import { TermsOfService } from ':frontend/components/settings/TermsOfService';
import { getProductsQuery } from './StorePage';
import { linkify } from ':utils/common';

type StoreOverviewTabProps = Readonly<{
    store: StoreOutput;
    products: ProductOutput[];
}>;

export function StoreOverviewTab({ store, products }: StoreOverviewTabProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'store' });
    const [ showUpdateBio, setShowUpdateBio ] = useState<StoreBioAction>();

    return (
        <div {...getStoreStyles(store.design, 'fl-store-bg fl-main-scroller px-4')}>
            <UpdateBioModal action={showUpdateBio} onClose={() => setShowUpdateBio(undefined)} store={store} />
            <div className='max-w-[600px] w-full mx-auto py-12 flex flex-col items-stretch gap-6'>
                <StoreBioDisplay bio={store.bio} image={store.image} onClick={setShowUpdateBio} />
                <CreateProductCard />
                <StoreOverviewProducts products={products} />
                <Card className='flex items-center p-4 h-16 shadow-none -mt-4'>
                    <TermsOfService customLabel={t('terms-of-service-label')} switchSize='small' store={store} />
                </Card>
                {/* Hiding the badge is disabled for everyone for now
                <ShowBadgeCard store={store} /> */}
            </div>
            <div className='fixed bottom-6 right-6 px-4 py-3 rounded-full border shadow-sm bg-white text-secondary-900 flex items-center gap-2'>
                <SquareWandSparkleIcon size='sm' className='text-primary' />{t('edit-mode-badge')}
            </div>
        </div>
    );
}

type UpdateBioModalProps = Readonly<{
    action?: StoreBioAction;
    onClose: () => void;
    store: StoreOutput;
}>;

function UpdateBioModal({ action, onClose, store }: UpdateBioModalProps) {
    const cached = useCached(action);
    const { t } = useTranslation('pages', { keyPrefix: `store.updateBioModal.${cached?.type}` });

    const [ fetching, setFetching ] = useState<string>();

    const InnerForm = cached && forms[cached.type];

    return (
        <Modal.Root open={!!action} onOpenChange={open => !open && !fetching && onClose()}>
            <Modal.Content closeButton={t('cancel-button')}>
                <Modal.Header>
                    <Modal.Title>{t('title')}</Modal.Title>
                    <Modal.Description className='mt-3 leading-5'>{t('description')}</Modal.Description>
                </Modal.Header>
                {InnerForm && (
                    <InnerForm
                        action={cached}
                        store={store}
                        fetching={fetching}
                        setFetching={setFetching}
                        onClose={onClose}
                    />
                )}
            </Modal.Content>
        </Modal.Root>
    );
}

const forms = {
    info: UpdateInfoForm,
    socials: UpdateSocialsForm,
    image: UpdateImageForm,
    video: UpdateVideoForm,
} as const;

export type UpdateBioFormProps = Readonly<{
    action: StoreBioAction;
    store: StoreOutput;
    fetching: string | undefined;
    setFetching: (value: string | undefined) => void;
    onClose: () => void;
}>;

type InfoFormData = {
    name: string;
    headline: string;
    description: string;
};

function UpdateInfoForm({ store, fetching, setFetching, onClose }: UpdateBioFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'store.updateBioModal.info' });
    const { addAlert } = useNotifications();

    const { register, handleSubmit, formState: { errors } } = useForm<InfoFormData>({
        defaultValues: {
            name: store.bio.name,
            headline: store.bio.headline ?? '',
            description: store.bio.description ?? '',
        },
    });

    const updateStoreMutation = trpc.store.updateStore.useMutation();
    const utils = trpc.useUtils();

    async function onSubmit(data: InfoFormData) {
        setFetching(FID_INFO);
        const response = await updateStoreMutation.mutateAsync({ bio: {
            ...store.bio,
            name: data.name,
            headline: optionalStringToPut(data.headline),
            description: optionalStringToPut(data.description),
        } });
        setFetching(undefined);
        if (updateStoreMutation.isError) {
            addAlert(createTranslatedErrorAlert());
            return;
        }

        utils.store.getStore.setData(undefined, response);

        onClose();
    }

    return (
        <Form.Root onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2'>
            <div>
                <Form.Input
                    {...register('name', { required: t('name-required') })}
                    placeholder={t('name-placeholder')}
                />
                <RHFErrorMessage errors={errors} name='name' />
            </div>
            <Form.Input
                {...register('headline')}
                placeholder={t('headline-placeholder')}
            />
            <Form.Textarea
                placeholder={t('description-placeholder')}
                minRows={3}
                {...register('description')}
            />
            <div className='mt-2 grid grid-cols-2 gap-2'>
                <Button variant='white' onClick={onClose} disabled={!!fetching}>
                    {t('cancel-button')}
                </Button>
                <SpinnerButton type='submit' fetching={fetching} fid={FID_INFO}>
                    {t('save-button')}
                </SpinnerButton>
            </div>
        </Form.Root>
    );
}

const FID_INFO = 'info';

function UpdateSocialsForm({ action, store, fetching, setFetching, onClose }: UpdateBioFormProps) {
    const index = (action as { type: 'socials', index: number | undefined }).index;
    const { t } = useTranslation('pages', { keyPrefix: 'store.updateBioModal.socials' });
    const { t: tf } = useTranslation('common', { keyPrefix: 'form' });
    const { addAlert } = useNotifications();

    const { control, register, handleSubmit, formState: { errors } } = useForm<StoreSocial>({
        defaultValues: index !== undefined
            ? { ...store.bio.socials[index] }
            : { type: 'instagram', url: '', title: '' },
    });

    const updateStoreMutation = trpc.store.updateStore.useMutation();
    const utils = trpc.useUtils();

    async function syncSocials(fid: string, socials: StoreSocial[]) {
        setFetching(fid);
        // FIXME All mutateAsync function should be replaced with mutate + onSuccess/onError (wtf why?)
        const response = await updateStoreMutation.mutateAsync({ bio: { ...store.bio, socials } });
        setFetching(undefined);
        if (updateStoreMutation.isError) {
            addAlert(createTranslatedErrorAlert());
            return;
        }

        utils.store.getStore.setData(undefined, response);

        onClose();
    }

    async function onSubmit(data: StoreSocial) {
        const dataNormalized: StoreSocial = {
            type: data.type,
            title: data.title.trim(),
            url: normalizeSocialUrl(data.url, data.type),
        };

        const newSocials = [ ...store.bio.socials ];
        if (index === undefined)
            newSocials.push(dataNormalized);
        else
            newSocials[index] = dataNormalized;

        syncSocials(FID_DELETE_SOCIAL, newSocials);
    }

    function onDelete() {
        if (index === undefined)
            return;

        const newSocials = [ ...store.bio.socials ];
        newSocials.splice(index, 1);
        syncSocials(FID_DELETE_SOCIAL, newSocials);
    }

    const optionTranslationFunction = useCallback((value: string) => (
        value === otherSocialIconId
            ? <><Globe2Icon size='md' className='mr-2 text-secondary-800'/>{t('other-label')}</>
            : <>{socialIconsWithLabels[value].icon({ size: 'md', className: 'mr-2' })}{socialIconsWithLabels[value].label}</>
    ), [ t ]);

    return (
        <Form.Root onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-4'>
            <div className='flex items-center'>
                <div className='flex flex-col gap-2 grow'>
                    <ControlledStringSelect
                        control={control}
                        name='type'
                        options={socialOptions}
                        t={optionTranslationFunction}
                        isSearchable={false}
                    />
                    <div>
                        <Form.Input
                            {...register('url', { required: t('url-required') })}
                            placeholder={t('url-placeholder')}
                            // otherwise the StringSelect is focused and deselecting it requires two clicks (reason unknown)
                            autoFocus
                        />
                        <RHFErrorMessage errors={errors} name='url' />
                    </div>
                    <div>
                        <Form.Input
                            {...register('title', { required: tf('title-required') })}
                            placeholder={t('title-placeholder')}
                        />
                        <RHFErrorMessage errors={errors} name='title' />
                    </div>
                </div>
            </div>
            {index !== undefined && (
                <SpinnerButton variant='ghost' className='w-fit self-center' onClick={() => onDelete()} fetching={fetching} fid={FID_DELETE_SOCIAL}>
                    <TrashXmarkIcon />{t('delete-button')}
                </SpinnerButton>
            )}
            <div className='grid grid-cols-2 gap-2'>
                <Button variant='white' onClick={onClose} disabled={!!fetching}>
                    {t('cancel-button')}
                </Button>
                <SpinnerButton type='submit' fetching={fetching} fid={FID_EDIT_SOCIAL}>
                    {t('save-button')}
                </SpinnerButton>
            </div>
        </Form.Root>
    );
}

const FID_EDIT_SOCIAL = 'edit-social';
const FID_DELETE_SOCIAL = 'delete-social';

const socialOptions = [ ...Object.keys(socialIconsWithLabels), otherSocialIconId ];

function normalizeSocialUrl(input: string, type: string) {
    input = input.trim();

    if (type === otherSocialIconId)
        return linkify(input);

    switch (type) {
    case 'facebook':
        if (input.includes('facebook.com'))
            return input;
        return `https://www.facebook.com/${input}`;

    case 'instagram':
        if (input.includes('instagram.com'))
            return input;
        return `https://www.instagram.com/${input}`;

    case 'twitter':
    case 'x':
        if (input.includes('x.com') || input.includes('twitter.com'))
            return input;
        return `https://www.twitter.com/${input}`;

    case 'linkedin':
        if (input.includes('linkedin.com'))
            return input;
        return `https://www.linkedin.com/in/${input}`;

    case 'youtube':
        if (input.includes('youtube.com'))
            return input;
        return `https://www.youtube.com/${input}`;

    case 'pinterest':
        if (input.includes('pinterest.com'))
            return input;
        return `https://www.pinterest.com/${input}`;

    case 'medium':
        if (input.includes('medium.com'))
            return input;
        return `https://www.medium.com/${input}`;

    case 'threads':
        if (input.includes('threads.net'))
            return input;
        if (input.includes('@'))
            return `https://www.threads.net/${input}`;
        return `https://www.threads.net/@${input}`;

    case 'whatsApp':
    case 'whatsapp':
        if (input.includes('whatsapp.com'))
            return input;
        return `https://wa.me/${input}`;

    case 'tikTok':
    case 'tiktok':
        if (input.includes('tiktok.com'))
            return input;
        if (input.includes('@'))
            return `https://www.tiktok.com/${input}`;
        return `https://www.tiktok.com/@${input}`;

    case 'telegram':
        if (input.includes('t.me'))
            return input;
        return `https://t.me/${input}`;
    }

    return linkify(input);
}

function UpdateImageForm({ store, fetching, setFetching, onClose }: UpdateBioFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'store.updateBioModal.image' });
    const { addAlert } = useNotifications();

    const [ image, setImage ] = useState<FileInputValue>(store.image);

    const updateStoreMutation = trpc.store.updateStore.useMutation();
    const utils = trpc.useUtils();

    async function onSubmit() {
        if (image && 'id' in image) {
            onClose();
            return;
        }

        setFetching(FID_IMAGE);
        const response = await updateStoreMutation.mutateAsync({
            image: image ? fileDataToServer(image) : null,
        });
        setFetching(undefined);
        if (updateStoreMutation.isError) {
            addAlert(createTranslatedErrorAlert());
            return;
        }

        utils.store.getStore.setData(undefined, response);

        onClose();
    }

    const isDirty = image !== store.image;

    return (
        <div className='flex flex-col gap-4'>
            <CroppedImageInput
                value={image}
                onChange={setImage}
                imageClass={STORE_IMAGE_CLASS}
                cropperOptions={{
                    modalDescription: t('cropper-description', { maxSize: STORE_IMAGE_MAX_SIZE }),
                    isSelectionRounded: true,
                    maxWidth: STORE_IMAGE_MAX_SIZE,
                    maxHeight: STORE_IMAGE_MAX_SIZE,
                }}
            />
            <div className='grid grid-cols-2 gap-2'>
                <Button variant='white' onClick={onClose} disabled={!!fetching}>
                    {t('cancel-button')}
                </Button>
                <SpinnerButton onClick={() => onSubmit()} disabled={!isDirty} fetching={fetching} fid={FID_IMAGE}>
                    {t('save-button')}
                </SpinnerButton>
            </div>
        </div>
    );
}

const FID_IMAGE = 'image';

function UpdateVideoForm({ store, fetching, setFetching, onClose }: UpdateBioFormProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'store.updateBioModal.video' });
    const { addAlert } = useNotifications();
    const { subscription } = useUser();
    const [ videoUrl, setVideoUrl ] = useState(store.bio.videoUrl);

    const isEnabled = subscription.restrictions.store.customDesign;

    const updateStoreMutation = trpc.store.updateStore.useMutation();
    const utils = trpc.useUtils();

    async function onSubmit() {
        setFetching(FID_VIDEO);
        const response = await updateStoreMutation.mutateAsync({ bio: {
            ...store.bio,
            videoUrl: optionalStringToPut(videoUrl),
        } });
        setFetching(undefined);
        if (updateStoreMutation.isError) {
            addAlert(createTranslatedErrorAlert());
            return;
        }

        utils.store.getStore.setData(undefined, response);

        onClose();
    }

    return (
        <Form.Root className='flex flex-col gap-2'>
            {!isEnabled && (
                <div className='flex flex-col items-center border-2 border-primary-100 rounded-lg p-4 w-4/5 mx-auto mb-2'>
                    <ChartComboIcon size={32} className='text-primary mb-4' />
                    <p className='text-primary text-lg font-semibold leading-5 mb-2.5'>{t('upsell-title')}</p>
                    <p className='text-center leading-normal mb-2.5'>{t('upsell-description')}</p>
                    <UpsellButton className='w-full' />
                </div>
            )}
            <Form.Input
                value={videoUrl}
                onChange={e => setVideoUrl(e.target.value)}
                placeholder={t('videoUrl-placeholder')}
                disabled={!isEnabled}
            />
            <div className='mt-2 grid grid-cols-2 gap-2'>
                <Button variant='white' onClick={onClose} disabled={!!fetching}>
                    {t('cancel-button')}
                </Button>
                <SpinnerButton type='submit' fetching={fetching} fid={FID_VIDEO} onClick={onSubmit} disabled={!isEnabled}>
                    {t('save-button')}
                </SpinnerButton>
            </div>
        </Form.Root>
    );
}

const FID_VIDEO = 'video';

type StoreOverviewProductsProps = Readonly<{
    products: ProductOutput[];
}>;

function StoreOverviewProducts({ products }: StoreOverviewProductsProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'store' });
    const [ isReordering, setIsReordering ] = useState(false);

    if (isReordering) {
        return (
            <ReorderingProductsList originalProducts={products} onFinish={() => setIsReordering(false)} />
        );
    }

    return (<>
        <div className='flex justify-end -mt-2 -mb-3'>
            <Button variant='transparent' size='small' className='fl-store-text-base' onClick={() =>  setIsReordering(true)}>
                <SortBottomToTop1Icon />{t('start-reordering')}
            </Button>
        </div>
        <div className='flex flex-col gap-2'>
            {products.map(product => (
                <ProductPublicDisplay
                    key={product.id}
                    product={product}
                    actionComponent={<Button>{product.buttonText}</Button>}
                    editComponent={<ProductEditLink id={product.id} />}
                />
            ))}
        </div>
    </>);
}

type ReorderingProductsListProps = Readonly<{
    originalProducts: ProductOutput[];
    onFinish: () => void;
}>;

function ReorderingProductsList({ originalProducts, onFinish }: ReorderingProductsListProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'store' });
    const [ activeId, setActiveId ] = useState<Id>();
    // In order for the drag animations to work, this useState has to be instant. So we can't just change state several components deep.
    // Also, we want to sync with server only after explicit save, not after every drag.
    const [ products, setProducts ] = useState<ProductOutput[]>(originalProducts);

    const dragEvents = useMemo(() => ({
        start: (e: DragStartEvent) => {
            setActiveId(e.active.id as Id);
        },
        end: (e: DragEndEvent) => {
            if (e.over === null)
                return;

            setActiveId(undefined);
            setProducts(oldProducts => {
                const oldIndex = oldProducts.findIndex(product => product.id === e.active.id);
                const newIndex = oldProducts.findIndex(product => product.id === e.over?.id);

                return newIndex === oldIndex
                    ? oldProducts
                    : arrayMove(oldProducts, oldIndex, newIndex);
            });
        },
    }), []);

    const utils = trpc.useUtils();
    const updateProductOrder = trpc.product.updateProductOrder.useMutation();
    const { addAlert } = useNotifications();

    function saveReordering() {
        const updates: ProductOrderEdit = new Map();
        // We can't just use the indexes in the array, there might be gaps (because of the soft-deleted products). We have to keep these gaps.
        // So we compare both arrays from the start to the end and find all differences.
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === originalProducts[i].id)
                continue;

            updates.set(products[i].id, originalProducts[i].index);
        }

        updateProductOrder.mutate(updates, {
            onError: () => {
                addAlert(createTranslatedErrorAlert());
            },
            onSuccess: () => {
                addAlert(createTranslatedSuccessAlert('pages:store.reorder-products-success'));
                utils.product.getProducts.setData(getProductsQuery, oldProducts => (
                    oldProducts
                        ?.map(product => {
                            const newIndex = updates.get(product.id);
                            return newIndex !== undefined ? { ...product, index: newIndex } : product;
                        })
                        .toSorted((a, b) => a.index - b.index)
                ));
                onFinish();
            },
        });
    }

    return (<>
        <div className='flex justify-end gap-2 -mt-2 -mb-3'>
            <Button variant='transparent' size='small' className='fl-store-text-base' onClick={onFinish} disabled={updateProductOrder.isPending}>
                {t('cancel-reordering')}
            </Button>
            <SpinnerButton variant='primary' size='small' onClick={saveReordering} isFetching={updateProductOrder.isPending}>
                {t('save-reordering')}
            </SpinnerButton>
        </div>
        <div className='flex flex-col gap-2'>
            <DndContext collisionDetection={closestCenter} onDragStart={dragEvents.start} onDragEnd={dragEvents.end}>
                <SortableContext strategy={verticalListSortingStrategy} items={products}>
                    {products.map(product => (
                        <SortableItem key={product.id} id={product.id} isActive={product.id === activeId}>
                            <ProductPublicDisplay
                                product={product}
                                actionComponent={<Button>{product.buttonText}</Button>}
                                // We don't show the edit link here. There's no time for that during reordering.
                            />
                        </SortableItem>
                    ))}
                </SortableContext>
                <DragOverlay>
                    {activeId && (
                        <ProductPublicDisplay
                            product={products.find(product => product.id === activeId)!}
                            actionComponent={<Button>{products.find(product => product.id === activeId)!.buttonText}</Button>}
                        />
                    )}
                </DragOverlay>
            </DndContext>
        </div>
    </>);
}

type SortableItemProps = Readonly<{
    id: UniqueIdentifier;
    children: ReactNode;
    isActive?: boolean;
    className?: string;
}>;

function SortableItem({ id, children, isActive, className }: SortableItemProps) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className={cn(isActive && 'opacity-25', className)}
        >
            {children}
        </div>
    );
}

import { trpc } from ':frontend/context/TrpcProvider';
import { routeToDisplayString, routesFE, routesStore } from ':utils/routes';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StoreOverviewTab } from './StoreOverviewTab';
import { StoreDesignTab } from './StoreDesignTab';
import { Button, Tabs } from ':components/shadcn';
import { Clone1Icon, Copies6Icon, Palette1Icon } from ':components/icons/basic';
import useNotifications from ':frontend/context/NotificationProvider';
import { createTranslatedSuccessAlert } from ':frontend/components/notifications';
import { TabsTopbar, TopHeader } from ':frontend/components/Layout';

export const getProductsQuery = { onlyPublic: true };

export function StorePage() {
    const { t } = useTranslation('pages', { keyPrefix: 'store' });
    trpc.user.visitStoreEditor.useQuery(undefined, {
        staleTime: Infinity,  // minimize refetches
    });
    const store = trpc.store.getStore.useQuery();
    // This page should look like the actual store, so only the public products should be displayed.
    const products = trpc.product.getProducts.useQuery(getProductsQuery);

    const { key } = useParams();
    const navigate = useNavigate();
    const handleSelect = useCallback((newKey: string | null) => newKey && navigate(routesFE.store.resolve({ key: newKey })), [ navigate ]);
    const { addAlert } = useNotifications();

    if (!store.data || !products.data)
        return null;

    const storeUrl = routesStore.root.absoluteResolve({ store: store.data.slug });

    async function copyStoreUrl() {
        await navigator.clipboard.writeText(storeUrl);
        addAlert(createTranslatedSuccessAlert('common:copied-to-clipboard'));
    }

    return (
        <Tabs.Root value={key} onValueChange={handleSelect} className='flex flex-col h-full w-full'>
            <TopHeader>
                <div className='flex items-center gap-2 overflow-hidden'>
                    <a href={storeUrl} target='_blank' rel='noreferrer' className='min-w-0 flex'>
                        <Button variant='transparent' size='small' className='block h-7 px-2 text-primary truncate'>
                            {routeToDisplayString(storeUrl)}
                        </Button>
                    </a>
                    <Button variant='white' size='exact' className='shrink-0 size-9' onClick={copyStoreUrl}>
                        <Clone1Icon />
                    </Button>
                </div>
            </TopHeader>

            <TabsTopbar>
                <Tabs.List>
                    <Tabs.Trigger value='overview'>
                        <span><Copies6Icon size='xs' /></span>
                        {t('overview-tab')}
                    </Tabs.Trigger>
                    <Tabs.Trigger value='design'>
                        <span><Palette1Icon size='xs' /></span>
                        {t('design-tab')}
                    </Tabs.Trigger>
                </Tabs.List>

                <div className='max-md:hidden flex items-center gap-2 overflow-hidden'>
                    <a href={storeUrl} target='_blank' rel='noreferrer' className='min-w-0 flex'>
                        <Button variant='transparent' size='small' className='block h-7 px-2 text-primary truncate'>
                            {routeToDisplayString(storeUrl)}
                        </Button>
                    </a>
                    <Button variant='white' size='tiny' onClick={copyStoreUrl}>
                        <Clone1Icon />{t('copy-url-button')}
                    </Button>
                </div>
            </TabsTopbar>

            <Tabs.Content value='overview' className='fl-main-scroller h-full'>
                <StoreOverviewTab store={store.data} products={products.data} />
            </Tabs.Content>
            <Tabs.Content value='design' className='fl-main-scroller h-full'>
                <StoreDesignTab store={store.data} products={products.data} />
            </Tabs.Content>
        </Tabs.Root>
    );
}

import type { Result } from ':frontend/types/result';
import type { DateTime } from 'luxon';
import type { MouseEvent } from 'react';
import { compareStringsUniversal, stringToQueryWords } from ':utils/common';

type UnionKeys<T> = T extends T ? keyof T : never;

type Expand<T> = T extends T ? { [K in keyof T]: T[K] } : never;

/**
 * Mutually exclusive. OneOf<[A, B, C]> means A xor B xor C. Usable only on objects.
 * @see https://stackoverflow.com/questions/42123407/does-typescript-support-mutually-exclusive-types#comment123255834_53229567
 * You probably want to use discriminated unions unless you're crazy :)
 * @see https://www.typescriptlang.org/docs/handbook/2/narrowing.html#discriminated-unions
 */
export type OneOf<T extends object[]> = {
    [K in keyof T]: Expand<T[K] & Partial<Record<Exclude<UnionKeys<T[number]>, keyof T[K]>, never>>>;
}[number];

export function roundDateToMinutes(inputDate: DateTime, minutes: number): DateTime {
    const rest = inputDate.minute % minutes;
    const minutesToAdd = minutes - rest;
    return inputDate.plus({ minutes: minutesToAdd }).set({ second: 0, millisecond: 0 });
}

export function last<T>(array: T[]): T {
    return array[array.length - 1];
}

export function compareArrays<T>(a: T[], b: T[]): boolean {
    if (a.length !== b.length)
        return false;

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i])
            return false;
    }

    return true;
}

export function compareSets<T>(a: Set<T>, b: Set<T>): boolean {
    if (a.size !== b.size)
        return false;

    for (const value of a.values()) {
        if (!b.has(value))
            return false;
    }

    return true;
}

export function abbreviateStringToLength(originalString: string, length: number): string {
    if (originalString.length <= length)
        return originalString;

    return originalString.slice(0, length - 3) + '...';
}

type SerialFetchResult<T> = {
    lastSuccessfulResult: T | undefined;
    errors: any[];
};

type FetchFunction<T> = () => Promise<Result<T>>;

/**
 * This function does multiple api requests at once (not simultaneously, i.e., one after another). Both the last successful result and the errors are returned.
 *
 * The typical usecase is when we have to call mutliple backend routes to fulfill one user action. In order to avoid race conditions, we have to know the last valid state of the updated resource, hence the function returns the last successful result. We also want to know all errors.
 *
 * @param fetchFunctions Functions that will be called (one after another and in the given order).
 * @param stopOnError If true, the function stops after first unsuccessful request.
 * @returns Last successful result and errors.
 */
export async function serialFetch<T>(fetchFunctions: FetchFunction<T>[], stopOnError = false): Promise<SerialFetchResult<T>> {
    let lastSuccessfulResult: T | undefined = undefined;
    const errors = [];

    for (const fetchFunction of fetchFunctions) {
        const response = await fetchFunction();

        if (response.status) {
            lastSuccessfulResult = 'data' in response ? response.data : undefined;
        }
        else {
            errors.push(response.error);
            if (stopOnError)
                return { lastSuccessfulResult, errors };
        }
    }

    return { lastSuccessfulResult, errors };
}

type ParallelFetchResult<T> = Result<T[]>;

export async function parallelFetch<T>(fetchFunctions: FetchFunction<T>[]): Promise<ParallelFetchResult<T>> {
    const allResponses = await Promise.all(fetchFunctions.map(f => f()));

    const outputData: T[] = [];
    for (const response of allResponses) {
        if (!response.status) {
            return {
                status: false,
                error: response.error,
            };
        }

        if ('data' in response)
            outputData.push(response.data);
    }

    return {
        status: true,
        data: outputData,
    };
}


export function sleep(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function stringToPatch<TType extends string>(oldValue: TType, newValue: TType): TType | undefined {
    const trimmed = newValue.trim() as TType;
    return oldValue === trimmed ? undefined : trimmed;
}

export function optionalStringToPut(string?: string): string | undefined {
    if (string === undefined)
        return undefined;

    return string.trim() || undefined;
}

/**
 * This function is used to create ternary update logic. On FE, the value is either defined or undefined. However, in the update to BE, there are three possibilities:
 * - The value is defined - it should change.
 * - The value is undefined - it should not change.
 * - The value is null - it should change to null, which means `not defined` on FE.
 */
export function optionalStringToPatch(
    oldValue: string | undefined,
    newValue: string | undefined,
): string | undefined | null {
    const trimmed = newValue?.trim() || undefined;

    // The old object isn't defined so we return the new object (or undefined, if it's undefined).
    if (oldValue === undefined)
        return trimmed === undefined ? undefined : trimmed;

    // The old object is defined, but the new one isn't which means we have to undefine it on BE.
    if (trimmed === undefined)
        return null;

    // Both objects are defined. If they are equal, no change is required.
    return oldValue === trimmed ? undefined : trimmed;
}

// TODO unify this function with the ones above.
export function ifChanged<T, U>(newValue: T, oldValue: U, equals?: (a: T, b: U) => boolean): T | undefined {
    const isEqual = equals ? equals(newValue, oldValue) : (newValue as unknown) === oldValue;
    return isEqual ? undefined : newValue;
}

let lastId = 0;

export function generateId(): number {
    return lastId++;
}

export function emptyFunction() {
    // This function is intentionally empty.
}

export function getNameFromEmail(email: string): string {
    const atIndex = email.indexOf('@');
    return atIndex !== -1 ? email.substring(0, atIndex) : email;
}

/**
 * Put `separator` between all elements of `array`.
 * @example
 * intersperse([1, 2, 3], 0)
 *   ==> [ 1, 0, 2, 0, 3 ]
 * intersperse([1, 2, 3].map(n => <span>{n}</span>), ', ')
 *   ==> shows JSX elements (created by mapping an array) separated by a string / another JSX element
 * @see https://gist.github.com/thomasjonas/f99f48e278fd2dfe82edb2c6f7d6c365
 * @see https://stackoverflow.com/questions/23618744/rendering-comma-separated-list-of-links
 */
export function intersperse<Type>(array: Type[], separator: Type): Type[] {
    if (array.length === 0)
        return [];

    return array.slice(1).reduce((ans: Type[], element) => ans.concat([ separator, element ]), [ array[0] ]);
}

export class Query {
    private readonly words;

    constructor(...words: string[]) {
        this.words = words.flatMap(stringToQueryWords).sort(compareStringsUniversal);
    }

    // TODO why not use general needle-in-haystack search? It should be possible to generate the suffix tree or what and then run it on all of the queries (e.g., customers). This way, it should be possible to just join all their strings together (and normalize them).

    /**
     * Returns whether the other Query is included in this Query.
     * This function is not transitive!
     */
    match(other: Query): boolean {
        if (other.words.length === 0)
            return true;

        let j = 0;
        for (const word of this.words) {
            while (other.words[j] <= word) {
                if (word.startsWith(other.words[j]))
                    return true;
                j++;

                if (j >= other.words.length)
                    return false;
            }
        }

        return false;
    }
}

export function isNewTabClick(event: MouseEvent): boolean {
    return event.ctrlKey || event.metaKey || event.button === 1;
}

export function isLeftClick(event: MouseEvent): boolean {
    return event.button === 0;
}

export function navigateNewTab(url: string) {
    window.open(url, '_blank', 'noreferrer');
}

/**
 * This object used as props block multiple event handling of links. Usecase:
 * There is a link inside a clickable element. If the user clicks on the link, he is redirected, but the event is also propagated to the parent element.
 * Even link inside a button with the same redirect onClick handler is problematic, because it will cause double record in history.
 * This object prevents that.
 */
export const stopPropagation = {
    onClick: (e: MouseEvent) => e.stopPropagation(),
    onAuxClick: (e: MouseEvent) => e.stopPropagation(),
};

type Equalable<T> = { equals(other: T): boolean };

export function coalescedEquals<T extends Equalable<T>>(a: T | undefined, b: T | undefined): boolean {
    return a === undefined
        ? b === undefined
        : (b !== undefined && a.equals(b));
}

export type TypedAction<T extends string, P = void> = P & { type: T };

import { memo } from 'react';
import { DateTime, type DateTimeFormatOptions } from 'luxon';
import { localizer } from ':frontend/lib/calendar/utils/common';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type DateTimeFormatType = { date?: true } | { time?: true };

type DateTimeDisplayProps = Readonly<{
    dateTime: DateTime;
    className?: string;
} & DateTimeFormatType>;

export function DateTimeDisplay({ dateTime, className, ...type }: DateTimeDisplayProps) {
    return (
        <span className={clsx('tabular-nums whitespace-nowrap', className)}>{dateTime.toLocaleString(getFormat(type))}</span>
    );
}

function getFormat(type: DateTimeFormatType): DateTimeFormatOptions {
    if ('date' in type)
        return DateTime.DATE_MED;

    if ('time' in type)
        return DateTime.TIME_SIMPLE;

    return DateTime.DATETIME_MED;
}

type DayLongFormatProps = Readonly<{
    day: DateTime;
}>;

export const DayLongFormat = memo(DayLongFormatRaw);

function DayLongFormatRaw({ day }: DayLongFormatProps) {
    return (
        <span className='tabular-nums whitespace-nowrap'>
            {day.toLocaleString(DateTime.DATE_HUGE)}
        </span>
    );
}

type EventRangeFormatProps = Readonly<{
    start: DateTime;
    end: DateTime;
    currentDayStart: DateTime;
}>;

export const EventRangeFormat = memo(EventRangeFormatRaw);

function EventRangeFormatRaw({ start, end, currentDayStart }: EventRangeFormatProps) {
    const { t } = useTranslation('components', { keyPrefix: 'calendar' });

    if (localizer.isSameDate(start, end)) {
        // The event starts and ends on the same day => we display only times.
        return (
            <span className={eventRangeClassName}>{start.toLocaleString(DateTime.TIME_SIMPLE)}{' - '}{end.toLocaleString(DateTime.TIME_SIMPLE)}</span>
        );
    }

    // Now we know that the event spans multiple days.

    if (localizer.isSameDate(start, currentDayStart)) {
        // The event starts on the same day as the current day => we display only the start time.
        return (
            <span className={eventRangeClassName}>{start.toLocaleString(DateTime.TIME_SIMPLE)}{' - '}{floatingDots(end)}</span>
        );
    }

    if (localizer.isSameDate(end, currentDayStart)) {
    // The event ends on the same day as the current day => we display only the end time.
    // The first part is here (but hidded) just for the spacing.
        return (
            <span className={eventRangeClassName}>{floatingDots(start)}{' - '}{end.toLocaleString(DateTime.TIME_SIMPLE)}</span>
        );
    }

    // The event spans the whole current day.
    return (
        <span className={eventRangeClassName}>{t('all-day')}</span>
    );
}

const eventRangeClassName = 'tabular-nums whitespace-nowrap';

function floatingDots(date: DateTime) {
    return (
        <span className='relative'>
            <span className='invisible'>{date.toLocaleString(DateTime.TIME_SIMPLE)}</span>
            <span className='absolute left-1/2 -translate-x-1/2'>...</span>
        </span>
    );
}

type DateRangeDisplayProps = Readonly<{
    from: DateTime;
    to: DateTime;
    className?: string;
    dateClassName?: string;
    hideYearIfCurrent?: boolean;
    hideYearIfSame?: boolean;
}>;

export function DateRangeDisplay({ from, to, className, dateClassName, hideYearIfCurrent, hideYearIfSame }: DateRangeDisplayProps) {
    const { fromFormat, toFormat } = getRangeFormats(from, to, hideYearIfCurrent, hideYearIfSame);

    return (
        <div className={clsx('flex flex-nowrap gap-2', className)}>
            <span className={clsx('tabular-nums whitespace-nowrap', dateClassName)}>{from.toLocaleString(fromFormat)}</span>
            {'-'}
            <span className={clsx('tabular-nums whitespace-nowrap', dateClassName)}>{to.toLocaleString(toFormat)}</span>
        </div>
    );
}

function getRangeFormats(from: DateTime, to: DateTime, hideYearIfCurrent?: boolean, hideYearIfSame?: boolean): { fromFormat: DateTimeFormatOptions, toFormat: DateTimeFormatOptions } {
    if (from.year !== to.year) // eg. 23. 1. 2005 - 25. 2. 2005
        return { fromFormat: DateTime.DATE_MED, toFormat: DateTime.DATE_MED };

    // now from.year === to.year

    const now = DateTime.now();
    if (hideYearIfCurrent && from.year === now.year) {
        const format = { ...DateTime.DATE_MED, year: undefined };
        return { fromFormat: format, toFormat: format };
    }

    if (hideYearIfSame) {
        return {
            fromFormat: { ...DateTime.DATE_MED, year: undefined },
            toFormat: DateTime.DATE_MED,
        };
    }

    return { fromFormat: DateTime.DATE_MED, toFormat: DateTime.DATE_MED };
}

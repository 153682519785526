import { useEffect, useMemo, useState } from 'react';
import UnauthorizedRoutes from ':frontend/router/UnauthorizedRoutes';
import Loading from ':frontend/pages/Loading';
import { routesFE } from ':utils/routes';
import { Visibility } from ':frontend/types/notifications';
import { SubscriptionEndedErrorModal } from './components/orders/SubscriptionErrorModal';
import { ErrorType, type SubscriptionInvalidError } from './types/Subscription';
import { useLocation, useSearchParams } from 'react-router-dom';
import DevBar from ':frontend/components/dev/DevBar';
import { localeToLanguage } from './types/i18n';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { UserProvider, useUser } from './context/UserProvider';
import AuthorizedRoutes from './router/AuthorizedRoutes';
import { useAuth } from ':frontend/context/AuthProvider';
import { AlertDisplay } from './components/notifications/AlertDisplay';
import NewVersionNotification, { useNewVersionNotification } from './components/notifications/NewVersionNotification';
import { trpc } from './context/TrpcProvider';
import { addCurrency, addTaxRate, CurrencyFE, TaxRateFE } from ':utils/money';
import type { TeamMemberRole } from ':utils/entity/team';
import { getI18nLocale } from ':utils/i18n';
import { Layout } from './components/Layout';
import Cookies from 'js-cookie';

const displayDevBar = import.meta.env.DEV;

export default function App() {
    const { state: authState } = useAuth();
    const { i18n } = useTranslation();
    const lang = localeToLanguage(getI18nLocale(i18n));
    const [ searchParams ] = useSearchParams();

    useEffect(() => {
        const ref = searchParams.get('ref');
        if (ref !== null)
            Cookies.set('ref', ref, { expires: 90 });
    }, [ searchParams ]);

    useEffect(() => {
        if (!authState.isAuthorized)
            Sentry.setUser(null);

    }, [ authState.isAuthorized ]);

    const currencies = trpc.money.getCurrencies.useQuery();
    const taxRates = trpc.money.getTaxRates.useQuery();

    const isMoneyLoaded = useMemo(() => {
        if (!currencies.data || !taxRates.data)
            return false;

        currencies.data.map(CurrencyFE.fromServer).forEach(addCurrency);
        taxRates.data.map(TaxRateFE.fromServer).forEach(addTaxRate);

        return true;
    }, [ currencies.data, taxRates.data ]);

    return (
        <div id='inner-root' lang={lang}>
            {!isMoneyLoaded ? (
                <Loading />
            ) : (<>
                <AlertDisplay visibility={authState.isAuthorized ? Visibility.Authorized : Visibility.Unauthorized} />
                {authState.isAuthorized ? (
                    <AppAuthorized role={authState.role} />
                ) : (<>
                    {authState.isFetchingNewToken ? (
                        <Loading />
                    ) : (
                        <UnauthorizedRoutes />
                    )}
                </>)}
            </>)}
            {displayDevBar && (
                <DevBar />
            )}
        </div>
    );
}

type AppAuthorizedProps = Readonly<{
    role: TeamMemberRole;
}>;

function AppAuthorized({ role }: AppAuthorizedProps) {
    const isShowNewVersion = useNewVersionNotification();

    return (
        <UserProvider role={role}>
            <NewVersionNotification isShow={isShowNewVersion} />
            <SubscriptionEndedControl />
            <Layout>
                <AuthorizedRoutes />
            </Layout>
        </UserProvider>
    );
}

function SubscriptionEndedControl() {
    const { subscription } = useUser();
    const location = useLocation();

    const [ subscriptionInvalidError, setSubscriptionInvalidError ] = useState<SubscriptionInvalidError>();
    useEffect(() => {
        if (subscription.isPending)
            setSubscriptionInvalidError({ type: ErrorType.PlanPending });
        else if (!subscription.isActive)
            setSubscriptionInvalidError({ type: ErrorType.PlanEnded });
        else
            setSubscriptionInvalidError(undefined);
    }, [ subscription ]);

    return (
        <SubscriptionEndedErrorModal error={location.pathname !== routesFE.settings.resolve({ key: 'subscription' }) ? subscriptionInvalidError : undefined} />
    );
}

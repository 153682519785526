const commonProps = { xmlns: 'http://www.w3.org/2000/svg', fill: 'none' };

const sizes = {
    xs: 14,
    sm: 16,
    md: 18,
    lg: 24,
} as const;

export type PredefinedIconSize = keyof typeof sizes;

export type IconProps = Readonly<{
    size?: PredefinedIconSize | number;
    className?: string;
}>;

export function getCommonProps({ size, className }: IconProps, defaultSize: number = 18): { width: number, height: number, viewBox?: string, className?: string } {
    const finalSize: number = typeof size === 'string'
        ? sizes[size]
        : size ?? defaultSize;

    return { ...commonProps, viewBox: `0 0 ${defaultSize} ${defaultSize}`, width: finalSize, height: finalSize, className };
}

export const pathProps = {
    stroke: { stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 1.5, vectorEffect: 'non-scaling-stroke' },
    strokeScaling: { stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 1.5 },
    fill: { fill: 'currentColor', vectorEffect: 'non-scaling-stroke' },
    fillScaling: { fill: 'currentColor', vectorEffect: 'non-scaling-stroke' },
    strokeFill: { fill: 'currentColor', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: 1.5, vectorEffect: 'non-scaling-stroke' },
    miterlimit: { stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: 1.5, vectorEffect: 'non-scaling-stroke' },
} as const;

export type IconType = (props: IconProps) => JSX.Element;

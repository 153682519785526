import { useCallback, useMemo, useState, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { localizer, NavigateDirection } from './utils/common';
import { type DateTime } from 'luxon';
import type { CalendarEvent } from ':frontend/types/calendar/Calendar';
import { Table } from ':frontend/components/common';
import { MoneyDisplay } from ':components/custom';
import { ClientIconLink } from ':frontend/components/client/ClientIconLink';
import type { TFunction } from 'i18next';
import { EventStateBadge } from ':frontend/components/event/EventStateBadge';
import { Button, Form } from ':components/shadcn';
import { type Signal, useSignal, computed } from '@preact/signals-react';
import { useNavigate } from 'react-router-dom';
import { routesFE } from ':utils/routes';
import { createActionState } from ':frontend/hooks';
import { EventPaymentStateBadge } from ':frontend/components/event/EventPaymentStateBadge';
import FilterRow, { useFilters, useFiltersApply, type UseFiltersControl } from ':frontend/components/common/filters/FilterRow';
import { CalendarEventStateFilter } from ':frontend/components/common/filters/EventStateFilter';
import createEventParticipantFilter from ':frontend/components/common/filters/EventParticipantFilter';
import { type ClientInfoFE } from ':frontend/types/Client';
import type { ViewObject } from './Views';
import { DayLongFormat, EventRangeFormat } from ':frontend/components/common';
import { toMaster, useMaster, useUser } from ':frontend/context/UserProvider';
import { TeamMemberBadge } from ':frontend/components/team/TeamMemberBadge';
import { TeamMemberRole } from ':utils/entity/team';
import type { PreselectEventOrder } from ':frontend/components/orders/useEventOrder';
import { EventNotesTooltip } from ':frontend/components/event/EventsTable';
import { DEFAULT_CALENDAR_COLOR } from './eventStyle';

type EventClickFunction = (event: CalendarEvent, e: React.SyntheticEvent<HTMLElement>) => void;

type AgendaProps = Readonly<{
    date: DateTime;
    events: CalendarEvent[];
    onSelectEvent?: EventClickFunction;
    clients: ClientInfoFE[];
}>;

function Agenda({ date, events, onSelectEvent, clients }: AgendaProps) {
    const { t } = useTranslation('components', { keyPrefix: 'calendar' });
    const checkedSignal = useSignal<AllCheckedEvents>({});

    const { startDay, endDay, range } = useMemo(() => {
        const startDay = localizer.startOf(date, 'month');
        const endDay = localizer.endOf(date, 'month');
        const range = localizer.range(startDay, endDay, 'day');

        return { startDay, endDay, range };
    }, [ date ]);

    const filters = useMemo(() => [
        CalendarEventStateFilter,
        createEventParticipantFilter(clients),
    ], [ clients ]);

    const filtersControl = useFilters(filters);
    const applyFilters = useFiltersApply(filtersControl);

    const agendaEvents = useMemo(() => {
        const filtered = events
            .filter(event => localizer.inRangeDay(event, startDay, endDay))
            .filter(applyFilters);

        filtered.sort((a, b) => +a.start - +b.start);

        return filtered;
    }, [ events, startDay, endDay, applyFilters ]);

    return (
        <div className='h-full flex-1 flex flex-col'>
            <AgendaToolbar checkedSignal={checkedSignal} filtersControl={filtersControl} />

            <div className='flex-1 flex flex-col overflow-y-auto'>
                {agendaEvents.length === 0 ? (
                    <div className='text-center text-xl py-12'>
                        {t('noEventsInRange')}
                    </div>
                ) : (
                    <Table noOuterLines>
                        <Table.Body>
                            {range.map(day => (
                                <AgendaDay
                                    key={+day}
                                    day={day}
                                    agendaEvents={agendaEvents}
                                    onSelectEvent={onSelectEvent}
                                    checkedSignal={checkedSignal}
                                />
                            ))}
                        </Table.Body>
                    </Table>
                )}
            </div>
        </div>
    );
}

type AgendaToolbarProps = Readonly<{
    checkedSignal: Signal<AllCheckedEvents>;
    filtersControl: UseFiltersControl;
}>;

function AgendaToolbar({ checkedSignal, filtersControl }: AgendaToolbarProps) {
    const { t } = useTranslation('components', { keyPrefix: 'calendar' });
    const isMasterOrFreelancer = !!toMaster(useUser());

    const checkedIds = computed(
        () => Object.values(checkedSignal.value)
            .filter((dayEvents): dayEvents is DayCheckedEvents => !!dayEvents)
            .flatMap(
                dayEvents => Object.entries(dayEvents)
                    .filter(([ , checked ]) => checked)
                    .map(([ id ]) => id),
            ),
    );

    const navigate = useNavigate();

    function invoiceSelected() {
        navigate(routesFE.directSale.event, { state: createActionState<PreselectEventOrder>('preselectEventOrder', {
            eventIds: checkedIds.value,
        }) });
    }

    return (
        <div className='min-h-12 border-b border-secondary-100 px-2 py-1 flex items-start bg-white'>
            <FilterRow control={filtersControl} className='max-md:w-full' rowSelectClassName='max-md:flex-col-reverse max-md:w-full max-md:items-start' />

            <div className='md:grow max-md:hidden' />

            {isMasterOrFreelancer && (<>
                <div className='max-md:hidden'>
                    <Button className='tabular-nums' disabled={checkedIds.value.length === 0} onClick={invoiceSelected}>
                        {t('invoice-selected-button')}
                        {checkedIds.value.length > 0 && ` (${checkedIds.value.length})`}
                    </Button>
                </div>

                {checkedIds.value.length > 0 && (
                    <div className='md:hidden fixed z-50 bottom-0 left-0 right-0 px-4 py-6 border-t border-primary bg-primary-50 flex justify-end'>
                        <Button className='tabular-nums' disabled={checkedIds.value.length === 0} onClick={invoiceSelected}>
                            {t('invoice-selected-button')}
                            {checkedIds.value.length > 0 && ` (${checkedIds.value.length})`}
                        </Button>
                    </div>
                )}
            </>)}
        </div>
    );
}

type AgendaDayProps = Readonly<{
    day: DateTime;
    agendaEvents: CalendarEvent[];
    onSelectEvent?: EventClickFunction;
    checkedSignal: Signal<AllCheckedEvents>;
}>;

function AgendaDay({ day, agendaEvents, onSelectEvent, checkedSignal }: AgendaDayProps) {
    const dayEvents = useMemo(() => agendaEvents.filter(eventInDayComparator(day)), [ agendaEvents, day ]);
    const { checkedEvents, checkEvents } = useCheckedEvents(checkedSignal, +day);
    const { checked, isCheckingDisabled } = useMemo(() => {
        const billableEvents = dayEvents.filter(event => event.isBillable);
        const checked: boolean | 'indeterminate' = billableEvents.some(event => checkedEvents[event.id])
            ? billableEvents.every(event => checkedEvents[event.id])
                ? true
                : 'indeterminate'
            : false;

        return {
            checked,
            isCheckingDisabled: billableEvents.length === 0,
        };
    }, [ dayEvents, checkedEvents ]);

    return (<>
        {dayEvents.length > 0 && (
            <Table.Row className='bg-white'>
                <Table.Col xs='fit' className='w-8 !pr-0'>
                    {!isCheckingDisabled && (
                        <Form.Threebox checked={checked} onCheckedChange={value => checkEvents(dayEvents, !!value)} />
                    )}
                </Table.Col>
                <Table.Col colSpan={8}><DayLongFormat day={day} /></Table.Col>
            </Table.Row>
        )}
        {dayEvents.map(event => (
            <EventRow key={event.id} event={event} day={day} onSelectEvent={onSelectEvent} isChecked={!!checkedEvents[event.id]} checkEvents={checkEvents} />
        ))}
    </>);
}

/**
 * The day has to be aligned to the start of a day.
 */
function eventInDayComparator(day: DateTime) {
    const range = { start: day, end: day };
    return (event: CalendarEvent) => localizer.inEventRange(event, range, true);
}

type EventRowProps = Readonly<{
    event: CalendarEvent;
    day: DateTime;
    onSelectEvent?: EventClickFunction;
    isChecked: boolean;
    checkEvents: CheckEventsFunction;
}>;

function EventRow({ event, day, onSelectEvent, isChecked, checkEvents }: EventRowProps) {
    const { t } = useTranslation('components', { keyPrefix: 'calendar' });
    const [ isCollapsed, setIsCollapsed ] = useState(true);
    const userContext = useUser();
    const isMaster = userContext.role === TeamMemberRole.master;
    const isMasterOrFreelancer = !!toMaster(userContext);

    const { isNotesEnabled } = useMaster().teamSettings;

    return (
        <Table.Row className='[&_td]:py-2 leading-5 select-none cursor-pointer' onClick={e => onSelectEvent?.(event, e)}>
            <Table.Col xs='fit' className='!pr-0 !pt-[10px] align-top select-none cursor-default' onClick={e => e.stopPropagation()}>
                {isMasterOrFreelancer && event.isBillable && (
                    <Form.Checkbox checked={isChecked} onCheckedChange={value => checkEvents(event, value)} />
                )}
            </Table.Col>
            <Table.Col className='text-center align-top'>
                <EventRangeFormat start={event.start} end={event.end} currentDayStart={day} />
            </Table.Col>
            <Table.Col className='select-none cursor-default truncate max-w-lg' onClick={e => e.stopPropagation()}>
                {eventParticipants(event, isCollapsed, setIsCollapsed, t)}
            </Table.Col>
            <Table.Col className='align-top'>
                <div className='flex items-center gap-2 max-w-lg'>
                    {calendarBadge(event)}
                    {/*
                        FIXME This is not ideal. In order for the truncating to work, we would need to set some global maximal width for the whole agenda.
                        This width should depend on the expanded/collapsed state of both the main menu and sidebar.
                    */}
                    <span className='truncate'>{event.title}</span>
                </div>
            </Table.Col>
            <Table.Col className='align-top text-right'>
                {event.resource.type === 'event' && event.resource.totalPrice && (
                    <MoneyDisplay money={event.resource.totalPrice} />
                )}
            </Table.Col>
            <Table.Col className='align-top'>
                {event.resource.type === 'event' && (
                    <EventStateBadge event={event.resource.event} />
                )}
            </Table.Col>
            <Table.Col className='align-top'>
                {event.resource.type === 'event' && (
                    <EventPaymentStateBadge event={event.resource.event} />
                )}
            </Table.Col>

            {isNotesEnabled && (
                <Table.Col xs='fit' className='align-top leading-none select-none cursor-default' onClick={e => e.stopPropagation()}>
                    {event.resource.type === 'event' && !!event.resource.event.notes && (
                        <EventNotesTooltip event={event.resource.event} />
                    )}
                </Table.Col>
            )}

            {isMaster && (
                <Table.Col xs='fit'>
                    {event.resource.type === 'event' && (
                        <TeamMemberBadge appUserId={event.resource.event.ownerId} />
                    )}
                </Table.Col>
            )}
        </Table.Row>
    );
}

function eventParticipants(event: CalendarEvent, isCollapsed: boolean, setIsCollapsed: (newValue: boolean) => void, t: TFunction): ReactNode {
    const inner = eventParticipantsInner(event, isCollapsed);
    if (inner.length === 0)
        return null;

    const totalParticipants = event.resource.type === 'draft' ? 0 : event.resource.event.guests.length;

    return (
        <div className='flex flex-col gap-2'>
            <div className='flex items-center gap-2'>
                {inner[0]}
                {totalParticipants > 1 && (
                    <span className='whitespace-nowrap text-secondary-600 select-none cursor-pointer hover:underline' onClick={() => setIsCollapsed(!isCollapsed)}>
                        {isCollapsed ? t('showMore', { count: totalParticipants - 1 }) : t('showLess')}
                    </span>
                )}
            </div>
            {inner.slice(1)}
        </div>
    );
}

function eventParticipantsInner(event: CalendarEvent, isCollapsed: boolean): ReactNode[] {
    if (event.resource.type === 'event') {
        return getFirstNoneOrAll(event.resource.event.guests, isCollapsed)
            .map(participant => (
                <ClientIconLink key={participant.client.id} client={participant.client} />
            ));
    }

    if (event.resource.type === 'google') {
        return getFirstNoneOrAll(event.resource.event.guests, isCollapsed)
            .map(participant => (
                <span key={participant}>{participant}</span>
            ));
    }

    return [];
}

function getFirstNoneOrAll<T>(array: T[], onlyFirst: boolean): T[] {
    return !onlyFirst
        ? array
        : array.length > 0
            ? [ array[0] ]
            : [];
}

function calendarBadge(event: CalendarEvent): ReactNode {
    const resource = event.resource;
    if (resource.type === 'draft')
        return null;

    const color = resource.calendar?.color ?? DEFAULT_CALENDAR_COLOR;

    return (
        <div className='shrink-0 size-3 rounded-xs' style={{ backgroundColor: `#${color}` }} />
    );
}

// The logic here is a little bit complicated due to performance reasons. If we kept the state in the Agenda component, each check or uncheck would cause the whole component to rerender. That means all event rows. However, rendering the events is actually quite expensive - there are dates and participants and a lot of formatting. So we keep the state in the days and then pass it up with a signal. This way, we need to rerender only one day at a time.

/** Indexed by the timestamp of the start of the day. */
type AllCheckedEvents = Record<number, DayCheckedEvents | undefined>;

type CheckEventsFunction = (events: CalendarEvent | CalendarEvent[], value: boolean) => void;
/** Indexed by event id. */
type DayCheckedEvents = Record<string, boolean | undefined>;

function useCheckedEvents(checkedSignal: Signal<AllCheckedEvents>, dayId: number) {
    const [ checkedEvents, setCheckedEvents ] = useState<DayCheckedEvents>({});

    const checkEvents = useCallback((events: CalendarEvent | CalendarEvent[], value: boolean) => {
        setCheckedEvents(oldState => {
            const newState = updateCheckedEvents(oldState, events, value);
            checkedSignal.value = updateAllCheckedEvents(checkedSignal.peek(), dayId, newState);
            return newState;
        });
    }, [ checkedSignal, dayId ]);

    return { checkedEvents, checkEvents };
}

function updateCheckedEvents(oldState: DayCheckedEvents, events: CalendarEvent | CalendarEvent[], value: boolean): DayCheckedEvents {
    const newState = { ...oldState };
    const eventsArray = Array.isArray(events) ? events : [ events ];

    for (const event of eventsArray) {
        if (event.isBillable)
            newState[event.id] = value;
    }

    return newState;
}

function updateAllCheckedEvents(allCheckedEvents: AllCheckedEvents, dayId: number, checkedEvents: DayCheckedEvents) {
    const newState = { ...allCheckedEvents };
    newState[dayId] = checkedEvents;

    return newState;
}

function navigateTo(date: DateTime, direction: NavigateDirection) {
    switch (direction) {
    case NavigateDirection.Prev:
        return localizer.add(date, -1, 'month');
    case NavigateDirection.Next:
        return localizer.add(date, 1, 'month');
    default:
        return date;
    }
}

function getRange(date: DateTime) {
    const start = localizer.startOf(date, 'month');
    const end = localizer.endOf(date, 'month');
    return localizer.range(start, end);
}

export const agendaViewObject: ViewObject = {
    component: Agenda,
    navigateTo,
    getRange,
};

import { useMemo } from 'react';
import { masterComponent, useUser } from ':frontend/context/UserProvider';
import { Select } from ':components/shadcn';
import { type TeamMemberFE } from ':frontend/types/Team';
import { useTranslation } from 'react-i18next';
import { getPersonName } from ':utils/entity/person';

type TeamMemberSelectProps = {
    value: TeamMemberFE | undefined;
    onChange: (newTeamMember: TeamMemberFE) => void;
};

export const TeamMemberSelect = masterComponent(({ value, onChange }: TeamMemberSelectProps) => {
    const { t } = useTranslation('common', { keyPrefix: 'select' });
    const { teamMembers } = useUser();
    const options = useMemo(() => teamMembers.other.map(memberToOption), [ teamMembers ]);

    return (
        <Select
            value={value && memberToOption(value)}
            onChange={newValue => newValue && onChange(newValue.value)}
            options={options}
            placeholder={t('team-member-placeholder')}
        />
    );
});

type TeamMemberOption = {
    label: string;
    value: TeamMemberFE;
};

function memberToOption(member: TeamMemberFE): TeamMemberOption {
    return {
        label: getPersonName(member),
        value: member,
    };
}

import type { GoogleCalendar } from ':frontend/types/calendar/Google';
import { useUser } from ':frontend/context/UserProvider';
import { TeamMemberRole } from ':utils/entity/team';
import { TeamMemberCalendarsFilter } from './TeamMemberCalendarsFilter';
import { GoogleIntegrationBadge } from '../integrations/GoogleIntegration';
import type { TeamMemberFE } from ':frontend/types/Team';
import { GoogleCalendarsFilter } from './GoogleCalendarsFilter';
import { isGoogleCalendarEnabled } from ':frontend/types/AppUser';

export type CalendarsFilterProps = Readonly<{
    disabledMemberIds: Set<string>;
    toggleMember: (member: TeamMemberFE, newValue: boolean) => void;
    calendars?: GoogleCalendar[];
    activeIds: Set<string>;
    toggleCalendar: (calendar: GoogleCalendar, newValue: boolean) => void;
}>;

export function CalendarsFilter({ disabledMemberIds, toggleMember, calendars, activeIds, toggleCalendar }: CalendarsFilterProps) {
    const userContext = useUser();
    const { appUser, role } = userContext;
    const isMaster = role === TeamMemberRole.master;

    if (isMaster) 
        return <TeamMemberCalendarsFilter disabledIds={disabledMemberIds} toggleMember={toggleMember} />;
    
    if (!isGoogleCalendarEnabled(appUser, role))
        return <GoogleIntegrationBadge />;

    if (calendars)
        return <GoogleCalendarsFilter calendars={calendars} activeIds={activeIds} toggleCalendar={toggleCalendar} />;

    return null;
}

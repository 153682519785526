
import { cn } from './utils';

type SkeletonProps = Readonly<{
    className?: string;
    height?: string | number;
}>;

export function Skeleton({ className, height }: SkeletonProps) {
    return (
        <div
            className={cn('w-full relative overflow-hidden rounded bg-[#ebebeb] select-none', className)}
            style={{ height }}
            aria-live='polite'
            aria-busy='true'
        >
            <div className='absolute top-0 left-0 right-0 h-full bg-no-repeat -translate-x-full bg-[linear-gradient(90deg,#ebebeb_0%,#f5f5f5_50%,#ebebeb_100%)] animate-skeleton' />
        </div>
    );
}

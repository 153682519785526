import { Button, Form, Modal } from ':components/shadcn';
import { ProductDisplay, ProductDirectSaleSkeleton } from ':components/store/product/ProductCard';
import { CreateProductCard } from ':frontend/components/product/CreateProductCard';
import { trpc } from ':frontend/context/TrpcProvider';
import { Query } from ':frontend/utils/common';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { type UseProductOrderDispatch, type UseProductOrderState, useProductOrder } from ':frontend/components/orders/useProductOrder';
import type { ClientInfoFE } from ':frontend/types/Client';
import { useNavigate } from 'react-router-dom';
import { CheckoutModalInner, type CheckoutOutput } from ':frontend/components/orders/checkout/CheckoutModalInner';
import { ProductOrderItemFE } from ':frontend/types/orders/ProductOrderItem';
import { routesFE } from ':utils/routes';
import { ProductOrderForm } from ':frontend/components/orders/ProductOrderForm';
import { useClients } from ':frontend/hooks';
import { productsWithoutPricing, type ProductOutput } from ':utils/entity/product';
import { getRandomSkeletonArray } from ':utils/math';
import { InfoCard } from ':frontend/components/settings/InfoCard';
import { productSwitchIcons, ProductSwitchType, sortProductsBySwitchType } from ':frontend/components/orders/OrdersStatsDisplay';
import { cn } from ':components/shadcn/utils';
import type { ProductStats } from ':utils/entity/order';
import { CartShoppingIcon } from ':components/icons/basic';

export function NewProductOrderTab() {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.product' });
    const [ query, setQuery ] = useState('');
    const [ activeSort, setActiveSort ] = useState<ProductSwitchType>();

    const products = trpc.product.getProducts.useQuery().data;
    const productsStats = trpc.product.getProductsStats.useQuery().data;
    const { clients, addClients } = useClients();

    const sortCache = useMemo(() => {
        if (!productsStats)
            return;

        const output = {} as Record<ProductSwitchType, (a: ProductOutput, b: ProductOutput) => number>;
        for (const type of availableSorts)
            output[type] = createSortFunction(productsStats, type);
        return output;
    }, [ productsStats ]);

    const filteredProducts = useMemo(() => {
        if (!products || !sortCache)
            return;

        let output = products.filter(product => !productsWithoutPricing.includes(product.type));

        if (query) {
            const filterQuery = new Query(...query.split(' '));

            // Computing the queries every single time probably isn't the most efficient way, but it should be ok.
            output = products.filter(product => {
                const productQuery = new Query(...product.title.split(' '));
                return productQuery.match(filterQuery);
            });
        }

        if (activeSort)
            output.sort(sortCache[activeSort]);

        return output;
    }, [ query, activeSort, products, sortCache ]);

    const { state, dispatch } = useProductOrder();

    return (
        <div className='fl-main-scroller px-4'>
            <div className='max-w-[910px] w-full mx-auto pt-6 pb-24 space-y-6'>
                <h1 className='font-semibold text-2xl leading-7'>{t('title')}</h1>

                <InfoCard infoKey='productOrder' className='lg:pr-28' />

                <div className='py-4 max-lg:space-y-2 lg:flex lg:items-center lg:gap-4'>
                    <Form.Input
                        size='compact'
                        placeholder={t('query-placeholder')}
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                    />

                    <div className='fl-hide-scrollbar max-lg:overflow-x-auto max-lg:-mx-4 shrink-0 max-lg:px-4'>
                        <div className='flex flex-nowrap items-center gap-2'>
                            {availableSorts.map(type => (
                                <ProductSwitch
                                    key={type}
                                    type={type}
                                    isActive={type === activeSort}
                                    onClick={() => setActiveSort(prev => prev === type ? undefined : type)}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <div className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3'>
                    <ProductsList clients={clients} products={products} filteredProducts={filteredProducts} dispatch={dispatch} />
                </div>
            </div>

            {clients && filteredProducts && (
                <Modal.Root
                    open={state.isModalOpen}
                    onOpenChange={open => !open && !state.checkout && dispatch({ type: 'closeModal' })}
                >
                    <Modal.OuterContent>
                        <NewProductOrderModalInner
                            state={state}
                            dispatch={dispatch}
                            clients={clients}
                            addClients={addClients}
                            products={filteredProducts}
                        />
                    </Modal.OuterContent>
                </Modal.Root>
            )}
        </div>
    );
}

function createSortFunction(productsStats: ProductStats[], type: ProductSwitchType): (a: ProductOutput, b: ProductOutput) => number {
    const sorted = sortProductsBySwitchType(productsStats, type);
    const map = new Map(sorted.map(({ id }, index) => [ id, index ]));
    // Again, not optimal, but who cares.
    return (a, b) => map.get(a.id)! - map.get(b.id)!;
}

const availableSorts = [ ProductSwitchType.mostProfitable, ProductSwitchType.mostSelling, ProductSwitchType.leastProfitable ];

type ProductSwitchProps = {
    type: ProductSwitchType;
    isActive: boolean;
    onClick: () => void;
};

function ProductSwitch({ type, isActive, onClick }: ProductSwitchProps) {
    const { t } = useTranslation('components', { keyPrefix: 'ordersStatsDisplay.productSwitches' });

    const className = cn(
        'shrink-0 h-10 px-4 flex flex-row items-center gap-1 rounded-full',
        isActive ? 'text-[#b36500] bg-[#fff0d8]' : 'text-secondary bg-white',
    );

    // TODO traingle at the bottom

    return (
        <button className={className} onClick={onClick}>
            <span className='flex items-center gap-1'>{productSwitchIcons[type]} {t(type)}</span>
        </button>
    );
}

type ProductsListProps = Readonly<{
    clients?: ClientInfoFE[];
    products?: ProductOutput[];
    filteredProducts?: ProductOutput[];
    dispatch: UseProductOrderDispatch;
}>;

function ProductsList({ clients, products, filteredProducts, dispatch }: ProductsListProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.product' });
    const skeletonArray = useMemo(() => getRandomSkeletonArray(1, 4), []);

    if (!clients || !products || !filteredProducts)
        return (<>{skeletonArray.map(id => <ProductDirectSaleSkeleton key={id} />)}</>);

    if (filteredProducts.length === 0) {
        return (
            <div className='col-span-3 flex flex-col items-center'>
                <span className='text-danger'>
                    {t(products.length === 0 ? 'no-products' : 'no-filtered-products')}
                </span>
                {products.length === 0 && (
                    <CreateProductCard className='mt-10' />
                )}
            </div>
        );
    }

    return  (<>
        {filteredProducts.map(product => (
            <ProductDisplay
                key={product.id}
                product={product}
                bottomRight={
                    <Button variant='outline' size='small' onClick={() => dispatch({ type: 'sellProduct', product })}>
                        <CartShoppingIcon />{t('sell-button')}
                    </Button>
                }
            />
        ))}
    </>);
}

type NewProductOrderModalInnerProps = Readonly<{
    state: UseProductOrderState;
    dispatch: UseProductOrderDispatch;
    products: ProductOutput[];
    clients: ClientInfoFE[];
    addClients: (clients: ClientInfoFE | ClientInfoFE[]) => void;
}>;

function NewProductOrderModalInner({ state, dispatch, products, clients, addClients }: NewProductOrderModalInnerProps) {
    const { t } = useTranslation('pages', { keyPrefix: 'directSale.product.modal' });
    const navigate = useNavigate();

    const checkoutOutput = useCallback((action: CheckoutOutput) => {
        switch (action.type) {
        case 'back':
            dispatch({ type: 'form', operation: 'checkoutBack' });
            break;
        case 'close':
            dispatch({ type: 'closeModal' });
            break;
        case 'finish': {
            const item = action.orders[0].items[0];
            if (item instanceof ProductOrderItemFE && item.guest)
                navigate(routesFE.clients.detail.resolve({ id: item.guest.id, key: 'orders' }));
            else
                dispatch({ type: 'closeModal' });

            break;
        }
        case 'addClients':
            addClients(action.clients);
            break;
        }
    }, [ dispatch, addClients, navigate ]);

    if (state.checkout) {
        return (
            <CheckoutModalInner input={state.checkout} output={checkoutOutput} />
        );
    }

    return (
        <Modal.InnerContent className='max-w-[680px] md:w-[680px] border-0' closeButton={t('cancel-button')}>
            <Modal.Header>
                <Modal.Title className='text-body text-2xl font-semibold'>{t('title')}</Modal.Title>
            </Modal.Header>

            <div className='py-4'>
                <ProductOrderForm products={products} clients={clients} state={state} dispatch={dispatch} />
            </div>

            <Modal.Footer>
                <Button className='w-full' onClick={() => dispatch({ type: 'form', operation: 'checkoutStart' })}>
                    {t('continue-button')}
                </Button>
            </Modal.Footer>
        </Modal.InnerContent>
    );
}

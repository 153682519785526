import { useCallback } from 'react';
import { ClientFE, type ClientInfoFE } from ':frontend/types/Client';
import { getEnumValues } from ':utils/common';
import { useToggle, useUpdating } from ':frontend/hooks';
import { Button, DropdownMenu } from ':components/shadcn';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ClientState } from ':utils/entity/client';
import { trpc } from ':frontend/context/TrpcProvider';
import { ChevronDownIcon } from ':components/icons/basic';
import type { IconType } from ':components/icons/common';
import { cn } from ':components/shadcn/utils';

type ClientStateBadgeProps = Readonly<{
    state: ClientState;
    IconAfter?: IconType;
    className?: string;
}>;

export function ClientStateBadge({ state, IconAfter, className }: ClientStateBadgeProps) {
    const { t } = useTranslation('common', { keyPrefix: 'client.state' });
    const stateClass = stateClasses[state];

    return (
        <span className={clsx(stateClass, 'rounded px-2 py-1 uppercase text-sm font-semibold flex items-center gap-1', className)}>
            {t(state)} {IconAfter && <IconAfter />}
        </span>
    );
}

const stateClasses: Record<ClientState, string> = {
    [ClientState.lead]: 'text-primary bg-primary-50',
    [ClientState.active]: 'text-success bg-success-50',
    [ClientState.inactive]: 'text-secondary bg-secondary-50',
};

type ClientStateEditProps = Readonly<{
    client: ClientInfoFE;
    setClient: (client: ClientFE) => void;
    /** added to the button */
    className?: string;
}>;

export function ClientStateEdit({ client, setClient, className }: ClientStateEditProps) {
    const updateStateMutation = trpc.$client.updateClient.useMutation();

    const syncState = useCallback(async (newValue: ClientState) => {
        if (client.state === newValue)
            return false;

        try {
            const response = await updateStateMutation.mutateAsync({ id: client.id, state: newValue });
            const updatedClient = ClientFE.fromServer(response);
            setClient(updatedClient);
            return true;
        }
        catch {
            return false;
        }
    }, [ client, setClient, updateStateMutation ]);

    const [ state, updateState, isUpdatingState ] = useUpdating(client.state, syncState);
    const [ show, setShow ] = useToggle(false);

    const onOptionClick = useCallback((option: ClientState) => {
        setShow.false();
        updateState(option);
    }, [ setShow, updateState ]);

    return (
        <DropdownMenu.Root open={show} onOpenChange={setShow.value}>
            {/* <DropdownMenu.Root autoClose='outside' open={show} onOpenChange={setShow.toggle}> */}
            <DropdownMenu.Trigger
                asChild
                onClick={setShow.toggle}
                disabled={isUpdatingState}
            >
                <Button variant='transparent' size='exact' className={cn('[&_svg]:size-3', className)}>
                    <ClientStateBadge state={state} IconAfter={ChevronDownIcon} />
                </Button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content className='p-0'>
                {CLIENT_STATE_VALUES.map(option => (
                    <DropdownMenu.Item
                        key={option}
                        onClick={() => onOptionClick(option)}
                        className='flex py-2 cursor-pointer'
                    >
                        <ClientStateBadge state={option} />
                    </DropdownMenu.Item>
                ))}
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    );
}

export const CLIENT_STATE_VALUES: ClientState[] = getEnumValues(ClientState);
